import * as API from "middleware/api";

export const getListFrequentDestinations = (id, filters) =>
    API.executeWithAccessToken("/frequentdestinations.list", { idEnviroment: id, nameSearch: filters });

export const createDestinationPreview = (cbu, recipientEmail, name, validateOnlyCbu, isValidationFromDebCred = false) =>
    API.executeWithAccessToken("/frequentdestinations.create.preview", {
        cbu,
        recipientEmail,
        name,
        validateOnlyCbu,
        isValidationFromDebCred,
    });

export const createDestination = (
    accountNumber,
    cbu,
    idEnvironment,
    idFrequentDestination,
    isOwn,
    localBank,
    name,
    productType,
    recipientDocumentNumber,
    recipientDocumentType,
    recipientEmail,
    recipientName,
    subsidiaryBank,
    otp,
) =>
    API.executeWithAccessToken("/frequentdestinations.create", {
        accountNumber,
        cbu,
        idEnvironment,
        idFrequentDestination,
        isOwn,
        localBank,
        name,
        productType,
        recipientDocumentNumber,
        recipientDocumentType,
        recipientEmail,
        recipientName,
        subsidiaryBank,
        _otp: otp,
    });

export const dowloadFrequentDestinations = () =>
    API.executeWithAccessToken("/frequentDestinations.exportFrequentDestinations");

export const deleteFrequentDestinationPreview = (params) =>
    API.executeWithAccessToken("/frequentdestinations.delete.preview", {
        idFrequentDestination: params.idFrequentDestination,
        _otp: params.otp,
    });

export const deleteFrequentDestinationRequenst = (params) =>
    API.executeWithAccessToken("/frequentdestinations.delete", {
        idFrequentDestination: params.idFrequentDestination,
        _otp: params.otp,
    });

export const modifyFrequentDestinationPre = (id) =>
    API.executeWithAccessToken("/frequentdestinations.modify.pre", { idFrequentDestination: id });

export const modifyFrequentDestinationPreview = (params) =>
    API.executeWithAccessToken("/frequentdestinations.modify.preview", {
        name: params.name,
        email: params.email,
        idFrequentDestination: params.idFrequentDestination,
    });

export const modifyFrequentDestination = (params) =>
    API.executeWithAccessToken("frequentdestinations.modify", {
        idFrequentDestination: params.idFrequentDestination,
        name: params.name,
        email: params.email,
        _otp: params.otp,
    });

export const createFrequentDestinationTransferPre = (params) =>
    API.executeWithAccessToken("frequentdestinations.transfer.create.pre", {
        cbu: params.cbuAlias,
    });
