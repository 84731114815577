/* eslint-disable func-names */
/* eslint-disable max-len */
import dateFormat from "date-fns";
import * as config from "util/config";

export const formatDate = (dateToFormat, format = null) => {
    let date = dateToFormat.replace("T", " ");
    date = new Date(date).getTime();

    if (format === null) {
        return dateFormat(date);
    }
    return dateFormat(date, format);
};

export const getNestedObject = (nestedObj, pathArr) =>
    pathArr.reduce((obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined), nestedObj);

export const getTransactionStatusIcon = (idTransactionStatus) => {
    let result = "";
    switch (idTransactionStatus) {
        case "PENDING":
            result = "pending";
            break;
        case "CANCELLED":
            result = "cancelled";
            break;
        case "SCHEDULED":
            result = "schedule";
            break;
        case "FINISHED":
            result = "check-fill";
            break;
        case "FAILED":
        case "RETURNED":
            result = "alert";
            break;
        case "PROCESSING":
            result = "process";
            break;
        case "DRAFT":
            result = "draft";
            break;
        default:
            result = "process";
    }
    return result;
};

export const retrieveMobileCountryCodes = () => {
    const options = config.getArray("country.codes").map((country) => {
        const code = config.get(`cellPhone.code.${country}`);

        return {
            value: code,
            label: code,
            country,
        };
    });
    return options;
};

export const validationRegexIP = new RegExp(
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
);

export const loadScript = (url, callback) => {
    const script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
        script.onreadystatechange = function() {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};

export const getCapType = (features) => {
    let type = "signature";
    if (features && features.length > 0) {
        const feature = features[0];
        const featuresDefaultCap = config.getArray("backend.features.default_cap");
        const featuresMap = featuresDefaultCap?.reduce((acc, str) => {
            const [clave, valor] = str.split(";");
            acc[clave] = valor;
            return acc;
        }, {});
        if (featuresMap) {
            const conf = featuresMap[feature];
            if (conf) {
                type = conf;
            }
        }
    }
    return type;
};
