import React, { Component } from "react";
import { bool, func, instanceOf, number, shape, string, arrayOf } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import Col from "react-bootstrap/lib/Col";

import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import List from "pages/transactions/_components/HistoricList";
import HiddenFilters from "pages/transactions/_components/HiddenFilters";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";

import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import { actions as statusActions } from "reducers/status";
import { selectors as sessionSelectors } from "reducers/session";

import * as i18n from "util/i18n";
import * as configUtils from "util/config";

import { CORPORATE_GROUP_ENVIRONMENT_TYPE } from "constants.js";

const LAST_TRANSACTIONS = "last";
const LAST_UPDATE = "lastUpdate";

class TransactionsList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        filters: shape({
            dateFrom: instanceOf(Date),
            dateTo: instanceOf(Date),
            minAmount: shape({ amount: number, currency: number }).isRequired,
            maxAmount: shape({ amount: number, currency: number }).isRequired,
            pageNumber: number,
            filter: string,
            idActivity: string,
        }),
        isDesktop: bool.isRequired,
        isFirstFetching: bool.isRequired,
        activeEnvironment: shape({ type: string.isRequired }).isRequired,
        history: shape({
            listen: func.isRequired,
        }).isRequired,
        location: shape({}).isRequired,
        activities: arrayOf(shape({})).isRequired,
        fetchingMore: bool.isRequired,
    };

    static defaultProps = {
        filters: null,
    };

    state = {
        defaultFilters: null,
    };

    componentDidMount = () => {
        const { filters, dispatch, location, activities } = this.props;
        const limitMonths = configUtils.getInteger("frontend.historyQuery.allowedPeriod.Months", 6);

        if (filters === null) {
            dispatch(transactionsActions.changeStatusType(null));
            dispatch(transactionsActions.setFilters(null));
            const dateFrom = new Date();
            dateFrom.setMonth(dateFrom.getMonth() - limitMonths);
            const dateTo = new Date();
            const defaultFilters = {
                orderBy: "submit_date_time DESC",
                dateFrom,
                dateTo,
                dateType: LAST_UPDATE,
                pageNumber: 1,
                filter: LAST_TRANSACTIONS,
            };
            this.setState({ defaultFilters });
            dispatch(transactionsActions.loadListRequest(defaultFilters, false, false, true));
        }
        if (!activities) {
            dispatch(transactionsActions.loadTransactionActivitiesRequest());
        }
        if (!localStorage.getItem("flow") || localStorage.getItem("flow") !== "/transactions/list") {
            localStorage.setItem("flow", JSON.stringify("/transactions/list"));
        }
        dispatch(transactionsActions.saveTransactionRoute(location?.pathname));
        dispatch(statusActions.saveLastHref(location));
        window.scrollTo(0, 1);
    };

    componentWillUnmount() {
        this.listenHistory();
    }

    handleFilterButtonsClick = (orderBy = "submit_date_time DESC") => {
        const { filters } = this.props;

        let orderFilters = { filters };
        orderFilters = {
            ...filters,
            orderBy,
            pageNumber: 1,
        };
        const { dispatch } = this.props;
        dispatch(transactionsActions.loadListRequest(orderFilters, false, false, false));
    };

    validUrl = (pathName) => {
        const pattern1 = /.*\/[A-Za-z0-9]*\/ticket/;
        const pattern2 = /.*\/(confirm|pending($|\/)|cancelled|failed|action|transaction($|\/)|modify($|\/))/;

        return pattern1.test(pathName) || pattern2.test(pathName);
    };

    listenHistory = () => {
        const { history, dispatch } = this.props;

        const unlisten = history.listen((location) => {
            const nextPath = location?.pathname;

            if (!this.validUrl(nextPath)) {
                dispatch(transactionsActions.setFilters(null));
            }
            unlisten();
        });
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop/"));
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0 align-center">{i18n.get("transactions.list.title")}</h1>
        </div>
    );

    render() {
        const { fetching, isDesktop, dispatch, isFirstFetching, activeEnvironment, fetchingMore } = this.props;
        const { defaultFilters } = this.state;
        const isCorporateGroup = activeEnvironment.type === CORPORATE_GROUP_ENVIRONMENT_TYPE;

        return (
            <>
                <Notification scopeToShow="transactions" />
                <div className={classNames("pl-0 pr-1", { "admin-detail-head": isDesktop })}>
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile transactions-list-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        accessibilityTextId="transactions.list.title"
                    />
                    {isDesktop && (
                        <>
                            <div className="view-title mb-45">
                                <h1>{i18n.get("transactions.list.title")}</h1>
                            </div>
                            <Container className="container--layaut flex-grow container-white">
                                <Col className="col col-12 pb-2">
                                    <HiddenFilters
                                        dispatch={dispatch}
                                        isDesktop={isDesktop}
                                        fetching={fetching}
                                        getList={this.handleFilterButtonsClick}
                                    />
                                </Col>
                            </Container>
                        </>
                    )}
                </div>
                <PageLoading className="screen-loader" loading={fetching || (isDesktop && fetchingMore)}>
                    <MainContainer showLoader={isFirstFetching} showChildrenWithLoader>
                        <div className="above-the-fold">
                            <List
                                isDesktop={isDesktop}
                                showSearchButton
                                defaultFilters={defaultFilters}
                                showEnvironment={isCorporateGroup}
                                itemsAreClickeable={!isCorporateGroup}
                            />
                        </div>
                    </MainContainer>
                </PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isFirstFetching: transactionsSelectors.getFirstFetch(state),
    fetching: transactionsSelectors.getFetching(state),
    filters: transactionsSelectors.getFilters(state),
    pageNumber: transactionsSelectors.getPageNumber(state),
    hasMoreData: transactionsSelectors.getHasMoreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    activities: transactionsSelectors.getActivities(state),
    fetchingMore: transactionsSelectors.isFetchingMore(state),
});

export default withRouter(connect(mapStateToProps)(TransactionsList));
