import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import Credential from "pages/_components/fields/credentials/Credential";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Hint from "pages/_components/hints/Hint";
import Notification from "pages/_components/Notification";
import { bool, func, shape } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { compose } from "redux";
import * as i18nUtils from "util/i18n";
import Yup from "yup";

import DrawerModal from "pages/_components/drawer/DrawerModal";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import { actions as ecomexActions, selectors as ecomexSelectors } from "reducers/ecomex";
import { selectors as ecomexIframeSelectors, actions as ecomexIframeActions } from "reducers/ecomexIframe";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";
import isTokenActive from "util/token";
import EcomexTransactionData from "./EcomexTransactionData";
import EcomexDeleteTransaction from "./EcomexDeleteTransaction";

const FORM_ID = "transferSummary";
class EcomexConfirmTransaction extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        fetchingDownload: bool.isRequired,
        summary: shape({}).isRequired,
    };

    state = {
        showDrawer: false,
        snackBarShownOnce: false,
        contentDrawer: "",
    };

    componentDidMount() {
        const { summary, dispatch } = this.props;
        if (!summary?.idTransaction && localStorage.getItem("flow")) {
            dispatch(push(`${JSON.parse(localStorage.getItem("flow"))}`));
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        const { snackBarShownOnce } = this.state;

        if (snackBarShownOnce) {
            dispatch(push(`/pendingTransaction/list`));
        } else {
            dispatch(
                notificationActions.showNotification(
                    i18nUtils.get("ecomex.transaction.handleBack.notification"),
                    "warning",
                    ["ecomexConfirm"],
                    "",
                    false,
                ),
            );
        }

        this.setState((prevState) => ({ ...prevState, snackBarShownOnce: true }));
    };

    handleDownloadRequestPDF = () => {
        const { dispatch, summary } = this.props;
        const sequence = summary?.transactionData?.sequencePDF;
        const requestNumber = summary?.transactionData?.requestIdEcomex;

        dispatch(ecomexIframeActions.downloadEcomexTransaction(sequence, requestNumber));
        // dispatch(ecomexIframeActions.downloadEcomexTransaction("169", "224"));
    };

    closeDrawer = () => {
        this.setState((prevState) => ({ ...prevState, showDrawer: false, contentDrawer: "" }));
    };

    render() {
        const { isDesktop, fetching, fetchingDownload } = this.props;
        const { showDrawer, contentDrawer } = this.state;

        const continueButton = (
            <Col className="col-12 confirmation__content-button" lg={12} md={12} sm={12}>
                <Row>
                    <div className="admin-content-center">
                        <Button
                            image="images/arrowRight.svg"
                            imageStyle={isDesktop ? "mr-2" : "mr-1"}
                            type="submit"
                            bsStyle="primary"
                            label="global.confirm"
                            loading={fetching}
                        />
                    </div>
                </Row>
            </Col>
        );

        const deleteButton = (
            <Col className="col-12 confirmation__content-button" lg={12} md={12} sm={12}>
                <Row>
                    <div className="admin-content-center">
                        <Button
                            image="images/trashBlue.svg"
                            imageStyle="mr-2"
                            onClick={() =>
                                this.setState((prevState) => ({
                                    ...prevState,
                                    showDrawer: true,
                                    contentDrawer: <EcomexDeleteTransaction />,
                                }))
                            }
                            label="ecomex.transaction.delete.request"
                            loading={fetching}
                            className="btn-outline btn-secondary"
                        />
                    </div>
                </Row>
            </Col>
        );

        const credentials = (
            <>
                <Row className="my-2">
                    {!isTokenActive(!isDesktop) ? (
                        <div className="admin-content-center">
                            <I18n
                                id="transfer.summary.confirm.label"
                                componentProps={{
                                    className: classNames("font-light", {
                                        "f-size-65": !isDesktop,
                                        "f-size-55": isDesktop,
                                    }),
                                }}
                            />
                        </div>
                    ) : (
                        <div className="admin-content-center pb-3">
                            <I18n
                                id="confirmation.withoutotp.text"
                                component="p"
                                componentProps={{ className: "confirmation__text-without-otp" }}
                            />
                        </div>
                    )}
                </Row>
                <Row>
                    <div className="admin-content-center">
                        <Field
                            name="otp"
                            type="otp"
                            idForm={FORM_ID}
                            component={Credential}
                            placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                        />
                    </div>
                </Row>
            </>
        );
        return (
            <>
                {showDrawer && (
                    <DrawerModal
                        content={contentDrawer}
                        width="442px"
                        showDrawerRemote={showDrawer}
                        isDesktop={isDesktop}
                        drawerClassName="ecomex-delete-drawer"
                        closeRemotely={() => this.closeDrawer()}
                    />
                )}
                <Notification scopeToShow="ecomexConfirm" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        title={!isDesktop && "menu.ecomex"}
                        centerContentClassName={classNames({
                            "mx-5": !isDesktop,
                        })}
                    />
                    {isDesktop && (
                        <div className="d-flex">
                            <div className="mx-3">
                                <h1>{i18nUtils.get("menu.ecomex")}</h1>
                            </div>
                        </div>
                    )}
                </div>
                <MainContainer>
                    <Form>
                        <Container
                            className={classNames("flex-grow align-items-center confirmation__container")}
                            gridClassName="form-content">
                            {!isDesktop && (
                                <div
                                    className={classNames("admin-content-center", {
                                        "mb-3": !isDesktop,
                                    })}>
                                    <I18n
                                        id="ecomex.summary.checkInfo.label"
                                        component="h2"
                                        componentProps={{ className: classNames({ "f-size-35": !isDesktop }) }}
                                    />
                                </div>
                            )}

                            <Col className="col-12 confirmation__form ecomex__confirmation__form" lg={6} md={9} sm={12}>
                                {isDesktop && (
                                    <div>
                                        <div
                                            className={classNames("admin-content-center", {
                                                "mb-3": !isDesktop,
                                            })}>
                                            <I18n
                                                id="ecomex.summary.checkInfo.label"
                                                component={!isDesktop ? "h2" : "h1"}
                                                componentProps={{ className: classNames({ "f-size-35": !isDesktop }) }}
                                            />
                                        </div>
                                    </div>
                                )}
                                <EcomexTransactionData isDesktop={isDesktop} />
                                <div
                                    className={classNames("admin-content-center", {
                                        "mb-3": !isDesktop,
                                    })}>
                                    <Button
                                        block
                                        label="ecomex.transaction.download.label"
                                        loading={fetchingDownload}
                                        image="images/download.svg"
                                        onClick={() => this.handleDownloadRequestPDF()}
                                        className="btn-outline btn-download"
                                        imageStyle="mr-2"
                                    />
                                </div>
                                <div>
                                    <Hint
                                        classNameGroup="d-flex align-items-center "
                                        classNameHintP="m-0 mx-2"
                                        idMessage="ecomex.transaction.data.disclaimer"
                                        alwaysShowMessage
                                    />
                                </div>
                                {credentials}
                                <Container className="flex-grow align-items-center w-100" gridClassName="form-content">
                                    {continueButton}
                                    {deleteButton}
                                </Container>
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    summary: ecomexSelectors.getSummary(state),
    userId: sessionSelectors.getUser(state).userId,
    envName: sessionSelectors.getActiveEnvironment(state).name,
    fetching: ecomexSelectors.getFetching(state),
    fetchingDownload: ecomexIframeSelectors.getFetchingDownload(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18nUtils.get(`${FORM_ID}.otp.error.required`)),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, summary, userId } = formikBag.props;
            const { transactionData } = summary;
            const { requestIdEcomex } = transactionData;

            const ecomexTransaction = {
                userId,
                idEnvironment: summary?.idEnvironment,
                lang: summary?.lang,
                channel: summary?.channel,
                transactionData: summary?.transactionData,
                _otp: otp,
                idTransaction: summary?.idTransaction,
                ecomexRequest: requestIdEcomex,
                deleting: false,
            };

            dispatch(ecomexActions.sendAcceptOrRejectEcomexTransaction(ecomexTransaction));
        },
    }),
)(EcomexConfirmTransaction);
