/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { bool, shape, string, node, number } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import Button from "pages/_components/Button";
import DateField from "pages/_components/fields/DateField";
import RangeDatePicker from "pages/_components/fields/rangedatepicker/RangeDatePicker";

import { actions as transactionsActions } from "reducers/transactions";

import * as i18n from "util/i18n";
import * as configUtils from "util/config";

const FORM_ID = "transactions.list.filters.period";

class PeriodFilterPendingTransactions extends Component {
    static propTypes = {
        fetching: bool,
        values: shape({}),
        buttonLabel: string,
        cleanFiltersButton: node,
        renderOrder: node,
    };

    static defaultProps = {
        fetching: false,
        values: shape({
            dateFrom: null,
            dateTo: null,
        }),
        buttonLabel: null,
        cleanFiltersButton: null,
        renderOrder: null,
    };

    render() {
        const { fetching, dateFrom, dateTo, buttonLabel, cleanFiltersButton, renderOrder } = this.props;

        const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);
        return (
            <Form autoComplete="off">
                <Col sm={12} md={3} lg={3} className="d-flex f-dir-col mt-35 pt-3 px-3">
                    <Button
                        bsStyle="primary"
                        label={buttonLabel || "product.filters.filter"}
                        loading={fetching}
                        type="submit"
                        className="filter-button m-0"
                    />
                    {cleanFiltersButton}
                </Col>
                <Col sm={12} md={3} lg={3} className="d-flex f-dir-col px-0">
                    {renderOrder}
                </Col>
                {enableRangeDatefield ? (
                    <Col sm={12} md={6} lg={6} className="mt-1">
                        <Field
                            component={RangeDatePicker}
                            idForm={FORM_ID}
                            name="dates"
                            maxDate={new Date()}
                            className="height-48"
                        />
                    </Col>
                ) : (
                    <>
                        <Col sm={12} md={3} lg={3} className="period-filter__periods">
                            <Field
                                idForm={FORM_ID}
                                idField="dateFromPeriodFilter"
                                name="dateFrom"
                                component={DateField}
                                maxDate={dateTo}
                                value={dateFrom}
                                hidePlaceholder
                                selectsStart
                            />
                        </Col>
                        <Col sm={12} md={3} lg={3} className="period-filter__periods">
                            <Field
                                idForm={FORM_ID}
                                idField="dateToPeriodFilter"
                                name="dateTo"
                                component={DateField}
                                minDate={dateFrom}
                                maxDate={new Date()}
                                value={dateTo}
                                hidePlaceholder
                                selectsEnd
                            />
                        </Col>
                    </>
                )}
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: (props) => {
        const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);
        return enableRangeDatefield
            ? {
                  dates: [props.dateFrom || null, props.dateTo || null],
              }
            : {
                  dateFrom: props.dateFrom ? props.dateFrom : null,
                  dateTo: props.dateTo ? props.dateTo : null,
              };
    },
    validationSchema: () =>
        Yup.lazy((values) =>
            Yup.object().shape({
                dateFrom: values.dateTo
                    ? Yup.date()
                          .nullable()
                          .max(values.dateTo, i18n.get("accounts.movements.filters.from.error"))
                    : Yup.date().nullable(),
                dateTo: values.dateFrom
                    ? Yup.date()
                          .nullable()
                          .min(values.dateFrom, i18n.get("accounts.movements.filters.to.error"))
                    : Yup.date().nullable(),
            }),
        ),
    handleSubmit: ({ dates, ...filters }, formikBag) => {
        const {
            dispatch,
            isDesktop,
            onlyPendings,
            pendingDispatch,
            dateType,
            status,
            idActivity,
            cleanSelectedRows,
        } = formikBag.props;
        const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

        if (cleanSelectedRows) {
            cleanSelectedRows();
        }
        if (isDesktop) {
            dispatch(
                transactionsActions.loadListRequest(
                    {
                        ...filters,
                        ...(enableRangeDatefield && {
                            dateFrom: dates[0],
                            dateTo: dates[1],
                        }),
                        dateType,
                        status,
                        idActivity,
                        filter: "creationDate",
                    },
                    onlyPendings,
                    pendingDispatch,
                    formikBag,
                ),
            );
        } else {
            dispatch(
                transactionsActions.setFilters({
                    filter: "period",
                    ...filters,
                    ...(enableRangeDatefield && {
                        dateFrom: dates[0],
                        dateTo: dates[1],
                    }),
                }),
            );
            dispatch(push(`/transactions/list/filters/results`));
        }
    },
})(PeriodFilterPendingTransactions);
