import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as configUtils from "util/config";

import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import Pagination from "pages/_components/pagination/Pagination";

import { selectors as accountsSelectors } from "reducers/accounts";

import * as numUtils from "util/number";
import Card from "pages/_components/Card/Card";
import MovementsEmptyState from "./MovementsEmptyState";

function LastMovementsGrid({
    isDesktop,
    selectedAccount: { currency },
    latestMovements,
    latestMovementsPageNumber,
    latestMovementsTotalPages,
    getStrDate,
    getMovementsNextPage,
}) {
    const enableNewPagination = configUtils.getBoolean("frontend.show.nuevoComponentePaginado.enabled", false);
    return (
        <div className={!isDesktop ? "px-3" : ""}>
            {latestMovements.length > 0 ? (
                <>
                    {latestMovements.map(
                        ({
                            identificador,
                            fechaValor,
                            comprobante,
                            concepto,
                            detalleTransaccion,
                            importe,
                            saldoParcial,
                            signoImporte,
                            signoSdoParcial,
                        }) => (
                            <Card
                                key={identificador}
                                isDesktop={isDesktop}
                                icon={signoImporte === "-" ? "movementArrowLeft.svg" : "movementArrowRight.svg"}
                                title={
                                    <div className="d-flex px-2">
                                        <div>
                                            <p className="card__component-item-title my-0">{getStrDate(fechaValor)}</p>
                                        </div>
                                        <div className="card__component-item-title mx-2">
                                            <p className="my-0">|</p>
                                        </div>
                                        <div className="d-flex">
                                            <I18n
                                                id="accounts.pendingMovements.table.header.voucher"
                                                component="p"
                                                componentProps={{
                                                    className: "card__component-item-title secondary my-0",
                                                }}
                                            />
                                            <p className="card__component-item-title secondary my-0">: {comprobante}</p>
                                        </div>
                                    </div>
                                }
                                content={
                                    <div className="px-2 mt-2">
                                        <div>
                                            <p className="my-0">{concepto}</p>
                                        </div>
                                        <div className="secondary mt-1">
                                            <p className="my-0">{detalleTransaccion}</p>
                                        </div>
                                    </div>
                                }
                                rightContent={
                                    <div className="card__component-item-amount">
                                        <FormattedAmount
                                            currency={currency}
                                            className="data-amount"
                                            quantity={numUtils.parseDoubleWithSymbol(importe, signoImporte)}
                                            medium
                                            notBold
                                        />
                                        <div className="card__component-item-parcial-balance">
                                            <I18n
                                                id="accounts.lastestMovements.table.header.partialBalance"
                                                component="p"
                                                componentProps={{
                                                    className: "card__component-item-parcial-balance-label my-0",
                                                }}
                                                upperCase
                                            />
                                            <FormattedAmount
                                                currency={currency}
                                                className="data-amount ml-1"
                                                quantity={numUtils.parseDoubleWithSymbol(saldoParcial, signoSdoParcial)}
                                                medium
                                                notBold
                                            />
                                        </div>
                                    </div>
                                }
                            />
                        ),
                    )}
                    <div className={`d-flex justify-content-end ${!enableNewPagination && "mr-3 my-3"}`}>
                        <Pagination
                            totalPages={latestMovementsTotalPages}
                            pageNumber={latestMovementsPageNumber}
                            action={getMovementsNextPage}
                        />
                    </div>
                </>
            ) : (
                <MovementsEmptyState />
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: accountsSelectors.getSelectedAccount(state),
    latestMovements: accountsSelectors.getLatestMovements(state),
    latestMovementsPageNumber: accountsSelectors.getLatestMovementsPageNumber(state),
    latestMovementsTotalPages: accountsSelectors.getLatestMovementsTotalPages(state),
});

export default compose(connect(mapStateToProps))(LastMovementsGrid);
