export const NO_TRANSITION = "noTransition";
export const FAILED = "FAILED";
export const FINISHED = "FINISHED";
export const CANCELLED = "CANCELLED";

export const ACTIVITIES_WITHOUT_PREVIEW_STEP = [
    "administration.medium.modify.signature.send",
    "administration.restrictions.user.delete.send",
    "account.checks",
    "accounts.opening",
    "transfers.internal.send",
    "transfers.internal.tad.send",
    "transfers.thirdParties.send",
    "transfers.thirdParties.tad.send",
    "transfers.toContact.send",
    "transfers.toContact.tad.send",
    "debin.incoming.accept.send",
    "debinCVU.incoming.accept.send",
    "credin.sentRequest.send",
    "credinCVU.sentRequest.send",
    "endorse.echeq.activity",
    "pay.multiline.send",
    "payments.makePayment",
    "emit.echeq.activity",
    "cesion.echeq.activity",
    "accept.return.echeqs",
    "load.debin.recurrency",
    "change.status.debin.recurrency",
    "delete.paymentAFIP",
    "pay.paymentAFIP",
    "administration.users.update",
    "scheduled.transactions.cancel",
    "scheduled.transactions.cancel",
    "deposits.confirmDeposit",
    "negotiate.echeq.activity",
    "emit.massive.echeqs.send",
    "transfer.multiple.send",
    "ecomex.send.accept.transaction",
];

export const MAX_FAILED_TIMES = 6;

export const TIME_IN_MILLIS_TO_REFRESH = 15000;

export const MAX_LAST_LOGIN_TO_SHOW = 5;

export const CORPORATE_GROUP_ENVIRONMENT_TYPE = "corporateGroup";

export const SALARY_PAYMENT_ID_FORM = "salaryPayment";

// para ocultar los permisos de funcionalidades con variable en OFF
export const FUNCTIONALITY_PERMISSION = {
    "massive.emit.echeq": "MassiveEcheq",
    "transfer.multiple": "MultipleTransfers",
};

export const REGION_USA = "US";
export const REGION_REST_OF_LATAM = "LATAM";
export const REGION_ARG = "AR";
export const REGION_CHI = "CL";
export const REGION_CAN = "CA";
export const REGION_UY = "UY";
export const REGION_COL = "CO";
export const REGION_MEX = "MX";
export const REGION_PER = "PE";
export const REGION_ECU = "EC";
export const REGION_PAR = "PY";
export const REGION_PAN = "PA";
export const REGION_CR = "CR";
export const AVAILABLE_REGIONS = [
    REGION_USA,
    REGION_REST_OF_LATAM,
    REGION_ARG,
    REGION_CHI,
    REGION_CAN,
    REGION_UY,
    REGION_COL,
    REGION_MEX,
    REGION_PER,
    REGION_ECU,
    REGION_PAR,
    REGION_PAN,
    REGION_CR,
];

export const DOCUMENT_TYPES = {
    1: "DNI",
    5: "CUIT",
    8: "CUIL",
    9: "CDI",
};

export const ENVIRONMENT_TYPE = {
    RETAIL: "retail",
    CORPORATE: "corporate",
};

export const RESCUE_TYPE = {
    BY_AMOUNT: "BY_AMOUNT",
    BY_SHAREACCOUNT: "BY_SHAREACCOUNT",
    TOTAL: "TOTAL",
};

export const CURRENCY_CODE = {
    ARS: "0",
    USD: "2",
};

export const CURRENCY_I18N_CODE = {
    ARS: "01",
    USD: "02",
};

export const ACCOUNT_TYPE = {
    CA_ARS: "01",
    CA_USD: "02",
    CC_ARS: "03",
    CC_USD: "04",
};

export const LEVEL_INVESTOR_PROFILE = {
    BAJO: 0,
    MEDIO: 1,
    ALTO: 2,
};

export const DATETIME_FORMAT_WITH_T = "YYYY-MM-DD'T'hh:mm:ss";