/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import { shape, bool, string } from "prop-types";
import classNames from "classnames";

import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import FieldError from "pages/_components/fields/FieldError";
import EnvironmentTag from "pages/_components/EnvironmentTag";
import { connect } from "react-redux";

class Enviroments extends Component {
    static propTypes = {
        environments: shape([]).isRequired,
        field: shape({}).isRequired,
        form: shape({}).isRequired,
        activeEnvironment: shape({}),
        fromSetDefaultEnvironmentPage: bool,
        fromLoginSelectEnvironment: bool,
        userData: shape({}),
        legendTextID: string,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        activeEnvironment: {},
        fromSetDefaultEnvironmentPage: false,
        fromLoginSelectEnvironment: false,
        userData: {},
        legendTextID: "settings.changeEnvironment",
    };

    state = {
        environmentSelected: false,
    };

    componentDidMount() {
        const { environmentSelected } = this.state;
        const { environments, fromSetDefaultEnvironmentPage } = this.props;
        if (!environmentSelected && environments.length > 0 && !fromSetDefaultEnvironmentPage) {
            this.handleClick(environments[0][0]);
            this.setState({ environmentSelected: true });
        }
    }

    handleClick = (value) => {
        const { field, form } = this.props;
        form.setFieldValue(field.name, value);
    };

    listEnvironments = (environemnts) => {
        const {
            activeEnvironment,
            form: { values },
            fromSetDefaultEnvironmentPage,
            userData,
            isDesktop,
        } = this.props;

        const environmentToCheck = fromSetDefaultEnvironmentPage ? userData.idDefaultEnvironment : activeEnvironment.id;

        return environemnts.map(([id, environment]) => {
            const allowedToAccess = environment.allowedToAccess.toString() === "true";
            const disabled = environment?.disabled || !allowedToAccess;

            return (
                <>
                    <div className={`c-control c-control--radio ${disabled ? "env-disabled" : ""}`} key={id}>
                        <input
                            disabled={disabled}
                            defaultChecked={Number(id) === environmentToCheck}
                            id={`environment-${id}`}
                            type="radio"
                            name="environment"
                            className="c-control-input"
                            onClick={() => this.handleClick(id)}
                        />
                        <label
                            className={`c-control-label c-control-label--environment ${
                                values.environment === id ? "selected" : ""
                            } ${fromSetDefaultEnvironmentPage && !isDesktop ? "big-font" : ""} ${!isDesktop &&
                                "label--environment-mobile"} w-100`}
                            htmlFor={`environment-${id}`}
                            style={fromSetDefaultEnvironmentPage && !isDesktop ? { justifyContent: "flex-start" } : {}}>
                            <EnvironmentTag
                                isBlocked={disabled}
                                color={disabled ? "gray" : ""}
                                name={environment.name}
                                type={environment.environmentType}
                                imageSize={fromSetDefaultEnvironmentPage && !isDesktop ? "2rem" : ""}
                            />
                        </label>
                    </div>
                </>
            );
        });
    };

    render() {
        const {
            environments,
            legendTextID,
            form: { errors },
            field,
            fromSetDefaultEnvironmentPage,
            fromLoginSelectEnvironment,
        } = this.props;

        return (
            <fieldset className={classNames("form-group", { "has-error": errors[field.name] })}>
                {legendTextID && (
                    <div>
                        <I18n id={legendTextID} component="legend" componentProps={{ className: "visually-hidden" }} />
                    </div>
                )}

                <div className="selection-list environments">
                    {this.listEnvironments(
                        environments
                            .filter((a) => !a[1].environmentType.localeCompare("retail"))
                            .filter(
                                ([_, env]) =>
                                    (!env?.disabled &&
                                        env.allowedToAccess.toString() === "true" &&
                                        fromSetDefaultEnvironmentPage) ||
                                    fromLoginSelectEnvironment,
                            )
                            .sort((a, b) => a[1].name.localeCompare(b[1].name)),
                    )}
                    {this.listEnvironments(
                        environments
                            .filter((a) => a[1].environmentType.localeCompare("retail"))
                            .filter(
                                ([_, env]) =>
                                    (!env?.disabled &&
                                        env.allowedToAccess.toString() === "true" &&
                                        fromSetDefaultEnvironmentPage) ||
                                    fromLoginSelectEnvironment,
                            )
                            .sort((a, b) => a[1].name.localeCompare(b[1].name)),
                    )}
                </div>
                {errors[field.name] && (
                    <div className="text-center">
                        <FieldError error={errors[field.name]} />
                    </div>
                )}
            </fieldset>
        );
    }
}

export default connect()(Enviroments);
