import { call, put, takeLatest } from "redux-saga/effects";
import { types } from "reducers/ecomexIframe";
import { delay } from "redux-saga";
import * as ecomexIframe from "middleware/ecomexIframe";
import * as i18n from "util/i18n";

import { downloadPdf } from "util/download";
import { actions as notificationActions } from "reducers/notification";

const sagas = [
    takeLatest(types.SHOW_SNACKBAR, showNot),
    takeLatest(types.ENTER_REQUESTS_PRE, enterRequestsRedirect),
    takeLatest(types.REQUEST_HISTORY_PRE, requestHistory),
    takeLatest(types.FETCH_ID_TRANSACTION_REQUEST, fetchIdTransaction),
    takeLatest(types.DOWNLOAD_ECOMEX_TRANSACTION_PDF_REQUEST, downloadTransactionDetail),
];

export default sagas;

function* showNot() {
    yield put(
        notificationActions.showNotification("tienes transacciones pendientes de token", "error", [
            "pages.ecomexIframe",
        ]),
    );
    yield put({ type: types.OK_NOT });
}

function* enterRequestsRedirect() {
    const response = yield call(ecomexIframe.loadEnterRequestCredentials);

    if (response.data?.code === "COR020W") {
        yield put({ type: types.ENTER_REQUESTS_FAILURE, error: response.data.data.message });
        yield put(notificationActions.showNotification(response.data.data.message, "error", ["desktop"]));
    } else {
        const { token, payload } = response.data.data;

        yield put({
            type: types.ENTER_REQUESTS_SUCCESS,
            token,
            payload,
        });
    }
}

function* requestHistory() {
    const response = yield call(ecomexIframe.loadRequestHistoryCredentials);

    if (response.data?.code === "COR020W") {
        yield put({ type: types.ENTER_REQUESTS_FAILURE, error: response.data.data.message });
        yield put(notificationActions.showNotification(response.data.data.message, "error", ["desktop"]));
    } else {
        const { token, payload } = response.data.data;

        yield put({
            type: types.REQUEST_HISTORY_SUCCESS,
            token,
            payload,
        });
    }
}

function* fetchIdTransaction(payload) {
    const { token } = payload;
    const response = yield call(ecomexIframe.fetchIdTransaction, token);

    if (response.data?.code === "COR020W") {
        yield put({ type: types.FETCH_ID_TRANSACTION_EMPTY, error: response.data.data.message });
        yield put(notificationActions.showNotification(response.data.data.message, "error", ["desktop"]));
    } else {
        const { idTransaction, error } = response.data.data;

        if (idTransaction) {
            yield put({
                type: types.FETCH_ID_TRANSACTION_SUCCESS,
                idTransaction,
            });
        } else if (error) {
            yield put({
                type: types.FETCH_ID_TRANSACTION_EMPTY,
                error,
            });
        }
    }
}

function* downloadTransactionDetail({ sequence, requestNumber }) {
    const response = yield call(ecomexIframe.downloadEcomexTransactionPDF, sequence, requestNumber);
    if (response?.type === "W") {
        yield put({ type: types.DOWNLOAD_ECOMEX_TRANSACTION_PDF_FAILURE, error: response?.data.data.message });
        yield put(
            notificationActions.showNotification(i18n.get("ecomex.transaction.download.error"), "error", [
                "ecomexConfirm",
            ]),
        );
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("global.successDownload"), "success", ["ecomexConfirm"]),
        );
        yield call(delay, 3000);

        const { content, fileName, clientVisible } = response?.data?.data;

        if (clientVisible === 1) {
            downloadPdf(fileName, content);
            yield put({ type: types.DOWNLOAD_ECOMEX_TRANSACTION_PDF_SUCCESS });
        } else if (clientVisible === 0) {
            yield put({ type: types.DOWNLOAD_ECOMEX_TRANSACTION_PDF_FAILURE, error: response.data?.data.message });
            yield put(
                notificationActions.showNotification(i18n.get("ecomex.transaction.download.error"), "error", [
                    "ecomexConfirm",
                ]),
            );
        }
    }
}
