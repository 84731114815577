/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { bool, func, shape, string } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux/actions";

import FormattedAmount from "pages/_components/FormattedAmount";
import InfoTag from "pages/_components/InfoTag";
import ContextMenu from "pages/_components/ContextMenu";
import FormattedDate from "pages/_components/FormattedDate";

import * as i18nUtils from "util/i18n";
import * as accountUtils from "util/accounts";
import * as thirdPaymentUtils from "util/thirdPayment";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as accountsSelectors, actions as accountsActions } from "reducers/accounts";
import { actions as thirdPaymentActions } from "reducers/thirdPayment";
import { selectors as transactionLinesSelectors } from "reducers/form/transactionLines";
import { actions as formActions } from "reducers/form";

import Card from "pages/_components/Card/Card";
import I18n from "pages/_components/I18n";
import CardContainer from "pages/_components/Card/CardContainer";
import { statuses } from "util/thirdPayment";
import ThirdPaymentCancelData from "./ThirdPaymentCancelData";

class ThirdPaymentItem extends Component {
    static propTypes = {
        accounts: shape({}),
        loggedUser: shape({
            userId: string.isRequired,
        }).isRequired,
        request: shape({}).isRequired,
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
    };

    static defaultProps = {
        accounts: null,
    };

    componentDidMount() {
        const { dispatch, loggedUser, accounts } = this.props;

        if (!accounts.length > 0) {
            dispatch(accountsActions.listAccounts(loggedUser.userId));
        }
    }

    cancelTransaction = () => {
        const { request, dispatch, accounts } = this.props;
        const { transaction, transactionOrder, idTransactionStatus } = request;
        let accountName = null;
        const numberAccount = transaction.data.debitAccount;
        if (accounts.length > 0) {
            const loggedUserAccount = accounts.find((account) => numberAccount.includes(account?.idProduct));
            accountName = accountUtils.getIncomingDebinAccountName(loggedUserAccount);
        }

        const cancelTransactionData = (
            <ThirdPaymentCancelData
                transactionOrder={transactionOrder}
                code={transaction.transactionStatusName || thirdPaymentUtils.getCodeMsg(idTransactionStatus)}
                summary={transaction}
                accountName={accountName}
            />
        );

        dispatch(push("/transactions/cancel"));
        dispatch(
            formActions.cancelTransactionData({
                idTransaction: transaction?.idTransaction,
                content: cancelTransactionData,
                title: "activities.thirdpay.table.menu.decline",
            }),
        );
        dispatch(formActions.cancelTransactionPre({ idActivity: "pay.multiline.send", idForm: "" }));
        dispatch(thirdPaymentActions.loadConfirmThirdPayment({ ...transaction, action: "cancel" }));
    };

    getItemsContextMenuMobile = () => {
        const { request, dispatch, accounts } = this.props;
        const { transaction } = request;
        const { data, idTransaction, idTransactionStatus, transactionStatusName } = transaction;
        const { transactionOrder } = request;
        const { debitAccount, amount } = data;

        let accountName = null;
        const numberAccount = debitAccount;
        if (accounts.length > 0) {
            const loggedUserAccount = accounts.find((account) => numberAccount.includes(account?.idProduct));
            accountName = accountUtils.getIncomingDebinAccountName(loggedUserAccount);
        }

        const items = [];
        if (idTransactionStatus === "PENDING") {
            items.push({
                label: "thirdPayments.decline",
                onClick: () => {
                    dispatch(thirdPaymentActions.setPageToGoBack("/payThirdParties/list"));
                    dispatch(thirdPaymentActions.getTransaction(idTransaction));
                    this.cancelTransaction();
                },
            });
        }
        items.push({
            label: "thirdPayments.download.detail",
            onClick: () => {
                dispatch(
                    thirdPaymentActions.downloadFileTransactionItem(
                        "pdf",
                        data.typePayment,
                        data.file[0].fileName,
                        idTransaction,
                        data.reference,
                        data.dateProcess,
                        transactionOrder,
                        amount?.currency,
                        idTransactionStatus,
                        accountName,
                        transactionStatusName,
                    ),
                );
            },
        });

        return items;
    };

    render() {
        const { request, isDesktop, dispatch, accounts } = this.props;
        const { transaction } = request;
        const { data, idTransaction, idTransactionStatus, transactionStatusName, totalAmount } = transaction;
        const { debitAccount, processedFileData, reference, dateProcess, isExport, amount, cancelledFromMenu } = data;
        const { currency } = amount;

        const firmaRechazada = statuses.find((status) => status.value === "CANCELLED");

        let accountName = null;
        if (!isExport) {
            if (accounts.length > 0) {
                const loggedUserAccount = accounts.find((account) => debitAccount.includes(account?.idProduct));
                if (isDesktop) {
                    accountName = accountUtils.getIncomingDebinAccountName(loggedUserAccount);
                } else {
                    accountName = `${accountUtils.getAccountNumber(loggedUserAccount?.cbu)} ${
                        loggedUserAccount?.productType
                    } ${i18nUtils.get(`currency.label.${loggedUserAccount.currency}`)}`;
                }
            }
        } else {
            accountName = debitAccount;
        }

        if (isDesktop) {
            return (
                <>
                    <Card
                        className="mb-0"
                        title={
                            <div className="thirdpayment__card-title">
                                {data.dateProcess !== undefined && (
                                    <div className="thirdpayment__date">
                                        <I18n
                                            id="payments.thirdPayment.table.dateProcess"
                                            componentProps={{ className: "thirdpayment__date-info" }}
                                        />
                                        <FormattedDate date={dateProcess} dateFormat="dd/MM/yyyy" />
                                    </div>
                                )}
                                <div className="thirdpayment__reference">
                                    <I18n
                                        id="payments.thirdPayment.table.reference"
                                        componentProps={{ className: "thirdpayment__reference-info" }}
                                    />
                                    <span className="thirdpayment__reference-data">{reference}</span>
                                </div>
                            </div>
                        }
                        content={
                            <div className="thirdpayment__card-content">
                                <div className="thirdpayment__id">
                                    <I18n
                                        id="payments.thirdPayment.table.id"
                                        componentProps={{ className: "thirdpayment__id-info" }}
                                    />
                                    <span className="thirdpayment__id-data">
                                        {idTransaction.substring(0, 8).toUpperCase()}
                                    </span>
                                </div>
                                {processedFileData && processedFileData.validLines && (
                                    <div className="thirdpayment__order">
                                        <I18n
                                            id="payments.thirdPayment.table.linesNumber"
                                            componentProps={{ className: "thirdpayment__order-info" }}
                                        />
                                        <span className="thirdpayment__order-data">{processedFileData.validLines}</span>
                                    </div>
                                )}
                            </div>
                        }
                        bottom={
                            <div className="thirdpayment__card-bottom">
                                <I18n
                                    id="payments.thirdPayment.table.account"
                                    componentProps={{ className: "thirdpayment__debit-info" }}
                                />
                                <span>{accountName}</span>
                            </div>
                        }
                        rightContent={
                            <div className="thirdpayment__card-right">
                                <div className="thirdpayment__balance">
                                    <FormattedAmount
                                        currency={currency}
                                        quantity={totalAmount}
                                        className="thirdpayment__balance-currency"
                                    />
                                    {!cancelledFromMenu && idTransactionStatus === "CANCELLED" ? (
                                        <InfoTag
                                            type="info"
                                            message={firmaRechazada.label}
                                            tagBackground={thirdPaymentUtils.statusTagColor(
                                                transactionStatusName?.toUpperCase() || idTransactionStatus,
                                            )}
                                            tagClass="align-self-flex-end"
                                            moreStyles={{
                                                color: "#072229",
                                            }}
                                        />
                                    ) : (
                                        <InfoTag
                                            type="info"
                                            message={
                                                transactionStatusName ||
                                                thirdPaymentUtils.getCodeMsg(idTransactionStatus)
                                            }
                                            tagBackground={thirdPaymentUtils.statusTagColor(
                                                transactionStatusName?.toUpperCase() || idTransactionStatus,
                                            )}
                                            tagClass="align-self-flex-end"
                                            moreStyles={{
                                                color: "#072229",
                                            }}
                                        />
                                    )}
                                </div>
                                <div className="thirdpayment__menu">
                                    {idTransactionStatus === "PENDING" ? (
                                        <ContextMenu
                                            isDesktop={isDesktop}
                                            items={[
                                                {
                                                    label: "thirdPayments.decline",
                                                    onClick: () => {
                                                        dispatch(
                                                            thirdPaymentActions.setPageToGoBack(
                                                                "/payThirdParties/list",
                                                            ),
                                                        );
                                                        dispatch(thirdPaymentActions.getTransaction(idTransaction));
                                                        this.cancelTransaction();
                                                    },
                                                },
                                            ]}
                                        />
                                    ) : (
                                        <div className="thirdpayment__hidden-menu">&nbsp;</div>
                                    )}
                                </div>
                            </div>
                        }
                    />
                </>
            );
        }
        return (
            <CardContainer className="mt-0">
                <Card
                    className="mt-0 mx-1"
                    title={
                        <div className="thirdpayment__card-title">
                            <div className="space-between w-100">
                                <div>
                                    <div className="thirdpayment__reference">
                                        <I18n
                                            id="payments.thirdPayment.table.reference"
                                            componentProps={{ className: "thirdpayment__reference-info" }}
                                        />
                                        <span className="thirdpayment__reference-data">{reference}</span>
                                    </div>
                                    <div className="thirdpayment__id">
                                        <I18n
                                            id="payments.thirdPayment.table.id"
                                            componentProps={{ className: "thirdpayment__id-info" }}
                                        />
                                        <span className="thirdpayment__id-data">
                                            {idTransaction.substring(0, 8).toUpperCase()}
                                        </span>
                                    </div>
                                </div>
                                <div className="thirdpayment__menu">
                                    <ContextMenu isDesktop={isDesktop} items={this.getItemsContextMenuMobile()} />
                                </div>
                            </div>
                            <hr className="mr-0" />
                        </div>
                    }
                    content={
                        <div className="thirdpayment__card-content">
                            <div className="thirdpayment__state">
                                <I18n
                                    id="payments.thirdPayment.table.status"
                                    componentProps={{ className: "thirdpayment__state-info" }}
                                />
                                <InfoTag
                                    type="info"
                                    message={
                                        !cancelledFromMenu && idTransactionStatus === "CANCELLED"
                                            ? firmaRechazada.label
                                            : transactionStatusName || thirdPaymentUtils.getCodeMsg(idTransactionStatus)
                                    }
                                    tagBackground={thirdPaymentUtils.statusTagColor(
                                        transactionStatusName?.toUpperCase() || idTransactionStatus,
                                    )}
                                    tagClass="align-self-flex-end"
                                    moreStyles={{
                                        color: "#072229",
                                    }}
                                />
                            </div>
                            {data.dateProcess !== undefined && (
                                <div className="thirdpayment__date">
                                    <I18n
                                        id="payments.thirdPayment.table.dateProcess"
                                        componentProps={{ className: "thirdpayment__date-info" }}
                                    />
                                    <FormattedDate date={dateProcess} dateFormat="dd/MM/yyyy" />
                                </div>
                            )}
                            {processedFileData && processedFileData.validLines && (
                                <div className="thirdpayment__order">
                                    <I18n
                                        id="payments.thirdPayment.table.linesNumber"
                                        componentProps={{ className: "thirdpayment__order-info" }}
                                    />
                                    <span className="thirdpayment__order-data">{processedFileData.validLines}</span>
                                </div>
                            )}
                            <div className="thirdpayment__debit">
                                <I18n
                                    id="payments.thirdPayment.table.account"
                                    componentProps={{ className: "thirdpayment__debit-info" }}
                                />
                                <span className="thirdpayment__debit-data">{accountName}</span>
                            </div>
                        </div>
                    }
                    rightContent={
                        <div className="thirdpayment__card-right">
                            <div className="thirdpayment__balance">
                                <FormattedAmount
                                    currency={currency}
                                    quantity={totalAmount}
                                    className="thirdpayment__balance-currency"
                                />
                            </div>
                        </div>
                    }
                />
            </CardContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    accounts: accountsSelectors.getAccounts(state),
    loggedUser: sessionSelectors.getUser(state),
    transactionLines: transactionLinesSelectors.getTransactionLines(state),
});

export default connect(mapStateToProps)(ThirdPaymentItem);
