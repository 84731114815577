import React from 'react'
import { bool, number, shape } from 'prop-types';
import { Field } from 'formik';
import { Col } from 'react-bootstrap';
import classNames from 'classnames';

import Checkbox from "pages/_components/Checkbox";
import Date from "pages/_components/fields/DateField";
import RangeDatePicker from 'pages/_components/fields/rangedatepicker/RangeDatePicker';

import { getLimitDate } from 'util/date';
import * as configUtils from "util/config";

const FORM_ID = "echeqs";

function EcheqsDatesSelectors({
    values,
    isNotFullsize,
    isSmallDesktop,
    isDesktop,
    width
}) {
    const enableRangeDatefield =  configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

    return (
        <>
            {!isDesktop && !enableRangeDatefield && (
                <Col xs={12}>
                    <Field
                        component={Checkbox}
                        idForm={FORM_ID}
                        name="emitedCheck"
                        isDesktop={isDesktop}
                    />
                </Col>
            )}
            <Col
                xs={12}
                md={width < 1020 && !enableRangeDatefield ? 10 : 6}
                lg={isNotFullsize && !enableRangeDatefield ? 5 : 4}
                className={classNames("d-flex align-items-center justify-content-start", {
                    "pl-3 pr-2": isDesktop,
                    "mb-3": !isDesktop
                })}>
                {isDesktop && !enableRangeDatefield && (
                    <div className="pt-45 mt-1">
                        <Field
                            component={Checkbox}
                            hideLabel
                            idForm={FORM_ID}
                            name="emitedCheck"
                            isDesktop={isDesktop}
                        />
                    </div>
                )}
                {
                    enableRangeDatefield ?
                        <div className='w-100 mb-1'>
                            <Field
                                idField="createdDates"
                                component={RangeDatePicker}
                                idForm={FORM_ID}
                                name="createdDates"
                                minDate={getLimitDate(-3650).toDate()}
                                maxDate={getLimitDate(3650).toDate()}
                            />
                        </div>
                    :
                        <>
                            <div className={classNames("d-flex align-items-center flex", { "mr-3": isDesktop })}>
                                <Field
                                    idField="createdFrom"
                                    formikValueFirst
                                    component={Date}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="createdFrom"
                                    customKey={!isDesktop && "echeqs.from.label"}
                                    disabled={!values.emitedCheck}
                                    isDisabled={!values.emitedCheck}
                                    maxDate={values.createdTo}
                                    minDate={getLimitDate(-3650)}
                                    formGroupClassName={classNames("mr-1 w-100", {
                                        "mb-0": isDesktop,
                                        "field-disabled": !values.emitedCheck,
                                    })}
                                />
                            </div>
                            <div
                                className={classNames("d-flex align-items-center flex", {
                                    "mr-3": isSmallDesktop,
                                    "mr-1": isDesktop,
                                })}>
                                <Field
                                    idField="createdTo"
                                    formikValueFirst
                                    component={Date}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    disabled={!values.emitedCheck}
                                    isDisabled={!values.emitedCheck}
                                    name="createdTo"
                                    customKey={!isDesktop && "echeqs.to.label"}
                                    minDate={values.createdFrom}
                                    maxDate={getLimitDate(3650)}
                                    formGroupClassName={classNames("w-100", {
                                        "mb-0": isDesktop,
                                        "field-disabled": !values.emitedCheck,
                                    })}
                                />
                            </div>
                        </>
                }
            </Col>
            {!isDesktop && !enableRangeDatefield && (
                <Col xs={12}>
                    <Field
                        component={Checkbox}
                        idForm={FORM_ID}
                        name="payedCheck"
                        label="tableHeader.paydate"
                        isDesktop={isDesktop}
                    />
                </Col>
            )}
            <Col
                xs={12}
                md={width < 1020 && !enableRangeDatefield ? 10 : 6}
                lg={isNotFullsize && !enableRangeDatefield ? 5 : 4}
                className={classNames("d-flex align-items-center justify-content-start", {
                    "pl-3 pr-2": isDesktop,
                    "mb-3": !isDesktop
                })}>
                {isDesktop && !enableRangeDatefield && (
                    <div className="pt-45 mt-1">
                        <Field
                            component={Checkbox}
                            hideLabel
                            idForm={FORM_ID}
                            name="payedCheck"
                            isDesktop={isDesktop}
                        />
                    </div>
                )}
                {
                    enableRangeDatefield ?
                        <div className='w-100 mb-1'>
                            <Field
                                idField="payedDates"
                                component={RangeDatePicker}
                                idForm={FORM_ID}
                                name="payedDates"
                                minDate={getLimitDate(-3650).toDate()}
                                maxDate={getLimitDate(3650).toDate()}
                            />
                        </div>
                    :
                        <>
                            <div className={classNames("d-flex align-items-center flex", { "mr-3": isDesktop })}>
                                <Field
                                    idField="payedFrom"
                                    formikValueFirst
                                    component={Date}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="payedFrom"
                                    customKey={!isDesktop && "echeqs.from.label"}
                                    disabled={!values.payedCheck}
                                    isDisabled={!values.payedCheck || values.action === "to_custody" || values.action === "request_discount"}
                                    minDate={getLimitDate(-3650)}
                                    maxDate={values.payedTo}
                                    formGroupClassName={classNames("mr-1 w-100", {
                                        "mb-0": isDesktop,
                                        "field-disabled": !values.payedCheck,
                                    })}
                                />
                            </div>
                            <div className={classNames("d-flex align-items-center flex", { "mr-3": isDesktop })}>
                                <Field
                                    idField="payedTo"
                                    formikValueFirst
                                    component={Date}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    disabled={!values.payedCheck}
                                    isDisabled={!values.payedCheck || values.action === "to_deposit"}
                                    name="payedTo"
                                    customKey={!isDesktop && "echeqs.to.label"}
                                    minDate={values.payedFrom}
                                    maxDate={getLimitDate(3650)}
                                    formGroupClassName={classNames("mr-1 w-100 mr-md-0", {
                                        "mb-0": isDesktop,
                                        "field-disabled": !values.payedCheck,
                                    })}
                                />
                            </div>
                        </>
                }
            </Col>
        </>
    );
}

EcheqsDatesSelectors.propTypes = {
    values: shape({}).isRequired,
    isNotFullsize: bool.isRequired,
    isSmallDesktop: bool.isRequired,
    isDesktop: bool.isRequired,
    width: number.isRequired
}

export default EcheqsDatesSelectors