import { arrayOf, bool, func, number, shape, string } from "prop-types";
import Table from "rc-table";
import React, { Component } from "react";
import Measure from "react-measure";

import * as configUtils from "util/config";
import * as i18n from "util/i18n";

import Button from "pages/_components/Button";
import Circle from "pages/_components/Circle";
import Container from "pages/_components/Container";
import FormattedAmount from "pages/_components/FormattedAmount";
import GeneralMsg from "pages/_components/GeneralMsg";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import { Col } from "react-bootstrap";
import { getCapType } from "../../../util/general";

const FORM_ID = "administration.signatures";
class SignaturesSchemes extends Component {
    static propTypes = {
        actions: shape({
            exportListRequest: func,
            listSignaturesSchemesRequest: func,
            loadMoreRequest: func,
        }).isRequired,
        activeEnvironment: shape({
            administrationScheme: string,
            type: string,
        }).isRequired,
        currentPage: number,
        fetching: bool,
        fetchingExport: bool,
        fetchingMoreSignaturesSchemes: bool,
        routerActions: shape({
            push: func,
        }).isRequired,
        signaturesSchemes: arrayOf(
            shape({
                capList: arrayOf(shape({ maximum: number })),
                idSignature: number,
                signatureType: string,
            }),
        ).isRequired,
        totalPages: number.isRequired,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        currentPage: 1,
        fetching: false,
        fetchingExport: false,
        fetchingMoreSignaturesSchemes: false,
    };

    state = {
        tableHeight: 0,
    };

    componentDidMount() {
        const { actions } = this.props;

        actions.listSignaturesSchemesRequest();
    }

    getSigners = (administrationScheme, signatureScheme) => {
        const { groupsMap } = signatureScheme;

        if (administrationScheme === "medium") {
            return <Circle value={groupsMap.A} bgColor="rgb(112, 112, 112)" smallSize />;
        }

        const signCircles = Object.keys(groupsMap).reduce((result, key) => {
            const arr = new Array(groupsMap[key]).fill(key.toString());
            const newObjects = arr.map((level) => (
                <Circle value={level} bgColor="rgb(112, 112, 112)" smallSize paddingTop="0.1rem" />
            ));
            return [...result, ...newObjects];
        }, []);

        return <>{signCircles}</>;
    };

    generateTableColumns = () => {
        const {
            activeEnvironment: { administrationScheme },
        } = this.props;

        const result = [
            {
                align: administrationScheme === "advanced" ? "left" : "center",
                key: "signers",
                dataIndex: "signers",
                title:
                    administrationScheme === "advanced"
                        ? i18n.get(`${FORM_ID}.export.signatureGroup.advanced`)
                        : i18n.get(`${FORM_ID}.export.signatureGroup`),
                width: "10%",
            },
            {
                align: "center",
                key: "signatureDispatch",
                dataIndex: "signatureDispatch",
                title: i18n.get(`${FORM_ID}.list.liberating`),
                width: "10%",
            },
            {
                align: "center",
                key: "transactions",
                dataIndex: "transactions",
                title: i18n.get(`${FORM_ID}.list.transactions`),
                width: "10%",
            },
            {
                align: "right",
                key: "amount",
                dataIndex: "amount",
                title: i18n.get("administration.channels.topAmount"),
                width: "22.5%",
            },
            {
                align: "center",
                key: "functionalGroups",
                dataIndex: "functionalGroups",
                title: i18n.get("administration.permissions.functionalities"),
                width: "22.5%",
            },
            {
                align: "right",
                key: "specificProducts",
                dataIndex: "specificProducts",
                title: i18n.get("administration.permissions.productList"),
                width: "25%",
            },
        ].filter((c) => !!c);

        return [...result];
    };

    handleClickDownload = (format) => {
        const { actions } = this.props;

        actions.exportListRequest(format);
    };

    handleMoreDataClick = () => {
        const { actions, currentPage } = this.props;

        actions.loadMoreRequest({ pageNumber: currentPage + 2 });
    };

    btnHandlerOnClick = () => {
        const {
            routerActions,
            activeEnvironment: { administrationScheme },
        } = this.props;
        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes/create`);
    };

    onRowClick = (record) => {
        const {
            activeEnvironment: { administrationScheme },
            routerActions,
        } = this.props;
        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes/detail/${record.idSignature}`);
    };

    signatureToLabel = (signatureDispatch) => (
        <Circle
            value={signatureDispatch ? i18n.get("global.yes").toUpperCase() : i18n.get("global.no").toUpperCase()}
            bgColor={signatureDispatch ? "#0be0a2" : "#fc5f5f"}
            smallSize
            paddingTop="0.1rem"
        />
    );

    populateSignaturesData = () => {
        const {
            activeEnvironment: { administrationScheme, type },
            signaturesSchemes,
        } = this.props;

        return (
            signaturesSchemes &&
            signaturesSchemes.map((signatureScheme) => {
                const {
                    capList,
                    signatureFeatures: functionalGroups,
                    idSignature,
                    signatureType,
                    signatureDispatch,
                    products,
                } = signatureScheme;

                let result = {
                    idSignature,
                    key: idSignature,
                    signers: this.getSigners(administrationScheme, signatureScheme),
                };

                if (administrationScheme === "advanced" || administrationScheme === "medium") {
                    result = { transactions: i18n.get(`signatures.type.${signatureType}`), ...result };
                    if (functionalGroups && functionalGroups.length) {
                        let textFunctionalGroups = functionalGroups
                            .slice(0, 2)
                            .map((functionalGroup) => i18n.get(`${FORM_ID}.functionalGroups.${functionalGroup}`))
                            .join(" | ");
                        textFunctionalGroups += "...";
                        result = { functionalGroups: textFunctionalGroups, ...result };
                    } else {
                        result = { functionalGroups: "-", ...result };
                    }
                }
                if (administrationScheme === "advanced" || administrationScheme === "medium") {
                    result = { signatureDispatch: this.signatureToLabel(signatureDispatch), ...result };
                }

                if (signatureType === "AMOUNT" && capList.length > 0) {
                    const typeCap = getCapType(functionalGroups);
                    result = {
                        amount: (
                            <FormattedAmount
                                className="data-amount"
                                frequencyInSpan={false}
                                currency={configUtils.get("core.masterCurrency")}
                                quantity={
                                    capList[0].maximum === -1
                                        ? configUtils.get(`default_cap_${typeCap}_${type}`)
                                        : capList[0].maximum
                                }
                                frequency={i18n.get(`${FORM_ID}.create.advanced.topAmountPerPeriod`, null, {
                                    period: i18n.get(`${FORM_ID}.transactions.capFrequency.${capList[0].frequency}`),
                                })}
                                notBold
                            />
                        ),
                        ...result,
                    };
                }

                if (administrationScheme === "advanced" || administrationScheme === "medium") {
                    if (products && products.length) {
                        let textFunctionalGroups = products.slice(0, 2).map((product) => product);

                        if (products.length > 2) {
                            textFunctionalGroups += ` (+${functionalGroups.length - 2})`;
                        }
                        textFunctionalGroups += "...";
                        result = { specificProducts: textFunctionalGroups, ...result };
                    } else {
                        result = { specificProducts: "-", ...result };
                    }
                }

                return {
                    ...result,
                };
            })
        );
    };

    renderPageHeader = () => {
        const { fetchingExport, signaturesSchemes } = this.props;

        return (
            <>
                <Notification scopeToShow="administrationSignaturesSchemes" />
                <div className="admin-detail-head admin-detail-head-section px-0 mt-5 mb-4">
                    <Head
                        handleCreateMessage={`${FORM_ID}.new.title`}
                        exportList
                        exportDisabled={signaturesSchemes && signaturesSchemes.length === 0}
                        handleCreateClick={() => {
                            this.btnHandlerOnClick();
                        }}
                        handleClick={this.handleClickDownload}
                        isFetchingExport={fetchingExport}
                        hasInlineButtons
                        csvDownload
                        imageStyle="mr-2"
                        title={`${FORM_ID}.list.title`}
                    />
                </div>
            </>
        );
    };

    renderTableFooter = () => {
        const { totalPages, fetchingMoreSignaturesSchemes, currentPage } = this.props;
        const currentPage1 = currentPage + 1;
        const hasMoreDataInternal = totalPages !== currentPage1;
        return (
            <div>
                {totalPages > 1 &&
                    (hasMoreDataInternal ? (
                        <div className="text-center no-more-data" key="noMoresignatures">
                            <Button
                                className="btn btn-link"
                                onClick={this.handleMoreDataClick}
                                loading={fetchingMoreSignaturesSchemes}
                                image="images/show.svg"
                                label={`${FORM_ID}.list.more`}
                            />
                        </div>
                    ) : (
                        <div className="text-center no-more-data" key="noMoreSignatures">
                            <p className="text-lead">
                                <I18n id={`${FORM_ID}.list.more.noMoreData`} />
                            </p>
                        </div>
                    ))}
            </div>
        );
    };

    renderSignaturesSchemesTable = () => {
        const { totalPages } = this.props;
        const { tableHeight } = this.state;
        return (
            <>
                {tableHeight ? (
                    <Table
                        columns={this.generateTableColumns()}
                        data={this.populateSignaturesData()}
                        rowKey={(record) => record.key}
                        emptyText={i18n.get(`${FORM_ID}.list.noRecords`)}
                        onRow={(record) => ({
                            onClick: () => this.onRowClick(record),
                        })}
                        footer={totalPages > 1 ? this.renderTableFooter : null}
                        className="table-signatures-schemes"
                    />
                ) : (
                    <Measure bounds onResize={({ bounds }) => this.setState({ tableHeight: bounds.height })}>
                        {({ measureRef }) => <div ref={measureRef} style={{ height: "100%", width: "100%" }} />}
                    </Measure>
                )}
            </>
        );
    };

    render() {
        const { fetching, signaturesSchemes, isDesktop } = this.props;

        return (
            <PageLoading loading={fetching} className="screen-loader">
                {this.renderPageHeader()}
                <MainContainer className="main-container px-0" showLoader={fetching} loadingClassName="screen-loader">
                    <div className="above-the-fold">
                        <Container
                            className="container--layout flex-grow align-items-center without-padding-left administration-users-table pr-0"
                            gridClassName="form-content">
                            <Col className="col col-12 px-0">
                                {signaturesSchemes.length ? (
                                    this.renderSignaturesSchemesTable()
                                ) : (
                                    <GeneralMsg
                                        imagePath="images/schemes.svg"
                                        description={i18n.get(`${FORM_ID}.list.noRecords`)}
                                        isDesktop={isDesktop}
                                    />
                                )}
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </PageLoading>
        );
    }
}

export default resizableRoute(SignaturesSchemes);
