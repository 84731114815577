import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, func, bool } from "prop-types";

import * as i18nUtils from "util/i18n";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as accountsSelectors, actions as accountsActions } from "reducers/accounts";

import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import PageLoading from "pages/_components/PageLoading";
import Pagination from "pages/_components/pagination/Pagination";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";

class ProyectedBalanceMovements extends Component {
    static propTypes = {
        selectedAccount: shape({}).isRequired,
        diferedMovements: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
    };

    getDiferedMovements = (pageNumber) => {
        const { dispatch, selectedAccount } = this.props;
        dispatch(accountsActions.diferedMovements(selectedAccount.idProduct, pageNumber));
    };

    render() {
        const { diferedMovements, selectedAccount, isDesktop } = this.props;

        let listDiferedMovements = null;

        if (diferedMovements) {
            listDiferedMovements = diferedMovements.listDiferedMovements.recuperarCuentaMovimientoDiferidoEnt;
        }

        return (
            <PageLoading loading={!diferedMovements || !selectedAccount}>
                {listDiferedMovements && isDesktop && (
                    <Row className="account-table-wrapper account-table-header">
                        <Col sm={12} md={2} lg={2} className="align-self-center text-align-left">
                            {i18nUtils.get("proyected.title.1").toUpperCase()}
                        </Col>
                        <Col sm={12} md={2} lg={2} className="align-self-center justify-content-center">
                            {i18nUtils.get("proyected.title.2").toUpperCase()}
                        </Col>
                        <Col sm={12} md={3} lg={2} className="align-self-center justify-content-center">
                            {i18nUtils.get("proyected.title.3").toUpperCase()}
                        </Col>
                        <Col sm={12} md={2} lg={3} className="align-self-center justify-content-center">
                            {i18nUtils.get("proyected.title.4").toUpperCase()}
                        </Col>
                        <Col sm={12} md={3} lg={3} className="align-self-center justify-content-end text-align-right">
                            {i18nUtils.get("proyected.title.5").toUpperCase()}
                        </Col>
                    </Row>
                )}
                {listDiferedMovements &&
                    listDiferedMovements.map((movement) => {
                        if (isDesktop) {
                            return (
                                <Row className="account-table-wrapper">
                                    <Col sm={12} md={2} lg={2} className="align-self-center text-align-left font-light">
                                        <FormattedDate date={movement.fechaIngreso} />
                                    </Col>
                                    <Col
                                        sm={12}
                                        md={2}
                                        lg={2}
                                        className="align-self-center justify-content-center font-light">
                                        <FormattedDate date={movement.fechaImputacion} />
                                    </Col>
                                    <Col
                                        sm={12}
                                        md={3}
                                        lg={2}
                                        className="align-self-center justify-content-center font-light">
                                        {movement.numeroComprobante}
                                    </Col>
                                    <Col
                                        sm={12}
                                        md={2}
                                        lg={3}
                                        className="align-self-center justify-content-center font-light">
                                        {movement.descripcion}
                                    </Col>
                                    <Col
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        className="align-self-center justify-content-end text-align-right">
                                        <FormattedAmount
                                            className="data-amount"
                                            currency={selectedAccount.currency}
                                            quantity={movement.importe}
                                            notBold
                                        />
                                    </Col>
                                </Row>
                            );
                        }

                        return (
                            <Row className="account-table-wrapper">
                                <Col sm={12} md={3} className="col col-6  align-self-center text-align-left">
                                    <div>
                                        <FormattedDate date={movement.fechaIngreso} />
                                    </div>
                                    <div>{movement.descripcion}</div>
                                </Col>
                                <Col sm={12} md={3} className=" col col-6 align-self-center text-align-right">
                                    <div className="align-self-center text-align-right account-table-header">
                                        <span>{i18nUtils.get("proyected.title.2").toUpperCase()}</span>
                                        <span>
                                            <FormattedDate date={movement.fechaImputacion} />
                                        </span>
                                    </div>
                                    <div className="align-self-center text-align-right">
                                        <FormattedAmount
                                            className="data-amount"
                                            currency={selectedAccount.currency}
                                            quantity={movement.importe}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        );
                    })}
                <div className="w-100 d-flex justify-content-end">
                    {diferedMovements && (
                        <div className="w-100 d-flex justify-content-end">
                            <Pagination
                                totalPages={diferedMovements.totalPages <= 0 ? 1 : diferedMovements.totalPages}
                                pageNumber={diferedMovements.pageNumber}
                                action={this.getDiferedMovements}
                            />
                        </div>
                    )}
                </div>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    selectedAccount: accountsSelectors.getSelectedAccount(state),
    proyectedBalances: accountsSelectors.getProyectedBalances(state),
    diferedMovements: accountsSelectors.getDiferedMovements(state),
});

export default connect(mapStateToProps)(ProyectedBalanceMovements);
