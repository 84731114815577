import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { func, bool, shape, arrayOf, number } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { Field, Form, withFormik } from "formik";
import moment from "moment";
import { compose } from "redux";
import Yup from "yup";

import { actions as checksActions, selectors as checksSelectors } from "reducers/checks";
import * as i18nUtils from "util/i18n";
import { statuses } from "util/checks";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import Container from "pages/_components/Container";
import { DateField } from "pages/_components/fields/DateField";
import TextField from "pages/_components/fields/TextField";
import Selector from "pages/_components/fields/formik/Selector";
import PageLoading from "pages/_components/PageLoading";
import Pagination from "pages/_components/pagination/Pagination";
import * as configUtils from "util/config";
import classNames from "classnames";
import I18n from "pages/_components/I18n";
import RangeDatePicker from "pages/_components/fields/rangedatepicker/RangeDatePicker";
import DiscountedChecksDetailTable from "./_components/DiscountedChecksDetailTable";

const FORM_ID = "discountedChecksDetail";

const getLimitDate = (dateDif) => {
    const date = moment(new Date()); // the day before DST in the US

    date.subtract(dateDif, "days");

    return date;
};

class DiscountedChecksDetail extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        values: shape({}).isRequired,
        isDesktop: bool,
        isSubmitting: bool.isRequired,
        listDiscountedChecksDetail: arrayOf(shape({})).isRequired,
        discountedChecksDetailTotalPages: number.isRequired,
        discountedChecksDetailCurrentPageNumber: number.isRequired,
        fetchingDownload: bool,
        setSubmitting: func.isRequired,
        handleSubmit: func.isRequired,
    };

    static defaultProps = {
        isDesktop: false,
        fetchingDownload: false,
    };

    state = {
        showForm: false,
    };

    componentDidMount = () => {
        const { dispatch, values, discountedFilters, setSubmitting, setValues } = this.props;
        const { checkNumber, dateFrom, dateTo, liquidationNumber, checkStatus } = values;
        const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

        setSubmitting(true);
        if (discountedFilters) {
            setValues({
                ...discountedFilters,
                ...(enableRangeDatefield && {
                    dates: [discountedFilters.dateFrom, discountedFilters.dateTo],
                }),
            });
            dispatch(
                checksActions.fetchDiscountedChecksDetailList(
                    setSubmitting,
                    1,
                    discountedFilters.checkNumber,
                    discountedFilters.liquidationNumber,
                    discountedFilters.checkStatus,
                    discountedFilters.dateFrom,
                    discountedFilters.dateTo,
                ),
            );
        } else {
            dispatch(
                checksActions.fetchDiscountedChecksDetailList(
                    setSubmitting,
                    1,
                    checkNumber,
                    liquidationNumber,
                    checkStatus,
                    dateFrom,
                    dateTo,
                ),
            );
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/checksDetail"));
    };

    handleExport = (format) => {
        const { dispatch, values, discountedChecksDetailTotalPages, listDiscountedChecksDetail } = this.props;
        const { checkNumber, dateFrom, dateTo, liquidationNumber, checkStatus } = values;
        const registersByPage = listDiscountedChecksDetail.length * discountedChecksDetailTotalPages;

        dispatch(
            checksActions.downloadDiscountedChecksDetail(
                format,
                "1",
                checkNumber,
                liquidationNumber,
                checkStatus,
                dateFrom,
                dateTo,
                registersByPage,
            ),
        );
    };

    fetchCheckDetailNextPage = (pageNumber) => {
        const { dispatch, values, setSubmitting } = this.props;
        const { checkNumber, dateFrom, dateTo, liquidationNumber, checkStatus } = values;
        setSubmitting(true);

        dispatch(
            checksActions.fetchDiscountedChecksDetailList(
                setSubmitting,
                pageNumber,
                checkNumber,
                liquidationNumber,
                checkStatus,
                dateFrom,
                dateTo,
            ),
        );
    };

    renderFirstRowFilter = (isDesktop, values, defaultOption, isSubmitting) => {
        const { dateFrom, dateTo } = values;
        const daysBeforeFrom = configUtils.getInteger("configuration.discount.DateFrom", 90);
        const daysBeforeTo = configUtils.getInteger("configuration.discount.DateTo", 1);
        const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

        return (
            <Container
                className={`flex-grow align-items-center my-2 discounted-echeqs ${
                    !isDesktop ? "transparent pt-0 mt-0" : "pt-3 pb-45 container-white discounted-checks-filter"
                }`}
                gridClassName="form-content">
                <Col xs={12} md={4} lg={2} className={classNames("wrap-labels", { "px-2": isDesktop })}>
                    <Field
                        component={TextField}
                        hidePlaceholder
                        idForm={FORM_ID}
                        name="checkNumber"
                        type="text"
                        pattern="\d*"
                        inputmode="numeric"
                        formGroupClassName="my-0"
                        controlLabelClassName="text-transform-none"
                        isDisabled={
                            values.liquidationNumber !== "" ||
                            (values.checkStatus !== null && values.checkStatus !== "")
                        }
                    />
                </Col>
                <Col xs={12} md={4} lg={2} className={classNames("wrap-labels", { "px-2": isDesktop })}>
                    <Field
                        component={TextField}
                        hidePlaceholder
                        idForm={FORM_ID}
                        name="liquidationNumber"
                        type="text"
                        pattern="\d*"
                        inputmode="numeric"
                        formGroupClassName="my-0"
                        controlLabelClassName="text-transform-none"
                        isDisabled={
                            values.checkNumber !== "" || (values.checkStatus !== null && values.checkStatus !== "")
                        }
                    />
                </Col>
                <Col
                    xs={12}
                    md={4}
                    lg={2}
                    className={classNames("discounted-check-status-filter wrap-labels", { "px-2": isDesktop })}>
                    <Field
                        component={Selector}
                        options={[defaultOption, ...statuses]}
                        idForm={FORM_ID}
                        name="checkStatus"
                        isRequired
                        value={values?.checkStatus || defaultOption}
                        formGroupClassName="my-0"
                        controlLabelClassName="text-transform-none"
                        isDisabled={values.checkNumber !== "" || values.liquidationNumber !== ""}
                    />
                </Col>
                {enableRangeDatefield ? (
                    <Col xs={12} md={6} lg={4} className={classNames("mb-1px", { "px-2": isDesktop })}>
                        <Field
                            component={RangeDatePicker}
                            idForm={FORM_ID}
                            name="dates"
                            minDate={getLimitDate(daysBeforeFrom).toDate()}
                            maxDate={getLimitDate(daysBeforeTo * -1).toDate()}
                            className="height-48"
                        />
                    </Col>
                ) : (
                    <>
                        {!isDesktop && (
                            <div className="w-100 px-3">
                                <I18n
                                    id="discountedChecksDetail.dateExpiration.label"
                                    component="label"
                                    componentProps={{
                                        className: "control-label text-transform-none mt-2 mb-0",
                                    }}
                                />
                            </div>
                        )}
                        <Col xs={6} md={4} lg={2} className={classNames("col-6 wrap-labels", { "px-2": isDesktop })}>
                            <Field
                                component={DateField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                name="dateFrom"
                                customKey={!isDesktop && "echeqs.from.label"}
                                selectsStart
                                minDate={getLimitDate(daysBeforeFrom)}
                                maxDate={dateTo}
                                autocomplete="off"
                                controlLabelClassName="text-transform-none"
                            />
                        </Col>
                        <Col xs={6} md={4} lg={2} className={classNames("col-6 wrap-labels", { "px-2": isDesktop })}>
                            <Field
                                component={DateField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                name="dateTo"
                                customKey={!isDesktop && "echeqs.to.label"}
                                selectsEnd
                                minDate={dateFrom}
                                maxDate={getLimitDate(daysBeforeTo * -1)}
                                autocomplete="off"
                                controlLabelClassName="text-transform-none"
                            />
                        </Col>
                    </>
                )}
                <Col
                    xs={12}
                    md={4}
                    lg={2}
                    className={classNames("mt-4 checks-button", {
                        "px-2": isDesktop,
                        "discounted-checks-button": !isDesktop,
                    })}>
                    <Button
                        type="submit"
                        label="echeq.filter"
                        className="mt-3 mr-0 mb-0"
                        bsStyle="primary"
                        loading={isSubmitting}
                    />
                </Col>
            </Container>
        );
    };

    renderHeader() {
        const { isDesktop, listDiscountedChecksDetail, fetchingDownload } = this.props;

        const isMVP4ReportsEnabled = configUtils.getBoolean("frontend.showReportesMVP4.funcionalities", false);

        if (isDesktop) {
            return (
                <>
                    <Head onBack={this.handleBack} />
                    <Head
                        title={`${FORM_ID}.header.title`}
                        exportList
                        handleClick={this.handleExport}
                        handleClickMessage="global.download"
                        headerClassName="discounted-checks-header"
                        isFetchingExport={fetchingDownload}
                        hasInlineButtons
                        csvDownload
                        exportDisabled={listDiscountedChecksDetail.length === 0 && !isMVP4ReportsEnabled}
                    />
                </>
            );
        }
        return (
            <Head
                title={`${FORM_ID}.header.title`}
                replace={{
                    component: "h1",
                }}
                titleClassName="my-0"
                headerClassName="blue-main-header-mobile blue-main-title-mobile"
                onBack={this.handleBack}
                exportList
                handleClick={this.handleExport}
                isFetchingExport={fetchingDownload}
                hasInlineButtons
                csvDownload={!isMVP4ReportsEnabled}
                downloadImageWhite
                exportDisabled={listDiscountedChecksDetail.length === 0 && !isMVP4ReportsEnabled}
            />
        );
    }

    render() {
        const {
            isDesktop,
            handleSubmit,
            values,
            isSubmitting,
            listDiscountedChecksDetail,
            dispatch,
            discountedChecksDetailTotalPages,
            discountedChecksDetailCurrentPageNumber,
        } = this.props;
        const { state } = this;
        const { showForm } = state;
        const defaultOption = { value: null, label: "TODOS" };

        return (
            <>
                <Notification scopeToShow="discountedChecksDetail" />
                <div className={classNames("px-0 mb-0", { "admin-detail-head": isDesktop })}>
                    {this.renderHeader()}
                    {!isDesktop && (
                        <Col xs={12} className="justify-content-end d-flex">
                            <Button
                                block
                                className="btn-link p-0 right"
                                label={showForm ? "echeq.button.hideFilter" : "echeq.button.seeFilter"}
                                onClick={() => this.setState((prev) => ({ ...prev, showForm: !prev.showForm }))}
                            />
                        </Col>
                    )}
                    {(isDesktop || showForm) && (
                        <Form
                            className="mt-4"
                            onSubmit={async (e) => {
                                await handleSubmit(e);
                                this.setState((prev) => ({
                                    ...prev,
                                    showForm: !prev.showForm,
                                }));
                            }}>
                            {this.renderFirstRowFilter(isDesktop, values, defaultOption, isSubmitting)}
                        </Form>
                    )}
                    <PageLoading className="line-loader" loading={isSubmitting}>
                        <DiscountedChecksDetailTable
                            listDiscountedChecksDetail={listDiscountedChecksDetail}
                            isDesktop={isDesktop}
                            tableLabel={FORM_ID}
                            dispatch={dispatch}
                        />
                        {listDiscountedChecksDetail.length > 0 && (
                            <div className="d-flex w-100 justify-content-end">
                                <div className="d-flex mt-2">
                                    <Pagination
                                        totalPages={discountedChecksDetailTotalPages}
                                        pageNumber={discountedChecksDetailCurrentPageNumber}
                                        action={this.fetchCheckDetailNextPage}
                                    />
                                </div>
                            </div>
                        )}
                    </PageLoading>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    listDiscountedChecksDetail: checksSelectors.getDiscountedChecksDetailList(state),
    discountedChecksDetailTotalPages: checksSelectors.getDiscountedChecksDetailTotalPages(state),
    discountedChecksDetailCurrentPageNumber: checksSelectors.getDiscountedChecksDetailCurrentPageNumber(state),
    fetchingDownload: checksSelectors.getFetchingDownload(state),
    discountedFilters: checksSelectors.getDiscountedFilters(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            checkNumber: "",
            liquidationNumber: "",
            checkStatus: null,
            dateFrom: getLimitDate(1),
            dateTo: getLimitDate(0),
            dates: [getLimitDate(1).toDate(), getLimitDate(0).toDate()],
        }),
        validationSchema: () => {
            const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

            return Yup.lazy((values) =>
                Yup.object().shape({
                    ...(enableRangeDatefield
                        ? {
                              dates: Yup.mixed().test(
                                  "dates",
                                  i18nUtils.get(`${FORM_ID}.validation.range.date`),
                                  ([from, to]) => from && to,
                              ),
                          }
                        : {
                              dateFrom: values.dateTo
                                  ? Yup.date()
                                        .nullable()
                                        .max(values.dateTo, i18nUtils.get(`${FORM_ID}.dateFrom.error`))
                                  : Yup.date().nullable(),
                              dateTo: values.dateFrom
                                  ? Yup.date()
                                        .nullable()
                                        .min(values.dateFrom, i18nUtils.get(`${FORM_ID}.dateTo.error`))
                                  : Yup.date().nullable(),
                          }),
                    checkNumber: values.checkNumber
                        ? Yup.string().matches(/^[0-9]*$/, i18nUtils.get("services.payment.field.isNotNumeric"))
                        : Yup.string().nullable(),
                    liquidationNumber: values.liquidationNumber
                        ? Yup.string().matches(/^[0-9]*$/, i18nUtils.get("services.payment.field.isNotNumeric"))
                        : Yup.string().nullable(),
                }),
            );
        },
        handleSubmit: ({ checkNumber, liquidationNumber, checkStatus, dateFrom, dateTo, dates }, formikBag) => {
            const { dispatch } = formikBag.props;
            const { setSubmitting } = formikBag;
            const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

            dispatch(
                checksActions.fetchDiscountedChecksDetailList(
                    setSubmitting,
                    "1",
                    checkNumber,
                    liquidationNumber,
                    checkStatus,
                    enableRangeDatefield ? dates[0] : dateFrom,
                    enableRangeDatefield ? dates[1] : dateTo,
                ),
            );
        },
    }),
)(DiscountedChecksDetail);
