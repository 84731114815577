const NUMBER_FOR_SEPARATORS = 12345.6;

export const countDecimalPlaces = (number, decimalSeparator = ",") => {
    if (!number) {
        return 0;
    }
    let value = number;

    if (typeof number === "string") {
        const INPUT_REGEX_REPLACE = new RegExp(`[^0-9${decimalSeparator}]`, "g");
        value = value.replace(INPUT_REGEX_REPLACE, "");
    } else {
        value = `${value}`.replace(".", decimalSeparator);
    }

    return value && value % 1 !== 0 ? `${value}`.split(decimalSeparator)[1].length : 0;
};

export const numberFormat = (lang) => {
    const localeString = NUMBER_FOR_SEPARATORS.toLocaleString(lang || undefined);

    const decimalSeparatorIndex = Math.max(localeString.lastIndexOf("."), localeString.lastIndexOf(","));
    const thousandSeparatorIndex = Math.min(localeString.lastIndexOf("."), localeString.lastIndexOf(","));

    return {
        decimalSeparator: localeString[decimalSeparatorIndex],
        thousandSeparator: localeString[thousandSeparatorIndex],
    };
};

export const toNumber = (str, decimalSeparator = ",", precision = 2) => {
    const INPUT_REGEX_REPLACE = new RegExp(`[^0-9${decimalSeparator}]`, "g");
    return str && str !== "" && str !== null
        ? parseFloat(parseFloat(str.replace(INPUT_REGEX_REPLACE, "").replace(decimalSeparator, ".")).toFixed(precision))
        : str;
};

export const toNumericString = (str, decimalSeparator = ",") => {
    const INPUT_REGEX_REPLACE = new RegExp(`[^0-9${decimalSeparator}]`, "g");
    return str && str !== "" && str !== null
        ? str.replace(INPUT_REGEX_REPLACE, "").replace(decimalSeparator, ".")
        : str;
};

export const clamp = (n, min, max) => Math.max(Math.min(n, max), min);

export const parseIntWithSymbol = (string, symbol = "+") => {
    if (symbol === "+") {
        return parseInt(string, 10);
    }
    return parseInt(string, 10) * -1;
};

export const parseDoubleWithSymbol = (string, symbol = "+") => {
    if (symbol === "+") {
        return parseFloat(string, 10);
    }
    return parseFloat(string, 10) * -1;
};

export const parseFloatToAmountFormat = (amount, precision = 2, lang = "en-US") => {
    const formatter = new Intl.NumberFormat(lang, {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
    });

    const amountFormatted = formatter.format(amount).substring(1);

    if (lang === "en-US") {
        amountFormatted.replace(",", ".");
    }

    return amountFormatted
        .substring(0, amountFormatted.length - (precision + 1))
        .concat(",", amountFormatted.substring(amountFormatted.length - precision));
};
