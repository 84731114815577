/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import { bool, arrayOf, shape, string, number, func } from "prop-types";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";

import { push, routerActions } from "react-router-redux";
import { selectors, actions } from "reducers/checks";
import { actions as formActions } from "reducers/form";
import { selectors as accountsSelectors, actions as accountsActions } from "reducers/accounts";
import { selectors as transactionsSelectors, actions as transactionsActions } from "reducers/transactions";

import * as i18nUtils from "util/i18n";
import Notification from "pages/_components/Notification";
import Button from "pages/_components/Button";
import * as checkUtils from "util/checks";

import PageLoading from "pages/_components/PageLoading";

import InfoTag from "pages/_components/InfoTag";
import Head from "pages/_components/Head";

import { checkSign } from "util/transaction";
import { Col } from "react-bootstrap";
import Select from "react-select";
import I18n from "pages/_components/I18n";
import ContextMenu from "pages/_components/ContextMenu";
import classNames from "classnames";
import Container from "pages/_components/Container";
import BatchDetailHeader from "./_components/BatchDetailHeader";
import BatchDetailList from "./_components/BatchDetailList";
import EmitMassiveSummaryData from "./EmitMassiveSummaryData";

const FORM_ID = "echeq.massive.emit";
class BatchDetail extends Component {
    static propTypes = {
        potentialSignaturesData: arrayOf(shape({})).isRequired,
        summary: shape({}).isRequired,
        totalLines: number.isRequired,
        fetchingAccount: bool.isRequired,
        isFetchingDownload: bool,
        fetching: bool,
        dispatch: func.isRequired,
        loggedUser: shape({
            userId: string.isRequired,
        }).isRequired,
        echeqBatch: shape({}),
        batchErrors: shape({}).isRequired,
        batchDetail: arrayOf(shape({})),
        match: shape({
            params: shape({}),
        }).isRequired,
        accounts: shape({}).isRequired,
        isDesktop: bool,
    };

    static defaultProps = {
        fetching: true,
        isDesktop: true,
        isFetchingDownload: false,
        echeqBatch: null,
        batchDetail: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            statusFilter: "TODOS",
        };
    }

    componentDidMount() {
        const { dispatch, match, loggedUser } = this.props;
        const { idTransaction } = match.params;
        if (idTransaction) {
            dispatch(transactionsActions.getPotentialSignaturesData(idTransaction));
            dispatch(accountsActions.listAccounts(loggedUser.userId));
            dispatch(actions.echeqBatchDetail(idTransaction));
        } else {
            dispatch(routerActions.push("/echeqs"));
        }
    }

    fetchMoreTransactions = (pageNumber, status) => {
        const { dispatch, echeqBatch } = this.props;
        const { statusFilter } = this.state;
        dispatch(actions.echeqBatchDetail(null, echeqBatch?.idBatch, status || statusFilter, pageNumber));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.goBack());
    };

    getTitle = () => {
        const { echeqBatch, isDesktop } = this.props;
        const name = `${i18nUtils.get(`${FORM_ID}.summary.idBatch.label`)} ${echeqBatch?.idBatch}`;

        return (
            <>
                <div
                    className={classNames(
                        "view-title data-wrapper-flex flex-flow-wrap",
                        { "mx-auto": !isDesktop },
                        { "w-100": isDesktop },
                    )}>
                    <h1 className={classNames("my-0", { "mr-3": isDesktop })}>{name}</h1>
                    {echeqBatch !== null && isDesktop && (
                        <InfoTag
                            type="info"
                            message={i18nUtils.get(`echeq.batch.status.${echeqBatch.status.toLowerCase()}`)}
                            tagBackground={checkUtils.statusTagColorBatch(echeqBatch.status)}
                            tagClass="d-flex align-self-flex-end widht max-width-mincontent no-wrap py-1 px-2"
                            moreStyles={{
                                color: "black",
                            }}
                        />
                    )}
                </div>
                {isDesktop && (
                    <div className="view-title data-wrapper-flex d-flow mt-2">
                        <span className="data-label no-wrap pr-2">
                            {i18nUtils.get(`${FORM_ID}.summary.fileName.label`)}:
                        </span>
                        <span className="data-desc no-wrap">{echeqBatch?.file?.fileName}</span>
                    </div>
                )}
            </>
        );
    };

    handleDownload = () => {
        const { dispatch, echeqBatch } = this.props;
        const { statusFilter } = this.state;
        const {
            idBatch,
            file: { fileName },
        } = echeqBatch;
        dispatch(actions.downloadEcheqBatchDetail(idBatch, statusFilter, fileName));
    };

    renderSubtitle = () => {
        const { echeqBatch, accounts, isDesktop, batchErrors } = this.props;
        return (
            <>
                {echeqBatch !== null && (
                    <BatchDetailHeader
                        transactionOrder={echeqBatch.quantity}
                        echeqBatch={echeqBatch}
                        accounts={accounts}
                        isDesktop={isDesktop}
                        batchErrors={batchErrors}
                    />
                )}
            </>
        );
    };

    signTransaction = () => {
        const { dispatch, summary, isDesktop } = this.props;
        const { idActivity } = summary;

        const signTransactionData = (
            <EmitMassiveSummaryData isDesktop={isDesktop} summary={summary} FORM_ID={FORM_ID} spaceBetween signature />
        );

        dispatch(push("/transactions/sign"));
        dispatch(
            formActions.signTransactionData({
                idTransaction: summary?.idTransaction,
                idActivity,
                idForm: "",
                content: signTransactionData,
                title: FORM_ID,
                pendingDispatch: summary?.pendingDispatch,
            }),
        );

        dispatch(
            formActions.signTransactionPreview({
                idActivity,
                idTransaction: summary.idTransaction,
                idForm: "",
                ticketData: summary,
            }),
        );
        dispatch(actions.checksRequestData({ ...summary, action: "sign" }));
    };

    cancelTransaction = () => {
        const { dispatch, summary, isDesktop } = this.props;

        const cancelTransactionData = (
            <EmitMassiveSummaryData isDesktop={isDesktop} summary={summary} FORM_ID={FORM_ID} spaceBetween signature />
        );
        dispatch(push("/transactions/cancel"));
        dispatch(
            formActions.cancelTransactionData({
                idTransaction: summary?.idTransaction,
                content: cancelTransactionData,
                title: FORM_ID,
            }),
        );
        dispatch(formActions.cancelTransactionPre({ idActivity: summary?.idActivity, idForm: "" }));
    };

    rigthContent = () => {
        const { isFetchingDownload, summary, loggedUser, potentialSignaturesData, isDesktop } = this.props;
        const signedTransaction = checkSign(summary, loggedUser, potentialSignaturesData);
        const isPending = summary?.idTransactionStatus === "PENDING";

        const menuContextItems = [];

        if (isPending && signedTransaction) {
            menuContextItems.push({
                label: "forms.signTransaction.link",
                onClick: this.signTransaction,
            });
        }
        if (isPending) {
            menuContextItems.push({
                label: "echeq.batch.detail.button.cancelBatch",
                onClick: this.cancelTransaction,
            });
        }
        menuContextItems.push({
            label: "echeq.batch.detail.button.downloadExcel",
            onClick: this.handleDownload,
        });
        if (menuContextItems.length === 1 && !isDesktop) {
            return undefined;
        }

        return isDesktop ? (
            <>
                {isPending && signedTransaction && (
                    <Button bsStyle="primary" onClick={this.signTransaction} label="forms.signTransaction.link" />
                )}
                {isPending && (
                    <Button
                        bsStyle="primary"
                        onClick={this.cancelTransaction}
                        label="echeq.batch.detail.button.cancelBatch"
                    />
                )}
                <Button
                    block
                    label="echeq.batch.detail.button.downloadExcel"
                    loading={isFetchingDownload}
                    image="images/download.svg"
                    onClick={() => this.handleDownload()}
                    className="btn-outline cmf-button-width"
                    imageStyle="mr-2"
                />
            </>
        ) : (
            <ContextMenu
                isDesktop={isDesktop}
                buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                items={menuContextItems}
            />
        );
    };

    renderPageHeader = () => {
        const { echeqBatch, isDesktop } = this.props;
        const renderUniqueDownload = !isDesktop && !this.rigthContent();

        return (
            <>
                <Notification scopeToShow="echeqs" />
                <div className="admin-detail-head px-0">
                    {isDesktop && <Head onBack={this.handleBack} />}
                    <Head
                        onBack={!isDesktop && this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : "mb-3"}
                        centerElement={this.getTitle}
                        centerContentClassName="d-flex flex-column align-items-start"
                        rightContent={this.rigthContent}
                        downloadImageWhite={renderUniqueDownload}
                        uniqueDownload={renderUniqueDownload}
                        handleClick={() => (renderUniqueDownload ? this.handleDownload() : undefined)}
                    />
                </div>
                {!isDesktop && (
                    <>
                        {echeqBatch !== null && (
                            <InfoTag
                                type="info"
                                message={i18nUtils.get(`echeq.batch.status.${echeqBatch.status.toLowerCase()}`)}
                                tagBackground={checkUtils.statusTagColorBatch(echeqBatch.status)}
                                tagClass="d-flex align-self-flex-end widht max-width-mincontent no-wrap py-1 px-2 m-3"
                                moreStyles={{
                                    color: "black",
                                }}
                            />
                        )}
                        <div className="d-block m-3">
                            <p className="no-wrap m-0">{i18nUtils.get(`${FORM_ID}.summary.fileName.label`)}:</p>
                            <p className="data-desc text-12 m-0">{echeqBatch?.file?.fileName}</p>
                        </div>
                    </>
                )}
                {this.renderSubtitle()}
            </>
        );
    };

    handleChangeState = (option) => {
        this.setState({ statusFilter: option.value });
        this.fetchMoreTransactions(1, option.value);
    };

    renderContent() {
        const { fetching, batchDetail, batchErrors, isDesktop } = this.props;
        const { statusFilter } = this.state;
        const options = [
            { value: "TODOS", label: "TODOS" },
            { value: "SUCCESS", label: i18nUtils.get(`echeq.batch.detail.status.success`).toUpperCase() },
            { value: "ERROR", label: i18nUtils.get(`echeq.batch.detail.status.error`).toUpperCase() },
        ];
        return (
            <>
                <div className="mb-2">{this.renderPageHeader()}</div>
                {batchErrors?.errors > 0 && (
                    <Container className="mb-3" rowClassName="justify-content-start">
                        <Col sm={12} lg={3} md={3}>
                            <div className="form-group-text">
                                <label htmlFor="status" className="data-label">
                                    <I18n id="echeq.batch.detail.filter.status.label" />
                                </label>
                            </div>
                            <div className="input-group">
                                <Select
                                    name="status"
                                    className="slideFromBottom"
                                    clearable={false}
                                    searchable={isDesktop}
                                    onChange={this.handleChangeState}
                                    options={options}
                                    optionClassName="needsclick"
                                    placeholder=""
                                    value={statusFilter}
                                    noResultsText={i18nUtils.get("global.no.results")}
                                />
                            </div>
                        </Col>
                    </Container>
                )}
                <BatchDetailList
                    fetchMoreTransactions={this.fetchMoreTransactions}
                    totalLines={batchDetail.length}
                    isFetching={fetching}
                    {...this.props}
                />
            </>
        );
    }

    render() {
        const { fetching, fetchingAccount } = this.props;

        return <PageLoading loading={fetchingAccount || fetching}>{this.renderContent()}</PageLoading>;
    }
}

const mapStateToProps = (state) => ({
    accounts: accountsSelectors.getAccounts(state),
    loggedUser: sessionSelectors.getUser(state),
    fetching: selectors.getBatchFetching(state),
    fetchingAccount: accountsSelectors.getFetching(state),
    isFetchingDownload: selectors.getFetchingDownload(state),
    echeqBatch: selectors.getEcheqBatch(state),
    batchDetail: selectors.getEcheqBatchDetailList(state),
    batchErrors: selectors.getBatchErrors(state),
    pagination: selectors.getEcheqsPagination(state),
    summary: selectors.getTransactionEcheqData(state),
    potentialSignaturesData: transactionsSelectors.getPotentialSignaturesData(state),
});

export default connect(mapStateToProps)(BatchDetail);
