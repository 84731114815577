import { Box, Form, Modal, Text, useMediaQuery } from "@ui-kit/components/index.es";
import { bool, func, shape, string } from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as i18n from "util/i18n";
import Yup from "yup";

import { selectors as templateSelectors, actions as templateActions } from "reducers/template";

function CreateTemplateModal(props) {
    CreateTemplateModal.propTypes = {
        dispatch: func.isRequired,
        isTemplateModalVisible: bool.isRequired,
        values: shape({}).isRequired,
        idActivityTemplate: string.isRequired,
        idForm: string.isRequired,
        fetching: bool.isRequired,
        creatingData: shape({}).isRequired,
    };
    const { isTemplateModalVisible, values, fetching } = props;

    const [formikProps, setFormikProps] = useState({});
    const isMobile = useMediaQuery("min", "md");

    const parseAmount = (value) => {
        if (typeof value === "string") {
            return parseFloat(value.replaceAll(".", "").replace(",", "."));
        }
        return value;
    };

    const handleHideTemplateModal = () => {
        const { dispatch } = props;
        dispatch(templateActions.closeCreateModal());
        cleanFormValue();
    };

    const handleSubmitTemplate = (templateName) => {
        const { dispatch } = props;
        const { idForm, idActivityTemplate, creatingData } = props;
        const { concept, transferKind, amount, currency } = creatingData;
        const templateValues = {
            ...values,
            ...creatingData,
            concept: concept?.value,
            transferKind: transferKind?.value,
            amount: {
                amount: parseAmount(amount),
                currency,
            },
        };

        dispatch(
            templateActions.saveTemplate(
                idForm,
                templateValues,
                templateName,
                formikProps,
                idActivityTemplate,
                cleanFormValue,
            ),
        );
    };

    const cleanFormValue = () => {
        if (formikProps?.resetForm) {
            formikProps.resetForm();
        }
        if (formikProps?.setValues) {
            formikProps.setValues({ templateName: "" });
        }
    };

    return (
        <Modal
            ariaLabelledBy="modal-label"
            modalControlElementId="modal-button"
            closeOnBgTap
            onClose={() => {}}
            isVisible={isTemplateModalVisible}>
            <Box align="center" gap={16} mt={24} mx={isMobile ? 24 : 48}>
                <Text variant="h2" align="left">
                    {i18n.get("transfers.template.title")}
                </Text>
            </Box>
            <Box align="center" gap={16} my={24} mx={isMobile ? 24 : 48}>
                <Form
                    buttonSubmit={{}}
                    initialValues={{}}
                    inputsList={[
                        {
                            colProps: {
                                size: 12,
                            },
                            component: "textField",
                            id: "templateName",
                            label: i18n.get("transfers.template.name"),
                            placeholder: i18n.get("transfers.template.name"),
                            maxLength: 50,
                        },
                    ]}
                    buttonsList={[
                        {
                            colProps: {
                                size: {
                                    xs: 6,
                                    lg: 3,
                                },
                                offset: {
                                    lg: 7,
                                },
                            },
                            component: "button",
                            key: "cancel",
                            text: i18n.get("global.cancel"),
                            variant: "text",
                            type: "button",
                            onClick: () => handleHideTemplateModal(),
                            isInactive: false,
                        },
                        {
                            colProps: {
                                size: {
                                    xs: 6,
                                    lg: 3,
                                },
                            },
                            component: "button",
                            key: "template",
                            text: i18n.get("global.accept"),
                            variant: "text",
                            type: "submit",
                            id: "modal-button",
                            onClick: () => {},
                            extraInactiveCondition: fetching,
                        },
                    ]}
                    onSubmit={(template) => {
                        handleSubmitTemplate(template.templateName);
                    }}
                    rowSpacingForm={16}
                    wrapButtons
                    className="w-100"
                    setFormikProps={setFormikProps}
                    validationSchema={Yup.object().shape({
                        templateName: Yup.string().required(i18n.get("transfers.validation.required")),
                    })}
                />
            </Box>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    isTemplateModalVisible: templateSelectors.isCreateModalVisible(state),
    creatingData: templateSelectors.getCreatingData(state),
    fetching: templateSelectors.isFetching(state),
});

export default withRouter(connect(mapStateToProps)(CreateTemplateModal));
