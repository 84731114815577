import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Heading from "pages/_components/Heading";
import { shape, func } from "prop-types";
import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import { selectors as ecomexSelectors } from "reducers/ecomex";

class EcomexTransactionData extends Component {
    static propTypes = {
        summary: shape({}).isRequired,
        dispatch: func.isRequired,
    };

    componentDidMount() {
        const { summary, dispatch } = this.props;
        if (!summary) {
            dispatch(routerActions.push("/pendingTransaction/list"));
        }
    }

    render() {
        const { summary } = this.props;
        return (
            summary && (
                <>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="ecomex.transaction.data.creationDate"
                            data={
                                <p className="data-desc m-0">
                                    <FormattedDate date={summary?.creationDate} dateFormat="dd/MM/yyyy" showTime />
                                </p>
                            }
                        />
                    </Col>

                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="ecomex.transaction.data.requestType"
                            data={<p className="data-desc m-0">{summary?.transactionData?.requestType}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="ecomex.transaction.data.requestNumber"
                            data={<p className="data-desc m-0">{summary?.transactionData?.requestIdEcomex}</p>}
                        />
                    </Col>
                    {summary?.transactionData?.referencedOperation !== "null" && (
                        <Col xs={12} className="px-0">
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label="ecomex.transaction.data.referencedOperation"
                                data={<p className="data-desc m-0">{summary?.transactionData?.referencedOperation}</p>}
                            />
                        </Col>
                    )}
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="ecomex.transaction.data.requestAccountNumber"
                            data={<p className="data-desc m-0">{summary?.transactionData?.requestAccountNumber}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="ecomex.transaction.data.beneficiaryName"
                            data={<p className="data-desc m-0">{summary?.transactionData?.beneficiaryName}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="ecomex.transaction.data.concept"
                            data={<p className="data-desc m-0">{summary?.transactionData?.concept}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="ecomex.transaction.data.requestCurrency"
                            data={<p className="data-desc m-0">{summary?.transactionData?.requestCurrency}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="ecomex.transaction.data.requestAmount"
                            data={
                                <p className="data-desc m-0">
                                    <FormattedAmount
                                        quantity={summary?.transactionData?.requestAmount}
                                        notBold
                                        noCurrency
                                    />
                                </p>
                            }
                        />
                    </Col>
                </>
            )
        );
    }
}
const mapStateToProps = (state) => ({
    summary: ecomexSelectors.getSummary(state),
});

export default connect(mapStateToProps)(EcomexTransactionData);
