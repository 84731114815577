import React from "react";
import { Field, Form } from "formik";
import { Col } from "react-bootstrap";
import classNames from "classnames";

import Container from "pages/_components/Container";
import { DateField } from "pages/_components/fields/DateField";
import Checkbox from "pages/_components/Checkbox";
import TextField from "pages/_components/fields/TextField";
import Selector from "pages/_components/fields/formik/Selector";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import PropTypes from "prop-types";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";

import { getLimitDate } from "util/date";
import CedipFilterSelect from "./CedipFilterSelect";
import RangeDatePicker from "pages/_components/fields/rangedatepicker/RangeDatePicker";
import { useIsMobile } from "@ui-kit/components/index.es";

const FORM_ID = "deposits.cedip";

const recievedStatusList = [{ value: "ACTIVO-PENDIENTE", label: "ACTIVO-PENDIENTE" }];

const ownedStatusList = [
    { value: "ACTIVO", label: "ACTIVO" },
    /*     { value: "Depositado", label: "DEPOSITADO" },
    { value: "Pagado", label: "PAGADO" },
    { value: "Presentado", label: "PRESENTADO" },
    { value: "Rechazado", label: "RECHAZADO" },
    { value: "Renovado", label: "RENOVADO" }, */
];
CedipFilters.propTypes = {
    isDesktop: PropTypes.bool.isRequired,
    isSmallDesktop: PropTypes.bool.isRequired,
    values: PropTypes.shape({
        status: PropTypes.string,
        idCedip: PropTypes.string,
        creationDate: PropTypes.bool,
        creationDateFrom: PropTypes.string,
        creationDateTo: PropTypes.string,
        dueDate: PropTypes.bool,
        dueDateFrom: PropTypes.string,
        dueDateTo: PropTypes.string,
    }).isRequired,
    fetching: PropTypes.bool.isRequired,
    recievedCedipSelected: PropTypes.bool.isRequired,
    filterOrder: PropTypes.shape({
        column: PropTypes.string.isRequired,
        direction: PropTypes.string.isRequired,
    }).isRequired,
    cleanFilter: PropTypes.bool.isRequired,
    changeFilterFunc: PropTypes.func.isRequired,
    showForm: PropTypes.bool.isRequired,
    setCleanFilter: PropTypes.func.isRequired,
    handleChangeOrder: PropTypes.func.isRequired,
    handleChangeFilterOrder: PropTypes.func.isRequired,
};

function CedipFilters({
    isDesktop,
    isSmallDesktop,
    values,
    fetching,
    recievedCedipSelected,
    filterOrder,
    cleanFilter,
    changeFilterFunc,
    showForm,
    setCleanFilter,
    handleChangeOrder,
    handleChangeFilterOrder,
    handleSubmitFilters
}) {
    const defaultStatusOption = recievedCedipSelected ? recievedStatusList : ownedStatusList;
    const { windowSize } = useIsMobile()

    const orderby = [
        {
            value: "fecha_vencimiento",
            label: i18n.get("deposits.cedip.searchBy.dueDate"),
        },
        {
            value: "fecha_emision",
            label: i18n.get("deposits.cedip.searchBy.creationDate"),
        },
    ];

    const radioOptions = [
        {
            id: "0",
            label: <I18n id="echeqs.filters.searchBy.upward" />,
        },
        {
            id: "1",
            label: <I18n id="echeqs.filters.searchBy.falling" />,
        },
    ];

    const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);
    const isNotFullsize = isDesktop && windowSize < 1625;

    if (!showForm) {
        return <></>;
    }

    return (
        <Form id="filter" className={enableRangeDatefield ? "cedip__form_v2" : undefined}>
            <Container
                rowClassName=""
                className={`flex-grow align-items-center container-white my-2 ${
                    !isDesktop ? "transparent pt-0 mt-0" : "pt-3 pb-2"
                }`}>
                <Col
                    xs={12}
                    md={6}
                    lg={2}
                    className={classNames("d-flex align-items-center justify-content-start", {
                        "pl-3 pr-2": isDesktop && !enableRangeDatefield,
                    })}>
                    <Field
                        component={Selector}
                        hidePlaceholder
                        options={defaultStatusOption}
                        idForm={FORM_ID}
                        name="status"
                        isRequired
                        value={values?.status}
                        searchable={isDesktop}
                        formGroupClassName={classNames("w-100", {
                            "mb-0": isDesktop,
                            "mr-1": !enableRangeDatefield
                        })}
                    />
                </Col>
                <Col
                    xs={12}
                    md={6}
                    lg={2}
                    className={classNames("d-flex align-items-center justify-content-start", {
                        "pl-3 pr-2": isDesktop && !enableRangeDatefield,
                    })}>
                    <div
                        className={classNames("d-flex align-items-center w-100", {
                            "mr-3": isSmallDesktop && !enableRangeDatefield,
                            "mr-1": isDesktop && !enableRangeDatefield,
                        })}>
                        <Field
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            name="idCedip"
                            type="text"
                            formGroupClassName={classNames("w-100", {
                                "mb-0": isDesktop,
                                "mr-1": !enableRangeDatefield
                            })}
                        />
                    </div>
                </Col>
                {
                    enableRangeDatefield ?
                        <>
                            <Col
                                xs={12}
                                md={6}
                                lg={isNotFullsize ? 4 : 3}
                                className={classNames("d-flex justify-content-end", {"mb-35": !isDesktop})}>
                                    <Field
                                        component={RangeDatePicker}
                                        idForm={FORM_ID}
                                        disabled={!values.creationDate}
                                        idField="creationDates"
                                        name="creationDates"
                                        minDate={getLimitDate(-3650).toDate()}
                                        maxDate={getLimitDate(3650).toDate()}
                                    />
                            </Col>
                            <Col
                                xs={12}
                                md={6}
                                lg={isNotFullsize ? 4 : 3}
                                className={classNames("d-flex justify-content-end", {"mb-3": !isDesktop})}>
                                    <Field
                                        component={RangeDatePicker}
                                        idForm={FORM_ID}
                                        disabled={!values.dueDate}
                                        idField="dueDates"
                                        name="dueDates"
                                        maxDate={getLimitDate(3650).toDate()}
                                        minDate={getLimitDate(-3650).toDate()}
                                    />
                            </Col>
                        </>
                    :
                        <>
                            {!isDesktop && (
                                <Col xs={12}>
                                    <Field component={Checkbox} idForm={FORM_ID} name="creationDate" isDesktop={isDesktop} />
                                </Col>
                            )}
                            <Col
                                xs={12}
                                md={12}
                                lg={4}
                                className={classNames("d-flex justify-content-start", {
                                    "pl-3 pr-2": isDesktop,
                                })}>
                                {isDesktop && (
                                    <div className="pt-5">
                                        <Field
                                            component={Checkbox}
                                            hideLabel
                                            idForm={FORM_ID}
                                            name="creationDate"
                                            isDesktop={isDesktop}
                                        />
                                    </div>
                                )}
                                <div className={classNames("d-flex align-items-center flex", { "mr-3": isDesktop })}>
                                    <Field
                                        idField="creationDateFrom"
                                        component={DateField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        name="creationDateFrom"
                                        disabled={!values.creationDate}
                                        isDisabled={!values.creationDate}
                                        maxDate={values.creationDateTo}
                                        minDate={getLimitDate(-3650)}
                                        formGroupClassName={classNames("mr-1 w-100", {
                                            "mb-0": isDesktop,
                                            "field-disabled": !values.creationDate,
                                        })}
                                        customKey={!isDesktop && "deposits.cedip.from.label.mobile"}
                                    />
                                </div>
                                <div
                                    className={classNames("d-flex align-items-center flex", {
                                        "mr-3": isSmallDesktop,
                                        "mr-1": isDesktop,
                                    })}>
                                    <Field
                                        idField="creationDateTo"
                                        component={DateField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        disabled={!values.creationDate}
                                        isDisabled={!values.creationDate}
                                        name="creationDateTo"
                                        minDate={values.creationDateFrom}
                                        formGroupClassName={classNames("w-100", {
                                            "mb-0": isDesktop,
                                            "field-disabled": !values.creationDate,
                                        })}
                                        customKey={!isDesktop && "deposits.cedip.to.label.mobile"}
                                    />
                                </div>
                            </Col>
                            {!isDesktop && (
                                <Col xs={12}>
                                    <Field component={Checkbox} idForm={FORM_ID} name="dueDate" isDesktop={isDesktop} />
                                </Col>
                            )}
                            <Col
                                xs={12}
                                md={12}
                                lg={4}
                                className={classNames("d-flex justify-content-start", {
                                    "pl-3 pr-2": isDesktop,
                                })}>
                                {isDesktop && (
                                    <div className="pt-5">
                                        <Field
                                            component={Checkbox}
                                            hideLabel
                                            idForm={FORM_ID}
                                            name="dueDate"
                                            isDesktop={isDesktop}
                                        />
                                    </div>
                                )}
                                <div className={classNames("d-flex align-items-center flex", { "mr-3": isDesktop })}>
                                    <Field
                                        idField="dueDateFrom"
                                        name="dueDateFrom"
                                        component={DateField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        disabled={!values.dueDate}
                                        isDisabled={!values.dueDate}
                                        maxDate={values.dueDateTo}
                                        minDate={getLimitDate(-3650)}
                                        formGroupClassName={classNames("mr-1 w-100", {
                                            "mb-0": isDesktop,
                                            "field-disabled": !values.dueDate,
                                        })}
                                        customKey={!isDesktop && "deposits.cedip.from.label.mobile"}
                                    />
                                </div>
                                <div
                                    className={classNames("d-flex align-items-center flex", {
                                        "mr-3": isSmallDesktop,
                                        "mr-1": isDesktop,
                                    })}>
                                    <Field
                                        idField="dueDateTo"
                                        name="dueDateTo"
                                        component={DateField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        disabled={!values.dueDate}
                                        isDisabled={!values.dueDate}
                                        minDate={values.dueDateFrom}
                                        maxDate={getLimitDate(3650)}
                                        formGroupClassName={classNames("w-100", {
                                            "mb-0": isDesktop,
                                            "field-disabled": !values.dueDate,
                                        })}
                                        customKey={!isDesktop && "deposits.cedip.to.label.mobile"}
                                    />
                                </div>
                            </Col>
                        </>
                }
                {
                    isDesktop && enableRangeDatefield && !isNotFullsize && 
                        <Col
                            md={2}
                            lg={2}
                            className={classNames("d-flex justify-content-end flex-column", {
                                "checks-search-button": !isDesktop,
                            })}>
                            <Button
                                type="submit"
                                label="echeq.filter"
                                className="mt-45 mb-0 mr-0 max-height-3"
                                bsStyle="primary"
                                loading={fetching}
                                onClick={()=> handleSubmitFilters()}
                            />
                        </Col>
                }
                {isDesktop ? (
                    <Col
                        md={6}
                        xs={12}
                        className={classNames("d-flex align-items-center justify-content-start", {
                            "pl-3 pr-2": isDesktop,
                        })}>
                        <CedipFilterSelect
                            options={orderby}
                            radioOptions={radioOptions}
                            isDesktop={isDesktop}
                            optionType={recievedCedipSelected}
                            columnFilter={{
                                filterSelected: filterOrder.column,
                                directionFilter: filterOrder.direction,
                            }}
                            changeFilter={changeFilterFunc}
                            cleanFilter={cleanFilter}
                            handleChangeFilterOrder={(value) => handleChangeFilterOrder({ newColumn: value })}
                            handleChangeOrder={(value) => handleChangeOrder({ newDirection: value })}
                            setCleanFilter={(value) => setCleanFilter(value)}
                        />
                    </Col>
                ) : (
                    undefined
                )}
                {
                    (!isDesktop || isNotFullsize || !enableRangeDatefield)  && 
                        <Col
                            xs={12}
                            md={3}
                            mdOffset={3}
                            lg={2}
                            lgOffset={4}
                            className={classNames("d-flex flex-column", {
                                "pr-25 pl-4": isDesktop,
                                "checks-search-button": !isDesktop,
                            })}>
                            <Button
                                type="submit"
                                label="echeq.filter"
                                className="mt-45 mb-0 mr-0 max-height-3"
                                bsStyle="primary"
                                loading={fetching}
                                onClick={()=> handleSubmitFilters()}
                            />
                        </Col>
                }
            </Container>
        </Form>
    );
}

export default CedipFilters;
