/**
 * Este Util y sus métodos son válidos solo para
 * CBU de CMF ya que es diferente para cada banco
 */
import * as i18nUtils from "util/i18n";

export const getCurrencyAccount = (cbu) => cbu.slice(9, 10);

export const getCurrencyIso = (cbu) => i18nUtils.get(`currency.label.${getCurrencyAccount(cbu)}`);

//diferencia cbu, cvu o alias en base a un string a partir de 3 digitos o mas
export const getAccountType = (value) => {
    if (value.startsWith("000") && value.match("^[0-9]+$")) {
       return "cvu";
    } else if (!value.startsWith("000") && value.match("^[0-9]+$")) {
        return "cbu";
    } else if (value && !value.match("^[0-9]+$")) {
        return "alias";
    }
    return null
};
