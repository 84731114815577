/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { push, routerActions } from "react-router-redux";
import { func, string, shape, arrayOf, int, bool } from "prop-types";

import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { connect } from "react-redux";
import { actions, selectors } from "reducers/frequentDestination";

import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import ContextMenu from "pages/_components/ContextMenu";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { actions as transferActions } from "reducers/transfer";
import Button from "pages/_components/Button";
import { Col } from "react-bootstrap";
import classNames from "classnames";
import getTransferPath from "util/transfers";
import { actions as statusActions } from "reducers/status";

class Details extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        listFrequentDestinations: arrayOf(
            shape({
                idFrequentDestinations: int,
                accountNumber: string,
                productType: string,
                isOwn: string,
                cbu: string,
                recipientDocumentType: string,
                recipientDocumentNumber: string,
                recipientName: string,
                localBank: string,
                SubsidiaryBank: string,
                recipientEmail: string,
                name: string,
            }),
        ).isRequired,
        match: shape({
            params: shape({
                idTransaction: string.isRequired,
            }).isRequired,
        }).isRequired,
        isDesktop: bool.isRequired,
        frequentDestinationModify: shape({
            idFrequentDestinations: int,
            accountNumber: string,
            productType: string,
            isOwn: string,
            cbu: string,
            recipientDocumentType: string,
            recipientDocumentNumber: string,
            recipientName: string,
            localBank: string,
            SubsidiaryBank: string,
            recipientEmail: string,
            name: string,
        }),
        activeEnvironment: shape({
            permissions: shape({}),
        }).isRequired,
    };

    static defaultProps = {
        frequentDestinationModify: {},
    };

    state = {
        goToModify: false,
    };

    componentDidMount() {
        const { dispatch, ...rest } = this.props;
        dispatch(actions.loadListRequest());
        dispatch(statusActions.saveLastHref(rest.location));
    }

    componentDidUpdate(prevProps) {
        const { dispatch, match, frequentDestinationModify } = this.props;
        const idFrequentDestination = match.params.id;
        const { goToModify } = this.state;

        if (
            frequentDestinationModify &&
            prevProps.frequentDestinationModify !== frequentDestinationModify &&
            goToModify
        ) {
            dispatch(push(`/frequentDestination/${idFrequentDestination}/modify/`));
        }
    }

    componentWillUnmount() {
        this.setState({ goToModify: false });
    }

    handleDelete = () => {
        const { dispatch, match } = this.props;
        const idFrequentDestination = match.params.id;
        dispatch(actions.deleteFrequentDestinationPreviewRequest(idFrequentDestination));
    };

    modify = () => {
        const { dispatch, match } = this.props;
        const idFrequentDestination = match.params.id;
        this.setState({ goToModify: true });
        dispatch(actions.modifyFrequentDestinationPreRequest(idFrequentDestination));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/FrequentDestination/"));
    };

    handleClickCopy = () => {
        const { dispatch } = this.props;
        dispatch(
            notificationActions.showNotification(i18n.get("frequentDestination.copyDate"), "success", [
                "frequentDestinationsDetail",
            ]),
        );
    };

    contextMenuDetail = () => {
        const { dispatch, isDesktop, listFrequentDestinations, match, activeEnvironment } = this.props;
        const detailDestination = listFrequentDestinations.find(
            (destination) => destination.idFrequentDestination === Number(match.params.id),
        );
        const i18nParams = {
            ...detailDestination,
            recipientEmail: detailDestination.recipientEmail || "",
            isOwn: detailDestination.isOwn === "1" ? "SI" : "NO",
        };

        const textCopy = detailDestination ? i18n.get("frequentDestinations.textCopy", null, i18nParams) : "";

        const buttonShare = (
            <Button
                label="frequentDestinations.contextMenu.share"
                className={`dropdown__item-btn dropdown__item-btn-custom border-none m-0
                    ${!isDesktop ? "justify-content-start ml-0 w-100" : ""}`}
                onClick={() => {
                    if (!isDesktop && window.cordova) {
                        const options = {
                            message: textCopy,
                            subject: detailDestination.name,
                        };
                        window.plugins.socialsharing.shareWithOptions(options, null, null);
                    } else {
                        this.handleClickCopy();
                    }
                }}
            />
        );

        const showTransfersHistoric = configUtils.getBoolean("frontend.showMVP1.transfers.functionalities", false);
        const hasPermission = showTransfersHistoric ? activeEnvironment.permissions.transferAccess : true;
        const items = [];
        const transferKind = detailDestination.isOwn === "1" ? "same" : "other";
        const showMVP5transfers = configUtils.getBoolean("frontend.showMVP5.transfers.functionalities");
        if (showMVP5transfers && activeEnvironment.permissions.transferToContact) {
            items.push({
                label: "frequentDestinations.contextMenu.transfer",
                onClick: () => {
                    dispatch(
                        transferActions.loadThirdPartiesTransfer({
                            cbu: detailDestination.cbu,
                            email: detailDestination.recipientEmail,
                            transferKind,
                        }),
                    );
                    dispatch(routerActions.push(getTransferPath("toContact")));
                },
            });
        } else if (hasPermission && activeEnvironment.permissions.transferThirdParties) {
            items.push({
                label: "frequentDestinations.contextMenu.transfer",
                onClick: () => {
                    dispatch(
                        transferActions.loadThirdPartiesTransfer({
                            cbu: detailDestination.cbu,
                            email: detailDestination.recipientEmail,
                            transferKind,
                        }),
                    );
                    dispatch(routerActions.push(getTransferPath("thirdParties")));
                },
            });
        }

        return (
            <ContextMenu
                isDesktop={isDesktop}
                buttonClassName={!isDesktop ? "dropdown-btn account-dropdown-header-font font-black-alpha" : ""}
                items={items}>
                {isDesktop || !window.cordova ? (
                    <CopyToClipboard text={textCopy}>{buttonShare}</CopyToClipboard>
                ) : (
                    buttonShare
                )}
            </ContextMenu>
        );
    };

    centerContentMobile = () => {
        const { listFrequentDestinations, match } = this.props;
        const detailDestination = listFrequentDestinations.find(
            (destination) => destination.idFrequentDestination === Number(match.params.id),
        );
        return (
            <div>
                <h1 className="m-0">{detailDestination.name}</h1>
            </div>
        );
    };

    renderHeader = () => {
        const { listFrequentDestinations, match, fetching, isDesktop, activeEnvironment } = this.props;
        const detailDestination = listFrequentDestinations.find(
            (destination) => destination.idFrequentDestination === Number(match.params.id),
        );
        const managePermission = activeEnvironment.permissions["frequentDestination.manage"];

        return (
            detailDestination && (
                <>
                    <Notification scopeToShow="frequentDestinationsDetail" />
                    <div className={classNames("admin-detail-head px-0", { "mb-3": isDesktop })}>
                        {isDesktop ? (
                            <>
                                <Head onBack={this.handleBack} />
                                <Head
                                    titleText={detailDestination.name}
                                    handleDeleteClick={managePermission ? this.handleDelete : undefined}
                                    handleModifyClick={managePermission ? this.modify : undefined}
                                    rightContent={this.contextMenuDetail}
                                    fetching={fetching}
                                />
                            </>
                        ) : (
                            <Head
                                onBack={this.handleBack}
                                headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                                centerElement={isDesktop ? null : this.centerContentMobile}
                                rightContent={this.contextMenuDetail}
                            />
                        )}
                    </div>
                </>
            )
        );
    };

    renderContent = () => {
        const { listFrequentDestinations, match, isDesktop, activeEnvironment } = this.props;

        const detailDestination = listFrequentDestinations.find(
            (destination) => destination.idFrequentDestination === Number(match.params.id),
        );

        const containerClassName = `transfer-data data-wrapper-flex ${
            !isDesktop ? "space-between mb-0 container-details" : ""
        }`;
        const labelClassName = `font-weight-600 font-light ${isDesktop ? "mb-0" : ""}`;
        const managePermission = activeEnvironment.permissions["frequentDestination.manage"];
        const documento = `${detailDestination.recipientDocumentType} ${detailDestination.recipientDocumentNumber}`;
        let bankInfo = detailDestination.localBank;
        if(!detailDestination.cbu.startsWith("000")){
            bankInfo += ` / ${detailDestination.subsidiaryBank}`;
        }
        
        return (
            <>
                {this.renderHeader()}
                {detailDestination && (
                    <MainContainer>
                        <div className="above-the-fold">
                            <Container
                                className={`flex-grow container-white with-padding ${isDesktop ? "-1-rem" : ""}`}>
                                <Container.Column md={4} sm={12}>
                                    {/* cuenta credito */}
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestinations.creditAccount.title"
                                        data={detailDestination.accountNumber}
                                    />
                                    {/* tipo de cuenta */}
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestinations.accountType.title"
                                        data={detailDestination.productType}
                                    />

                                    {/* cuenta propia */}
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestinations.account.title"
                                        data={detailDestination.isOwn === "1" ? "SI" : "NO"}
                                    />
                                </Container.Column>

                                <Container.Column md={4} sm={12}>
                                    {/* CBU/CVU */}
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestinations.cbu.cvu.title"
                                        data={detailDestination.cbu}
                                    />
                                    {/* Tipo de documento */}
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestinations.document.title"
                                        data={documento}
                                    />

                                    {/* titular */}
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestinations.headline.title"
                                        data={detailDestination.recipientName}
                                    />
                                </Container.Column>

                                <Container.Column md={4} sm={12}>
                                    {/* banco / sucursal */}
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label={`frequentDestinations.${detailDestination.cbu.startsWith("000") ? "entity" : "bank" }Direction.title`}
                                        data={bankInfo}
                                    />
                                    {/* email */}
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestinations.email.title"
                                        data={detailDestination.recipientEmail}
                                    />
                                    {/* referencia */}
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestinations.reference.title"
                                        data={detailDestination.name}
                                    />
                                    {/* </Container.Column> */}
                                </Container.Column>
                            </Container>
                            {!isDesktop && (
                                <Container className="align-items-center flex-grow">
                                    <Col
                                        className="col col-12"
                                        style={{
                                            height: "40vh",
                                            marginTop: "auto",
                                            display: "flex",
                                            alignItems: "flex-end",
                                        }}>
                                        {managePermission ? (
                                            <Col className="col col-12 px-0">
                                                <Button
                                                    onClick={this.handleDelete}
                                                    className="my-1"
                                                    label="administration.groups.list.delete"
                                                    bsStyle="primary"
                                                />
                                                <Button
                                                    onClick={this.modify}
                                                    className="my-1 btn-outline"
                                                    image="/images/pencil.svg"
                                                    label="administration.groups.list.modifyGroup"
                                                />
                                            </Col>
                                        ) : (
                                            undefined
                                        )}
                                    </Col>
                                </Container>
                            )}
                        </div>
                    </MainContainer>
                )}
            </>
        );
    };

    render() {
        const isLoading = false;
        return (
            <>
                <Notification scopeToShow="FrequentDestinationsDetail" />
                <PageLoading loading={isLoading}>{!isLoading && this.renderContent()}</PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    listFrequentDestinations: selectors.getListFrequentDestinations(state),
    fetching: selectors.getFetching(state),
    frequentDestinationModify: selectors.getFrequentDestinationModify(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(Details);
