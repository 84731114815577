/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func, number, arrayOf, shape, string } from "prop-types";
import { compose } from "redux";
import Yup from "yup";
import { Field, Form, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import moment from "moment";
import classNames from "classnames";
import { isIOS } from "react-device-detect";

import { actions as metrocorpActions, selectors as metrocorpSelectors } from "reducers/metrocorp";
import { actions as globalActions } from "reducers/types/global";

import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import PageLoading from "pages/_components/PageLoading";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import EquivalentTotalBalance from "pages/metrocorp/_components/EquivalentTotalBalance";
import I18n from "pages/_components/I18n";
import Selector from "pages/_components/fields/formik/Selector";
import DateField from "pages/_components/fields/DateField";
import RangeDatePicker from "pages/_components/fields/rangedatepicker/RangeDatePicker";
import TabletSelectors from "pages/_components/TabletSelectors";
import MetrocorpMovementDetail from "pages/metrocorp/_components/MetrocorpMovementDetail";
import MetrocorpList from "pages/metrocorp/_components/MetrocorpList";
import Image from "pages/_components/Image";

import metrocorpTitleFirstPart from "styles/images/metrocorp-grey.svg";

import uniqBy from "lodash/uniqBy";
import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";

const FORM_ID = "metrocorp.list";

class MetrocorpMain extends Component {
    static propTypes = {
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        handleSubmit: func.isRequired,
        fetchingDownload: bool.isRequired,
        activeTotal: number.isRequired,
        holdings: arrayOf(shape({})).isRequired,
        dateHoldings: arrayOf(shape({})).isRequired,
        futureValues: arrayOf(shape({})).isRequired,
        movements: arrayOf(shape({})).isRequired,
        fullMovementsList: arrayOf(shape({})).isRequired,
        filtersData: shape({}).isRequired,
        values: shape({}).isRequired,
        clients: arrayOf(shape({})).isRequired,
        optionSelected: string.isRequired,
        setFieldValue: func.isRequired,
    };

    constructor(props) {
        super(props);
        const { values } = props;

        this.state = {
            isDisplayed: false,
            selectedDateFrom: null,
            currentDate: values.date,
            showForm: false,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(metrocorpActions.listMetrocorpPre());
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(globalActions.resetSelectedElements());
    }

    handleChangeDateFrom = (selectedDate) => {
        this.setState({ selectedDateFrom: selectedDate });
    };

    handleOpenBottomSheetClick = () => {
        this.setState({ isDisplayed: true });
    };

    handleCloseBottomSheetClick = () => {
        this.setState({ isDisplayed: false });
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    handleExport = (format) => {
        const {
            dispatch,
            activeTotal,
            holdings,
            futureValues,
            fullMovementsList,
            filtersData,
            optionSelected,
        } = this.props;

        const summary = {
            activeTotal,
            holdings,
            futureValues,
            movements: fullMovementsList,
            optionSelected,
            filtersData,
        };
        dispatch(metrocorpActions.downloadMetrocorpList(summary, format));
    };

    changeOption = (option) => {
        const { dispatch, setFieldValue, clients } = this.props;
        const selectedClient = clients && clients.length > 0 ? clients[0].codClie : "";

        setFieldValue("principalAccount", selectedClient);
        setFieldValue("species", "all");
        setFieldValue("date", moment());
        setFieldValue("dateFrom", moment());
        setFieldValue("dateTo", moment());
        setFieldValue("dates", [new Date(), new Date()]);

        dispatch(
            metrocorpActions.saveOptionSelected(
                option,
                selectedClient ? `${selectedClient.nombre} ${selectedClient.apellido}` : "",
                selectedClient ? selectedClient.codClie : "",
                "all",
                moment(),
                moment(),
                moment(),
            ),
        );

        dispatch(
            metrocorpActions.listMetrocorp(
                option,
                `${selectedClient.nombre} ${selectedClient.apellido}`,
                selectedClient,
                "all",
                moment(),
                moment(),
                moment(),
                1,
            ),
        );
    };

    centerElement = () => {
        const { isDesktop } = this.props;

        if (isDesktop) {
            return (
                <div className="metrocorp__head">
                    <img src={metrocorpTitleFirstPart} alt="Metrocorp" className="metrocorp__brand" />
                    <h1 className="my-0 ml-2 px-1">|</h1>
                    <div className="ml-2">
                        <I18n id="metrocorp.title.secondPart" component="h1" componentProps={{ className: "my-0" }} />
                    </div>
                </div>
            );
        }
        return (
            <div
                className={classNames("metrocorp__head view-title data-wrapper-flex", "justify-content-center", {
                    "align-center": !isDesktop,
                })}>
                <div>
                    <I18n
                        id="metrocorp.title.secondPart"
                        component="h1"
                        componentProps={{ className: "cmf-title-center my-0" }}
                    />
                    <Image src="images/metrocorp-white.svg" className="metrocorp__brand" />
                </div>
            </div>
        );
    };

    modalDetail = () => {
        const { isDisplayed } = this.state;
        const { isDesktop } = this.props;

        return (
            <MetrocorpMovementDetail
                handleCloseBottomSheetClick={this.handleCloseBottomSheetClick}
                goBack={this.handleCloseBottomSheetClick}
                isDisplayed={isDisplayed}
                isDesktop={isDesktop}
            />
        );
    };

    rowButtons = () => {
        const { isDesktop, optionSelected } = this.props;

        const possibleOptions = ["holdings", "futureValues", "movements"];
        const options = possibleOptions.map((possibleOption) => ({
            value: possibleOption,
            label: `metrocorp.${possibleOption}.label`,
        }));

        return (
            <TabletSelectors
                isDesktop={isDesktop}
                possibleOptions={options}
                changeOption={this.changeOption}
                optionSelected={optionSelected}
                section="metrocorp"
                containerClass="metrocorp-tablet-selector py-25"
            />
        );
    };

    getSpecies = () => {
        const { optionSelected, dateHoldings, holdings, futureValues, movements } = this.props;
        const data = {
            holdings: holdings || dateHoldings,
            futureValues,
            movements,
        };

        if (!optionSelected || !data[optionSelected]?.length) {
            return [];
        }

        return uniqBy(
            data[optionSelected].map((item) => ({
                value: item.codEspe,
                label: item.codEspe + item.descripcionEspecie,
            })),
            "label",
        );
    };

    renderForm() {
        const { fetching, values, clients, optionSelected, handleSubmit, setFieldValue, isDesktop } = this.props;

        const { selectedDateFrom, showForm } = this.state;
        const { dateFrom, dateTo, principalAccount } = values;

        const principalAccounts = clients.map((client) => ({ value: client.codClie, label: client.codClie }));
        const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

        if (!principalAccount && clients && clients.length > 0) {
            setFieldValue("principalAccount", clients[0].codClie);
        }

        const speciesDefault = {
            value: "all",
            label: i18nUtils.get(`${FORM_ID}.search.species.options.all`),
        };

        const species = this.getSpecies();
        const speciesToShow = [speciesDefault, ...species];

        const options = speciesToShow.map((speciesShow) => ({
            value: speciesShow.value,
            label: speciesShow.label,
        }));

        let mdCol = {};
        switch (optionSelected) {
            case "holdings":
                mdCol = {
                    account: 3,
                    species: 3,
                    date: 3,
                    dateTo: null,
                    dateFrom: null,
                    button: 3,
                };
                break;
            case "movements":
                mdCol = {
                    account: 6,
                    species: 6,
                    date: null,
                    dateTo: 4,
                    dateFrom: 4,
                    button: 4,
                };
                break;
            default:
                mdCol = {
                    account: 4,
                    species: 4,
                    date: null,
                    dateTo: null,
                    dateFrom: null,
                    button: 4,
                };
                break;
        }

        return (
            (isDesktop || showForm) && (
                <Form
                    onSubmit={async (e) => {
                        await handleSubmit(e);
                        this.setState({
                            showForm: false,
                            currentDate: values.date,
                        });
                    }}
                    autoComplete="off"
                    className={enableRangeDatefield ? "metrocorp__form_v2" : ""}>
                    <Container
                        className={classNames(
                            "align-items-left account-header-detail account-header-align-items-left",
                            { "background-transparent": !isDesktop },
                            { "py-3": isDesktop },
                        )}
                        gridClassName="form-content"
                        rowClassName={`justify-content-left align-items-start ${isIOS && "w-100 m-0"}`}>
                        <Col sm={12} md={mdCol.account} lg={3}>
                            <Field
                                component={Selector}
                                options={principalAccounts}
                                idForm={`${FORM_ID}.search`}
                                name="principalAccount"
                                isRequired
                                hidePlaceholder
                                controlLabelClassName="color-black"
                            />
                        </Col>
                        <Col sm={12} md={mdCol.species} lg={3}>
                            <Field
                                component={Selector}
                                options={options}
                                idForm={`${FORM_ID}.search`}
                                name="species"
                                isRequired
                                controlLabelClassName="color-black"
                                searchable={isDesktop}
                                filterOption={(option, inputValue) =>
                                    option.label.toLowerCase().includes(inputValue.toLowerCase())
                                }
                            />
                        </Col>

                        {optionSelected === "holdings" && (
                            <Col sm={12} md={mdCol.date} lg={2}>
                                <Field
                                    component={DateField}
                                    idForm={`${FORM_ID}.search`}
                                    name="date"
                                    hidePlaceholder
                                    minDate={null}
                                    maxDate={moment()}
                                    isRequired
                                    controlLabelClassName="color-black"
                                />
                            </Col>
                        )}

                        {optionSelected === "movements" && (
                            !enableRangeDatefield ? 
                                <>
                                    <Col sm={6} md={mdCol.dateFrom} lg={2} className="col-6">
                                        <Field
                                            component={DateField}
                                            idForm={`${FORM_ID}.search`}
                                            name="dateFrom"
                                            hidePlaceholder
                                            startDate={dateFrom}
                                            handleChange={this.handleChangeDateFrom}
                                            endDate={dateTo}
                                            isRequired
                                            minDate={null}
                                            controlLabelClassName="color-black"
                                        />
                                    </Col>
                                    <Col sm={6} md={mdCol.dateTo} lg={2} className="col-6">
                                        <Field
                                            component={DateField}
                                            hidePlaceholder
                                            idForm={`${FORM_ID}.search`}
                                            name="dateTo"
                                            minDate={selectedDateFrom}
                                            isRequired
                                            controlLabelClassName="color-black"
                                        />
                                    </Col>
                                </>
                            :
                            <Col sm={12} md={6} lg={4} className="mt-1">
                                <Field
                                    component={RangeDatePicker}
                                    idForm={`${FORM_ID}.search`}
                                    name="dates"
                                    required
                                />
                            </Col>
                        )}

                        <Col sm={12} md={mdCol.button} lg={2}>
                            <Button
                                bsStyle="primary"
                                label={`${FORM_ID}.search.button`}
                                loading={fetching}
                                type="submit"
                                className="metrocorp__button-filters"
                            />
                        </Col>
                    </Container>
                </Form>
            )
        );
    }

    renderList = () => {
        const { currentDate } = this.state;
        return (
            <MetrocorpList
                {...this.props}
                handleOpenBottomSheetClick={this.handleOpenBottomSheetClick}
                currentDate={currentDate}
            />
        );
    };

    renderContent() {
        const { isDesktop, fetchingDownload, activeTotal, optionSelected, fetching, filtersData } = this.props;
        const { showForm } = this.state;

        if (isDesktop) {
            return (
                <div className="admin-detail-head px-0 mb-0">
                    <Head onBack={this.handleBack} />
                    <Head
                        centerElement={this.centerElement}
                        isFetchingExport={fetchingDownload}
                        exportList
                        handleClick={this.handleExport}
                        handleClickMessage="global.download"
                        csvDownload
                        xlsDownload
                        accessibilityTextId="metrocorp.title.secondPart"
                        imageStyle="mr-2"
                    />
                    <div className="metrocorp__total-assets">
                        <EquivalentTotalBalance
                            balance={activeTotal}
                            isDesktop={isDesktop}
                            title="metrocorp.balance.activeTotal"
                            bold
                        />
                    </div>
                    <div className="mt-3 mb-1">{this.rowButtons()}</div>
                    <PageLoading loading={fetching} className="line-loader">
                        {this.modalDetail()}
                        {this.renderForm()}
                        {this.renderList()}
                    </PageLoading>
                </div>
            );
        }

        return (
            <>
                <Head
                    centerElement={this.centerElement}
                    headerClassName="blue-main-header-mobile"
                    centerContentClassName="mx-5"
                    accessibilityTextId="metrocorp.title.secondPart"
                    isFetchingExport={fetchingDownload}
                    onBack={this.handleBack}
                    downloadImageWhite={!fetching}
                    handleClickMessage="global.download"
                    handleClick={this.handleExport}
                    hasInlineButtons
                    exportList={!fetching}
                    csvDownload
                    xlsDownload
                />
                {this.rowButtons()}
                {!isDesktop && (
                    <Col xs={12} className="justify-content-end d-flex">
                        <Button
                            block
                            className="btn-link p-0 right"
                            label={showForm ? "echeq.button.hideFilter" : "echeq.button.seeFilter"}
                            onClick={() => this.setState((prev) => ({ ...prev, showForm: !prev.showForm }))}
                        />
                    </Col>
                )}
                <PageLoading
                    loading={fetching && !(!isDesktop && optionSelected === "movements" && filtersData.page > 1)}
                    className="screen-loader">
                    {this.renderForm()}
                    <div className={`metrocorp__total-acount ${isIOS && "mx-3 w-auto"}`}>
                        <EquivalentTotalBalance
                            balance={activeTotal}
                            isDesktop={isDesktop}
                            title="metrocorp.balance.activeTotal"
                            bold
                            block
                        />
                    </div>
                    {this.renderList()}
                </PageLoading>
            </>
        );
    }

    render() {
        const { isDesktop } = this.props;

        return (
            <>
                <Notification
                    scopeToShow={FORM_ID}
                    notificationClassname={classNames({ "navbar-fixed-top": !isDesktop })}
                />
                <div className="metrocorp__page">{this.renderContent()}</div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: metrocorpSelectors.getFetching(state),
    fetchingDownload: metrocorpSelectors.getFetchingDownload(state),
    activeTotal: metrocorpSelectors.getActiveTotal(state),
    holdings: metrocorpSelectors.getHoldings(state),
    dateHoldings: metrocorpSelectors.getDateHoldings(state),
    futureValues: metrocorpSelectors.getFutureValues(state),
    movements: metrocorpSelectors.getMovements(state),
    fullMovementsList: metrocorpSelectors.getFullMovementsList(state),
    filtersData: metrocorpSelectors.getFiltersData(state),
    clients: metrocorpSelectors.getClients(state),
    optionSelected: metrocorpSelectors.getOptionSelected(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: true,
        validateOnBlur: true,
        mapPropsToValues: (props) => ({
            principalAccount: props.clients && props.clients.length > 0 ? props.clients[0].codClie : "",
            species: "all",
            date: moment(),
            dateFrom: moment(),
            dateTo: moment(),
        }),
        validationSchema: (props) =>
            Yup.lazy((values) => {
                const { optionSelected } = props;
                const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

                let validations = {
                    principalAccount: Yup.string().required(
                        i18nUtils.get(`${FORM_ID}.search.principalAccount.error.required`),
                    ),
                    species: Yup.string().required(i18nUtils.get(`${FORM_ID}.search.species.error.required`)),
                };

                if (optionSelected === "holdings") {
                    validations = {
                        ...validations,
                        date: Yup.date()
                            .nullable()
                            .required(i18nUtils.get(`${FORM_ID}.search.date.error.required`)),
                    };
                }

                if (optionSelected === "movements" && !enableRangeDatefield) {
                    validations = {
                        ...validations,
                        dateFrom: values.dateTo
                            ? Yup.date()
                                  .nullable()
                                  .max(values.dateTo, i18nUtils.get(`${FORM_ID}.search.dateFrom.max.error`))
                                  .required(i18nUtils.get(`${FORM_ID}.search.date.error.required`))
                            : Yup.date()
                                  .nullable()
                                  .required(i18nUtils.get(`${FORM_ID}.search.date.error.required`)),
                        dateTo: values.dateFrom
                            ? Yup.date()
                                  .nullable()
                                  .min(values.dateFrom, i18nUtils.get(`${FORM_ID}.search.dateTo.min.error`))
                                  .required(i18nUtils.get(`${FORM_ID}.search.date.error.required`))
                            : Yup.date()
                                  .nullable()
                                  .required(i18nUtils.get(`${FORM_ID}.search.date.error.required`)),
                    };
                }
                if (optionSelected === "movements" && enableRangeDatefield) {
                    validations = {
                        ...validations,
                        dates: Yup.mixed().test(
                            "dates",
                            i18nUtils.get(`${FORM_ID}.validation.range.date`),
                            ([from, to]) => from && to
                        )
                    }
                }

                return Yup.object().shape({
                    ...validations,
                });
            }),
        handleSubmit: ({ principalAccount, species, date, dateFrom, dateTo, dates }, formikBag) => {
            const { dispatch, optionSelected, clients } = formikBag.props;
            const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

            const selectedClient = clients.filter((client) => client.codClie === principalAccount)[0];

            dispatch(
                metrocorpActions.listMetrocorp(
                    optionSelected,
                    `${selectedClient.nombre} ${selectedClient.apellido}`,
                    principalAccount,
                    species,
                    date,
                    enableRangeDatefield && dates ? dates[0] : dateFrom,
                    enableRangeDatefield && dates ? dates[1] : dateTo,
                    1,
                ),
            );
        },
    }),
)(MetrocorpMain);
