import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { actions as recoveryPasswordActions, selectors as recoveryPasswordSelectors } from "reducers/recoveryPassword";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import withExchangeToken from "pages/_components/withExchangeToken";
import { selectors as statusSelectors } from "reducers/status";
import MainContainer from "pages/_components/MainContainer";
import Step1Content from "pages/recoveryPassword/_components/Step1Content";
import Head from "pages/_components/Head";
import { bool, func, shape, string } from "prop-types";
import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";
import Logo from "pages/login/_components/Logo";
import Container from "pages/_components/Container";
import { Col } from "react-bootstrap";
import I18n from "pages/_components/I18n";
import * as configUtils from "util/config";

class RecoveryPassStep1 extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(recoveryPasswordActions.recoveryPassCleanUp());
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    renderTitleMobile = () => (
        <div className="w-min-content">
            <Logo className="svg-image logo logo-mobile-size" logoColor />
        </div>
    );

    render() {
        const { isDesktop, propsGrid, lastPathname } = this.props;

        const step2 = lastPathname === "/loginStep2";

        return (
            <GridLayoutExternal scopeToShow="recoveryPassword" isDesktop={isDesktop} {...propsGrid}>
                <div className="admin-detail-head px-0">
                    <Container>
                        <Col xs="12" className={isDesktop ? "px-0" : ""}>
                            <Head
                                title={
                                    isDesktop
                                        ? `${
                                              !step2
                                                  ? "recoveryPassword.step1.header"
                                                  : "recoveryPassword.step1.only.pass.header"
                                          }`
                                        : null
                                }
                                onClose={this.onHeaderClose}
                                headerClassName={
                                    !isDesktop
                                        ? "login-background-color-white"
                                        : "recover-pass-desktop login-header-desktop"
                                }
                                centerElement={!isDesktop && this.renderTitleMobile}
                                hideMobileMenu={!isDesktop && true}
                                fixed
                                tag="h4"
                            />
                        </Col>
                    </Container>
                </div>
                <MainContainer
                    className={classNames("login__layout main-container", {
                        "container-fluid": isDesktop,
                        "px-3": !isDesktop,
                    })}>
                    {!isDesktop && configUtils.getBoolean("mobile.recovercredentials.enabled", false) && (
                        <Container className="container--layout flex-grow">
                            <Col className={`col col-12 view-title ${!isDesktop && "px-0"}`}>
                                <div className="justify-content-center">
                                    <I18n
                                        id={`${
                                            !step2
                                                ? "recoveryPassword.step1.header"
                                                : "recoveryPassword.step1.only.pass.header"
                                        }`}
                                        component="h2"
                                        componentProps={{ className: "f-size-5 font-regular mt-0" }}
                                    />
                                </div>
                            </Col>
                        </Container>
                    )}
                    <Step1Content {...this.props} />
                </MainContainer>
            </GridLayoutExternal>
        );
    }
}

RecoveryPassStep1.propTypes = {
    dispatch: func.isRequired,
    propsGrid: shape({}).isRequired,
    isDesktop: bool,
    lastPathname: string.isRequired,
};

RecoveryPassStep1.defaultProps = {
    isDesktop: true,
};

const mapStateToProps = (state) => ({
    showCaptcha: recoveryPasswordSelectors.getShowCaptcha(state),
    exchangeToken: recoveryPasswordSelectors.getExchangeToken(state),
    maskedEmail: loginSelectors.getMaskedEmail(state),
    maskedPhone: loginSelectors.getMaskedPhone(state),
    userName: loginSelectors.getUsername(state),
    lastPathname: statusSelectors.getPathname(state),
});

export default connect(mapStateToProps)(withExchangeToken(RecoveryPassStep1));
