import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { bool, func, string, shape, instanceOf, arrayOf } from "prop-types";
import { Field, Form, withFormik } from "formik";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import classNames from "classnames";

import TabletSelectors from "pages/_components/TabletSelectors";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import { DateField } from "pages/_components/fields/DateField";
import FormattedAmount from "pages/_components/FormattedAmount";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import Heading from "pages/_components/Heading";
import RangeDatePicker from "pages/_components/fields/rangedatepicker/RangeDatePicker";

import { actions as financingActions } from "reducers/financing";

import { parseFloatToAmountFormat } from "util/number";
import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";
import InfoTag from "../../_components/InfoTag";

const FORM_ID = "financing.feeds";
class MovementsFinancing extends Component {
    static propTypes = {
        id: string.isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        financingFeed: arrayOf(shape([])).isRequired,
        financingDetail: shape([]).isRequired,
        listCategories: arrayOf(shape({})).isRequired,
        values: shape({
            lastDateTo: instanceOf(moment),
            lastDateFrom: instanceOf(moment),
            dates: shape([]),
        }),
        optionSelected: string.isRequired,
        fetching: bool,
    };

    static defaultProps = {
        values: {},
        fetching: false,
    };

    state = {
        showForm: false,
    };

    changeOption = (option) => {
        const { dispatch, id, listCategories } = this.props;
        if (option === "toPay") {
            dispatch(financingActions.getFinancingDetailReques(id, listCategories, "3"));
        } else {
            dispatch(financingActions.getFinancingDetailReques(id, listCategories, "1"));
        }

        dispatch(financingActions.setOptionSelected(option));
    };

    rowButtons = () => {
        const { isDesktop, optionSelected } = this.props;

        const possibleOptions = ["toPay", "paid"];
        const options = possibleOptions.map((possibleOption) => ({
            value: possibleOption,
            label: `${FORM_ID}.${possibleOption}.label`,
        }));

        return (
            <TabletSelectors
                isDesktop={isDesktop}
                possibleOptions={options}
                changeOption={this.changeOption}
                optionSelected={optionSelected}
                containerClass={classNames("my-0 p-3", { "bg-white": !isDesktop })}
                buttonClass={classNames("align-self-flex-end m-0", {
                    "w-min-224": isDesktop,
                    "mh-auto movements-financing-button": !isDesktop,
                })}
                buttonContainerClass={classNames({ "d-flex flex": !isDesktop, "m-0": isDesktop })}
                section="MovementsFinancing"
            />
        );
    };

    getStrDate = (str) => {
        if (!str) {
            return "";
        }
        const date = str.substring(0, str.indexOf("T"));
        return moment(date).format("DD/MM/YYYY");
    };

    statusTag = (estado) => {
        if ([1, 2, 3].includes(estado)) {
            return i18nUtils.get(`financing.feeds.state.label.${estado}`);
        }
        return i18nUtils.get("financing.feeds.state.label.default");
    };

    statusTagColor = (estado) => {
        switch (estado) {
            case 1:
                return "#84efd0";
            case 2:
                return "#feb0af";
            case 3:
                return "#ffe5b6";
            case 4:
                return "#ffe5b6";
            default:
                return "#84efd0";
        }
    };

    renderTableToPay = () => {
        const { financingFeed, isDesktop, financingDetail } = this.props;
        const list = financingFeed.map((movement, index) => {
            const { numeroCuota, fechaVencimiento, estado, saldoCuota } = movement;
            return (
                <Link
                    className={classNames("account-table-wrapper row", {
                        "text-decoration-none pt-2 mb-2": !isDesktop,
                    })}
                    renderAs={Link}
                    to={`/financing/feeds/detail/${index}`}>
                    <>
                        {isDesktop ? (
                            <Col sm={12} md={1} className="align-self-center text-align-left">
                                <span className="data-aux">{numeroCuota}</span>
                            </Col>
                        ) : (
                            <div className="space-between w-100 align-items-center pr-2">
                                <Heading.DataGroup
                                    label="feeds.toPay.table.header.feed"
                                    data={numeroCuota}
                                    containerClassName="w-100 transfer-data pl-3"
                                    labelClassName="mb-0"
                                />
                                <InfoTag
                                    type="default"
                                    message={this.statusTag(estado)}
                                    tagBackground={this.statusTagColor(estado)}
                                    moreStyles={{
                                        display: "flex",
                                        alignSelf: "flex-end",
                                        width: "min-content",
                                        color: "black",
                                        padding: "6px 12px",
                                        fontWeight: "bold",
                                    }}
                                />
                            </div>
                        )}

                        {isDesktop ? (
                            <Col sm={12} md={5} className="align-self-center text-align-center justify-content-center">
                                <span className="data-aux">{this.getStrDate(fechaVencimiento)}</span>
                            </Col>
                        ) : (
                            <Heading.DataGroup
                                label="feeds.toPay.table.header.date"
                                data={this.getStrDate(fechaVencimiento)}
                                containerClassName="w-100 transfer-data data-wrapper-flex space-between pl-3 pr-3"
                                labelClassName="mb-0"
                            />
                        )}

                        {isDesktop ? (
                            <Col sm={12} md={3} className="align-self-center text-align-right justify-content-right">
                                <FormattedAmount
                                    currency={i18nUtils.get(`currency.label.${financingDetail.codigoMoneda}`)}
                                    className="data-amount"
                                    quantity={saldoCuota}
                                    medium
                                    notBold
                                />
                            </Col>
                        ) : (
                            <Heading.DataGroup
                                label="feeds.toPay.table.header.amount"
                                currency={i18nUtils.get(`currency.label.${financingDetail.codigoMoneda}`)}
                                data={`${i18nUtils.get(`currency.label.${financingDetail.codigoMoneda}`)} 
                                    ${parseFloatToAmountFormat(saldoCuota)}`}
                                containerClassName="w-100 transfer-data data-wrapper-flex space-between pl-3 pr-3"
                                labelClassName="mb-0"
                            />
                        )}

                        {isDesktop && (
                            <Col sm={12} md={3} className="align-self-center text-align-left justify-content-end flex">
                                <InfoTag
                                    type="default"
                                    message={this.statusTag(estado)}
                                    tagBackground={this.statusTagColor(estado)}
                                    moreStyles={{
                                        display: "flex",
                                        alignSelf: "flex-end",
                                        width: "min-content",
                                        color: "black",
                                        margin: "0.5rem 0",
                                        padding: "6px 12px",
                                        fontWeight: "bold",
                                        fontFamily: "Graphik-Regular",
                                    }}
                                />
                            </Col>
                        )}
                    </>
                </Link>
            );
        });

        return (
            <>
                {isDesktop && (
                    <Row className="account-table-wrapper account-table-header mt-2">
                        <Col md={1} className="align-self-center text-align-left">
                            <I18n id="feeds.toPay.table.header.feed" />
                        </Col>
                        <Col md={5} className="align-self-center text-align-center justify-content-center">
                            <I18n id="feeds.toPay.table.header.date" />
                        </Col>

                        <Col md={3} className="align-self-center text-align-right justify-content-right">
                            <I18n id="feeds.toPay.table.header.amount" />
                        </Col>

                        <Col md={3} className="align-self-center text-align-left justify-content-end flex">
                            <I18n id="feeds.toPay.table.header.state" />
                        </Col>
                    </Row>
                )}

                {list.length > 0 ? (
                    list
                ) : (
                    <div className="text-center no-more-data" key="noMoreMovements">
                        <div className="illustration-wrapper">
                            <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                        </div>
                        <p className="text-lead">
                            <I18n id="feeds.notFound" />
                        </p>
                    </div>
                )}
            </>
        );
    };

    renderTablePaid = () => {
        const { financingFeed, isDesktop, financingDetail } = this.props;
        const list = financingFeed.map((movement, index) => {
            const { numeroCuota, fechaPago, estado, saldoCuota } = movement;
            return (
                <Link
                    className={classNames("account-table-wrapper row", {
                        "text-decoration-none pt-2 mb-2": !isDesktop,
                    })}
                    renderAs={Link}
                    to={`/financing/feeds/detail/${index}`}>
                    <>
                        {isDesktop ? (
                            <Col sm={12} md={3} className="align-self-center text-align-left">
                                <span className="data-aux">{numeroCuota}</span>
                            </Col>
                        ) : (
                            <div className="space-between w-100 align-items-center pl-3 pr-2">
                                <Heading.DataGroup
                                    label="feeds.paid.table.header.feed"
                                    data={numeroCuota}
                                    containerClassName="transfer-data data-wrapper-flex space-between"
                                    labelClassName="mb-0"
                                />
                                <InfoTag
                                    type="default"
                                    message={this.statusTag(estado)}
                                    tagBackground={this.statusTagColor(estado)}
                                    moreStyles={{
                                        display: "flex",
                                        alignSelf: "flex-end",
                                        width: "min-content",
                                        color: "black",
                                        padding: "6px 12px",
                                        fontWeight: "bold",
                                    }}
                                />
                            </div>
                        )}

                        {isDesktop ? (
                            <Col sm={12} md={3} className="align-self-center text-align-center justify-content-center">
                                <span className="data-aux">{this.getStrDate(fechaPago)}</span>
                            </Col>
                        ) : (
                            <Heading.DataGroup
                                label="feeds.paid.table.header.date"
                                data={this.getStrDate(fechaPago)}
                                containerClassName="w-100 transfer-data data-wrapper-flex space-between pl-3 pr-3"
                                labelClassName="mb-0"
                            />
                        )}

                        {isDesktop ? (
                            <Col sm={12} md={3} className="align-self-center text-align-right justify-content-right">
                                <FormattedAmount
                                    currency={i18nUtils.get(`currency.label.${financingDetail.codigoMoneda}`)}
                                    className="data-amount"
                                    quantity={saldoCuota}
                                    medium
                                    notBold
                                />
                            </Col>
                        ) : (
                            <Heading.DataGroup
                                label="feeds.paid.table.header.amount"
                                currency={i18nUtils.get(`currency.label.${financingDetail.codigoMoneda}`)}
                                data={`${i18nUtils.get(`currency.label.${financingDetail.codigoMoneda}`)} 
                                    ${parseFloatToAmountFormat(saldoCuota)}`}
                                containerClassName="w-100 transfer-data data-wrapper-flex space-between pl-3 pr-3"
                                labelClassName="mb-0"
                            />
                        )}

                        {isDesktop && (
                            <Col sm={12} md={3} className="align-self-center text-align-left justify-content-end flex">
                                <InfoTag
                                    type="default"
                                    message={this.statusTag(estado)}
                                    tagBackground={this.statusTagColor(estado)}
                                    moreStyles={{
                                        display: "flex",
                                        alignSelf: "flex-end",
                                        width: "min-content",
                                        color: "black",
                                        margin: "0.5rem 0",
                                        padding: "6px 12px",
                                        fontWeight: "bold",
                                        fontFamily: "Graphik-Regular",
                                    }}
                                />
                            </Col>
                        )}
                    </>
                </Link>
            );
        });

        return (
            <>
                {isDesktop && (
                    <Row className="account-table-wrapper account-table-header mt-2">
                        <Col sm={12} md={3} className="align-self-center text-align-left">
                            <I18n id="feeds.paid.table.header.feed" />
                        </Col>
                        <Col sm={12} md={3} className="align-self-center text-align-center justify-content-center">
                            <I18n id="feeds.paid.table.header.date" />
                        </Col>
                        <Col sm={12} md={3} className="align-self-center text-align-right justify-content-right">
                            <I18n id="feeds.paid.table.header.amount" />
                        </Col>
                        <Col sm={12} md={3} className="align-self-center text-align-left justify-content-end flex">
                            <I18n id="feeds.paid.table.header.state" />
                        </Col>
                    </Row>
                )}
                <div>
                    {list.length > 0 ? (
                        list
                    ) : (
                        <div className="text-center no-more-data" key="noMoreMovements">
                            <div className="illustration-wrapper">
                                <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                            </div>
                            <p className="text-lead">
                                <I18n id="feeds.notFound" />
                            </p>
                        </div>
                    )}
                </div>
            </>
        );
    };

    renderPeriod = () => {
        const {
            values: { lastDateTo, lastDateFrom },
            isDesktop,
        } = this.props;
        const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);
        const maxDateFrom = moment().add(-configUtils.getInteger("financing.max.dateFrom", 3), "months");

        const { showForm } = this.state;

        return (
            <>
                {!isDesktop && showForm && !enableRangeDatefield && (
                    <div className="align-self-center px-3">
                        <I18n id="feeds.paid.table.header.date" />
                    </div>
                )}

                <div
                    className={classNames("d-flex gap-3", {
                        "pl-3 py-3": isDesktop,
                        "px-3": !isDesktop,
                        "w-320px": isDesktop && enableRangeDatefield,
                    })}>
                    {enableRangeDatefield ? (
                        <Field
                            component={RangeDatePicker}
                            idForm={FORM_ID}
                            name="lastDates"
                            maxDate={new Date()}
                            className="height-48"
                            minDate={maxDateFrom.toDate()}
                        />
                    ) : (
                        <>
                            <Field
                                component={DateField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                name="lastDateFrom"
                                customKey={!isDesktop && "financing.feeds.mobile.lastDateFrom.label"}
                                selectsStart
                                minDate={maxDateFrom}
                                maxDate={lastDateTo || moment()}
                                autocomplete="off"
                                formGroupClassName={classNames({ "m-0 min-width-14": isDesktop, "w-50": !isDesktop })}
                                controlLabelClassName={classNames({ "font-size-16-px": !isDesktop })}
                                formGroupTextClassName={classNames({ "mt-0": !isDesktop })}
                            />
                            <Field
                                component={DateField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                name="lastDateTo"
                                customKey={!isDesktop && "financing.feeds.mobile.lastDateTo.label"}
                                selectsEnd
                                minDate={lastDateFrom || maxDateFrom}
                                maxDate={moment()}
                                autocomplete="off"
                                formGroupClassName={classNames({ "m-0 min-width-14": isDesktop, "w-50": !isDesktop })}
                                controlLabelClassName={classNames({ "font-size-16-px": !isDesktop })}
                                formGroupTextClassName={classNames({ "mt-0": !isDesktop })}
                            />
                        </>
                    )}
                </div>
                <div className={classNames("p-3", { "m-0 align-self-flex-end": isDesktop })}>
                    <Button
                        bsStyle="primary"
                        label={`${FORM_ID}.btn.filter.label`}
                        type="submit"
                        className={classNames("w-100", { "mb-0 min-width-14": isDesktop })}
                        block={false}
                    />
                </div>
            </>
        );
    };

    render() {
        const { isDesktop, financingFeed, optionSelected, fetching } = this.props;

        const { showForm } = this.state;

        const isToPaySelected = optionSelected === "toPay";

        return (
            <>
                <PageLoading className="line-loader" loading={fetching}>
                    <h2 className={classNames("mr-3 font-weight-lighter", { "ml-3": !isDesktop })}>
                        {i18nUtils.get("financing.feeds.detail.label")}
                    </h2>
                    {isDesktop ? (
                        <Form
                            autoComplete="off"
                            className={classNames("bg-white mb-1 px-1", {
                                "py-45": isToPaySelected,
                                "pt-2 pb-45": !isToPaySelected,
                            })}>
                            <div className="d-flex flex-wrap align-items-end">
                                {this.rowButtons()}
                                {!isToPaySelected && this.renderPeriod()}
                            </div>
                        </Form>
                    ) : (
                        <Form autoComplete="off" className="mb-2">
                            {this.rowButtons()}
                            {!isToPaySelected && (
                                <div className="d-flex w-100 justify-content-end">
                                    <Button
                                        block
                                        className="btn-link p-0 right mt-0 mr-3"
                                        label={showForm ? "financing.button.hideFilter" : "financing.button.seeFilter"}
                                        onClick={() => this.setState((prev) => ({ ...prev, showForm: !prev.showForm }))}
                                    />
                                </div>
                            )}
                            {!isToPaySelected && showForm && this.renderPeriod()}
                        </Form>
                    )}
                    {isToPaySelected ? (
                        <div>
                            {financingFeed && this.renderTableToPay()}
                            {!financingFeed && (
                                <div className="text-center no-more-data" key="noMoreMovements">
                                    <div className="illustration-wrapper">
                                        <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                                    </div>
                                    <p className="text-lead">
                                        <I18n id="feeds.notFound" />
                                    </p>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            {financingFeed && this.renderTablePaid()}
                            {!financingFeed && (
                                <div className="text-center no-more-data" key="noMoreMovements">
                                    <div className="illustration-wrapper">
                                        <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                                    </div>
                                    <p className="text-lead">
                                        <I18n id="feeds.notFound" />
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                </PageLoading>
            </>
        );
    }
}

export default compose(
    connect(),
    withFormik({
        mapPropsToValues: () => ({
            lastDates: [null, null],
        }),
        handleSubmit: (values, formikBag) => {
            const { id, dispatch, listCategories } = formikBag.props;
            const { lastDateFrom, lastDateTo, lastDates } = values;
            const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

            dispatch(
                financingActions.getFinancingDetailReques(id, listCategories, "1", {
                    dateFrom: enableRangeDatefield ? lastDates[0] : lastDateFrom,
                    dateTo: enableRangeDatefield ? lastDates[1] : lastDateTo,
                }),
            );
        },
    }),
)(MovementsFinancing);
