import React, { useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { arrayOf, bool, func, number, shape } from "prop-types";
import { Field, Form, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";

import Yup from "yup";
import moment from "moment";

import { actions as paymentsAFIPActions, selectors as paymentsAFIPSelectors } from "reducers/paymentsAFIP";

import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import PageLoading from "pages/_components/PageLoading";
import Image from "pages/_components/Image";

import Selector from "pages/_components/fields/formik/Selector";
import TextField from "pages/_components/fields/TextField";
import { DateField } from "pages/_components/fields/DateField";

import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";

import classNames from "classnames";
import { remove } from "lodash";
import RangeDatePicker from "pages/_components/fields/rangedatepicker/RangeDatePicker";
import { useIsMobile } from "@ui-kit/components/index.es";
import ListHeader from "./MultipleAFIPListHeader";
import {
    INITIAL_STATE,
    AFIPListReducer,
    setPayments,
    setDate,
    setShowFilters,
} from "./reducers/MultipleAFIPlistReducer";

const FORM_ID = "payments.afip.list";

const MultipleAFIPList = ({
    dispatch,
    values,
    multiplePaymentsAFIP,
    isDesktop,
    setFieldValue,
    fetching,
    isFetchingPaymentsAFIP,
    accounts,
    enabledContributors,
    setErrors,
    setTouched,
    ...args
}) => {
    const { paymentType, generatedVEP, numberVEP, taxpayerCUIT, dateFrom, dateTo, thirdPartyCuit } = values;
    const [{ payments, areAllPaymentSelected, showFilters, selectedDate }, dispatcher] = useReducer(
        AFIPListReducer,
        INITIAL_STATE,
    );
    const [hasPagination, setHasPagination] = useState(false);
    const [selectedPayments, setSelectedPayments] = useState([]);
    const { windowSize } = useIsMobile();
    const currencies = [{ id: 0, label: i18nUtils.get(`currency.label.0`) }];
    const accountsArs = accounts?.filter((account) => account.currency === currencies[0].id.toString());
    const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

    const handleSetPayments = (newPayments) => dispatcher(setPayments(newPayments));

    useEffect(() => {
        if (payments.length === 0 || multiplePaymentsAFIP.length === 0 || (hasPagination && !isFetchingPaymentsAFIP)) {
            const selectedPaymentsAfip = multiplePaymentsAFIP.map(({ isChecked, key, ...payment }) => ({
                ...payment,
                key,
                isChecked: selectedPayments?.some((p) => p.key === key && p.isChecked),
            }));
            handleSetPayments(selectedPaymentsAfip);
            setHasPagination(false);
        }
    }, [multiplePaymentsAFIP, hasPagination, isFetchingPaymentsAFIP, payments.length, selectedPayments]);

    useEffect(() => {
        dispatch(paymentsAFIPActions.listEnabledContributors());
    }, [dispatch]);

    const handleSelectAll = (checked) => {
        const updatedPayments = payments.map((payment) => {
            const { isPending, isChecked, ...rest } = payment;
            return {
                ...rest,
                isChecked: isPending || !payment.actions.isPaymentAllowed ? false : checked,
                isPending,
            };
        });

        if (checked) {
            setSelectedPayments((prevPayments) => {
                const newSelectedPayments = payments
                    .filter((payment) => {
                        const { isPending, isChecked, ...rest } = payment;
                        return isChecked === false && isPending === false && rest.actions.isPaymentAllowed === true;
                    })
                    .map((payment) => ({
                        ...payment,
                        isChecked: true,
                    }));
                return [...prevPayments, ...newSelectedPayments];
            });
        } else {
            const removedKeyPayments = payments.map(({ key }) => key);
            setSelectedPayments((prevPayments) => {
                remove(prevPayments, ({ key }) => removedKeyPayments.includes(key));
                return prevPayments;
            });
        }

        handleSetPayments(updatedPayments);
    };

    const handleChangeDateFrom = (newSelectedDate) => dispatcher(setDate(newSelectedDate));

    const hangleChangePaymentType = (mode) => {
        setFieldValue("generatedVEP", "");
        setFieldValue("taxpayerCUIT", "");
        setFieldValue("numberVEP", "");
        setFieldValue("thirdPartyCuit", "");
        if (mode === "stillToPay") {
            setFieldValue("dateFrom", null);
            setFieldValue("dateTo", null);
            setFieldValue("dates", [null, null]);
        }
        if (mode === "paymentsMade") {
            setFieldValue("dateFrom", moment().subtract(6, "months"));
            setFieldValue("dateTo", moment(new Date()));
            setFieldValue("dates", [
                moment()
                    .subtract(6, "months")
                    .toDate(),
                new Date(),
            ]);
        }
    };

    const hangleChangeGeneratedVEP = () => {
        setFieldValue("taxpayerCUIT", "");
        setFieldValue("numberVEP", "");
        setFieldValue("thirdPartyCuit", "");
    };

    const paymentsTypes = [
        {
            id: "stillToPay",
            label: i18nUtils.get(`${FORM_ID}.paymentsType.stillToPay`),
        },
        {
            id: "paymentsMade",
            label: i18nUtils.get(`${FORM_ID}.paymentsType.paymentsMade`),
        },
    ];

    const generatedVEPTypes = [
        {
            value: "1",
            label: i18nUtils.get(`${FORM_ID}.generatedVEP.options.owns`),
        },
        {
            value: "2",
            label: i18nUtils.get(`${FORM_ID}.generatedVEP.options.third`),
        },
        {
            value: "3",
            label: i18nUtils.get(`${FORM_ID}.generatedVEP.options.taxpayer`),
        },
    ];

    const taxpayerCUITs = enabledContributors?.map((enabledContributor) => ({
        value: enabledContributor.cuit,
        label: enabledContributor.cuit,
    }));

    return (
        <>
            {!isDesktop && (
                <Col xs={12} className="d-flex justify-content-end">
                    <Button
                        block
                        className="btn-link p-0 right ml-0 min-height-auto"
                        label={showFilters ? "echeq.button.hideFilter" : "echeq.button.seeFilter"}
                        onClick={() => dispatcher(setShowFilters())}
                    />
                </Col>
            )}

            <PageLoading
                className={classNames({ "line-loader": isDesktop })}
                loading={fetching || isFetchingPaymentsAFIP}>
                {(isDesktop || showFilters) && (
                    <div className="pagosvep mb-1 mt-2">
                        <Form autoComplete="off">
                            <Container
                                className={`align-items-left account-header-align-items-left ${isDesktop &&
                                    "account-header-detail"}`}
                                rowClassName="justify-content-left pb-3 filters">
                                <Col
                                    xs={12}
                                    md={paymentType === "paymentsMade" ? 12 : 3}
                                    lg={windowSize < 1540 && paymentType === "paymentsMade" ? 12 : 3}
                                    className="d-flex align-items-end pagosvep__checkbox mb-3">
                                    <Field
                                        component={Selector}
                                        options={paymentsTypes}
                                        idForm={FORM_ID}
                                        idField="paymentType"
                                        name="paymentType"
                                        inLineControl
                                        isRequired
                                        renderAs="radio"
                                        hideLabel
                                        defaultValue={paymentType}
                                        onCustomChange={hangleChangePaymentType}
                                        customOnChange
                                    />
                                </Col>
                                <Col
                                    xs={12}
                                    md={paymentType === "paymentsMade" && generatedVEP && generatedVEP === "3" ? 4 : 3}
                                    lg={windowSize < 1540 && paymentType === "paymentsMade" ? 3 : 2}
                                    className="align-items-end mb-3">
                                    <Field
                                        component={Selector}
                                        options={generatedVEPTypes}
                                        idForm={FORM_ID}
                                        name="generatedVEP"
                                        isRequired
                                        placeholder={i18nUtils.get(`${FORM_ID}.generatedVEP.placeholder`)}
                                        defaultValue={accountsArs?.find((account) => account.favorite)?.idProduct}
                                        onCustomChange={hangleChangeGeneratedVEP}
                                        customOnChange
                                        formGroupClassName="w-100"
                                        errorClassName="p-absolute"
                                        controlLabelClassName="text-transform-none"
                                    />
                                </Col>
                                {generatedVEP && generatedVEP === "3" && (
                                    <>
                                        <Col
                                            xs={12}
                                            md={paymentType === "paymentsMade" ? 8 : 5}
                                            lg={windowSize < 1200 ? 8 : windowSize < 1540 ? 4 : 3}
                                            className="align-items-end mb-3">
                                            <div
                                                className={classNames("w-100 gap-2", {
                                                    "d-flex align-items-end": isDesktop,
                                                })}>
                                                <Field
                                                    component={Selector}
                                                    options={taxpayerCUITs}
                                                    idForm={FORM_ID}
                                                    name="taxpayerCUIT"
                                                    isRequired
                                                    defaultValue={taxpayerCUIT}
                                                    placeholder={i18nUtils.get(`${FORM_ID}.taxpayerCUIT.placeholder`)}
                                                    formGroupClassName={classNames({ "w-50": isDesktop })}
                                                    errorClassName="p-absolute"
                                                    controlLabelClassName="text-transform-none"
                                                />
                                                <Field
                                                    component={TextField}
                                                    hidePlaceholder
                                                    idForm={FORM_ID}
                                                    name="numberVEP"
                                                    defaultValue={numberVEP}
                                                    type="text"
                                                    label={`${FORM_ID}.numberVEP`}
                                                    pattern="[0-9]*"
                                                    maxLength={12}
                                                    formGroupClassName={classNames({ "w-50": isDesktop })}
                                                    errorClassName="p-absolute"
                                                    controlLabelClassName="text-transform-none"
                                                />
                                            </div>
                                        </Col>
                                    </>
                                )}
                                {generatedVEP && generatedVEP === "2" && (
                                    <Col
                                        xs={12}
                                        md={enableRangeDatefield ? 4 : 3}
                                        lg={windowSize < 1200 ? 8 : windowSize < 1540 ? 3 : 2}
                                        className="align-items-end px-2 mb-3">
                                        <Field
                                            component={TextField}
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            name="thirdPartyCuit"
                                            defaultValue={thirdPartyCuit}
                                            type="text"
                                            label={`${FORM_ID}.thirdPartyCuit`}
                                            pattern="[0-9]*"
                                            maxLength={11}
                                            formGroupClassName="w-100"
                                            errorClassName="p-absolute"
                                            controlLabelClassName="text-transform-none"
                                        />
                                    </Col>
                                )}
                                {paymentType === "paymentsMade" && !enableRangeDatefield && (
                                    <>
                                        <Col xs={12} md={5} lg={4} className="mb-3">
                                            <div className="d-flex align-items-end w-100 gap-2">
                                                {!isDesktop && (
                                                    <I18n
                                                        component="p"
                                                        id={`${FORM_ID}.payday`}
                                                        componentProps={{ className: "my-0" }}
                                                    />
                                                )}
                                                <Field
                                                    idField="dateFrom"
                                                    component={DateField}
                                                    idForm={FORM_ID}
                                                    name="dateFrom"
                                                    defaultValue={moment(dateFrom).format("YYYY/MM/DD")}
                                                    hidePlaceholder
                                                    handleChange={handleChangeDateFrom}
                                                    maxDate={dateTo}
                                                    customKey={
                                                        !isDesktop ? `${FORM_ID}.dateFromMobile.label` : undefined
                                                    }
                                                    formGroupClassName="w-50"
                                                    errorClassName="p-absolute"
                                                    controlLabelClassName="text-transform-none"
                                                />
                                                <Field
                                                    idField="dateTo"
                                                    defaultValue={moment(dateTo).format("YYYY/MM/DD")}
                                                    component={DateField}
                                                    hidePlaceholder
                                                    idForm={FORM_ID}
                                                    name="dateTo"
                                                    minDate={selectedDate}
                                                    customKey={!isDesktop ? `${FORM_ID}.dateToMobile.label` : undefined}
                                                    formGroupClassName="w-50"
                                                    errorClassName="p-absolute"
                                                    controlLabelClassName="text-transform-none"
                                                />
                                            </div>
                                        </Col>
                                    </>
                                )}
                                {paymentType === "paymentsMade" && enableRangeDatefield && (
                                    <Col
                                        xs={12}
                                        md={5}
                                        lg={windowSize < 1540 && windowSize >= 1200 ? 4 : 3}
                                        className={`d-flex align-items-end ${
                                            isDesktop && windowSize > 1200 ? "px-2" : ""
                                        } mb-3`}>
                                        <Field component={RangeDatePicker} idForm={FORM_ID} name="dates" required />
                                    </Col>
                                )}

                                <Col
                                    xs={12}
                                    sm={
                                        windowSize > 860 &&
                                        windowSize <= 1200 &&
                                        generatedVEP &&
                                        generatedVEP === "3" &&
                                        paymentType === "paymentsMade"
                                            ? 2
                                            : windowSize < 860
                                            ? 12
                                            : 1
                                    }
                                    smOffset={
                                        windowSize > 860 &&
                                        windowSize <= 1200 &&
                                        generatedVEP &&
                                        generatedVEP === "3" &&
                                        paymentType === "paymentsMade"
                                            ? 5
                                            : undefined
                                    }
                                    className={classNames("d-flex align-items-end mt-2 mb-3", { "px-1": isDesktop })}>
                                    <Button
                                        bsStyle="primary"
                                        label={`${FORM_ID}.btn.filter.label`}
                                        loading={isFetchingPaymentsAFIP}
                                        type="submit"
                                        className="my-0"
                                        onClick={() => {
                                            setSelectedPayments([]);
                                        }}
                                    />
                                </Col>
                            </Container>
                        </Form>
                    </div>
                )}

                <div>
                    {payments.length > 0 &&
                        (payments.every((payment) =>
                            (paymentType === "paymentsMade" ? payment.type === "PA" : payment.type === "PE"),
                        ) ? (
                            <ListHeader
                                isDesktop={isDesktop}
                                allSelected={areAllPaymentSelected}
                                handleSelectAll={handleSelectAll}
                                payments={payments}
                                currencies={currencies}
                                handleSetPayments={handleSetPayments}
                                setSelectedPayments={setSelectedPayments}
                                selectedPayments={selectedPayments}
                                setHasPagination={setHasPagination}
                                filterValues={values}
                                args={args}
                            />
                        ) : (
                            undefined
                        ))}
                    {(!payments || !payments.length > 0) && !isFetchingPaymentsAFIP && (
                        <div
                            className="text-center no-more-data px-3 d-flex f-dir-col justify-content-center"
                            key="noMoreMovements">
                            <div className="illustration-wrapper mx-auto my-0">
                                <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                            </div>
                            <p className="text-lead">
                                <I18n id={`${FORM_ID}.empty.message`} />
                            </p>
                        </div>
                    )}
                </div>
            </PageLoading>
        </>
    );
};

const mapStateToProps = (state) => ({
    fetching: paymentsAFIPSelectors.getFetching(state),
    multiplePaymentsAFIP: paymentsAFIPSelectors.getPaymentsAFIP(state),
    pageNumber: paymentsAFIPSelectors.getPageNumber(state),
    totalPages: paymentsAFIPSelectors.getTotalPages(state),
    isFetchingPaymentsAFIP: paymentsAFIPSelectors.getFetchingPaymentsAFIP(state),
    enabledContributors: paymentsAFIPSelectors.getEnabledContributors(state),
    prevFormState: paymentsAFIPSelectors.getPrevFormState(state),
    accounts: paymentsAFIPSelectors.getAccounts(state),
});

MultipleAFIPList.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    fetching: bool.isRequired,
    isFetchingPaymentsAFIP: bool.isRequired,
    values: shape({}),
    multiplePaymentsAFIP: arrayOf(shape({})).isRequired,
    pageNumber: number.isRequired,
    totalPages: number.isRequired,
    enabledContributors: shape([]).isRequired,
    accounts: arrayOf(shape({})).isRequired,
    setFieldValue: func.isRequired,
    setErrors: func.isRequired,
    setTouched: func.isRequired,
};

MultipleAFIPList.defaultProps = {
    values: {},
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities");
            return {
                paymentType: props.prevFormState.paymentType || "stillToPay",
                generatedVEP: props.prevFormState.generatedVEP || "",
                taxpayerCUIT: props.prevFormState.taxpayerCUIT || "",
                numberVEP: props.prevFormState.numberVEP || "",
                thirdPartyCuit: props.prevFormState.thirdPartyCuit || "",
                dateFrom: props.prevFormState.dateFrom || moment().subtract(6, "months"),
                dateTo: props.prevFormState.dateTo || moment(new Date()),
                ...(enableRangeDatefield && {
                    dates: props.prevFormState.dates,
                }),
            };
        },
        validationSchema: () =>
            Yup.lazy((values) => {
                const { generatedVEP, paymentType } = values;
                const enableRangeDatefield = configUtils.getBoolean(
                    "frontend.show.RangeDatePicker.functionalities",
                    false,
                );

                let validations = {
                    paymentType: Yup.string().required(i18nUtils.get(`${FORM_ID}.paymentType.error.required`)),
                    generatedVEP: Yup.string().required(i18nUtils.get(`${FORM_ID}.generatedVEP.error.required`)),
                };

                if (generatedVEP === "2") {
                    validations = {
                        ...validations,
                        thirdPartyCuit: Yup.string().required(
                            i18nUtils.get(`${FORM_ID}.thirdPartyCuit.error.required`),
                        ),
                    };
                }

                if (generatedVEP === "3") {
                    validations = {
                        ...validations,
                        numberVEP: Yup.string().required(i18nUtils.get(`${FORM_ID}.numberVEP.error.required`)),
                        taxpayerCUIT: Yup.string().required(i18nUtils.get(`${FORM_ID}.taxpayerCUIT.error.required`)),
                    };
                }

                if (enableRangeDatefield && paymentType === "paymentsMade") {
                    validations = {
                        ...validations,
                        dates: Yup.mixed().test(
                            "dates",
                            i18nUtils.get(`${FORM_ID}.validation.range.date`),
                            ([from, to]) => from && to,
                        ),
                    };
                }

                return Yup.object().shape({
                    ...validations,
                });
            }),
        handleSubmit: (values, formikBag) => {
            const { dispatch } = formikBag.props;
            const {
                paymentType,
                generatedVEP,
                numberVEP,
                taxpayerCUIT,
                dateFrom,
                dateTo,
                dates,
                thirdPartyCuit,
            } = values;
            const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

            const dateFromFinal =
                paymentType === "stillToPay" ? null : moment(enableRangeDatefield ? dates[0] : dateFrom).format();
            const dateToFinal =
                paymentType === "stillToPay" ? null : moment(enableRangeDatefield ? dates[1] : dateTo).format();

            dispatch(paymentsAFIPActions.saveFormState(values));
            dispatch(
                paymentsAFIPActions.fetchPaymentsAFIP(
                    paymentType,
                    generatedVEP,
                    numberVEP,
                    taxpayerCUIT,
                    dateFromFinal,
                    dateToFinal,
                    "1",
                    thirdPartyCuit,
                ),
            );
            dispatch(paymentsAFIPActions.payPaymentAFIPPre({}, false, false));
        },
    }),
)(MultipleAFIPList);
