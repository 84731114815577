import React, { Component, Fragment } from "react";
import { bool, shape, string, func, arrayOf, number } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions, goBack } from "react-router-redux";

import * as i18nUtils from "util/i18n";
import { resizableRoute } from "pages/_components/Resizable";
import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import Heading from "pages/_components/Heading";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import { actions as debinActions, selectors as debinSelectors } from "reducers/debin";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import InfoTag from "pages/_components/InfoTag";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import * as accountsUtils from "util/accounts";
import Button from "pages/_components/Button";
import { push } from "react-router-redux/actions";

import { statusTagColor } from "util/debin";

class SentDebinDetail extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        fetching: bool,
        fetchingAccounts: bool,
        match: shape({
            params: shape({
                idSignature: string,
            }),
        }),
        dispatch: func.isRequired,
        debinDetail: shape({
            id: string,
        }).isRequired,
        buyerDetail: shape({}).isRequired,
        accounts: shape({}),
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
            securitySeal: number,
            userFullName: string,
        }),
        concepts: arrayOf(shape({})).isRequired,
    };

    static defaultProps = {
        fetching: false,
        fetchingAccounts: false,
        match: null,
        accounts: null,
        loggedUser: null,
    };

    componentDidMount() {
        const {
            dispatch,
            match: {
                params: { idDebin },
            },
            accounts,
            loggedUser,
            concepts,
        } = this.props;

        if (concepts.length === 0) {
            // dispatch(debinActions.loadConcepts());
        }
        dispatch(debinActions.getDebinDetail(idDebin));

        if (!accounts.length > 0) {
            dispatch(accountsActions.listAccounts(loggedUser.userId));
        }
    }

    handleCancel = () => {
        const { debinDetail, dispatch } = this.props;
        const debin = {
            ...debinDetail,
            seller: debinDetail?.sellerDetail,
            buyer: debinDetail?.buyerDetail,
        };
        dispatch(debinActions.debinToCancel(debin));
        dispatch(push("/charges/debin/cancel/summary"));
    };

    handleDecline = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.push("/payments/payDebin/decline/confirm"));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    getDebinTitle = () => {
        const {
            debinDetail: {
                id,
                status: { code },
                sellerDetail,
            },
            accounts,
        } = this.props;

        const name = `${i18nUtils.get("payments.debin.detail.header.label ")} ${id}`.toUpperCase();
        const loggedUserAccount = accounts.find((account) =>
            sellerDetail.cbu.substring(7, 21).includes(account?.number),
        );

        if (!code) {
            return <></>;
        }
        const accountName = this.getAccountName(loggedUserAccount);

        return (
            <>
                <div className="view-title data-wrapper-flex">
                    <h1 className="my-0 mr-3">{name}</h1>
                    <InfoTag
                        type="info"
                        message={code}
                        tagBackground={statusTagColor(code)}
                        moreStyles={{
                            alignSelf: "flex-end",
                            width: "fit-content",
                            color: "black",
                            margin: "0.5rem 0",
                        }}
                    />
                </div>
                <div className="d-flex my-1">
                    <p className="font-bold my-0 mr-2">{i18nUtils.get(`charges.debin.requestDebin.account.label`)}</p>
                    <p className="my-0">{accountName}</p>
                </div>
            </>
        );
    };

    getAccountName = (account) => {
        if (!account) {
            return "";
        }
        const { productAlias = "", number: accountNumber, productType, currency } = account;

        const { isDesktop } = this.props;

        if (isDesktop) {
            return `${productAlias || ""} ${accountsUtils.maskedAccountNumber(
                accountNumber,
            )} ${productType} ${i18nUtils.get(`currency.label.${currency}`)}`;
        }

        return `${productAlias || ""} ${accountsUtils.maskedAccountNumber(
            accountNumber,
        )} ${productType} ${i18nUtils.get(`currency.label.${currency}`)}`;
    };

    getRightContent = () => {
        const {
            isDesktop,
            debinDetail: {
                status: { code },
            },
        } = this.props;

        if (code !== "INICIADO") {
            return <></>;
        }
        return (
            <div
                className={`toolbar-item toolbar-item--fixed toolbar-item-right ${
                    !isDesktop ? "toolbar-item-right-1" : ""
                }`}
                key="acceptDebin">
                <Button
                    onClick={this.handleCancel}
                    className="btn btn-small"
                    label="global.cancel"
                    bsStyle="primary"
                    imageStyle={!isDesktop ? "toolbar-btn-image-mobile" : undefined}
                />
            </div>
        );
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18nUtils.get("payments.debin.detail.header.label.mobile")}</h1>
        </div>
    );

    renderPageHeader = () => {
        const {
            isDesktop,
            debinDetail: {
                id,
                status: { code },
                sellerDetail,
            },
            accounts,
        } = this.props;
        const name = `${i18nUtils.get("payments.debin.detail.header.label ")} ${id}`.toUpperCase();
        const loggedUserAccount = accounts.find((account) =>
            sellerDetail.cbu.substring(7, 21).includes(account?.number),
        );

        if (!code) {
            return <></>;
        }
        const accountName = this.getAccountName(loggedUserAccount);
        return (
            <>
                <Notification scopeToShow="debin" />
                <div className="admin-detail-head px-0" style={isDesktop ? {} : { marginBottom: 0 }}>
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                    />
                    {isDesktop ? (
                        <Head
                            hasInlineButtons
                            rightContent={this.getRightContent}
                            centerElement={this.getDebinTitle}
                            centerContentClassName="d-flex flex-column align-items-start"
                        />
                    ) : (
                        <div className="view-title data-wrapper my-0 mx-3">
                            <h3>{name}</h3>
                            <p className="my-0" style={{ fontWeight: "normal" }}>{`${i18nUtils.get(
                                "charges.debin.requestDebin.account.label",
                            )} `}</p>
                            <p className="my-0">{accountName}</p>
                            <InfoTag
                                type="info"
                                message={code}
                                tagBackground={statusTagColor(code)}
                                tagClass="my-2 mx-0 "
                                moreStyles={{ color: "black" }}
                            />
                        </div>
                    )}
                </div>
            </>
        );
    };

    renderContent = () => {
        const {
            debinDetail: {
                amount: { currency, quantity },
                concept: conceptId,
                description,
                expirationDate,
                idVoucher,
                buyerDetail,
                creationDate,
                reference,
            },
            debinDetail: { amount },
            debinDetail: {
                status: { code },
            },
            concepts,
            isDesktop,
        } = this.props;

        const concept = concepts.find(({ id }) => id === conceptId);

        let bankInfo = buyerDetail.bank;
        if(!buyerDetail?.cbu?.startsWith("000")){
            bankInfo += ` / ${buyerDetail.branch}`
        }
        return (
            <Fragment>
                {this.renderPageHeader()}
                <MainContainer>
                    <div className="above-the-fold">
                        <Container
                            className="flex-grow container-white my-2 with-padding"
                            style={{ textTransform: "uppercase" }}>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data ${
                                        isDesktop ? "data-wrapper" : "space-between mb-0 debin-detail"
                                    }`}
                                    labelClassName={isDesktop ? "data-label-medium" : "data-label-medium mb-0"}
                                    label="payments.debin.detail.table.header.concept"
                                    data={concept ? `${concept.id} - ${concept.name}` : <span> - </span>}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data ${
                                        isDesktop ? "data-wrapper" : "space-between mb-0 debin-detail"
                                    }`}
                                    labelClassName={isDesktop ? "data-label-medium" : "data-label-medium mb-0"}
                                    label="payments.debin.detail.table.header.description"
                                    data={description || <span> - </span>}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data ${
                                        isDesktop ? "data-wrapper" : "space-between mb-0 debin-detail"
                                    }`}
                                    labelClassName={isDesktop ? "data-label-medium" : "data-label-medium mb-0"}
                                    label="payments.debin.detail.table.header.creationDate"
                                    data={
                                        creationDate ? (
                                            <FormattedDate date={creationDate} dateFormat="dd/MM/yyyy" showTime />
                                        ) : (
                                            <span> - </span>
                                        )
                                    }
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data ${
                                        isDesktop ? "data-wrapper" : "space-between mb-0 debin-detail"
                                    }`}
                                    labelClassName={isDesktop ? "data-label-medium" : "data-label-medium mb-0"}
                                    label="payments.debin.detail.table.header.expirationDate"
                                    data={
                                        expirationDate ? (
                                            <FormattedDate date={expirationDate} dateFormat="dd/MM/yyyy" showTime />
                                        ) : (
                                            <span> - </span>
                                        )
                                    }
                                />
                            </Col>
                        </Container>
                        <Container
                            className=" flex-grow container-white my-2 with-padding"
                            style={{ textTransform: "uppercase" }}>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data ${
                                        isDesktop ? "data-wrapper" : "space-between mb-0 debin-detail"
                                    }`}
                                    labelClassName={isDesktop ? "data-label-medium" : "data-label-medium mb-0"}
                                    label="payments.debin.detail.table.header.voucher"
                                    data={idVoucher || <span> - </span>}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data ${
                                        isDesktop ? "data-wrapper" : "space-between mb-0 debin-detail"
                                    }`}
                                    labelClassName={isDesktop ? "data-label-medium" : "data-label-medium mb-0"}
                                    label="charges.debin.table.header.titular"
                                    data={buyerDetail.titular || <span> - </span>}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data ${
                                        isDesktop ? "data-wrapper" : "space-between mb-0 debin-detail"
                                    }`}
                                    labelClassName={isDesktop ? "data-label-medium" : "data-label-medium mb-0"}
                                    label="payments.debin.detail.table.header.documentType"
                                    data={
                                        (
                                            <div>
                                                <span style={{ marginRight: "0.5rem" }}>
                                                    {buyerDetail.documentType}
                                                </span>
                                                <span>{buyerDetail.documentNumber}</span>
                                            </div>
                                        ) || <span> - </span>
                                    }
                                />
                            </Col>

                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data ${
                                        isDesktop ? "data-wrapper" : "space-between mb-0 debin-detail"
                                    }`}
                                    labelClassName={isDesktop ? "data-label-medium" : "data-label-medium mb-0"}
                                    label="payments.debin.detail.table.header.amount"
                                    data={
                                        amount ? (
                                            <FormattedAmount currency={currency} quantity={quantity} notBold />
                                        ) : (
                                            <span> - </span>
                                        )
                                    }
                                />
                            </Col>
                        </Container>
                        <Container
                            className=" flex-grow container-white my-2 with-padding"
                            style={{ textTransform: "uppercase" }}>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data ${
                                        isDesktop ? "data-wrapper" : "space-between mb-0 debin-detail"
                                    }`}
                                    labelClassName={isDesktop ? "data-label-medium" : "data-label-medium mb-0"}
                                    label={`payments.debin.detail.table.header.${buyerDetail?.cbu?.startsWith("000") ? "cvu" : "cbu"}`}
                                    data={buyerDetail.cbu || <span> - </span>}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data ${
                                        isDesktop ? "data-wrapper" : "space-between mb-0 debin-detail"
                                    }`}
                                    labelClassName={isDesktop ? "data-label-medium" : "data-label-medium mb-0"}
                                    label="payments.debin.detail.table.header.alias"
                                    data={buyerDetail.cbuAlias || <span> - </span>}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data ${
                                        isDesktop ? "data-wrapper" : "space-between mb-0 debin-detail"
                                    }`}
                                    labelClassName={isDesktop ? "data-label-medium" : "data-label-medium mb-0"}
                                    label={`payments.debin.detail.table.header.${buyerDetail?.cbu?.startsWith("000") ? "entity" : "branch"}`}
                                    data={bankInfo}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data ${
                                        isDesktop ? "data-wrapper" : "space-between mb-0 debin-detail"
                                    }`}
                                    labelClassName={isDesktop ? "data-label-medium" : "data-label-medium mb-0"}
                                    label="payments.debin.detail.table.header.reference"
                                    data={reference || <span> - </span>}
                                />
                            </Col>
                        </Container>
                        {!isDesktop && code === "INICIADO" && (
                            <Container className=" flex-grow">
                                <Col className="col col-12">
                                    <Button
                                        onClick={this.handleCancel}
                                        className="btn"
                                        label="global.cancel"
                                        bsStyle="primary"
                                    />
                                </Col>
                            </Container>
                        )}
                    </div>
                </MainContainer>
            </Fragment>
        );
    };

    render() {
        const { fetching, debinDetail, fetchingAccounts } = this.props;

        return (
            <Fragment>
                <PageLoading loading={fetching || fetchingAccounts}>
                    {!fetching && debinDetail && this.renderContent()}
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    debinDetail: debinSelectors.getDebinDetail(state),
    loggedUser: sessionSelectors.getUser(state),
    fetchingAccounts: accountsSelectors.getFetching(state),
    accounts: accountsSelectors.getAccounts(state),
    fetching: debinSelectors.getFetching(state),
    concepts: debinSelectors.getConcepts(state),
});

export default connect(mapStateToProps)(resizableRoute(SentDebinDetail));
