import React, { Component } from "react";
import classNames from "classnames";
import { routerActions } from "react-router-redux";
import { arrayOf, bool, func, int, number, shape, string } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Field, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";

import { selectors as sessionSelectors } from "reducers/session";
import { actions, selectors } from "reducers/frequentDestination";
import { actions as transferActions } from "reducers/transfer";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";

import TextField from "pages/_components/fields/TextField";
import GeneralMsg from "pages/_components/GeneralMsg";
import { ReactComponent as Show } from "styles/images/search.svg";
import Pagination from "pages/_components/pagination/Pagination";
import Button from "pages/_components/Button";
import ContextMenu from "pages/_components/ContextMenu";
import Container from "pages/_components/Container";
import Table from "pages/_components/Table";
import PageLoading from "pages/_components/PageLoading";
import getTransferPath from "util/transfers";
import FrequentDestinationMobileRow from "./FrequentDestinationMobile";

class TableFrequentDestination extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        idEnvironment: number.isRequired,
        values: shape({}),
        listFrequentDestinations: arrayOf(
            shape({
                name: string,
                recipientName: string,
                recipientDocumentType: string,
                recipientDocumentNumber: string,
                cbu: string,
                recipientEmail: string,
            }),
        ),
        fetching: bool,
        moreDestinations: bool,
        isDesktop: bool.isRequired,
        formId: string,
        onRowClick: func.isRequired,
        visibleContextMenu: bool,
        searchClassName: string,
        onClickCopy: func,
        fromModal: bool,
        frequentDestinationModify: shape({
            idFrequentDestinations: int,
            accountNumber: string,
            productType: string,
            isOwn: string,
            cbu: string,
            recipientDocumentType: string,
            recipientDocumentNumber: string,
            recipientName: string,
            localBank: string,
            SubsidiaryBank: string,
            recipientEmail: string,
            name: string,
        }),
        activeEnvironment: shape({
            permissions: shape({
                transferInternal: bool,
                transferThirdParties: bool,
            }),
        }).isRequired,
        selectedDebitAccount: shape({}),
    };

    static defaultProps = {
        listFrequentDestinations: [],
        values: {},
        fetching: false,
        moreDestinations: false,
        formId: "",
        visibleContextMenu: false,
        searchClassName: "",
        onClickCopy: null,
        fromModal: false,
        frequentDestinationModify: {},
        selectedDebitAccount: {},
    };

    state = {
        tableHeight: 0,
        currentRow: {},
        goToModify: false,
        pageNumber: 1,
        /* eslint-disable-next-line */
        totalPages: Math.ceil(this.props.listFrequentDestinations.length / 5),
        idFrequentDestinationModify: null,
    };

    componentDidMount() {
        const {
            dispatch,
            idEnvironment,
            values: { filter },
        } = this.props;
        dispatch(actions.loadListRequest(idEnvironment, filter));
    }

    componentDidUpdate(prevProps) {
        const { dispatch, frequentDestinationModify, listFrequentDestinations } = this.props;
        const { idFrequentDestinationModify, goToModify } = this.state;

        if (
            frequentDestinationModify &&
            prevProps.frequentDestinationModify !== frequentDestinationModify &&
            goToModify &&
            idFrequentDestinationModify
        ) {
            dispatch(routerActions.push(`/frequentDestination/${idFrequentDestinationModify}/modify/`));
        }

        if (this.props !== prevProps) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                totalPages: Math.ceil(listFrequentDestinations.length / 10),
            });
        }
    }

    componentWillUnmount() {
        this.setState((prevState) => ({ ...prevState, goToModify: false, idFrequentDestinationModify: null }));
    }

    getFrequentDestinations = () => {
        const { listFrequentDestinations, selectedDebitAccount } = this.props;
        return (
            listFrequentDestinations &&
            listFrequentDestinations.filter(
                (frequentDestination) =>
                    !selectedDebitAccount?.cbu || frequentDestination.cbu !== selectedDebitAccount.cbu,
            )
        );
    };

    onSearch = () => {
        const { dispatch, idEnvironment, values } = this.props;
        dispatch(actions.loadListRequest(idEnvironment, values.filter));
    };

    handleCurrentRow = (row) => {
        this.setState((prevState) => ({ ...prevState, currentRow: row }));
    };

    generateTableColumns = () => {
        const { visibleContextMenu } = this.props;

        const onRow = (row) => {
            this.setState((prevState) => ({ ...prevState, currentRow: row }));
        };

        return [
            {
                align: "left",
                key: "reference",
                dataIndex: "reference",
                title: i18n.get("transfer.frequentDestinations.column.reference"),
                width: "15%",
            },
            {
                align: "left",
                key: "headline",
                dataIndex: "headline",
                title: i18n.get("transfer.frequentDestinations.column.headline"),
                width: "20%",
            },
            {
                align: "center",
                key: "document",
                dataIndex: "document",
                title: i18n.get("transfer.frequentDestinations.column.documentType"),
                width: "15%",
            },
            {
                align: "center",
                key: "account",
                dataIndex: "account",
                title: i18n.get("transfer.frequentDestinations.column.acount"),
                width: "20%",
            },
            {
                align: "right",
                key: "email",
                dataIndex: "email",
                title: i18n.get("transfer.frequentDestinations.column.email"),
                width: visibleContextMenu ? "25%" : "30%",
            },
            visibleContextMenu && {
                align: "right",
                key: "contextComponent",
                dataIndex: "contextComponent",
                width: "5%",
                onCell: (record) => ({
                    onClick(e) {
                        e.stopPropagation();
                        onRow(record);
                    },
                }),
            },
        ].filter((c) => !!c);
    };

    renderTableFooter = () => {
        const { moreDestinations } = this.props;
        const { totalPages, pageNumber } = this.state;

        const enableNewPagination = configUtils.getBoolean("frontend.show.nuevoComponentePaginado.enabled", false);

        return (
            <div>
                {moreDestinations && (
                    <div className={`d-flex w-100 justify-content-end ${!enableNewPagination && "pr-4"}`}>
                        <div className={`d-flex ${!enableNewPagination && "mt-2"}`}>
                            <Pagination
                                totalPages={totalPages}
                                pageNumber={pageNumber}
                                action={(num) => this.setState({ pageNumber: num })}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    };

    renderContextMenu = (data) => {
        const { dispatch, onClickCopy, isDesktop, activeEnvironment } = this.props;
        const { currentRow } = this.state;
        const args = data || currentRow;
        const transferKind = args.isOwn === "1" ? "same" : "other";

        const { reference, headline, document, account, email } = args;
        const i18nParams = {
            reference,
            headline,
            document,
            account,
            email: email || "",
        };
        const textCopy = i18n.get("transfer.frequentDestinations.textCopy", null, i18nParams);

        const buttonShare = (
            <Button
                label="frequentDestinations.contextMenu.share"
                className="dropdown__item-btn btn-b-none dropdown__item-btn-custom justify-content-start my-0 ml-0"
                onClick={() => {
                    if (!isDesktop && window.cordova) {
                        const options = {
                            message: textCopy,
                        };
                        window.plugins.socialsharing.shareWithOptions(options, null, null);
                    } else {
                        onClickCopy();
                    }
                }}
            />
        );

        const showTransfersHistoric = configUtils.getBoolean("frontend.showMVP1.transfers.functionalities", false);
        const hasPermission = showTransfersHistoric ? activeEnvironment.permissions.transferAccess : true;
        const items = [];
        const showMVP5transfers = configUtils.getBoolean("frontend.showMVP5.transfers.functionalities");
        if (showMVP5transfers && activeEnvironment.permissions.transferToContact) {
            items.push({
                label: "frequentDestinations.contextMenu.transfer",
                onClick: () => {
                    dispatch(
                        transferActions.loadThirdPartiesTransfer({
                            cbu: args.account,
                            email: args.email,
                            transferKind,
                            isFrequentDestinations: true,
                        }),
                    );
                    dispatch(routerActions.push(getTransferPath("toContact")));
                },
            });
        } else if (hasPermission && activeEnvironment.permissions.transferThirdParties) {
            items.push({
                label: "frequentDestinations.contextMenu.transfer",
                onClick: () => {
                    dispatch(
                        transferActions.loadThirdPartiesTransfer({
                            cbu: args.account,
                            email: args.email,
                            transferKind,
                            isFrequentDestinations: true,
                        }),
                    );
                    dispatch(routerActions.push(getTransferPath("thirdParties")));
                },
            });
        }
        if (activeEnvironment.permissions["frequentDestination.manage"]) {
            items.push(
                {
                    label: "frequentDestinations.contextMenu.modify",
                    onClick: () => {
                        this.setState((prevState) => ({
                            ...prevState,
                            goToModify: true,
                            idFrequentDestinationModify: args.idFrequentDestination,
                        }));
                        dispatch(actions.modifyFrequentDestinationPreRequest(args.idFrequentDestination));
                    },
                },
                {
                    label: "frequentDestinations.contextMenu.delete",
                    onClick: () => {
                        dispatch(actions.deleteFrequentDestinationPreviewRequest(args.idFrequentDestination));
                    },
                },
            );
        }

        return (
            <ContextMenu
                buttonClassName={`align-self-center account-dropdown-header-font ${
                    !isDesktop ? "font-black-alpha" : ""
                }`}
                isDesktop={isDesktop}
                items={items}>
                {isDesktop || !window.cordova ? (
                    <CopyToClipboard text={textCopy}>{buttonShare}</CopyToClipboard>
                ) : (
                    buttonShare
                )}
            </ContextMenu>
        );
    };

    populateDestinationData = () => {
        const { listFrequentDestinations, visibleContextMenu } = this.props;
        const { pageNumber } = this.state;

        return (
            listFrequentDestinations &&
            this.getFrequentDestinations()
                .map((frequentDestination) => {
                    const {
                        idFrequentDestination,
                        name,
                        recipientName,
                        recipientDocumentType,
                        recipientDocumentNumber,
                        cbu,
                        recipientEmail,
                        isOwn,
                        accountNumber,
                    } = frequentDestination;

                    let result = {
                        idFrequentDestination,
                        key: idFrequentDestination,
                        isOwn,
                    };

                    if (name) {
                        result = { reference: name, ...result };
                    }

                    if (recipientName) {
                        result = { headline: recipientName, ...result };
                    }

                    if ((recipientDocumentType, recipientDocumentNumber)) {
                        const recipientDocument = `${recipientDocumentType} ${recipientDocumentNumber}`;
                        result = { document: recipientDocument, ...result };
                    }
                    if (cbu) {
                        result = { account: cbu, ...result };
                    }

                    if (recipientEmail) {
                        result = { email: recipientEmail, ...result };
                    }
                    if (visibleContextMenu) {
                        result = { contextComponent: this.renderContextMenu(result), ...result };
                    }
                    if (accountNumber) {
                        result = { accountNumber, ...result };
                    }
                    return {
                        ...result,
                    };
                })
                .slice(10 * (pageNumber - 1), 10 + 10 * (pageNumber - 1))
        );
    };

    revealFunction = () => (
        <Button className="btn-only-icon border-none" bsStyle="link" block={false} onClick={this.onSearch}>
            <Show className="svg-image" />
        </Button>
    );

    renderFilter = (formId) => {
        const { isDesktop } = this.props;

        const field = (
            <Col xs={12} className="px-1 px-md-3 py-3 mt-3 mb-1">
                <Field
                    idForm={formId}
                    component={TextField}
                    name="filter"
                    type="text"
                    onKeyDown={this.onSearch}
                    inputFunctions={this.revealFunction()}
                    hideLabel
                />
            </Col>
        );

        if (formId === "frequentDestination.modal") {
            return field;
        }

        return (
            <Col sm={12} md={6} lg={4} className={classNames({ "px-0": isDesktop })}>
                {field}
            </Col>
        );
    };

    mensajeEmpty = () => {
        const { isDesktop, fromModal } = this.props;

        return !fromModal && isDesktop ? (
            <div className={classNames({ "mb-9 w-100": !fromModal })}>
                <Table className="container-white mt-2">
                    {this.generateTableColumns().map((head) => (
                        <Table.HeaderData key={head.key} align={head.align} width={head.width}>
                            <span>{head.title}</span>
                        </Table.HeaderData>
                    ))}
                </Table>
                <Col xs={12} className="px-0">
                    <div className="py-6">
                        <GeneralMsg
                            idEnviroment
                            imagePath="images/loupeInFile.svg"
                            description={i18n.get("transfer.frequentDestinations.list.noRecords")}
                            isDesktop={isDesktop}
                        />
                    </div>
                </Col>
            </div>
        ) : (
            <Col xs={12} className="px-0">
                <div className="py-8 w-100">
                    <GeneralMsg
                        idEnviroment
                        imagePath="images/loupeInFile.svg"
                        description={i18n.get("transfer.frequentDestinations.list.noRecords")}
                        isDesktop={isDesktop}
                    />
                </div>
            </Col>
        );
    };

    renderContentDesktop() {
        const { onRowClick, fromModal } = this.props;

        return (
            <div className={classNames({ "mb-9": !fromModal })}>
                <Table className="container-white mt-2">
                    {this.generateTableColumns().map((head) => (
                        <Table.HeaderData key={head.key} align={head.align} width={head.width}>
                            <span>{head.title}</span>
                        </Table.HeaderData>
                    ))}
                    {this.populateDestinationData().map((row) => (
                        <Table.Row
                            key={row.key}
                            onClick={() => {
                                onRowClick(row);
                            }}>
                            <Table.Data className="table-data-cell py-3 pl-3" align="left">
                                <span className="font-light">{row.reference}</span>
                            </Table.Data>
                            <Table.Data className="table-data-cell py-3" align="left">
                                <span className="font-light">{row.headline}</span>
                            </Table.Data>
                            <Table.Data className="table-data-cell py-3" align="center">
                                <span className="font-light">{row.document}</span>
                            </Table.Data>
                            <Table.Data className="table-data-cell py-3" align="center">
                                <span className="font-light">{row.account}</span>
                            </Table.Data>
                            <Table.Data className="table-data-cell  py-3" align="right">
                                <span className="font-light word-break-all">{row.email}</span>
                            </Table.Data>
                            <Table.Data className="table-data-cell px-0 py-3" align="center">
                                <button
                                    type="button"
                                    className="btn btn-link"
                                    style={{ padding: 0, margin: 0 }}
                                    onClick={(e) => e.stopPropagation()}>
                                    {row.contextComponent}
                                </button>
                            </Table.Data>
                        </Table.Row>
                    ))}
                </Table>
                {this.renderTableFooter()}
            </div>
        );
    }

    renderContentMobile() {
        const { onRowClick, fromModal } = this.props;

        return this.getFrequentDestinations().map((frequentDestination) => {
            const {
                idFrequentDestination,
                name,
                recipientName,
                recipientDocumentType,
                recipientDocumentNumber,
                cbu,
                recipientEmail,
                isOwn,
            } = frequentDestination;

            let emailDestination = "";

            if (recipientEmail) {
                emailDestination = recipientEmail;
            }

            return (
                <FrequentDestinationMobileRow
                    idFrequentDestination={idFrequentDestination}
                    name={name}
                    recipientName={recipientName}
                    recipientDocumentType={recipientDocumentType}
                    recipientDocumentNumber={recipientDocumentNumber}
                    cbu={cbu}
                    recipientEmail={emailDestination}
                    isOwn={isOwn}
                    onRowClick={onRowClick}
                    fromModal={fromModal}
                    contextMenu={this.renderContextMenu({
                        idFrequentDestination,
                        reference: name,
                        headline: recipientName,
                        recipientDocumentType,
                        document: recipientDocumentNumber,
                        account: cbu,
                        email: emailDestination,
                        isOwn,
                    })}
                />
            );
        });
    }

    renderContent() {
        const { isDesktop } = this.props;

        return (
            <Col
                xs={12}
                className={classNames("px-0", {
                    "pb-6": !isDesktop,
                })}>
                {isDesktop ? this.renderContentDesktop() : this.renderContentMobile()}
            </Col>
        );
    }

    render() {
        const { formId, fetching, searchClassName } = this.props;

        return (
            <PageLoading loading={fetching}>
                <div className={searchClassName}>
                    <Container className="flex-grow admin-action justify-content-start">
                        <Col sm={12} className="px-0">
                            {this.renderFilter(formId)}
                        </Col>
                    </Container>
                </div>
                <Container fluid className="main-container px-0 frequent-destination-modal-body">
                    {this.getFrequentDestinations().length > 0 ? this.renderContent() : this.mensajeEmpty()}
                </Container>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    listFrequentDestinations: selectors.getListFrequentDestinations(state),
    moreDestinations: selectors.getMoreDestinations(state),
    idEnvironment: selectors.getEnviromentId(state),
    frequentDestinationModify: selectors.getFrequentDestinationModify(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            filter: null,
        }),
    }),
)(TableFrequentDestination);
