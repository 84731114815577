import React, { Component } from "react";
import { shape, func, string, bool, arrayOf, number } from "prop-types";

import { connect } from "react-redux";
import { compose } from "redux";
import Yup from "yup";
import { Field, Form, withFormik } from "formik";
import { push } from "react-router-redux";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors, actions } from "reducers/widgets";

import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import moment from "moment";

import * as i18nUtils from "util/i18n";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import Button from "pages/_components/Button";
import Selector from "pages/_components/fields/formik/Selector";
import { DateField } from "pages/_components/fields/DateField";
import FinancingTable from "pages/financing/_components/FinancingTable";
import { actions as financingActions, selectors as financingSelectors } from "reducers/financing";
import isEmpty from "lodash/isEmpty";
import * as configUtils from "util/config";
import classNames from "classnames";
import RangeDatePicker from "pages/_components/fields/rangedatepicker/RangeDatePicker";

const FORM_ID = "financing.category";

class FinancingId extends Component {
    static propTypes = {
        loggedUser: shape({}).isRequired,
        listCategories: shape({}).isRequired,
        dispatch: func.isRequired,
        match: shape({
            params: shape({
                id: string,
            }),
        }).isRequired,
        values: shape({}).isRequired,
        category: shape({}).isRequired,
        isDesktop: bool.isRequired,
        fetchingDownload: bool.isRequired,
        financingList: arrayOf(shape({})).isRequired,
        setValues: func.isRequired,
        totalPagesFinancing: number.isRequired,
    };

    state = {
        showForm: false,
    };

    componentDidMount() {
        const {
            dispatch,
            loggedUser,
            match: { params },
            listCategories,
            values,
            setValues,
        } = this.props;
        let cat = {};

        if (!listCategories) {
            this.handleBack();
        } else {
            const dateFrom = this.getMaxDateFrom();
            const dateTo = moment();
            const dates = [dateFrom.toDate(), dateTo.toDate()];
            setValues({ ...values, dateFrom, dateTo, dates, currency: null });

            dispatch(actions.listRequest("financing", loggedUser.userId));

            listCategories
                .filter((categor) => categor.productCategoryDescription === params.id)
                .forEach((categor) => {
                    cat = categor;
                });
            dispatch(financingActions.loadCategorySelected(cat));

            dispatch(
                financingActions.loadListRequest({
                    dateFrom: dateFrom.format(),
                    dateTo: dateTo.format(),
                    currency: null,
                    categoryCode: cat.productCategoryCode,
                    pageNumber: 1,
                }),
            );
        }
    }

    getMaxDateFrom = () => {
        const maxDateFrom = configUtils.getInteger("financing.max.dateFrom", 3);
        return moment()
            .startOf("day")
            .add(maxDateFrom * -1, "months");
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/financing/categories"));
    };

    handleExport = (format) => {
        const { dispatch, financingList, category, totalPagesFinancing, values } = this.props;
        const { dateFrom, dateTo, dates, currency } = values;
        const title = category?.productCategoryDescription;
        const categoryCode = category?.productCategoryCode;
        const registersByPage = (financingList?.length || 0) * totalPagesFinancing;
        const isMVP4ReportsEnabled = configUtils.getBoolean("frontend.showReportesMVP4.funcionalities", false);
        const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

        dispatch(
            financingActions.downloadList(
                format,
                isMVP4ReportsEnabled || !financingList ? [] : financingList,
                title,
                enableRangeDatefield ? moment(dates[0]) : dateFrom,
                enableRangeDatefield ? moment(dates[1]) : dateTo,
                currency,
                registersByPage,
                categoryCode,
            ),
        );
    };

    getBalanceDescription = (listCategories, isDesktop) => {
        const ARS_POSITION = 0;
        const USD_POSITION = 2;
        return (
            <div
                className={classNames(
                    "flex-flow-wrap",
                    { "d-flex flex-column mt-1 line-height-25": !isDesktop },
                    { "d-inline-flex": isDesktop },
                )}>
                <FormattedAmount
                    className="data-wrapper-flex account-flex-wrap account-flex-wrap-align-left"
                    fontClassName={classNames({ "f-size-3": !isDesktop })}
                    quantity={listCategories?.amountPerCategory[ARS_POSITION]}
                    currency={i18nUtils.get("currency.label.ARS")}
                />
                {isDesktop && <span className="data-label margin-left-right"> | </span>}
                {listCategories?.amountPerCategory[USD_POSITION] ? (
                    <FormattedAmount
                        className="data-wrapper-flex account-flex-wrap account-flex-wrap-align-left"
                        fontClassName={classNames({ "f-size-3": !isDesktop })}
                        quantity={listCategories?.amountPerCategory[USD_POSITION]}
                        currency={i18nUtils.get("currency.label.USD")}
                    />
                ) : (
                    <FormattedAmount
                        className="data-wrapper-flex account-flex-wrap account-flex-wrap-align-left"
                        fontClassName={classNames({ "f-size-3": !isDesktop })}
                        quantity={0}
                        currency={i18nUtils.get("currency.label.USD")}
                    />
                )}
            </div>
        );
    };

    render() {
        const {
            match: { params },
            values,
            setValues,
            category,
            isDesktop,
            financingList,
            fetchingDownload,
        } = this.props;
        const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

        const currencyOptions = [
            { value: null, label: "TODAS" },
            { value: "0", label: "PESOS" },
            { value: "2", label: "DOLARES" },
        ];
        const { showForm } = this.state;
        const amountLabel = i18nUtils.get("financing.loans.totalAmount.label");

        return (
            <>
                <Notification scopeToShow="loans" />
                <div className={classNames({ "admin-detail-head": isDesktop }, "px-0")}>
                    {isDesktop && <Head onBack={this.handleBack} />}
                    <Head
                        headerClassName={classNames({ "blue-main-header-mobile": !isDesktop })}
                        titleText={params.id}
                        exportList={!isEmpty(financingList)}
                        handleClick={this.handleExport}
                        isFetchingExport={fetchingDownload}
                        onBack={!isDesktop && this.handleBack}
                        handleClickMessage="deposits.list.download"
                        uniqueDownload
                        downloadImageWhite={!isDesktop}
                        addButtonImageWhite={!isDesktop}
                        imageStyle="fit-width-context-menu mr-2"
                    />
                </div>
                <div className={classNames("d-flex f-dir-col", { "flex-column ml-3 mt-2": !isDesktop })}>
                    <div className={classNames("d-inline-flex flex-flow-wrap", { "ml-2": !isDesktop })}>
                        <p className="data-label data-amount-label-flex mb-0">{amountLabel}</p>
                    </div>
                    {this.getBalanceDescription(category, isDesktop)}
                </div>
                {!isDesktop && (
                    <div className="d-flex justify-content-end mb-3">
                        <Button
                            block={false}
                            className="btn-link my-0 mr-2 mh-auto"
                            label={showForm ? "financing.button.hideFilter" : "financing.button.seeFilter"}
                            onClick={() => this.setState((prev) => ({ ...prev, showForm: !prev.showForm }))}
                        />
                    </div>
                )}
                <main className="px-0">
                    {(isDesktop || showForm) && (
                        <Form
                            autocomplete="off"
                            className={classNames({
                                "bg-white pt-3 pb-4 px-3 mt-3 mb-1": isDesktop,
                                "pr-3 pl-3 mb-45": !isDesktop,
                            })}>
                            <div
                                className={classNames({
                                    "d-flex gap-3 flex-wrap financing__filters": isDesktop,
                                    "financing__filters-v2": enableRangeDatefield,
                                })}>
                                <Field
                                    component={Selector}
                                    formGroupClassName={classNames({ "m-0": isDesktop })}
                                    options={currencyOptions}
                                    idForm={FORM_ID}
                                    name="currency"
                                    isRequired
                                    value={values?.currency || currencyOptions[0]}
                                    controlLabelChildClassName="text-transform-none"
                                />

                                {enableRangeDatefield ? (
                                    <div
                                        className={classNames(
                                            "d-flex align-items-end align-self-end text-left mb-2 mr-0",
                                            { "w-320px": isDesktop, "mb-4": !isDesktop },
                                        )}>
                                        <Field
                                            component={RangeDatePicker}
                                            name="dates"
                                            idForm={FORM_ID}
                                            minDate={this.getMaxDateFrom().toDate()}
                                            maxDate={new Date()}
                                            className="height-48"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <>
                                        {!isDesktop && (
                                            <I18n
                                                componentProps={{ className: `text-uppercase` }}
                                                id="financing.detail.subtitle.settlementDate"
                                            />
                                        )}
                                        <div className="d-flex gap-3">
                                            <Field
                                                component={DateField}
                                                idField="dateFrom"
                                                name="dateFrom"
                                                idForm={FORM_ID}
                                                customKey={!isDesktop && "financing.feeds.mobile.lastDateFrom.label"}
                                                value={values?.dateFrom}
                                                endDate={values?.dateTo}
                                                minDate={this.getMaxDateFrom()}
                                                maxDate={values?.dateTo}
                                                onChange={(date) => {
                                                    const dateFrom = date ? moment(date) : this.getMaxDateFrom();
                                                    if (
                                                        dateFrom.isBetween(
                                                            this.getMaxDateFrom(),
                                                            moment(),
                                                            undefined,
                                                            "[]",
                                                        )
                                                    ) {
                                                        setValues({ ...values, dateFrom });
                                                    }
                                                }}
                                                hidePlaceholder
                                                selectsStart
                                                formGroupClassName={classNames({
                                                    "m-0 min-width-14": isDesktop,
                                                    "w-50": !isDesktop,
                                                })}
                                            />
                                            <Field
                                                component={DateField}
                                                idField="dateTo"
                                                name="dateTo"
                                                idForm={FORM_ID}
                                                customKey={!isDesktop && "financing.feeds.mobile.lastDateTo.label"}
                                                value={values?.dateTo}
                                                startDate={values?.dateFrom}
                                                minDate={values?.dateFrom}
                                                maxDate={moment()}
                                                onChange={(date) => {
                                                    const dateTo = date ? moment(date) : moment();
                                                    if (dateTo.isBetween(values?.dateFrom, moment(), undefined, "[]")) {
                                                        setValues({ ...values, dateTo });
                                                    }
                                                }}
                                                hidePlaceholder
                                                selectsEnd
                                                formGroupClassName={classNames({
                                                    "m-0 min-width-14": isDesktop,
                                                    "w-50": !isDesktop,
                                                })}
                                            />
                                        </div>
                                    </>
                                )}
                                <Button
                                    label="payments.payThirdParties.button.search"
                                    bsStyle="primary"
                                    type="submit"
                                    className={classNames({ "min-width-14": isDesktop })}
                                    block={!isDesktop}
                                />
                            </div>
                        </Form>
                    )}
                    <FinancingTable isDesktop={isDesktop} values={values} category={category} />
                </main>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { data } = selectors.getWidget(state, "financing");
    return {
        category: financingSelectors.getCategory(state),
        loggedUser: sessionSelectors.getUser(state),
        listCategories: data.financingCategories,
        financingList: financingSelectors.getFinancing(state),
        fetchingDownload: financingSelectors.getFetchingDownload(state),
        totalPagesFinancing: financingSelectors.getTotalPages(state),
    };
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            dates: [new Date(), new Date()],
            dateFrom: moment(),
            dateTo: moment(),
        }),
        validationSchema: () => {
            const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);
            return Yup.lazy((values) =>
                Yup.object().shape({
                    ...(enableRangeDatefield
                        ? {
                              dates: Yup.mixed().test(
                                  "dates",
                                  i18nUtils.get(`${FORM_ID}.validation.range.date`),
                                  ([from, to]) => from && to,
                              ),
                          }
                        : {
                              dateFrom: values.dateTo
                                  ? Yup.date()
                                        .nullable()
                                        .max(values.dateTo, i18nUtils.get(`${FORM_ID}.search.dateFrom.max.error`))
                                        .required(i18nUtils.get(`${FORM_ID}.search.date.error.required`))
                                  : Yup.date()
                                        .nullable()
                                        .required(i18nUtils.get(`${FORM_ID}.search.date.error.required`)),
                              dateTo: values.dateFrom
                                  ? Yup.date()
                                        .nullable()
                                        .min(values.dateFrom, i18nUtils.get(`${FORM_ID}.search.dateTo.min.error`))
                                        .required(i18nUtils.get(`${FORM_ID}.search.date.error.required`))
                                  : Yup.date()
                                        .nullable()
                                        .required(i18nUtils.get(`${FORM_ID}.search.date.error.required`)),
                          }),
                }),
            );
        },
        handleSubmit: (values, formikBag) => {
            const { dispatch, category } = formikBag.props;
            const { currency, dateFrom, dateTo, dates } = values;
            const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);
            dispatch(
                financingActions.loadListRequest({
                    dateFrom: enableRangeDatefield ? moment(dates[0]).format() : dateFrom.format(),
                    dateTo: enableRangeDatefield ? moment(dates[1]).format() : dateTo.format(),
                    currency,
                    categoryCode: category.productCategoryCode,
                    pageNumber: 1,
                }),
            );
        },
    }),
)(FinancingId);
