/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { bool, shape, string, node, instanceOf } from "prop-types";
import moment from "moment";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";
import { startOfDay } from "date-fns";

import { actions as transactionsActions } from "reducers/transactions";

import Button from "pages/_components/Button";
import Date from "pages/_components/fields/DateField";
import RangeDatePicker from "pages/_components/fields/rangedatepicker/RangeDatePicker";

import * as i18n from "util/i18n";
import * as configUtils from "util/config";

const FORM_ID = "transactions.list.filters.period";

class PeriodFilter extends Component {
    state = {
        selectedDateFrom: null,
    };

    static propTypes = {
        fetching: bool,
        values: shape({}),
        buttonLabel: string,
        cleanFiltersButton: node,
        rightButton: bool.isRequired,
        maxDate: instanceOf(moment),
        minDate: instanceOf(moment),
    };

    static defaultProps = {
        fetching: false,
        values: shape({
            dateFrom: null,
            dateTo: null,
        }),
        buttonLabel: null,
        cleanFiltersButton: null,
        minDate: undefined,
        maxDate: undefined,
    };

    handleChangeDateFrom = (selectedDate) => {
        this.setState({ selectedDateFrom: selectedDate });
    };

    render() {
        const {
            fetching,
            values: { dateFrom, dateTo },
            buttonLabel,
            cleanFiltersButton,
            rightButton,
            maxDate,
            minDate,
        } = this.props;

        const { selectedDateFrom } = this.state;
        const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

        return (
            <Form autoComplete="off">
                {!rightButton ? (
                    <Col sm={12} md={2} lg={2} className="d-flex f-dir-col mt-35 pt-3 px-3">
                        <Button
                            bsStyle="primary"
                            label={buttonLabel || "product.filters.filter"}
                            loading={fetching}
                            type="submit"
                            className="filter-button m-0"
                        />
                        {cleanFiltersButton}
                    </Col>
                ) : null}
                {enableRangeDatefield ? (
                    <Col sm={12} md={8} lg={5} className="period-filter__periods align-items-end">
                        <Field
                            component={RangeDatePicker}
                            idForm={FORM_ID}
                            name="dates"
                            minDate={startOfDay(minDate)}
                            maxDate={startOfDay(maxDate)}
                        />
                    </Col>
                ) : (
                    <>
                        <Col sm={12} md={3} lg={3} className="period-filter__periods">
                            <Field
                                idField="dateFromPeriodFilter"
                                component={Date}
                                endDate={dateTo}
                                hidePlaceholder
                                idForm={FORM_ID}
                                name="dateFrom"
                                selectsStart
                                startDate={dateFrom}
                                minDate={startOfDay(minDate)}
                                maxDate={dateTo || startOfDay(maxDate)}
                                handleChange={this.handleChangeDateFrom}
                                disableEmptyDate
                            />
                        </Col>
                        <Col sm={12} md={3} lg={3} className="period-filter__periods">
                            <Field
                                idField="dateToPeriodFilter"
                                component={Date}
                                endDate={dateTo}
                                hidePlaceholder
                                idForm={FORM_ID}
                                name="dateTo"
                                selectsEnd
                                startDate={dateFrom}
                                minDate={selectedDateFrom || startOfDay(minDate)}
                                maxDate={startOfDay(maxDate)}
                                disableEmptyDate
                            />
                        </Col>
                    </>
                )}
                {rightButton ? (
                    <Col sm={12} md={3} lg={2} className="d-flex f-dir-col mt-35 pt-3 px-3">
                        <Button
                            bsStyle="primary"
                            label={buttonLabel || "product.filters.filter"}
                            loading={fetching}
                            type="submit"
                            className="filter-button m-0"
                        />
                        {cleanFiltersButton}
                    </Col>
                ) : null}
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: (props) => {
        const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);
        return enableRangeDatefield
            ? {
                  dates: [props.dateFrom || null, props.dateTo || null],
              }
            : {
                  dateFrom: props.dateFrom ? props.dateFrom : null,
                  dateTo: props.dateTo ? props.dateTo : null,
              };
    },
    validationSchema: (props) => {
        const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);
        return Yup.lazy((values) =>
            Yup.object().shape({
                dateFrom: values.dateTo
                    ? Yup.date()
                          .nullable()
                          .transform((_, originalValue) => (originalValue ? startOfDay(originalValue) : null))
                          .min(startOfDay(props?.minDate), i18n.get("accounts.movements.filters.from.error"))
                          .max(startOfDay(values.dateTo), i18n.get("accounts.movements.filters.period.dateFrom.error"))
                    : Yup.date().nullable(),
                dateTo: values.dateFrom
                    ? Yup.date()
                          .nullable()
                          .transform((_, originalValue) => (originalValue ? startOfDay(originalValue) : null))
                          .min(startOfDay(values.dateFrom), i18n.get("accounts.movements.filters.period.dateTo.error"))
                          .max(startOfDay(props?.maxDate), i18n.get("accounts.movements.filters.from.error"))
                    : Yup.date().nullable(),
                ...(enableRangeDatefield && {
                    dates: Yup.mixed().test(
                        "dates",
                        i18n.get(`${FORM_ID}.validation.range.date`),
                        ([from, to]) => from && to,
                    ),
                }),
            }),
        );
    },
    handleSubmit: (filters, formikBag) => {
        const {
            dispatch,
            isDesktop,
            onlyPendings,
            pendingDispatch,
            dateType,
            status,
            idActivity,
            cleanSelectedRows,
            orderBy,
        } = formikBag.props;
        const { dates, ...filtersRest } = filters;
        const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

        if (cleanSelectedRows) {
            cleanSelectedRows();
        }
        if (isDesktop) {
            dispatch(
                transactionsActions.loadListRequest(
                    {
                        ...filtersRest,
                        ...(enableRangeDatefield && { dateFrom: dates[0], dateTo: dates[1] }),
                        dateType,
                        status,
                        idActivity,
                        filter: "creationDate",
                        orderBy,
                    },
                    onlyPendings,
                    pendingDispatch,
                    formikBag,
                ),
            );
        } else {
            dispatch(
                transactionsActions.setFilters({
                    filter: "period",
                    ...filtersRest,
                    ...(dates && { dateFrom: dates[0], dateTo: dates[1] }),
                    orderBy,
                }),
            );
            dispatch(push(`/transactions/list/filters/results`));
        }
    },
})(PeriodFilter);
