import { setActions } from "util/paymentsAFIP";

export const types = {
    CLEAN_UP: "paymentsAFIP/CLEAN_UP",
    LIST_ENABLED_CONTRIBUTORS_REQUEST: "paymentsAFIP/LIST_ENABLED_CONTRIBUTORS_REQUEST",
    LIST_ENABLED_CONTRIBUTORS_FAILURE: "paymentsAFIP/LIST_ENABLED_CONTRIBUTORS_FAILURE",
    LIST_ENABLED_CONTRIBUTORS_SUCCESS: "paymentsAFIP/LIST_ENABLED_CONTRIBUTORS_SUCCESS",
    ENABLE_CONTRIBUTOR_REQUEST: "paymentsAFIP/ENABLE_CONTRIBUTOR_REQUEST",
    ENABLE_CONTRIBUTOR_FAILURE: "paymentsAFIP/ENABLE_CONTRIBUTOR_FAILURE",
    ENABLE_CONTRIBUTOR_SUCCESS: "paymentsAFIP/ENABLE_CONTRIBUTOR_SUCCESS",
    DELETE_ENABLED_CONTRIBUTOR_REQUEST: "paymentsAFIP/DELETE_ENABLED_CONTRIBUTOR_REQUEST",
    DELETE_ENABLED_CONTRIBUTOR_FAILURE: "paymentsAFIP/DELETE_ENABLED_CONTRIBUTOR_FAILURE",
    DELETE_ENABLED_CONTRIBUTOR_SUCCESS: "paymentsAFIP/DELETE_ENABLED_CONTRIBUTOR_SUCCESS",
    SHOW_DELETE_ENABLED_CONTRIBUTOR: "paymentsAFIP/SHOW_DELETE_ENABLED_CONTRIBUTOR",
    HIDE_DELETE_ENABLED_CONTRIBUTOR: "paymentsAFIP/HIDE_DELETE_ENABLED_CONTRIBUTOR",
    LIST_PAYMENTS_AFIP_REQUEST: "paymentsAFIP/LIST_PAYMENTS_AFIP_REQUEST",
    LIST_PAYMENTS_AFIP_FAILURE: "paymentsAFIP/LIST_PAYMENTS_AFIP_FAILURE",
    LIST_PAYMENTS_AFIP_SUCCESS: "paymentsAFIP/LIST_PAYMENTS_AFIP_SUCCESS",
    PAY_PAYMENT_AFIP_PRE_REQUEST: "paymentsAFIP/PAY_PAYMENT_AFIP_PRE_REQUEST",
    PAY_MULTIPLE_AFIP_PRE_REQUEST: "paymentsAFIP/PAY_MULTIPLE_AFIP_PRE_REQUEST",
    PAY_MULTIPLE_AFIP_REQUEST: "paymentsAFIP/PAY_MULTIPLE_AFIP_REQUEST",
    PAY_PAYMENT_AFIP_PRE_SUCCESS: "paymentsAFIP/PAY_PAYMENT_AFIP_PRE_SUCCESS",
    PAY_PAYMENT_AFIP_PREVIEW_REQUEST: "paymentsAFIP/PAY_PAYMENT_AFIP_PREVIEW_REQUEST",
    PAY_PAYMENT_AFIP_PREVIEW_SUCCESS: "paymentsAFIP/PAY_PAYMENT_AFIP_PREVIEW_SUCCESS",
    PAY_PAYMENT_AFIP_REQUEST: "paymentsAFIP/PAY_PAYMENT_AFIP_REQUEST",
    PAY_PAYMENT_AFIP_FAILURE: "paymentsAFIP/PAY_PAYMENT_AFIP_FAILURE",
    PAY_PAYMENT_AFIP_SUCCESS: "paymentsAFIP/PAY_PAYMENT_AFIP_SUCCESS",
    DELETE_PAYMENT_AFIP_FROM_MAIN_PRE_REQUEST: "paymentsAFIP/DELETE_PAYMENT_AFIP_FROM_MAIN_PRE_REQUEST",
    DELETE_PAYMENT_AFIP_PRE_REQUEST: "paymentsAFIP/DELETE_PAYMENT_AFIP_PRE_REQUEST",
    DELETE_PAYMENT_AFIP_PRE_SUCCESS: "paymentsAFIP/DELETE_PAYMENT_AFIP_PRE_SUCCESS",
    DELETE_PAYMENT_AFIP_REQUEST: "paymentsAFIP/DELETE_PAYMENT_AFIP_REQUEST",
    DELETE_PAYMENT_AFIP_FAILURE: "paymentsAFIP/DELETE_PAYMENT_AFIP_FAILURE",
    DELETE_PAYMENT_AFIP_SUCCESS: "paymentsAFIP/DELETE_PAYMENT_AFIP_SUCCESS",
    READ_PAYMENT_AFIP_REQUEST: "paymentsAFIP/READ_PAYMENT_AFIP_REQUEST",
    READ_MULTPILE_PAYMENT_AFIP_REQUEST: "paymentsAFIP/READ_MULTPILE_PAYMENT_AFIP_REQUEST",
    READ_PAYMENT_AFIP_SUCCESS: "paymentsAFIP/READ_PAYMENT_AFIP_SUCCESS",
    DOWNLOAD_PAY_CONFIRMATION_REQUEST: "paymentsAFIP/DOWNLOAD_PAY_CONFIRMATION_REQUEST",
    DOWNLOAD_PAY_CONFIRMATION_FAILURE: "paymentsAFIP/DOWNLOAD_PAY_CONFIRMATION_FAILURE",
    DOWNLOAD_PAY_CONFIRMATION_SUCCESS: "paymentsAFIP/DOWNLOAD_PAY_CONFIRMATION_SUCCESS",
    GET_TOKEN_REQUEST: "paymentsAFIP/GET_TOKEN_REQUEST",
    GET_TOKEN_FAILURE: "paymentsAFIP/GET_TOKEN_FAILURE",
    GET_TOKEN_SUCCESS: "paymentsAFIP/GET_TOKEN_SUCCESS",
    SET_IS_BACK_FROM_OTHER_PAGE: "paymentsAFIP/SET_IS_BACK_FROM_OTHER_PAGE",
    SET_IS_RETURN_FROM_DETAIL: "paymentsAFIP/SET_IS_RETURN_FROM_DETAIL",
    CLEAN_BACK_FROM_OTHER_PAGE: "paymentsAFIP/CLEAN_BACK_FROM_OTHER_PAGE",
    SAVE_FORM_STATE: "paymentsAFIP/SAVE_FORM_STATE",
    CLEAN_FORM_STATE: "paymentsAFIP/CLEAN_FORM_STATE",
    HANDLE_BACK_TICKET: "paymentsAFIP/HANDLE_BACK_TICKET",
    CLEAN_BACK_TICKET: "paymentsAFIP/CLEAN_BACK_TICKET",
};

export const INITIAL_STATE = {
    fetching: false,
    enabledContributors: [],
    showDeletePage: false,
    paymentsAFIP: [],
    pageNumber: 0,
    totalPages: 0,
    fetchingPaymentsAFIP: false,
    paymentAFIP: null,
    selectedPaymentsAFIP: [],
    debitAccountMultipleAFIP: [],
    summary: null,
    fetchingDownload: false,
    accounts: [],
    tokenAFIP: null,
    getFetchingPayOrDelete: false,
    isBackFromOtherPage: false,
    isReturnFromDetail: false,
    prevFormState: {},
    backFromTicket: false,
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        enabledContributors,
        paymentsAFIP,
        pageNumber,
        totalPages,
        paymentAFIP,
        debitAccount,
        accounts,
        newSummary,
        tokenAFIP,
        numberVEP,
        isReturnFromDetail,
        prevFormState,
        selectedPaymentsAFIP,
        debitAccountMultipleAFIP,
    } = action;

    switch (action.type) {
        case types.LIST_ENABLED_CONTRIBUTORS_REQUEST:
            return {
                ...state,
                fetching: true,
                showDeletePage: false,
            };
        case types.LIST_ENABLED_CONTRIBUTORS_FAILURE:
            return {
                ...state,
                fetching: false,
                fetchingPaymentsAFIP: false,
            };
        case types.LIST_ENABLED_CONTRIBUTORS_SUCCESS:
            return {
                ...state,
                fetching: false,
                enabledContributors,
            };
        case types.ENABLE_CONTRIBUTOR_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.ENABLE_CONTRIBUTOR_FAILURE:
        case types.ENABLE_CONTRIBUTOR_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.DELETE_ENABLED_CONTRIBUTOR_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.DELETE_ENABLED_CONTRIBUTOR_FAILURE:
        case types.DELETE_ENABLED_CONTRIBUTOR_SUCCESS:
            return {
                ...state,
                fetching: false,
                showDeletePage: false,
            };
        case types.SHOW_DELETE_ENABLED_CONTRIBUTOR:
            return {
                ...state,
                showDeletePage: true,
            };
        case types.HIDE_DELETE_ENABLED_CONTRIBUTOR:
            return {
                ...state,
                showDeletePage: false,
            };
        case types.LIST_PAYMENTS_AFIP_REQUEST:
            return {
                ...state,
                fetching: true,
                fetchingPaymentsAFIP: true,
                paymentsAFIP: [],
            };
        case types.LIST_PAYMENTS_AFIP_FAILURE:
            return {
                ...state,
                fetching: false,
                fetchingPaymentsAFIP: false,
                paymentsAFIP: INITIAL_STATE.paymentsAFIP,
                pageNumber: INITIAL_STATE.pageNumber,
                totalPages: INITIAL_STATE.totalPages,
            };
        case types.LIST_PAYMENTS_AFIP_SUCCESS:
            return {
                ...state,
                fetching: false,
                fetchingPaymentsAFIP: false,
                paymentsAFIP,
                pageNumber,
                totalPages,
            };
        case types.PAY_PAYMENT_AFIP_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
                fetchingPayOrDelete: true,
                isReturnFromDetail: true,
            };
        case types.PAY_MULTIPLE_AFIP_PRE_REQUEST:
            return {
                ...state,
                selectedPaymentsAFIP,
                debitAccountMultipleAFIP,
            };
        case types.PAY_PAYMENT_AFIP_PRE_SUCCESS:
            return {
                ...state,
                fetching: false,
                fetchingPayOrDelete: false,
                paymentAFIP,
                accounts,
            };
        case types.PAY_PAYMENT_AFIP_PREVIEW_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.PAY_PAYMENT_AFIP_PREVIEW_SUCCESS:
            return {
                ...state,
                fetching: false,
                isReturnFromDetail: false,
                summary: { paymentAFIP, debitAccount },
            };
        case types.PAY_PAYMENT_AFIP_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.PAY_PAYMENT_AFIP_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.PAY_PAYMENT_AFIP_SUCCESS:
            return {
                ...state,
                fetching: false,
                summary: newSummary,
            };
        case types.DELETE_PAYMENT_AFIP_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
                fetchingPayOrDelete: true,
                isReturnFromDetail: true,
            };
        case types.DELETE_PAYMENT_AFIP_FROM_MAIN_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
                fetchingPayOrDelete: true,
                isReturnFromDetail: false,
            };
        case types.DELETE_PAYMENT_AFIP_PRE_SUCCESS:
            return {
                ...state,
                fetching: false,
                fetchingPayOrDelete: false,
                paymentAFIP,
            };
        case types.DELETE_PAYMENT_AFIP_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.DELETE_PAYMENT_AFIP_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.DELETE_PAYMENT_AFIP_SUCCESS:
            return {
                ...state,
                fetching: false,
                summary: newSummary,
                paymentsAFIP: state.paymentsAFIP.filter((payAFIP) => payAFIP.numberVEP !== numberVEP),
            };
        case types.READ_PAYMENT_AFIP_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.READ_MULTPILE_PAYMENT_AFIP_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.READ_PAYMENT_AFIP_SUCCESS:
            return {
                ...state,
                fetching: false,
                paymentAFIP,
            };
        case types.DOWNLOAD_PAY_CONFIRMATION_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_PAY_CONFIRMATION_FAILURE:
        case types.DOWNLOAD_PAY_CONFIRMATION_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.GET_TOKEN_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.GET_TOKEN_FAILURE:
        case types.GET_TOKEN_SUCCESS:
            return {
                ...state,
                fetching: false,
                tokenAFIP,
            };
        case types.SET_IS_BACK_FROM_OTHER_PAGE:
            return {
                ...state,
                isBackFromOtherPage: true,
            };

        case types.SET_IS_RETURN_FROM_DETAIL:
            return {
                ...state,
                isReturnFromDetail,
            };
        case types.CLEAN_BACK_FROM_OTHER_PAGE:
            return {
                ...state,
                isBackFromOtherPage: false,
                isReturnFromDetail: false,
            };

        case types.CLEAN_UP:
            return INITIAL_STATE;

        case types.SAVE_FORM_STATE:
            return {
                ...state,
                prevFormState,
            };

        case types.CLEAN_FORM_STATE:
            return {
                ...state,
                paymentsAFIP: INITIAL_STATE.paymentsAFIP,
            };
        case types.HANDLE_BACK_TICKET:
            return {
                ...state,
                backFromTicket: true,
            };
        case types.CLEAN_BACK_TICKET:
            return {
                ...state,
                backFromTicket: false,
            };
        default:
            return state;
    }
};

export const actions = {
    cleanUp: () => ({
        type: types.CLEAN_UP,
    }),
    cleanFormState: () => ({
        type: types.CLEAN_FORM_STATE,
    }),

    listEnabledContributors: () => ({
        type: types.LIST_ENABLED_CONTRIBUTORS_REQUEST,
    }),
    enableContributor: (cuit, reference, closeDrawer) => ({
        type: types.ENABLE_CONTRIBUTOR_REQUEST,
        cuit,
        reference,
        closeDrawer,
    }),
    deleteEnabledContributor: (cuit) => ({
        type: types.DELETE_ENABLED_CONTRIBUTOR_REQUEST,
        cuit,
    }),
    showDeleteEnabledContributor: () => ({
        type: types.SHOW_DELETE_ENABLED_CONTRIBUTOR,
    }),
    hideDeleteEnabledContributor: () => ({
        type: types.HIDE_DELETE_ENABLED_CONTRIBUTOR,
    }),
    fetchPaymentsAFIP: (
        paymentType,
        generatedVEP,
        numberVEP,
        taxpayerCUIT,
        dateFrom,
        dateTo,
        pageNumber,
        thirdPartyCuit,
    ) => ({
        type: types.LIST_PAYMENTS_AFIP_REQUEST,
        paymentType,
        generatedVEP,
        numberVEP,
        taxpayerCUIT,
        dateFrom,
        dateTo,
        pageNumber,
        thirdPartyCuit,
    }),
    payPaymentAFIPPre: (paymentAFIP, fromDetail = false, redirect = true) => ({
        type: types.PAY_PAYMENT_AFIP_PRE_REQUEST,
        paymentAFIP,
        fromDetail,
        redirect,
    }),
    payMultipleAFIPPre: (selectedPaymentsAFIP, debitAccountMultipleAFIP, formikBag) => ({
        type: types.PAY_MULTIPLE_AFIP_PRE_REQUEST,
        selectedPaymentsAFIP,
        debitAccountMultipleAFIP,
        formikBag,
    }),
    payMultipleAFIP: (payMultipleAFIPRequest, formikBag) => ({
        type: types.PAY_MULTIPLE_AFIP_REQUEST,
        payMultipleAFIPRequest,
        formikBag,
    }),
    payPaymentAFIPPreview: (debitAccount, paymentAFIP) => ({
        type: types.PAY_PAYMENT_AFIP_PREVIEW_REQUEST,
        debitAccount,
        paymentAFIP,
    }),
    payPaymentAFIP: (summary, otp) => ({
        type: types.PAY_PAYMENT_AFIP_REQUEST,
        summary,
        otp,
    }),
    deletePaymentAFIPPre: (paymentAFIP, fromDetail) => ({
        type: types.DELETE_PAYMENT_AFIP_PRE_REQUEST,
        paymentAFIP,
        fromDetail,
    }),
    deletePaymentAFIPPreFromMain: (paymentAFIP, fromDetail) => ({
        type: types.DELETE_PAYMENT_AFIP_FROM_MAIN_PRE_REQUEST,
        paymentAFIP,
        fromDetail,
    }),
    deletePaymentAFIP: (summary, otp) => ({
        type: types.DELETE_PAYMENT_AFIP_REQUEST,
        summary,
        otp,
    }),
    readPaymentAFIP: (paymentAFIP) => ({
        type: types.READ_PAYMENT_AFIP_REQUEST,
        paymentAFIP,
    }),
    readMultiplePaymentAFIP: (paymentAFIP) => ({
        type: types.READ_MULTPILE_PAYMENT_AFIP_REQUEST,
        paymentAFIP,
    }),
    downloadPay: (summary) => ({
        type: types.DOWNLOAD_PAY_CONFIRMATION_REQUEST,
        summary,
    }),
    getToken: () => ({
        type: types.GET_TOKEN_REQUEST,
    }),
    setIsBackFromOtherPage: (isReturnFromDetail) => ({
        type: types.SET_IS_BACK_FROM_OTHER_PAGE,
        isReturnFromDetail,
    }),
    cleanBackFromOtherPage: () => ({
        type: types.CLEAN_BACK_FROM_OTHER_PAGE,
    }),
    saveFormState: (prevFormState) => ({
        type: types.SAVE_FORM_STATE,
        prevFormState,
    }),
    cleanHandleBackFromTicket: () => ({
        type: types.CLEAN_BACK_TICKET,
    }),
};

export const selectors = {
    getFetching: ({ paymentsAFIP }) => paymentsAFIP.fetching,
    getEnabledContributors: ({ paymentsAFIP }) => paymentsAFIP.enabledContributors,
    getShowDeletePage: ({ paymentsAFIP }) => paymentsAFIP.showDeletePage,
    getPaymentsAFIP: ({ paymentsAFIP, session: { activeEnvironment } }) =>
        setActions(paymentsAFIP.paymentsAFIP, activeEnvironment),
    getMultiplePaymentsAFIP: ({ paymentsAFIP: { paymentsAFIP } }) => paymentsAFIP,
    getSelectedPaymentsAFIP: ({ paymentsAFIP: { selectedPaymentsAFIP } }) => selectedPaymentsAFIP,
    getDebitAccountMultipleAFIP: ({ paymentsAFIP: { debitAccountMultipleAFIP } }) => debitAccountMultipleAFIP,
    getPageNumber: ({ paymentsAFIP }) => paymentsAFIP.pageNumber,
    getTotalPages: ({ paymentsAFIP }) => paymentsAFIP.totalPages,
    getFetchingPaymentsAFIP: ({ paymentsAFIP }) => paymentsAFIP.fetchingPaymentsAFIP,
    getPaymentAFIP: ({ paymentsAFIP }) => paymentsAFIP.paymentAFIP,
    getSummary: ({ paymentsAFIP }) => paymentsAFIP.summary,
    getFetchingDownload: ({ paymentsAFIP }) => paymentsAFIP.fetchingDownload,
    getAccounts: ({ paymentsAFIP }) => paymentsAFIP.accounts,
    getTokenAFIP: ({ paymentsAFIP }) => paymentsAFIP.tokenAFIP,
    getFetchingPayOrDelete: ({ paymentsAFIP }) => paymentsAFIP.fetchingPayOrDelete,
    getIsBackFromOtherPage: ({ paymentsAFIP }) => paymentsAFIP.isBackFromOtherPage,
    getIsReturnFromDetail: ({ paymentsAFIP }) => paymentsAFIP.isReturnFromDetail,
    getPrevFormState: ({ paymentsAFIP }) => paymentsAFIP.prevFormState,
    getBackFromTicket: ({ paymentsAFIP }) => paymentsAFIP.backFromTicket,
};
