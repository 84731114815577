/* eslint-disable linebreak-style */
import { call, put, takeLatest } from "redux-saga/effects";
import { actions as notificationActions } from "reducers/notification";
import { downloadPdf } from "util/download";
import { types } from "reducers/posicionConsolidada";
import * as posicionConsolidadaMiddleware from "middleware/posicionConsolidada";
import * as i18n from "util/i18n";
import { delay } from "redux-saga";

const sagas = [takeLatest(types.DOWNLOAD_POSICION_CONSOLIDADA_REQUEST, downloadPosicionConsolidada)];

export default sagas;

function* downloadPosicionConsolidada({ year, month, filters }) {
    try {
        const { status, data } = yield call(
            posicionConsolidadaMiddleware.downloadPosicionConsolidada,
            year,
            month,
            filters,
        );

        if (status !== 200) {
            yield put({ type: types.DOWNLOAD_POSICION_CONSOLIDADA_FAILURE });
            yield put(
                notificationActions.showNotification(
                    i18n.get("global.unexpectedError"),
                    "error",
                    ["posicionConsolidada"],
                    null,
                    false,
                ),
            );
        } else {
            const { content, fileName, isData } = data.data;

            if (isData) {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("global.successDownload"),
                        "success",
                        ["posicionConsolidada"],
                        null,
                        false,
                    ),
                );
                yield put({ type: types.DOWNLOAD_POSICION_CONSOLIDADA_SUCCESS });
                yield call(delay, 3000);
                downloadPdf(fileName, content);
            } else {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("desktop.posicionConsolidada.noData"),
                        "warning",
                        ["posicionConsolidada"],
                        null,
                        false,
                    ),
                );
                yield put({ type: types.DOWNLOAD_POSICION_CONSOLIDADA_FAILURE });
            }
        }
    } finally {
        yield put({ type: types.DOWNLOAD_POSICION_CONSOLIDADA_FAILURE });
    }
}
