import React, { Component } from "react";
import Heading from "pages/_components/Heading";
import Col from "react-bootstrap/lib/Col";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as i18nUtils from "util/i18n";
import { shape, bool } from "prop-types";
import classNames from "classnames";
import { getAccountType } from "util/cbuCmf";

class CreateDebinRecipientSummary extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        recipient: shape({}).isRequired,
    };

    render() {
        const { isDesktop, recipient } = this.props;
        const {
            recipientName,
            recipientDocumentType,
            recipientDocumentNumber,
            amount: { currency, quantity },
            accountNumber,
            cbu,
            localBank,
            reference,
        } = recipient;
        const accountType = getAccountType(cbu);
        return (
            <>
                <Col className="col col-12" lg={3} xl={3} md={9} sm={12}>
                    <Heading.DataGroup
                        containerClassName={classNames(
                            "transfer-data data-wrapper-flex",
                            { "mb-2": isDesktop },
                            { "space-between debin-detail": !isDesktop },
                        )}
                        label="debin.create.summary.idDebin.label"
                        data=""
                    />
                </Col>

                <Col className="col col-12" lg={3} xl={3} md={9} sm={12}>
                    <Heading.DataGroup
                        containerClassName={classNames(
                            "transfer-data data-wrapper-flex",
                            { "mb-2": isDesktop },
                            { "space-between debin-detail": !isDesktop },
                        )}
                        label="debin.create.summary.owner.label"
                        data={recipientName}
                    />
                </Col>

                <Col className="col col-12" lg={3} xl={3} md={9} sm={12}>
                    <Heading.DataGroup
                        containerClassName={classNames(
                            "transfer-data data-wrapper-flex",
                            { "mb-2": isDesktop },
                            { "space-between debin-detail": !isDesktop },
                        )}
                        label="debin.create.summary.documentType.label"
                        data={`${i18nUtils.get(
                            `documentType.label.${recipientDocumentType.toUpperCase()}`,
                        )} ${recipientDocumentNumber}`}
                    />
                </Col>

                <Col className="col col-12" lg={3} xl={3} md={9} sm={12}>
                    <Heading.DataGroup
                        containerClassName={classNames(
                            "transfer-data data-wrapper-flex",
                            { "mb-2": isDesktop },
                            { "space-between debin-detail": !isDesktop },
                        )}
                        label="debin.create.summary.amount.label"
                        data={<FormattedAmount quantity={quantity} currency={currency} notBold />}
                    />
                </Col>
                <Col className="col col-12" lg={3} xl={3} md={9} sm={12}>
                    <Heading.DataGroup
                        containerClassName={classNames(
                            "transfer-data data-wrapper-flex",
                            { "mb-2": isDesktop },
                            { "space-between debin-detail": !isDesktop },
                        )}
                        label="debin.create.summary.debitAccount.label"
                        data={accountNumber}
                    />
                </Col>

                <Col className="col col-12" lg={3} xl={3} md={9} sm={12}>
                    <Heading.DataGroup
                        containerClassName={classNames(
                            "transfer-data data-wrapper-flex",
                            { "mb-2": isDesktop },
                            { "space-between debin-detail": !isDesktop },
                        )}
                        label="debin.create.summary.cbu.label"
                        data={cbu}
                    />
                </Col>

                <Col className="col col-12" lg={3} xl={3} md={9} sm={12}>
                    <Heading.DataGroup
                        containerClassName={classNames(
                            "transfer-data data-wrapper-flex",
                            { "mb-2": isDesktop },
                            { "space-between debin-detail": !isDesktop },
                        )}
                        label={`debin.create.summary.${accountType && accountType === "cvu" ? "entityName" : "bankName"}.label`}
                        data={localBank}
                    />
                </Col>

                <Col className="col col-12" lg={3} xl={3} md={9} sm={12}>
                    <Heading.DataGroup
                        containerClassName={classNames(
                            "transfer-data data-wrapper-flex",
                            { "mb-2": isDesktop },
                            { "space-between debin-detail": !isDesktop },
                        )}
                        label="debin.create.summary.reference.label"
                        data={reference}
                    />
                </Col>
            </>
        );
    }
}

export default CreateDebinRecipientSummary;
