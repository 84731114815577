/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { bool, func, shape, string, number, arrayOf } from "prop-types";

import { selectors as fundcorpSelectors } from "reducers/fundcorp";
import { selectors as sessionSelectors } from "reducers/session";

import * as i18nUtils from "util/i18n";
import { exportGenericTicketPdf, getGeneralTicketItem } from "util/transaction";

import PageLoading from "pages/_components/PageLoading";
import TransactionTicket from "pages/_components/TransactionTicket";
import ContextMenu from "pages/_components/ContextMenu";
import SubscribeSummaryData from "./SubscribeSummaryData";

const FORM_ID = "fund.subscribe.ticket";

class SubscribeTicket extends Component {
    static propTypes = {
        summary: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
        }),
        fetching: bool.isRequired,
        history: shape({}).isRequired,
        activeEnvironment: shape({}).isRequired,
    };

    static defaultProps = {
        loggedUser: null,
    };

    state = {
        showDrawerMobile: false,
        fetchingDownload: false,
    };

    componentDidMount() {
        const { summary, dispatch } = this.props;

        if (!summary || (!summary?.fundName && localStorage.getItem("flow"))) {
            dispatch(push(`${JSON.parse(localStorage.getItem("flow"))}`));
        }
    }

    centerContentMobile = (isDesktop) => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ margin: isDesktop && "-0.5rem 2rem" }}>{i18nUtils.get(`${FORM_ID}.title`)}</h1>
        </div>
    );

    getRescueDeadlineLabel = (rescatePlazo) => {
        switch (rescatePlazo) {
            case "0":
                return "Inmediato";
            case "1":
                return "24 hs";
            case "2":
                return "48 hs";
            default:
                return `${rescatePlazo} dias`;
        }
    };

    exportPdf = async () => {
        this.setState({ fetchingDownload: true });
        const { summary } = this.props;
        const FORM_SUMMARY = "fund.subscribe.summary";
        const { submitDateTime, fundName, amount, fondoClase, debitAccontLabel, shareAccount } = summary;

        const bodyData = [
            getGeneralTicketItem(`forms.transaction.ticket.date`, `${submitDateTime}`, "datefulltime"),
            getGeneralTicketItem(
                `forms.transaction.ticket.number`,
                summary?.idTransaction.substring(0, 8).toUpperCase(),
                "string",
            ),
            getGeneralTicketItem(`${FORM_SUMMARY}.fund.label`, `${fundName}`, "string"),
            getGeneralTicketItem(
                `${FORM_SUMMARY}.subscribeAmount.label`,
                amount.quantity,
                `amount${i18nUtils.get(`currency.label.${amount.currency}`)}`,
            ),
            getGeneralTicketItem(`${FORM_SUMMARY}.class.label`, fondoClase, "string"),
            getGeneralTicketItem(`${FORM_SUMMARY}.debitAccount.label`, debitAccontLabel, "string"),
            getGeneralTicketItem(`${FORM_SUMMARY}.shareAccount.label`, shareAccount, "string"),
            getGeneralTicketItem(`${FORM_SUMMARY}.date.label`, summary?.creationDate, "date"),
        ];

        const ticketData = {
            ticketName: i18nUtils.get(`${FORM_ID}.title`),
            ticketBody: bodyData,
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    handlePreCancel = () => {
        const { dispatch } = this.props;
        dispatch(push("/subscribe/scheduled/cancel"));
    };

    buttonsDetail = () => {
        const { dispatch, activeEnvironment, summary } = this.props;
        const buttons = [
            {
                label: `${FORM_ID}.goToDesktop`,
                bsStyle: "outline",
                onClick: () => dispatch(push("/desktop")),
            },
        ];

        if (summary?.idTransactionStatus !== "SCHEDULED") {
            buttons.push({
                label: `${FORM_ID}.goToFundTrade`,
                bsStyle: "primary",
                onClick: () => dispatch(push("/fundcorpOperations")),
            });
        }

        if (
            activeEnvironment?.permissions?.scheduledTransactionsCancel &&
            summary?.idTransactionStatus === "SCHEDULED"
        ) {
            buttons.push({
                label: "scheduler.cancel.button",
                bsStyle: "primary",
                onClick: this.handlePreCancel,
            });
        }

        return buttons;
    };

    rightContentTransactionScheme = (isFinished) => {
        const { isDesktop, summary } = this.props;

        if (isDesktop || isFinished) {
            return <></>;
        }

        return (
            <>
                {summary?.idTransactionStatus !== "CANCELLED" && (
                    <ContextMenu
                        isDesktop={isDesktop}
                        buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                        items={[
                            {
                                label: "pendingTransaction.info.scheme",
                                onClick: () => this.setState({ showDrawerMobile: true }),
                            },
                        ]}
                    />
                )}
            </>
        );
    };

    getTitle = () => {
        const { summary } = this.props;
        const { idActivity } = summary;
        let title;

        if (idActivity === "scheduled.transactions.cancel") {
            title = "activities.scheduled.transactions.cancel";
        } else {
            title = `${FORM_ID}.title`;
        }

        return title;
    };

    renderContent = () => {
        const { summary, isDesktop, isSmallDesktop, fetching } = this.props;
        const { showDrawerMobile, fetchingDownload } = this.state;
        const isFinished = summary?.idTransactionStatus === "FINISHED";

        return (
            <>
                {summary && (
                    <TransactionTicket
                        notificationScope="forms"
                        handleClick={this.exportPdf}
                        handleClickMessage="global.download"
                        uniqueDownload={isFinished}
                        downloadImageWhite={!isDesktop}
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        hasInlineButtons
                        hideMobileMenu={!isDesktop}
                        summary={summary}
                        fetching={fetching}
                        generateSignFunction
                        ignoreHomeButton
                        buttonsDetail={this.buttonsDetail()}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                        summaryTitle={this.getTitle()}
                        showDrawerMobile={showDrawerMobile}
                        closeMobileDrawer={() => this.setState({ showDrawerMobile: false })}
                        isFetchingExport={fetchingDownload}>
                        <SubscribeSummaryData {...this.props} spaceBetween />
                    </TransactionTicket>
                )}
            </>
        );
    };

    render() {
        const { fetching } = this.props;

        return <PageLoading loading={fetching}>{!fetching && this.renderContent()}</PageLoading>;
    }
}

const mapStateToProps = (state) => ({
    summary: fundcorpSelectors.getSummary(state),
    fetching: fundcorpSelectors.getFetching(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(SubscribeTicket);
