/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { selectors as i18nSelectors } from "reducers/i18n";
import { numberFormat, toNumber, toNumericString } from "util/number";

import ConnectedAmountField from "pages/_components/fields/AmountField";

class FormikAmountField extends Component {
    handleBlur = () => {
        const { field, form } = this.props;
        form.setFieldTouched(field.name);
    };

    handleCurrencyChange = (event) => {
        const value = event == null ? "" : event.value;
        const { field, form, onCurrencyChange, data } = this.props;

        if (onCurrencyChange) {
            onCurrencyChange(value);
        } else if (data?.onCurrencyChange) {
            data.onCurrencyChange(value, form);
        } else {
            form.setFieldValue(`${field.name}.currency`, value);
        }
    };

    handleInputChange = ({ target }) => {
        const { field, form, lang, onInputChange, onCustomOnChange, plainValue, maximumDecimalsCustom, isNumericString } = this.props;
        const { decimalSeparator } = numberFormat(lang);

        const number = isNumericString
        ? toNumericString(target.value, decimalSeparator)
        : toNumber(target.value, decimalSeparator, maximumDecimalsCustom && maximumDecimalsCustom);

        if (onInputChange) {
            onInputChange(number);
        } else {
            if (plainValue) {
                form.setValues({ ...form.values, [field.name]: number });
            } else {
                form.setFieldValue(`${field.name}.amount`, number);
            }
        }
        if (onCustomOnChange) {
            onCustomOnChange(number);
        }
    };

    render() {
        const { field, form, handleKeyDown, isNumericString } = this.props;
        return (
            <ConnectedAmountField
                currencyClassName
                formGroupClassName
                inputGroupClassName
                amountClassName
                {...field}
                hasError={form.touched[field.name] && form.errors[field.name]}
                onCurrencyChange={this.handleCurrencyChange}
                onInputChange={this.handleInputChange}
                onBlur={this.handleBlur}
                error={form.errors[field.name] && form.errors[field.name].amount}
                autocomplete="off"
                isNumericString={isNumericString}
                onKeyDown={handleKeyDown}
                {...this.props}
                {...field.value}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    lang: i18nSelectors.getLang(state),
});

export default compose(connect(mapStateToProps))(FormikAmountField);
