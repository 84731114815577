/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { bool, shape, arrayOf, func, number } from "prop-types";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import Button from "pages/_components/Button";
import * as config from "util/config";

import { selectors as metrocorpSelectors, actions as metrocorpActions } from "reducers/metrocorp";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";
import Pagination from "pages/_components/pagination/Pagination";
import Spinner from "pages/_components/Spinner";

const FORM_ID = "metrocorp.list";

class MetrocorpMovementsList extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        movements: arrayOf(shape({})).isRequired,
        dispatch: func.isRequired,
        handleOpenBottomSheetClick: func.isRequired,
        fetchingDetailDownloadByReferenceMobile: number.isRequired,
    };

    handleDetail = (referenciaMinuta, codEspe, nombre, apellido, descripcionEspecie, descripcionEspecieRelacionada) => {
        const { dispatch, handleOpenBottomSheetClick } = this.props;
        handleOpenBottomSheetClick();
        const enableCurrencyValidation = config.getBoolean("metrocorp.currencyValidation.enabled");
        const currency = enableCurrencyValidation
            ? this.getCurrencyFromDescriptions(descripcionEspecie, descripcionEspecieRelacionada)
            : "0";
        dispatch(metrocorpActions.getMovementDetail(referenciaMinuta, codEspe.trim(), nombre, apellido, currency));
    };

    getCurrencyFromDescriptions(descripcionEspecie, descripcionEspecieRelacionada) {
        let currency = "";
        if (descripcionEspecieRelacionada === "PESOS" || descripcionEspecie === "PESOS") {
            currency = "0";
        } else if (descripcionEspecieRelacionada.startsWith("DOLAR") || descripcionEspecie.startsWith("DOLAR")) {
            currency = "2";
        }
        return currency;
    }

    handleExportItemMobile = (
        referenciaMinuta,
        codEspe,
        nombre,
        apellido,
        descripcionEspecie,
        descripcionEspecieRelacionada,
        index,
    ) => {
        const { dispatch } = this.props;

        const enableCurrencyValidation = config.getBoolean("metrocorp.currencyValidation.enabled");
        const currency = enableCurrencyValidation
            ? this.getCurrencyFromDescriptions(descripcionEspecie, descripcionEspecieRelacionada)
            : "0";

        dispatch(
            metrocorpActions.downloadMetrocorpDetailByReference(
                index,
                referenciaMinuta,
                codEspe.trim(),
                nombre,
                apellido,
                currency,
                "pdf",
            ),
        );
    };

    nextPage = (page) => {
        const { dispatch, optionSelected, filtersData, clients, isDesktop } = this.props;
        const { principalAccount, species, date, dateFrom, dateTo } = filtersData;
        const selectedClient = clients.filter((client) => client.codClie === principalAccount)[0];

        dispatch(
            metrocorpActions.listMetrocorp(
                optionSelected,
                `${selectedClient.nombre} ${selectedClient.apellido}`,
                principalAccount,
                species,
                date,
                dateFrom,
                dateTo,
                page,
                isDesktop,
            ),
        );
    };

    renderListForMovements = () => {
        const { movements, filtersData, totalPages, isDesktop } = this.props;

        if (!(movements && movements.length > 0)) {
            return (
                <div className="text-center no-more-data">
                    <div className="illustration-wrapper">
                        <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                    </div>
                    <p className="text-lead">
                        <I18n id={`${FORM_ID}.empty.message`} />
                    </p>
                </div>
            );
        }

        const endOfListItem = (
            <div className="table-row" key="noMoreTransactions">
                <div className="table-data text-center">
                    <I18n id="transactions.list.noMoreTransactions" />
                </div>
            </div>
        );

        return isDesktop ? (
            <>
                {movements.map((item, index) => this.renderListForMovementsItem(item, index))}
                <div className="d-flex justify-content-end w-100 my-3 pr-2">
                    <Pagination
                        totalPages={totalPages || 1}
                        pageNumber={filtersData.page}
                        action={this.nextPage}
                        showFirstAndLastButton
                        showText
                    />
                </div>
            </>
        ) : (
            <InfiniteScroll
                dataLength={movements.length}
                next={() => this.nextPage(filtersData.page + 1)}
                hasMore={totalPages > filtersData.page}
                loader={<Spinner />}
                endMessage={endOfListItem}>
                {movements.map((item, index) => this.renderListForMovementsItem(item, index))}
            </InfiniteScroll>
        );
    };

    renderListForMovementsItem = (item, index) => {
        const { isDesktop, fetchingDetailDownloadByReferenceMobile } = this.props;
        const {
            fechaMovimiento,
            descripcionEspecie,
            descripcionOperacion,
            importe,
            referenciaMinuta,
            codEspe,
            numeroBoleto,
            Nombre,
            Apellido,
            descripcionEspecieRelacionada,
        } = item;
        const debitAccount = importe < 0 ? importe : "";
        const creditAccount = importe >= 0 ? importe : "";
        const enableCurrencyValidation = config.getBoolean("metrocorp.currencyValidation.enabled");

        if (isDesktop) {
            return (
                <>
                    <CardContainer className="metrocorp__card-container">
                        <Card
                            className="metrocorp__card"
                            icon={debitAccount ? "images/movementArrowLeft.svg" : "images/movementArrowRight.svg"}
                            iconClass="metrocorp__arrow-icon"
                            title={
                                <div className="metrocorp__card-title">
                                    <div className="metrocorp__date-movement">
                                        <I18n
                                            id={`${FORM_ID}.movements.column.movementDate`}
                                            componentProps={{ className: "metrocorp__date-movement-info" }}
                                        />
                                        <FormattedDate
                                            date={fechaMovimiento}
                                            className="metrocorp__date-movement-data"
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                </div>
                            }
                            content={
                                <div className="metrocorp__card-content">
                                    <div
                                        className={
                                            descripcionOperacion >= 30
                                                ? "metrocorp__description description-mov"
                                                : "metrocorp__description"
                                        }>
                                        <span className="metrocorp__description-info">{descripcionOperacion}</span>
                                    </div>
                                    <div className="metrocorp__specie specie-mov">
                                        <I18n
                                            id={`${FORM_ID}.movements.column.species`}
                                            componentProps={{ className: "metrocorp__specie-info" }}
                                        />
                                        <span className="metrocorp__specie-data">{`${codEspe.trim()} - ${descripcionEspecie}`}</span>
                                    </div>
                                </div>
                            }
                            rightContent={
                                <div className="metrocorp__card-right metrocorp__mov">
                                    <div className="metrocorp__totalamount">
                                        <I18n
                                            id={`${FORM_ID}.movements.column.nominal`}
                                            componentProps={{ className: "metrocorp__totalamount-info" }}
                                        />
                                        {debitAccount ? (
                                            <FormattedAmount
                                                noCurrency
                                                className="metrocorp__totalamount-data metrocorp__red"
                                                quantity={debitAccount}
                                                notBold
                                            />
                                        ) : (
                                            <FormattedAmount
                                                noCurrency
                                                className="metrocorp__totalamount-data metrocorp__green"
                                                quantity={creditAccount}
                                                notBold
                                            />
                                        )}

                                        {/*
                                        TENER EN CUENTA POR SI LO PIDEN:

                                        {filtersData?.species !== "all" && (
                                            <Table.Data align="right">
                                                {saldoAcumulado && (
                                                    <FormattedAmount
                                                        noCurrency
                                                        className="data-amount light-font"
                                                        quantity={saldoAcumulado}
                                                        notBold
                                                    />
                                                )}
                                            </Table.Data>

                                        )}
                                        */}
                                    </div>
                                    {(enableCurrencyValidation ? (
                                        referenciaMinuta && numeroBoleto
                                    ) : (
                                        referenciaMinuta
                                    )) ? (
                                        <Button
                                            className="btn-view"
                                            onClick={() =>
                                                this.handleDetail(
                                                    referenciaMinuta,
                                                    codEspe,
                                                    Nombre,
                                                    Apellido,
                                                    descripcionEspecie,
                                                    descripcionEspecieRelacionada,
                                                )
                                            }
                                            image="images/show.svg"
                                        />
                                    ) : (
                                        enableCurrencyValidation && <div className="column-empty" />
                                    )}
                                </div>
                            }
                        />
                    </CardContainer>
                </>
            );
        }
        return (
            <>
                <CardContainer className="metrocorp__card-container">
                    <Card
                        className="metrocorp__card metrocorp__card-mov mt-0"
                        title={
                            <div className="metrocorp__card-title metrocorp__title-movs">
                                <Image
                                    src={
                                        debitAccount ? "images/movementArrowLeft.svg" : "images/movementArrowRight.svg"
                                    }
                                    className="metrocorp__arrow-icon"
                                />
                                <div className="metrocorp__description">
                                    <span className="metrocorp__description-info">{descripcionOperacion}</span>
                                    <hr className="mx-0" />
                                </div>
                            </div>
                        }
                        rightMenu={
                            (enableCurrencyValidation ? (
                                numeroBoleto
                            ) : (
                                numeroBoleto !== null
                            )) ? (
                                <Button
                                    className="metrocorp__btn-download"
                                    onClick={() =>
                                        this.handleExportItemMobile(
                                            referenciaMinuta,
                                            codEspe,
                                            Nombre,
                                            Apellido,
                                            descripcionEspecie,
                                            descripcionEspecieRelacionada,
                                            index,
                                        )
                                    }
                                    image="images/download_bold-black.svg"
                                    disabled={fetchingDetailDownloadByReferenceMobile === index}
                                />
                            ) : null
                        }
                        content={
                            <div
                                className={
                                    numeroBoleto !== null
                                        ? "metrocorp__card-content m-right"
                                        : "metrocorp__card-content"
                                }>
                                <div className="metrocorp__date-movement">
                                    <I18n
                                        id={`${FORM_ID}.movements.column.movementDate`}
                                        componentProps={{ className: "metrocorp__date-movement-info" }}
                                    />
                                    <FormattedDate
                                        date={fechaMovimiento}
                                        className="metrocorp__date-movement-data"
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </div>
                                <div className="metrocorp__specie specie-mov">
                                    <I18n
                                        id={`${FORM_ID}.movements.column.species`}
                                        componentProps={{ className: "metrocorp__specie-info" }}
                                    />
                                    <span className="metrocorp__specie-data">{`${codEspe.trim()} - ${descripcionEspecie}`}</span>
                                </div>
                            </div>
                        }
                        rightContent={
                            <div className="metrocorp__card-right metrocorp__mov">
                                <div className="metrocorp__totalamount">
                                    <I18n
                                        id={`${FORM_ID}.movements.column.nominal`}
                                        componentProps={{ className: "metrocorp__totalamount-info" }}
                                    />
                                    {debitAccount ? (
                                        <FormattedAmount
                                            noCurrency
                                            className="metrocorp__totalamount-data metrocorp__red"
                                            quantity={debitAccount}
                                            notBold
                                        />
                                    ) : (
                                        <FormattedAmount
                                            noCurrency
                                            className="metrocorp__totalamount-data metrocorp__green"
                                            quantity={creditAccount}
                                            notBold
                                        />
                                    )}
                                </div>
                            </div>
                        }
                    />
                </CardContainer>
            </>
        );
    };

    render() {
        return <>{this.renderListForMovements()}</>;
    }
}

const mapStateToProps = (state) => ({
    detail: metrocorpSelectors.getMovementDetail(state),
    fetchingDetailDownloadByReferenceMobile: metrocorpSelectors.getFetchingDetailDownloadByReferenceMobile(state),
    filtersData: metrocorpSelectors.getFiltersData(state),
    clients: metrocorpSelectors.getClients(state),
    totalPages: metrocorpSelectors.getMovementsTotalPages(state),
});

export default connect(mapStateToProps)(MetrocorpMovementsList);
