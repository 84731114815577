import React, { Component } from "react";
import { string, shape, func } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as i18nUtils from "util/i18n";
import * as accountUtils from "util/accounts";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import { Col } from "react-bootstrap";
import Heading from "pages/_components/Heading";
import { isFinalState } from "util/transaction";

class IncomingDebinTransactionData extends Component {
    static propTypes = {
        summary: shape({}).isRequired,
        dispatch: func.isRequired,
        accountName: string.isRequired,
        debinDetail: shape({}).isRequired,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push(`/pendingTransaction/list`));
    };

    render() {
        const { summary, accountName, debinDetail } = this.props;
        const {
            sellerDetail,
            amount: { currency, quantity },
            idVoucher,
            concept,
            expirationDate,
            description,
        } = debinDetail;

        return (
            summary && (
                <>
                    {isFinalState(summary.idTransactionStatus) && (
                        <Col xs={12} className="px-0">
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label="negotiate.transaction.date.label"
                                data={
                                    <p className="data-desc m-0">
                                        <FormattedDate date={summary.submitDateTime} dateFormat="dd/MM/yyyy" showTime />
                                    </p>
                                }
                            />
                        </Col>
                    )}
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="pay.paymentAFIP.idTransaction"
                            data={
                                <p className="data-desc m-0">{summary.idTransaction.substring(0, 8).toUpperCase()}</p>
                            }
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="payments.debin.confirm.table.header.debitAccount"
                            data={<p className="data-desc m-0">{accountName}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0 mb-3">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="payments.debin.confirm.table.header.id"
                            data={<p className="data-desc m-0">{summary?.idDebin}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`payments.debin.confirm.table.header.type${
                                sellerDetail.cbu?.startsWith("000") ? "cvu" : "cbu"
                            }`}
                            data={<p className="data-desc m-0">{sellerDetail.cbu}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="payments.debin.confirm.table.header.creditAccount"
                            data={<p className="data-desc m-0">{accountUtils.getAccountNumber(sellerDetail.cbu)}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="payments.debin.detail.table.header.documentType"
                            data={
                                <p className="data-desc m-0">
                                    <span style={{ marginRight: "0.5rem" }}>{sellerDetail.documentType}</span>
                                    <span>{sellerDetail.documentNumber}</span>
                                </p>
                            }
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="payments.debin.detail.table.header.sender"
                            data={<p className="data-desc m-0">{sellerDetail.titular}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`payments.debin.confirm.table.header.${!sellerDetail.cbu.startsWith("000") ? "bank": "entity"}`}
                            data={<p className="data-desc m-0">{sellerDetail.bank && sellerDetail.bank!== "000" ? sellerDetail.bank : "-"}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0 mb-3">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="payments.debin.detail.table.header.reference"
                            data={<p className="data-desc m-0">{debinDetail.refence}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="payments.debin.detail.table.header.amount"
                            data={
                                <p className="data-desc m-0">
                                    <FormattedAmount currency={currency} quantity={quantity} notBold />
                                </p>
                            }
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="payments.debin.detail.table.header.voucher"
                            data={<p className="data-desc m-0">{idVoucher}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="payments.debin.detail.table.header.concept"
                            data={
                                <p className="data-desc m-0">
                                    {concept} - {i18nUtils.get(`transfers.concept.${concept}`)}
                                </p>
                            }
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="payments.debin.table.header.expiration"
                            data={
                                <p className="data-desc m-0">
                                    <FormattedDate date={expirationDate} dateFormat="dd/MM/yyyy" showTime />
                                </p>
                            }
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="payments.debin.detail.table.header.description"
                            data={<p className="data-desc m-0">{description}</p>}
                        />
                    </Col>
                </>
            )
        );
    }
}

export default connect(null)(IncomingDebinTransactionData);
