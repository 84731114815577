import React, { Component } from "react";
import { oneOfType, array, string, func, bool, number, shape, arrayOf } from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { push } from "react-router-redux";

import { actions as depositsActions, selectors as depositsSelectors } from "reducers/deposits";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as holidaysActions } from "reducers/holidays";

import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import Deposit from "pages/deposits/_components/Deposit";
import GeneralMsg from "pages/_components/GeneralMsg";
import Container from "pages/_components/Container";
import * as i18n from "util/i18n";
import EquivalentTotalBalance from "pages/accounts/_components/EquivalentTotalBalance";

import * as configUtil from "util/config";

class ProductList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: oneOfType([string, bool]),
        deposits: oneOfType([array]),
        holidays: arrayOf(string),
        ARSTotalBalance: number,
        USDTotalBalance: number,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        fetchingDownload: bool.isRequired,
        activeEnvironment: shape({}).isRequired,
    };

    static defaultProps = {
        fetching: false,
        deposits: [],
        holidays: [],
        ARSTotalBalance: null,
        USDTotalBalance: null,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(depositsActions.listDeposits());
        dispatch(holidaysActions.listHolidays());
        this.handlePlazoFijoAvailable();
    }

    handlePlazoFijoAvailable = () => {
        const { dispatch, activeEnvironment } = this.props;
        const plazoFijoDisponible = configUtil.getBoolean("frontend.constitucionPlazoFijo.disponible", false);
        if (!plazoFijoDisponible && activeEnvironment.permissions.depositsConstitute) {
            const textoDisponible = configUtil.get("frontend.constitucionPlazoFijo.textoDisponible", "");
            dispatch(notificationActions.showNotification(textoDisponible, "warning", ["deposits"], null, false));
            return false;
        }
        return true;
    };

    handleExport = (format) => {
        const { dispatch } = this.props;
        dispatch(depositsActions.downloadList(format));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop/"));
    };

    handleBuildDepositClick = () => {
        if (this.handlePlazoFijoAvailable()) {
            const { dispatch } = this.props;
            dispatch(depositsActions.checkHours());
        }
    };

    renderHeader = () => {
        const { deposits, isDesktop, fetching, fetchingDownload, activeEnvironment } = this.props;
        const rightContentResult =
            isEmpty(deposits) && !activeEnvironment.permissions.depositsConstitute ? <div /> : null;

        return (
            <>
                <div className="admin-detail-head px-0">
                    {isDesktop && <Head onBack={this.handleBack} />}
                    <Head
                        title="menu.deposits"
                        addLinkToLabel={isDesktop ? "deposit.new" : null}
                        handleCreateMessage={isDesktop ? "deposits.page.newDeposit" : ""}
                        exportList
                        handleClick={this.handleExport}
                        isFetchingExport={fetchingDownload}
                        onBack={!isDesktop ? this.handleBack : null}
                        handleClickMessage="deposits.list.download"
                        csvDownload={isDesktop}
                        xlsDownload={isDesktop}
                        downloadImageWhite={!isDesktop}
                        addButtonImageWhite={!isDesktop}
                        titleClassName={!isDesktop ? "header-mobile-title-background-blue" : ""}
                        headerClassName={!isDesktop && "blue-main-header-mobile blue-main-title-mobile"}
                        hideMobileMenu={!isDesktop}
                        handleNewClick={
                            !fetching &&
                            activeEnvironment.permissions.depositsConstitute &&
                            this.handleBuildDepositClick
                        }
                        newButtonLabel={
                            isDesktop && activeEnvironment.permissions.depositsConstitute ? "deposit.new" : null
                        }
                        rightContent={rightContentResult && (() => rightContentResult)}
                        imageStyle="mr-2"
                    />
                </div>
            </>
        );
    };

    render() {
        const { deposits, fetching, isDesktop, isSmallDesktop } = this.props;
        const maxBoxDisplay = configUtil.getInteger("product.list.maxBoxDisplay", 5);

        const productAsCard = deposits.length < maxBoxDisplay;

        return (
            <>
                <Notification scopeToShow="deposits" notificationClassname="navbar-fixed-top" />
                {!isDesktop && this.renderHeader()}
                <PageLoading loading={fetching} className="screen-loader">
                    {!fetching && isDesktop && this.renderHeader()}
                    <div className="above-the-fold">
                        <EquivalentTotalBalance {...this.props} title="deposits.balance.total" />
                        {deposits.length ? (
                            <>
                                <Container className="align-items-center flex-grow">
                                    <div className="table table--products">
                                        <div
                                            role="menu"
                                            className={classNames("table-body", {
                                                "table-body--grid": productAsCard,
                                            })}>
                                            {deposits &&
                                                Object.entries(deposits).map(([id, deposit]) => (
                                                    <Deposit
                                                        deposit={deposit}
                                                        key={`${id} - ${deposit.depositNumber}`}
                                                        productAsCard={productAsCard}
                                                        isDesktop={isDesktop}
                                                        isSmallDesktop={isSmallDesktop}
                                                    />
                                                ))}
                                        </div>
                                    </div>
                                </Container>
                            </>
                        ) : (
                            <GeneralMsg
                                imagePath="images/coloredIcons/find-in-page.svg"
                                description={i18n.get("deposits.list.empty")}
                                isDesktop={isDesktop}
                            />
                        )}
                    </div>
                </PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    deposits: depositsSelectors.getDeposits(state),
    ARSTotalBalance: depositsSelectors.getARSTotalBalance(state),
    USDTotalBalance: depositsSelectors.getUSDTotalBalance(state),
    fetching: depositsSelectors.getFetching(state),
    fetchingDownload: depositsSelectors.getFetchingDownload(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(ProductList);
