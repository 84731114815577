import { call, put, takeLatest } from "redux-saga/effects";
import { types, actions } from "reducers/frequentDestination";
import { types as debinTypes } from "reducers/debin";
import * as frequentDestinationMidleware from "middleware/frequentDestination";
import { actions as notificationActions } from "reducers/notification";
import { downloadPdf } from "util/download";
import * as i18n from "util/i18n";
import { push, go, goBack } from "react-router-redux";
import { delay } from "redux-saga";
import * as form from "middleware/form";

const sagas = [
    takeLatest(types.LOAD_LIST_REQUEST, loadListRequest),
    takeLatest(types.FREQUENT_DESTINATION_CREATE_PREVIEW, frequentDestinationCreatePreview),
    takeLatest(types.FREQUENT_DESTINATION_CREATE_REQUEST, frequentDestinationCreate),
    takeLatest(types.DELETE_FREQUENT_DESTINATION_PREVIEW_REQUEST, deleteFrequentDestinationPreview),
    takeLatest(types.DELETE_FREQUENT_DESTINATION_REQUEST, deleteFrequentDestination),
    takeLatest(types.MODIFY_FREQUENT_DESTINATION_PRE_REQUEST, modifyFrequentDestinationPre),
    takeLatest(types.MODIFY_FREQUENT_DESTINATION_PREVIEW_REQUEST, modifyFrequentDestinationPreview),
    takeLatest(types.MODIFY_FREQUENT_DESTINATION_REQUEST, modifyFrequentDestinationRequest),
    takeLatest(types.FREQUENT_DESTINATION_CREATE_TRANSFER_PRE_REQUEST, createFrequentDestinationTransferPre),
    takeLatest(types.FREQUENT_DESTINATION_REQUEST, verifyFrequentDestination),
    takeLatest(types.GET_CBU_ACCOUNT_INFORMATION, getCbuAccountInformation),
    takeLatest(types.FREQUENT_DESTINATIONS_DOWLOAD, dowloadFrequentDestinations),
];

export default sagas;

function* loadListRequest(params) {
    const { idEnvironment, filters } = params;
    const response = yield call(frequentDestinationMidleware.getListFrequentDestinations, idEnvironment, filters);
    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["FrequentDestinations"]),
        );
    } else {
        yield put({
            type: types.LOAD_LIST_SUCCESS,
            idEnvironment,
            frequentDestinations: response.data.data.frequentDestinations,
            moreStatements: response.data.data.moreStatements,
        });
    }
}

function* deleteFrequentDestinationPreview(idFrequentDestination) {
    const response = yield call(frequentDestinationMidleware.deleteFrequentDestinationPreview, idFrequentDestination);
    if (response.type === "W") {
        yield put(actions.deleteFrequentDestinationPreviewFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "frequentDestinationsDetail",
            ]),
        );
    } else {
        const responseCredentials = yield call(
            form.listCredentialsGroups,
            null,
            "administration.signatures.create.send",
        );
        const credentialGroups = responseCredentials.data.data.groups;
        yield put({
            type: types.DELETE_FREQUENT_DESTINATION_PREVIEW_SUCCESS,
            destinationDelete: response.data.data.frequentDestination,
            credentialGroupsData: credentialGroups,
        });
        yield put(push(`/frequentDestination/${response.data.data.frequentDestination.idFrequentDestination}/delete/`));
    }
}

function* deleteFrequentDestination(params) {
    const response = yield call(frequentDestinationMidleware.deleteFrequentDestinationRequenst, params);
    if (response.type === "W") {
        yield put(actions.frequentDestinationCreateFailure());
        if (response.data.code === "COR020W") {
            params.formikBag.setErrors(response.data.data);
        } else {
            yield put(
                notificationActions.showNotification(response.data.message, "error", [
                    "frequentDestinationDeleteConfirmation",
                ]),
            );
        }
    } else {
        yield put(actions.deleteFrequentDestinationSuccess());
        yield put(
            notificationActions.showNotification(i18n.get("frequentDestination.delete.success"), "success", [
                "frequentDestination",
                "transfersHistoric",
            ]),
        );
        yield put(params.lastPathname !== undefined ? push("/FrequentDestination") : goBack());
    }
}

function* dowloadFrequentDestinations() {
    const response = yield call(frequentDestinationMidleware.dowloadFrequentDestinations);
    const { content, fileName } = response?.data.data;
    if (response.type === "W") {
        yield put(actions.downloadListFrequentDestinationsFailure());
        yield put(
            notificationActions.showNotification(
                i18n.get("global.unexpectedError"),
                "error",
                ["FrequentDestinations"],
                null,
                false,
            ),
        );
    } else {
        yield put(
            notificationActions.showNotification(
                i18n.get("global.successDownload"),
                "success",
                ["FrequentDestinations"],
                null,
                false,
            ),
        );
        yield call(delay, 3000);
        downloadPdf(fileName, content);
        yield put(actions.downloadListFrequentDestinationsSuccess());
    }
}

function* getCbuAccountInformation(params) {
    const { cbu, email, name, schedule, isValidationFromDebCred } = params.params;
    const { formikBag } = params;
    const {
        props: { cbuList, closeDrawer },
    } = formikBag;
    const validateOnlyCbu = true;
    const response = yield call(
        frequentDestinationMidleware.createDestinationPreview,
        cbu,
        email,
        name,
        validateOnlyCbu,
        isValidationFromDebCred ?? false,
    );
    formikBag.setSubmitting(false);
    if (response.data.code === "COR000I") {
        const { frequentDestination } = response.data.data;
        const {
            cbu: recipientCbu,
            recipientName,
            isOwn,
            recipientDocumentType,
            recipientDocumentNumber,
        } = frequentDestination;
        const frequentDestinationExistsInList = cbuList.find((cbuElement) => cbuElement.cbu === recipientCbu);

        if (frequentDestinationExistsInList) {
            formikBag.setErrors({ receiver: i18n.get("accounts.new.account.field.error.repeated") });
        } else {
            yield put({
                type: debinTypes.ADD_TO_CBU_LIST,
                newCbu: {
                    cbu: recipientCbu,
                    document: `${recipientDocumentType || ""} ${recipientDocumentNumber || ""}`,
                    headline: recipientName,
                    isOwn,
                    reference: name,
                    email,
                    amount: { amount: null },
                    comprobante: null,
                    schedule,
                },
            });
            closeDrawer();
        }
    } else if (response.data.data.NO_FIELD) {
        formikBag.setErrors({ receiver: response.data.data.NO_FIELD });
    }
}

function* frequentDestinationCreatePreview(params) {
    const { cbu, email, name, validateOnlyCbu, isValidationFromDebCred } = params.params;
    const { setErrors } = params.formikBag;
    const response = yield call(
        frequentDestinationMidleware.createDestinationPreview,
        cbu,
        email,
        name,
        validateOnlyCbu,
        isValidationFromDebCred,
    );
    if (response.type === "W") {
        yield put(actions.frequentDestinationCreatePreviewFailed());
        if (response.data.code === "COR020W" && !isValidationFromDebCred) {
            setErrors({ cbuAlias: response.data.data.NO_FIELD });
        } else if (response.data.data.NO_FIELD) {
            yield put(
                notificationActions.showNotification(response.data.data.NO_FIELD, "error", [
                    "FrequentDestinationsCreate",
                ]),
            );
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "FrequentDestinationsCreate",
                ]),
            );
        }
    } else {
        const responseCredentials = yield call(
            form.listCredentialsGroups,
            null,
            "administration.signatures.create.send",
        );
        const credentialGroupsData = responseCredentials.data.data.groups;
        yield put(actions.frequentDestinationCreatePreviewSuccess(response.data.data, credentialGroupsData));
        yield put(push(`/frequentDestination/create/${response.data.data.frequentDestination.cbu}/confirm`));
    }
}

function* frequentDestinationCreate(params) {
    const { otp } = params;
    const { setSubmitting, setErrors } = params.formikBag;

    const {
        accountNumber,
        cbu,
        idEnvironment,
        idFrequentDestination,
        isOwn,
        localBank,
        name,
        productType,
        recipientDocumentNumber,
        recipientDocumentType,
        recipientEmail,
        recipientName,
        subsidiaryBank,
    } = params.frequentDestination;

    const response = yield call(
        frequentDestinationMidleware.createDestination,
        accountNumber,
        cbu,
        idEnvironment,
        idFrequentDestination,
        isOwn,
        localBank,
        name,
        productType,
        recipientDocumentNumber,
        recipientDocumentType,
        recipientEmail,
        recipientName,
        subsidiaryBank,
        otp,
    );
    setSubmitting(false);
    if (response.type === "W") {
        yield put(actions.frequentDestinationCreateFailure());
        if (response.data.code !== "COR020W") {
            yield put(
                notificationActions.showNotification(response.data.message, "error", [
                    "frequentDestinationCreateConfirm",
                ]),
            );
        } else {
            setErrors(response.data.data);
        }
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("frequentDestination.create.success"), "success", [
                "FrequentDestinations",
                "transfersHistoric",
                "pendingTransfer",
            ]),
        );
        yield put(actions.frequentDestinationCreateSuccess(response.data.data));
        yield put(go(-2));
    }
}

function* modifyFrequentDestinationPre(params) {
    const { idFrequentDestination, fromTransfers } = params;
    const response = yield call(frequentDestinationMidleware.modifyFrequentDestinationPre, idFrequentDestination);
    if (response.type === "W") {
        yield put(actions.modifyFrequentDestinationPreFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "frequentDestinationsDetail",
            ]),
        );
    } else {
        yield put({
            type: types.MODIFY_FREQUENT_DESTINATION_PRE_SUCCESS,
            destinationModify: response.data.data.frequentDestination,
        });
        if (fromTransfers) {
            yield put(push(`/frequentDestination/${idFrequentDestination}/modify/`));
        }
    }
}

function* modifyFrequentDestinationPreview(params) {
    const response = yield call(frequentDestinationMidleware.modifyFrequentDestinationPreview, params.params);
    if (response.type === "W") {
        yield put(actions.modifyFrequentDestinationPreviewFailure());
        if (response.data.data.NO_FIELD) {
            yield put(
                notificationActions.showNotification(response.data.data.NO_FIELD, "error", [
                    "FrequentDestinationsModify",
                ]),
            );
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "FrequentDestinationsModify",
                ]),
            );
        }
    } else {
        const { name, email, idFrequentDestination } = response.data.data;
        const id = idFrequentDestination;
        yield put({
            type: types.MODIFY_FREQUENT_DESTINATION_PREVIEW_SUCCESS,
            nameModify: name,
            emailModify: email,
            idFrequentDestination: id,
        });
        yield put(push(`/frequentDestination/${response.data.idTransaction}/modify/confirmation`));
    }
}

function* modifyFrequentDestinationRequest(params) {
    const response = yield call(frequentDestinationMidleware.modifyFrequentDestination, params);
    if (response.type === "W") {
        yield put(actions.modifyFrequentDestinationFailure());
        if (response.data.code === "COR020W") {
            params.formikBag.setErrors(response.data.data);
        } else {
            yield put(
                notificationActions.showNotification(response.data.message, "error", [
                    "frequentDestinationModifyConfirm",
                ]),
            );
        }
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("frequentDestination.modify.success"), "success", [
                "FrequentDestinations",
                "transfersHistoric",
            ]),
        );
        yield put(actions.modifyFrequentDestinationSuccess());
        yield put(go(-2));
    }
}

function* createFrequentDestinationTransferPre(params) {
    const response = yield call(frequentDestinationMidleware.createFrequentDestinationTransferPre, params);
    if (response.type === "W") {
        yield put(actions.createFrequentDestinationTransferPreFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["form"]));
    } else {
        yield put(actions.createFrequentDestinationTransferPreSuccess(response.data.data));
    }
}

function* verifyFrequentDestination(params) {
    const { idEnvironment, cbu } = params;
    const response = yield call(frequentDestinationMidleware.getListFrequentDestinations, idEnvironment, cbu);
    if (response.type === "W") {
        yield put(actions.getFrequentDestinationFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["form"]));
    } else {
        yield put(actions.getFrequentDestinationSuccess(response.data.data));
    }
}
