import { call, put, takeLatest, take, select } from "redux-saga/effects";
import { push } from "react-router-redux";
import { delay } from "redux-saga";
import { history } from "store";

import * as fundcorpMiddleware from "middleware/fundcorp";
import * as session from "middleware/session";
import * as i18n from "util/i18n";
import { types, selectors as fundcorpSelectors } from "reducers/fundcorp";
import { actions as notificationActions } from "reducers/notification";
import { actions as formActions } from "reducers/form";
import { INSUFFICIENT_SIGNATURES, INTERNAL_ERROR, SCHEDULED_TRANSACTION, TRANSACTION_FINISHED } from "util/responses";
import { downloadPdf, downloadXls } from "util/download";

const sagas = [
    takeLatest(types.LIST_FUNDCORP_REQUEST, listFundcorp),
    takeLatest(types.LIST_FUNDCORP_PRE_REQUEST, listFundcorpPre),
    takeLatest(types.LIST_FUNDCORP_INF_PRE_REQUEST, listFundcorpInfPre),
    takeLatest(types.DOWNLOAD_LIST_FUNDCORP_REQUEST, downloadList),
    takeLatest(types.CREATE_RESCUE_REQUEST_PRE, rescueRequestPre),
    takeLatest(types.CREATE_RESCUE_REQUEST, validateRescue),
    takeLatest(types.SEND_RESCUE_REQUEST, sendRescue),
    takeLatest(types.CREATE_SUBSCRIBE_REQUEST, validateSubscribe),
    takeLatest(types.SEND_SUBSCRIBE_REQUEST, sendSubscribe),
    takeLatest(types.CREATE_SUBSCRIBE_PRE_REQUEST, subscribePreRequest),
    takeLatest(types.CREATE_SHARE_ACCOUNT_PRE_REQUEST, createShareAccountPre),
    takeLatest(types.CREATE_SHARE_ACCOUNT, createShareAccount),
    takeLatest(types.DOWNLOAD_TERMS_REQUEST, handleDownloadTerms),
    takeLatest(types.SEND_BANK_ACCOUNT_CPT_REQUEST, sendBankAccountCpt),
    takeLatest(types.INVESTOR_PROFILE_REQUEST, validateInvestorProfile),
    takeLatest(types.UPDATE_INVESTOR_PROFILE_REQUEST, updateInvestorProfile),
    takeLatest(types.CANCEL_SCHEDULED_TRANSACTION_REQUEST, cancelScheduled),
];

export default sagas;

function* listFundcorp({
    optionSelected,
    positionsType,
    positionDate,
    movementsShareAccount,
    movementsShareAccountDescription,
    movementsFund,
    movementsFundDescription,
    movementsDateFrom,
    movementsDateTo,
    requestsShareAccount,
    requestsShareAccountDescription,
    requestsDateFrom,
    requestsDateTo,
    shareValuesFund,
    shareValuesFundDescription,
    shareValuesDate,
    portfolioCompositionFund,
    portfolioCompositionFundDescription,
    portfolioCompositionDate,
    verifyCode,
}) {
    let response = null;
    if (optionSelected === "shareValues" || optionSelected === "portfolioComposition") {
        response = yield call(
            fundcorpMiddleware.listFundcorpInf,
            optionSelected,
            positionsType,
            positionDate,
            movementsShareAccount,
            movementsShareAccountDescription,
            movementsFund,
            movementsFundDescription,
            movementsDateFrom,
            movementsDateTo,
            requestsShareAccount,
            requestsShareAccountDescription,
            requestsDateFrom,
            requestsDateTo,
            shareValuesFund,
            shareValuesFundDescription,
            shareValuesDate,
            portfolioCompositionFund,
            portfolioCompositionFundDescription,
            portfolioCompositionDate,
        );
    } else {
        response = yield call(
            fundcorpMiddleware.listFundcorp,
            optionSelected,
            positionsType,
            positionDate,
            movementsShareAccount,
            movementsShareAccountDescription,
            movementsFund,
            movementsFundDescription,
            movementsDateFrom,
            movementsDateTo,
            requestsShareAccount,
            requestsShareAccountDescription,
            requestsDateFrom,
            requestsDateTo,
            shareValuesFund,
            shareValuesFundDescription,
            shareValuesDate,
            portfolioCompositionFund,
            portfolioCompositionFundDescription,
            portfolioCompositionDate,
            verifyCode,
        );
    }

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["fundcorp.list"]));
        yield put({ type: types.LIST_FUNDCORP_FAILURE });
    } else {
        const {
            positions,
            movements,
            requests,
            shareValues,
            portfolioComposition,
            positionsByShareAccount,
            fundFeatures,
            managementReport,
        } = response.data.data;

        let updatedMovements = [];
        if (optionSelected === "movementsAndRequests") {
            updatedMovements = movements.map((movement) => {
                let sharesQuantity;
                let netAmount;
                if (movement.estado.toUpperCase() === "LIQUIDADA") {
                    sharesQuantity = Math.abs(movement.liquidacionCuotapartes);
                    netAmount = movement.liquidacionImporteNeto;
                } else {
                    sharesQuantity = movement.cuotapartes !== 0 ? movement.cuotapartes : null;
                    netAmount = movement.importe !== 0 ? movement.importe : null;
                }
                return { ...movement, netAmount, sharesQuantity };
            });
        }

        yield put({
            type: types.LIST_FUNDCORP_SUCCESS,
            positionsType,
            positionDate,
            movementsShareAccount,
            movementsShareAccountDescription,
            movementsFund,
            movementsFundDescription,
            movementsDateFrom,
            movementsDateTo,
            requestsShareAccount,
            requestsShareAccountDescription,
            requestsDateFrom,
            requestsDateTo,
            shareValuesFund,
            shareValuesFundDescription,
            shareValuesDate,
            portfolioCompositionFund,
            portfolioCompositionFundDescription,
            portfolioCompositionDate,
            positions,
            positionsByShareAccount,
            movements: optionSelected === "movementsAndRequests" ? updatedMovements : movements,
            requests,
            shareValues,
            portfolioComposition: portfolioComposition || [],
            fundFeatures,
            managementReport: managementReport || [],
        });
    }
}

function* listFundcorpInfPre({ page, defaultOption = null }) {
    const response = yield call(fundcorpMiddleware.listFundcorpInfPre, page);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["fundcorp.list"]));
        yield put({ type: types.LIST_FUNDCORP_PRE_FAILURE });
    } else {
        const {
            funds,
            shareAccounts,
            positions,
            positionsByShareAccount,
            shareValues,
            fundFeatures,
        } = response.data.data;

        const option = defaultOption || (page === "operations" ? "positions" : "shareValues");
        yield put({
            type: types.LIST_FUNDCORP_PRE_SUCCESS,
            funds,
            shareAccounts,
            positions,
            positionsByShareAccount,
            shareValues,
            optionSelected: option,
            fundFeatures,
        });
    }
}

function* listFundcorpPre({ page, defaultOption = null }) {
    const response = yield call(fundcorpMiddleware.listFundcorpPre, page);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["fundcorp.list"]));
        yield put({ type: types.LIST_FUNDCORP_PRE_FAILURE });
    } else {
        const {
            funds,
            shareAccounts,
            positions,
            positionsByShareAccount,
            shareValues,
            fundFeatures,
            fundOrderedFeatures,
        } = response.data.data;

        const option = defaultOption || (page === "operations" ? "positions" : "shareValues");
        yield put({
            type: types.LIST_FUNDCORP_PRE_SUCCESS,
            funds,
            shareAccounts,
            positions,
            positionsByShareAccount,
            shareValues,
            optionSelected: option,
            fundFeatures,
            fundOrderedFeatures,
        });
    }
}

function* downloadList({ summary, format }) {
    const { type, data } = yield call(fundcorpMiddleware.downloadList, summary, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_LIST_FUNDCORP_FAILURE });
        yield put(
            notificationActions.showNotification(
                i18n.get("global.unexpectedError"),
                "error",
                ["fundcorp.list"],
                null,
                false,
            ),
        );
    } else {
        yield put(
            notificationActions.showNotification(
                i18n.get("global.successDownload"),
                "success",
                ["fundcorp.list"],
                null,
                false,
            ),
        );
        yield call(delay, 3000);

        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_LIST_FUNDCORP_SUCCESS });
    }
}

function* rescueRequestPre() {
    const response = yield call(fundcorpMiddleware.rescueRequestPre);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["rescue"]));
    } else {
        yield put({
            type: types.CREATE_RESCUE_REQUEST_PRE_SUCCESS,
        });
    }
}

function* validateRescue({ summary, formikBag }) {
    const {
        amount,
        cuotapartes,
        rescueType,
        maxAmount,
        maxCuotapartes,
        selectedShareAccount,
        selectedFund,
        equivalentAmount,
        equivalentCuotapartes,
        creditAccountLabel,
        rescueDate,
        selectedScheduler,
    } = summary;

    const response = yield call(
        fundcorpMiddleware.validateRescueRequest,
        amount,
        cuotapartes,
        rescueType,
        maxAmount,
        maxCuotapartes,
        selectedShareAccount,
        selectedFund,
        equivalentAmount,
        equivalentCuotapartes,
        creditAccountLabel,
        rescueDate,
        selectedScheduler,
    );

    const { NO_FIELD } = response.data.data;

    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            if (response.data.data.message) {
                yield put(notificationActions.showNotification(response.data.data.message, "error", ["rescue"]));
            } else if (NO_FIELD) {
                yield put(notificationActions.showNotification(NO_FIELD, "warning", ["rescue"]));
            } else {
                formikBag.setErrors(response.data.data);
            }
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", ["rescue"]));
        }

        yield put({ type: types.CREATE_RESCUE_FAILURE });
    } else if (response.type === "I") {
        yield put({
            type: types.CREATE_RESCUE_SUCCESS,
            summary: { ...summary },
        });
        yield put(push("/rescue/summary"));
    } else {
        yield put({
            type: types.CREATE_RESCUE_SUCCESS,
            summary: { ...summary },
        });
        yield put(push("/rescue/summary"));
    }
}

function* sendRescue({ summary, formikBag }) {
    try {
        const response = yield call(fundcorpMiddleware.sendRescueRequest, summary);

        if (response.type === "W" && response.data.code !== INTERNAL_ERROR) {
            if (response.data.code === "COR020W") {
                formikBag.setErrors(response.data.data);
            } else {
                yield put(notificationActions.showNotification(response.data.message, "error", ["rescueSummary"]));
            }
            yield put({ type: types.SEND_RESCUE_FAILURE });
        } else {
            yield put(
                formActions.readTransaction({
                    pathname: `/transaction/${response.data.data.idTransaction}`,
                    newTransaction: true,
                }),
            );
            yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action

            const { data } = response;
            const { code } = data;

            if (code !== INTERNAL_ERROR && code !== INSUFFICIENT_SIGNATURES) {
                if (code === TRANSACTION_FINISHED) {
                    yield put(
                        notificationActions.showNotification(i18n.get("transfers.success.message"), "success", [
                            "transferTransaction",
                            "pendingTransfer",
                        ]),
                    );
                } else if (code === SCHEDULED_TRANSACTION) {
                    yield put(
                        notificationActions.showNotification(
                            i18n.get("transfers.schedule.success.message"),
                            "success",
                            ["transactionScheduled"],
                        ),
                    );
                } else if (summary.idTransaction !== null) {
                    yield put(
                        notificationActions.showNotification(i18n.get("transfers.modify.success.message"), "success", [
                            "transferTransaction",
                            "pendingTransfer",
                        ]),
                    );
                }
            }
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* validateSubscribe({ summary, formikBag }) {
    const {
        fundName,
        selectedFund,
        amount,
        debitAccontLabel,
        debitAccountId,
        shareAccount,
        subscribeDate,
        selectedScheduler,
    } = summary;

    const response = yield call(
        fundcorpMiddleware.validateSubscribeRequest,
        fundName,
        selectedFund,
        amount,
        debitAccontLabel,
        debitAccountId,
        shareAccount,
        subscribeDate,
        selectedScheduler,
    );

    const { NO_FIELD } = response.data.data;
    const { fondoClase } = response.data.data;

    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            if (response.data.data.message) {
                yield put(notificationActions.showNotification(response.data.data.message, "error", ["subscribe"]));
            } else if (NO_FIELD) {
                yield put(notificationActions.showNotification(NO_FIELD, "warning", ["subscribe"]));
            } else {
                formikBag.setErrors(response.data.data);
            }
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", ["subscribe"]));
        }

        yield put({ type: types.CREATE_SUBSCRIBE_FAILURE });
    } else if (response.type === "I") {
        yield put({
            type: types.CREATE_SUBSCRIBE_SUCCESS,
            summary: { ...summary, fondoClase },
        });
        yield put(push("/subscribe/summary"));
    } else {
        yield put({
            type: types.CREATE_SUBSCRIBE_SUCCESS,
            summary: { ...summary, fondoClase },
        });
        yield put(push("/subscribe/summary"));
    }
}

function* sendSubscribe({ summary, formikBag }) {
    try {
        const response = yield call(fundcorpMiddleware.sendSubscribeRequest, summary);

        if (response.type === "W" && response.data.code !== INTERNAL_ERROR) {
            if (response.data.code === "COR020W") {
                formikBag.setErrors(response.data.data);
            } else {
                yield put(notificationActions.showNotification(response.data.message, "error", ["subscribeSummary"]));
            }
            yield put({ type: types.SEND_SUBSCRIBE_FAILURE });
        } else {
            yield put(formActions.readTransaction({ pathname: `/transaction/${response.data.data.idTransaction}` }));
            yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action

            const { data } = response;
            const { code } = data;

            if (code !== INTERNAL_ERROR && code !== INSUFFICIENT_SIGNATURES) {
                if (code === TRANSACTION_FINISHED) {
                    yield put(
                        notificationActions.showNotification(i18n.get("transfers.success.message"), "success", [
                            "transferTransaction",
                            "pendingTransfer",
                        ]),
                    );
                } else if (code === SCHEDULED_TRANSACTION) {
                    yield put(
                        notificationActions.showNotification(
                            i18n.get("transfers.schedule.success.message"),
                            "success",
                            ["transactionScheduled"],
                        ),
                    );
                } else if (summary.idTransaction !== null) {
                    yield put(
                        notificationActions.showNotification(i18n.get("transfers.modify.success.message"), "success", [
                            "transferTransaction",
                            "pendingTransfer",
                        ]),
                    );
                }
            }
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* subscribePreRequest() {
    const response = yield call(fundcorpMiddleware.subscribePreRequest);

    if (response.type === "W") {
        yield put({ type: types.CREATE_SUBSCRIBE_PRE_FAILURE });
    } else {
        yield put({
            type: types.CREATE_SUBSCRIBE_PRE_SUCCESS,
        });
    }
}

function* createShareAccountPre() {
    const response = yield call(fundcorpMiddleware.createShareAccountPreRequest);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "registerCuotapartista",
            ]),
        );
        yield put({ type: types.CREATE_SHARE_ACCOUNT_PRE_FAILURE });
    } else {
        const { shareAccounts, investorProfile } = response.data.data;

        yield put({
            type: types.CREATE_SHARE_ACCOUNT_PRE_SUCCESS,
            shareAccounts,
            investorProfile,
        });
    }
}

function* createShareAccount({ summaryShareaccount, formikBag }) {
    try {
        const response = yield call(fundcorpMiddleware.createShareAccountRequest, summaryShareaccount);
        const { NO_FIELD } = response.data.data;

        if (response.type === "W") {
            if (response.data.code === "COR020W") {
                if (response.data.data.message) {
                    yield put(
                        notificationActions.showNotification(response.data.data.message, "error", [
                            "registerCuotapartista",
                        ]),
                    );
                } else if (NO_FIELD) {
                    yield put(notificationActions.showNotification(NO_FIELD, "warning", ["registerCuotapartista"]));
                } else {
                    formikBag.setErrors(response.data.data);
                }
            } else {
                yield put(
                    notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                        "registerCuotapartista",
                    ]),
                );
            }

            yield put({ type: types.CREATE_SHARE_ACCOUNT_FAILURE });
        } else {
            const { shareAccounts, numCuotapartista, status } = response.data.data;
            yield put({
                type: types.CREATE_SHARE_ACCOUNT_SUCCESS,
                shareAccounts,
                summaryShareaccount: { ...summaryShareaccount },
                statusRegisterShareAccount: status,
                numCuotapartista,
            });
            if (status === "FINISHED") {
                yield put(push("/registerShareAccount/success"));
            } else {
                yield put(push("/registerShareAccount/failed"));
            }
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* handleDownloadTerms({ format, configVar }) {
    const { type, data } = yield call(session.exportTermsAndConditionsConfigVar, format, configVar);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_TERMS_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]));
    } else {
        const { content, fileName } = data.data;
        downloadPdf(fileName, content);
        yield put({ type: types.DOWNLOAD_TERMS_SUCCESS });
    }
}

function* sendBankAccountCpt({ debitAccount, shareAccount, formikBag }) {
    try {
        const response = yield call(fundcorpMiddleware.insertBankAccountCptRequest, debitAccount, shareAccount);
        const { NO_FIELD } = response.data.data;

        if (response.type === "W") {
            if (response.data.code === "COR020W") {
                if (response.data.data.message) {
                    yield put(notificationActions.showNotification(response.data.data.message, "error", ["subscribe"]));
                } else if (NO_FIELD) {
                    yield put(notificationActions.showNotification(NO_FIELD, "warning", ["subscribe"]));
                } else {
                    formikBag.setErrors(response.data.data);
                }
            } else {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("fund.operation.associateShareAccount.error"),
                        "error",
                        ["subscribe"],
                    ),
                );
            }

            yield put({ type: types.SEND_BANK_ACCOUNT_CPT_FAILURE });
        } else {
            const { shareAccounts, status } = response.data.data;

            if (status === "FINISHED") {
                yield put({ type: types.SEND_BANK_ACCOUNT_CPT_SUCCESS, shareAccounts });
                yield put(
                    notificationActions.showNotification(
                        i18n.get("fund.operation.associateShareAccount.success"),
                        "success",
                        ["subscribe"],
                    ),
                );
            } else {
                yield put({ type: types.SEND_BANK_ACCOUNT_CPT_FAILURE });
                yield put(
                    notificationActions.showNotification(
                        i18n.get("fund.operation.associateShareAccount.error"),
                        "error",
                        ["subscribe"],
                    ),
                );
            }
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* validateInvestorProfile({ redirectionUrl }) {
    const response = yield call(fundcorpMiddleware.createShareAccountPreRequest);
    if (response.type === "W") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["fundcorp.list"]));
        yield put({ type: types.INVESTOR_PROFILE_FAILURE });
    } else {
        const { shareAccounts, investorProfile } = response.data.data;

        yield put({
            type: types.RESET_SUMMARY,
        });
        yield put({
            type: types.INVESTOR_PROFILE_SUCCESS,
            investorProfile,
        });

        if (
            !investorProfile.valid &&
            (Boolean(!investorProfile?.investorProfileType.trim()) || Boolean(!investorProfile?.lastUpdate.trim()))
        ) {
            yield put(push({ pathname: "/fundcorpOperations/investor-profile", state: { from: redirectionUrl } }));
        } else if (!investorProfile.valid) {
            yield put(
                notificationActions.showNotification(i18n.get("fundcorp.investorProfile.expired"), "warning", [
                    "fundcorp.investorProfile",
                ]),
            );
            yield put(push("/fundcorpOperations/investor-profile"));
            yield put(push({ pathname: "/fundcorpOperations/investor-profile", state: { from: redirectionUrl } }));
        } else if (shareAccounts.length <= 0) {
            yield put(push("/fundcorpOperations/registerShareAccount"));
        } else {
            yield put(push(redirectionUrl));
        }
    }
}

function* updateInvestorProfile({ investorProfile }) {
    const { location } = history;
    const shareAccounts = yield select(fundcorpSelectors.getShareAccounts);

    const response = yield call(fundcorpMiddleware.updateInvestorProfile, investorProfile);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "fundcorp.investorProfile",
            ]),
        );
        yield put({ type: types.UPDATE_INVESTOR_PROFILE_FAILURE });
    } else {
        const { investorProfile: investorProfileResponse } = response.data.data;
        yield put({
            type: types.UPDATE_INVESTOR_PROFILE_SUCCESS,
            investorProfile: investorProfileResponse,
        });

        yield put(
            notificationActions.showNotification(
                `Actualizaste tu perfil de inversor a ${i18n.get(
                    `fundcorp.investorProfile.${investorProfileResponse.investorProfileType}.title`,
                )}.`,
                "success",
                ["fundcorp.investorProfile"],
            ),
        );
        if (location?.state?.from && shareAccounts.length <= 0) {
            yield put(
                notificationActions.showNotification(
                    `Actualizaste tu perfil de inversor a ${i18n.get(
                        `fundcorp.investorProfile.${investorProfileResponse.investorProfileType}.title`,
                    )}.`,
                    "success",
                    ["registerCuotapartista"],
                ),
            );
            yield put(
                push({ pathname: "/fundcorpOperations/registerShareAccount", state: { from: location.state.from } }),
            );
        } else if (location?.state?.from) {
            yield put(
                notificationActions.showNotification(
                    `Actualizaste tu perfil de inversor a ${i18n.get(
                        `fundcorp.investorProfile.${investorProfileResponse.investorProfileType}.title`,
                    )}.`,
                    "success",
                    ["subscribe"],
                ),
            );
            yield put(push({ pathname: location.state.from, state: { from: location.state.from } }));
        }
    }
}

function* cancelScheduled({ summary }) {
    const { formikBag, idTransaction } = summary;
    const response = yield call(fundcorpMiddleware.cancelScheduledTransaction, summary);

    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            formikBag.setErrors(response.data.data);
        } else {
            yield put(
                notificationActions.showNotification(response.data.message, "error", ["transactionScheduledCancel"]),
            );
        }
        yield put({ type: types.CANCEL_SCHEDULED_TRANSACTION_FAILURE });
    } else {
        yield put(
            formActions.readTransaction({
                pathname: `/transaction/${response.data.data?.idTransactionToCancel || response.data.idTransaction}`,
            }),
        );

        const { data } = response;
        const { code } = data;

        if (code === SCHEDULED_TRANSACTION) {
            yield put(
                notificationActions.showNotification(i18n.get("scheduler.cancel.success"), "success", [
                    "transactionScheduled",
                ]),
            );
        } else if (code !== INSUFFICIENT_SIGNATURES && idTransaction !== null) {
            yield put(
                notificationActions.showNotification(i18n.get("scheduler.cancel.success"), "success", [
                    "pendingTransfer",
                ]),
            );
        }

        yield put({
            type: types.CANCEL_SCHEDULED_TRANSACTION_SUCCESS,
            idTransaction,
        });
    }
}
