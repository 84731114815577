/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { func, shape, string, bool, arrayOf, number } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withFormik } from "formik";
import Yup from "yup";
import Col from "react-bootstrap/lib/Col";
import classNames from "classnames";

import Head from "pages/_components/Head";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import I18n from "pages/_components/I18n";
import Check from "pages/_components/fields/Checkbox";
import ContextMenu from "pages/_components/ContextMenu";
import EcheqsList from "pages/checks/Echeqs/EcheqsList";
import MultipleEcheqsButtons from "pages/checks/Echeqs/MutipleEcheqsButtons";
import SelectedAccountsAmount from "pages/charges/_components/SelectedAccountsAmount";

import { actions, selectors, types } from "reducers/checks";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transactionsActions } from "reducers/transactions";
import { actions as notificationActions } from "reducers/notification";
import { actions as globalActions } from "reducers/types/global";

import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import { getLimitDate } from "util/date";
import { echeqShape, checkDisableMultiple } from "util/checks";
import { saveLocation } from "util/transaction";
import EcheqsFilters from "./EcheqsFilters";

const FORM_ID = "echeqs";

class EcheqsMainPage extends Component {
    static propTypes = {
        setSubmitting: func.isRequired,
        setValues: func.isRequired,
        isSubmitting: bool.isRequired,
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        multipleSelection: bool.isRequired,
        values: shape({}).isRequired,
        option: string.isRequired,
        environmentCuit: string.isRequired,
        echeqsList: arrayOf(echeqShape).isRequired,
        fetchingEcheqsDownload: bool.isRequired,
        filters: shape({
            createdFrom: string.isRequired,
            createdTo: string.isRequired,
            payedFrom: string.isRequired,
            payedTo: string.isRequired,
            payedCheck: bool.isRequired,
            emitedCheck: bool.isRequired,
            receiver: string.isRequired,
            emitted: string.isRequired,
            status: shape({ value: string.isRequired, label: string.isRequired }).isRequired,
            number: arrayOf(string).isRequired,
            grouper: string.isRequired,
            id: arrayOf(string).isRequired,
            orderColumName: string.isRequired,
            orderDirection: string.isRequired,
        }).isRequired,
        echeqPagination: shape({
            extra_info: number.isRequired,
            estado: number.isRequired,
            pageNumber: number.isRequired,
        }).isRequired,
        handleSubmit: func.isRequired,
        configForm: shape({}).isRequired,
        history: shape({
            listen: func.isRequired,
        }).isRequired,
        activeEnvironment: shape({
            permissions: shape({
                emitEcheq: bool,
            }),
        }).isRequired,
        match: shape.isRequired,
        stateList: arrayOf(shape({})),
        accountsFetching: bool,
        serverSearchDate: string.isRequired,
        idSelectorError: bool.isRequired,
        numberSelectorError: bool.isRequired,
        showCleanFilterButton: bool.isRequired,
    };

    static defaultProps = {
        stateList: [],
        accountsFetching: false,
    };

    state = {
        selectedRows: [],
        selectedAll: false,
        showForm: false,
        showSnackbarFiltred: false,
        width: 0,
        enabledEcheqMassive: false,
        isAvailableEcheqMassive: false,
        filterOrder: {
            column: "",
            direction: "",
        },
        changeFilterFunc: () => {},
        cleanFilter: false,
        showCleanFilterButton: false,
        activeHint: "",
    };

    componentDidMount() {
        const {
            dispatch,
            values,
            option,
            setSubmitting,
            filters,
            setValues,
            isDesktop,
            match,
            history,
            activeEnvironment,
            showCleanFilterButton,
        } = this.props;
        saveLocation(this.props);
        let newFilters = filters;
        let echeqType = option;
        if (match?.params?.echeqId) {
            echeqType = match?.params?.echeqType;
            newFilters = {
                ...newFilters,
                id: match?.params?.echeqId,
                payedCheck: false,
                emitedCheck: false,
            };
        }
        if (match?.params?.state) {
            newFilters = {
                ...newFilters,
                status: match?.params?.state,
                payedCheck: false,
                emitedCheck: false,
            };
        }
        const filtersEcheq = { ...values, pageNumber: 1, ...newFilters, option: echeqType };
        const isAvailableEcheqMassive = configUtils.getBoolean("frontend.show.MassiveEcheq.functionalities", false);

        this.setState((prev) => ({
            ...prev,
            isAvailableEcheqMassive,
            showCleanFilterButton,
            enabledEcheqMassive: !!activeEnvironment.permissions?.massiveEmitEcheq && isAvailableEcheqMassive,
        }));
        setValues({ ...values, ...newFilters });
        setSubmitting(true);
        this.chargeConfig();
        dispatch(actions.cleanEcheqAction());
        dispatch(actions.setEcheqOption(echeqType));
        dispatch(actions.filterEcheqList(filtersEcheq, setSubmitting, isDesktop));
        dispatch(actions.setEcheqFilters(filtersEcheq));
        dispatch(actions.setGoBackEcheqs(false));
        dispatch(actions.setRememberMassiveData(false));
        dispatch(accountsActions.listAccounts());
        dispatch(transactionsActions.saveTransactionRoute(history?.location?.pathname));

        window.addEventListener("resize", this.updateDimensions);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.width !== window.innerWidth) {
            this.updateDimensions();
        }
        this.checkForFilterChange();
    }

    componentWillUnmount() {
        const { dispatch, history, serverSearchDate } = this.props;

        const nextPath = history?.location?.pathname;
        this.validateUrl(nextPath);

        dispatch({
            type: types.SET_ECHEQ_LIST,
            listEcheqs: [],
            totalPages: 1,
            pageNumber: 1,
            serverDate: serverSearchDate,
        });
        if (
            !nextPath.includes("/echeqs/action") &&
            !nextPath.includes("/echeqs/anulate") &&
            !nextPath.includes("/echeqs/return") &&
            !nextPath.includes("/echeqs/emit") &&
            !nextPath.includes("/echeqs/received/detail")
        ) {
            dispatch(globalActions.resetSelectedElements());
        }
        window.removeEventListener("resize", this.updateDimensions);
    }

    enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

    checkForFilterChange = () => {
        const { numberSelectorError, idSelectorError } = this.props;
        const { showCleanFilterButton } = this.state;

        if ((numberSelectorError && !showCleanFilterButton) || (idSelectorError && !showCleanFilterButton)) {
            this.setState({ showCleanFilterButton: true });
        }
    };

    updateDimensions = () => {
        this.setState({ width: window.innerWidth });
    };

    validateUrl = (nextPath) => {
        const { dispatch } = this.props;
        const pattern = /.*\/echeqs\/(received|emitted)/;

        let configForm = null;

        if (pattern.test(nextPath)) {
            const { multipleSelection, selectedRows, selectedAll, showForm, showSnackbarFiltred } = this.state;
            configForm = {
                multipleSelection,
                selectedRows,
                selectedAll,
                showForm,
                showSnackbarFiltred,
            };
        }

        dispatch({
            type: types.SET_CONFIG_FORM,
            configForm,
        });
    };

    chargeConfig = () => {
        const { configForm } = this.props;

        if (configForm) {
            this.setState(configForm);
        }
    };

    selectAll = (checked) => {
        const { echeqsList, environmentCuit, filters, dispatch } = this.props;
        const { selectedRows } = this.state;
        let listElements = checked ? selectedRows : [];
        if (checked) {
            for (let i = 0; i < echeqsList.length; i++) {
                const okToAdd = !checkDisableMultiple(echeqsList[i], environmentCuit, filters.status);
                const maxSelectedReached =
                    listElements.length >= configUtils.getInteger("echeq.multiple.selection.max", 20);

                if (
                    okToAdd &&
                    !maxSelectedReached &&
                    !selectedRows.some((echeq) => echeq.cheque_id === echeqsList[i].cheque_id)
                ) {
                    listElements = [...listElements, echeqsList[i]];
                } else if (listElements.length >= configUtils.getInteger("echeq.multiple.selection.max", 20)) {
                    dispatch(
                        notificationActions.showNotification(i18n.get("echeq.multiple.selection.max"), "warning", [
                            "echeqsMainPage",
                        ]),
                    );
                }
            }
        }
        this.setState({
            selectedRows: listElements,
            selectedAll: checked && selectedRows.length >= configUtils.getInteger("echeq.multiple.selection.max", 20),
        });
    };

    addSelectedRow = (echeq) => {
        const { environmentCuit, filters, dispatch } = this.props;
        const { selectedRows } = this.state;
        const maxSelectedReached = selectedRows.length >= configUtils.getInteger("echeq.multiple.selection.max", 20);
        const alreadySelected = selectedRows.find((element) => element.cheque_id === echeq.cheque_id);
        const okToAdd = !checkDisableMultiple(echeq, environmentCuit, filters.status);

        if (okToAdd) {
            if (!alreadySelected && !maxSelectedReached) {
                this.setState({
                    selectedRows: [...selectedRows, echeq],
                    selectedAll: false,
                });
            } else if (!alreadySelected) {
                dispatch(
                    notificationActions.showNotification(i18n.get("echeq.multiple.selection.max"), "warning", [
                        "echeqsMainPage",
                    ]),
                );
            }

            if (alreadySelected) {
                this.setState({
                    selectedRows: selectedRows.filter((element) => element.cheque_id !== echeq.cheque_id),
                    selectedAll: false,
                });
            }
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    multipleActionsButtons = () => {
        const { selectedRows } = this.state;
        const { environmentCuit, isDesktop, echeqsList } = this.props;
        return (
            <MultipleEcheqsButtons
                selectedRows={selectedRows}
                environmentCuit={environmentCuit}
                isDesktop={isDesktop}
                echeqsListTotal={echeqsList.length}
            />
        );
    };

    getSelectedOption = () => {
        const { option } = this.props;
        switch (option) {
            case "emitted.echeqs":
                return "emitted";
            case "received.echeqs":
                return "received";
            case "emitted.massive.echeq":
                return "massive";
            default:
                return "";
        }
    };

    emitEcheq = () => {
        const { dispatch } = this.props;
        dispatch(actions.cleanEcheqAction());
        dispatch(push("/echeqs/emit"));
    };

    emitMassiveEcheq = () => {
        const { dispatch } = this.props;

        dispatch(actions.cleanEcheqAction());
        dispatch(push("/echeqs/emit/massive"));
    };

    handleExport = (format) => {
        const { dispatch, values, option, filters, echeqPagination } = this.props;
        const { pageNumber } = echeqPagination;
        const filtersEcheq = { ...values, ...filters, option, pageNumber, format };
        dispatch(actions.downloadEcheqList(filtersEcheq));
    };

    downloadEcheqListCuit = () => {
        const { dispatch } = this.props;
        dispatch(push("/echeqs/downloadEcheqListCuit"));
    };

    rightContent = () => {
        const { isDesktop, fetchingEcheqsDownload, isMobile, activeEnvironment, isSubmitting, option } = this.props;
        const { enabledEcheqMassive } = this.state;
        const downloadEcheqByCuitEnabled = configUtils.getBoolean("frontend.show.ListEcheqs.functionalities");

        let itemActiveMultiple = [];

        if (enabledEcheqMassive && isMobile) {
            itemActiveMultiple = [
                {
                    label: "echeq.massive.emit",
                    onClick: this.emitMassiveEcheq,
                },
                ...itemActiveMultiple,
            ];
        }
        if (activeEnvironment.permissions.emitEcheq && isMobile) {
            itemActiveMultiple = [
                {
                    label: "echeq.emit",
                    onClick: this.emitEcheq,
                },
                ...itemActiveMultiple,
            ];
        }

        let menuItems = [
            {
                label: "global.download.file.xls",
                onClick: () => this.handleExport("xls"),
            },
        ];

        if (
            option === "emitted.massive.echeq" &&
            configUtils.getBoolean("frontend.showReportesMVP3.funcionalities", false)
        ) {
            menuItems.push({
                label: "global.download.file.pdf",
                onClick: () => this.handleExport("pdf"),
            });
        }

        if (downloadEcheqByCuitEnabled) {
            menuItems.push({
                label: "download.echeqs.list.cuit",
                onClick: this.downloadEcheqListCuit,
            });
        }

        if (itemActiveMultiple.length > 0) {
            menuItems = [...itemActiveMultiple, ...menuItems];
        }

        return (
            <>
                {isDesktop ? (
                    <>
                        {activeEnvironment.permissions.emitEcheq && (
                            <Button
                                block
                                label="echeq.emit"
                                bsStyle="primary"
                                onClick={this.emitEcheq}
                                className="cmf-button-width text-capitalize"
                                image="images/echeq__emit-icon.svg"
                                imageStyle="mr-2"
                            />
                        )}
                        {enabledEcheqMassive && (
                            <Button
                                block
                                label="echeq.massive.emit"
                                bsStyle="outline"
                                onClick={this.emitMassiveEcheq}
                                className="cmf-button-width text-transform-none px-2"
                                image="images/echeq__emit-massive-icon.svg"
                                imageStyle="mr-2"
                            />
                        )}
                        {!enabledEcheqMassive && (
                            <Button
                                block
                                label="global.download"
                                loading={fetchingEcheqsDownload}
                                image="images/download.svg"
                                onClick={this.handleExport}
                                className="btn-outline cmf-button-width"
                                imageStyle="mr-2"
                                disabled={isSubmitting}
                            />
                        )}
                    </>
                ) : (
                    undefined
                )}
                {(!isDesktop || enabledEcheqMassive) && (
                    <ContextMenu
                        isDesktop={isDesktop}
                        buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                        items={menuItems}
                    />
                )}
            </>
        );
    };

    resetDateValues = (setValues, values, option) => {
        let newValues = { ...values };
        let changeValue = false;
        if (!values.payedCheck && (values.payedFrom || values.payedTo)) {
            newValues = { ...newValues, payedFrom: null, payedTo: null };
            changeValue = true;
        } else if (values.payedCheck && (!values.payedFrom || !values.payedTo)) {
            if (option === "emitted.echeqs") {
                newValues = { ...newValues, payedFrom: getLimitDate(0), payedTo: getLimitDate(365) };
                changeValue = true;
            } else {
                newValues = { ...newValues, payedFrom: getLimitDate(0), payedTo: getLimitDate(7) };
                changeValue = true;
            }
        }
        if (!values.emitedCheck && (values.createdFrom || values.createdTo)) {
            newValues = { ...newValues, createdFrom: null, createdTo: null };
            changeValue = true;
        } else if (values.emitedCheck && (!values.createdFrom || !values.createdTo)) {
            if (option !== "received.echeqs") {
                newValues = { ...newValues, createdFrom: getLimitDate(-365), createdTo: getLimitDate(0) };
                changeValue = true;
            } else {
                newValues = { ...newValues, createdFrom: getLimitDate(-7), createdTo: getLimitDate(0) };
                changeValue = true;
            }
        }

        if (changeValue) {
            setValues({ ...newValues });
        }
    };

    renderHeader = () => {
        const { isDesktop } = this.props;
        const title = "echeqs.operate.title";

        if (isDesktop) {
            return (
                <>
                    <Head onBack={this.handleBack} />
                    <Head
                        title={title}
                        handleClickMessage="global.download"
                        rightContent={() => this.rightContent()}
                        headerClassName="mb-4"
                    />
                </>
            );
        }

        return (
            <>
                <Head
                    headerClassName="blue-main-header-mobile"
                    titleClassName="w-100 align-center"
                    onBack={this.handleBack}
                    title={title}
                    rightContent={() => this.rightContent()}
                />
            </>
        );
    };

    handleFilterOrder = (columns, directions, changeFilters) => {
        this.setState({
            filterOrder: {
                column: columns,
                direction: directions,
            },
            changeFilterFunc: changeFilters,
        });
    };

    allChecksAreChecked = () => {
        const { echeqsList, environmentCuit, filters } = this.props;
        const { selectedRows } = this.state;

        let allSelected = false;
        echeqsList.forEach((echeq) => {
            const isSelectable = !checkDisableMultiple(echeq, environmentCuit, filters.status);
            if (isSelectable) {
                allSelected = selectedRows.some((selectedEcheq) => selectedEcheq.cheque_id === echeq.cheque_id);
            }
        });

        return allSelected;
    };

    render() {
        const {
            dispatch,
            isDesktop,
            values,
            isSubmitting,
            setValues,
            option,
            setSubmitting,
            handleSubmit,
            isSmallDesktop,
            isMobile,
            echeqsList,
            accountsFetching,
            stateList,
            numberSelectorError,
            idSelectorError,
        } = this.props;
        const {
            showForm,
            selectedAll,
            selectedRows,
            width,
            filterOrder,
            changeFilterFunc,
            cleanFilter,
            showSnackbarFiltred,
            showCleanFilterButton,
            enabledEcheqMassive,
            activeHint,
        } = this.state;
        if (!this.enableRangeDatefield) {
            this.resetDateValues(setValues, values, option);
        }

        return (
            <>
                <Notification scopeToShow="echeqsMainPage" />
                <Container className={!isSmallDesktop && isDesktop && "mr-2"}>
                    <div className="admin-detail-head w-100 px-0 mb-0">{this.renderHeader()}</div>
                    <EcheqsFilters
                        dispatch={dispatch}
                        values={values}
                        setValues={setValues}
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        setSubmitting={setSubmitting}
                        option={option}
                        showForm={showForm}
                        showSnackbarFiltred={showSnackbarFiltred}
                        setEcheqsState={(newValues) => this.setState((prev) => ({ ...prev, ...newValues }))}
                        selectAll={this.selectAll}
                        multipleSelection
                        enabledEcheqMassive={enabledEcheqMassive}
                        showCleanFilterButton={showCleanFilterButton}
                        stateList={stateList}
                        filterOrder={filterOrder}
                        changeFilterFunc={changeFilterFunc}
                        cleanFilter={cleanFilter}
                        width={width}
                        isSmallDesktop={isSmallDesktop}
                        isDesktop={isDesktop}
                        isMobile={isMobile}
                        idSelectorError={idSelectorError}
                        numberSelectorError={numberSelectorError}
                        activeHint={activeHint}
                    />
                    {!isDesktop && option !== "emitted.massive.echeq" && (
                        <Col xs={12} className="justify-content-right flex">
                            <SelectedAccountsAmount
                                list={selectedRows}
                                isDesktop={isDesktop}
                                summaryView
                                className={!isDesktop && "align-right"}
                            />
                        </Col>
                    )}
                    <PageLoading
                        className={classNames({ "screen-loader": !isDesktop, "line-loader": isDesktop })}
                        loading={accountsFetching || (isSubmitting && !(isMobile && echeqsList.length))}>
                        {option === "received.echeqs" && (
                            <Col xs={12} className="px-0">
                                <div className="px-0 pb-2 echeqs__select-actions-row w-100">
                                    <div className={width > 1200 ? "d-flex" : ""}>
                                        {echeqsList.length ? (
                                            <div className="d-flex align-items-center min-height-3">
                                                <Check
                                                    hideLabel
                                                    id="selectAll"
                                                    name="selectAll"
                                                    checked={this.allChecksAreChecked()}
                                                    onChange={(e) => this.selectAll(e.target.checked)}
                                                    formGroupClassName="d-flex"
                                                />
                                                <I18n component="span" id="echeqs.filters.selectAll.label" />
                                            </div>
                                        ) : (
                                            undefined
                                        )}
                                        {this.multipleActionsButtons()}
                                    </div>
                                    {isDesktop && (
                                        <div className="echeq__totals mt-0 pl-2">
                                            <SelectedAccountsAmount list={selectedRows} summaryView />
                                        </div>
                                    )}
                                </div>
                            </Col>
                        )}
                        <EcheqsList
                            isDesktop={isDesktop}
                            isSmallDesktop={isSmallDesktop}
                            setSubmitting={setSubmitting}
                            option={option}
                            multipleSelection
                            showForm={showForm}
                            selectedRows={selectedRows}
                            selectedAll={selectedAll}
                            addSelectedRow={this.addSelectedRow}
                            selectAll={this.selectAll}
                            unCheckSelectAll={() => this.setState({ selectedAll: false })}
                            getSelectedOption={this.getSelectedOption}
                            orderByData={this.handleFilterOrder}
                        />
                    </PageLoading>
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    option: selectors.getEcheqOption(state),
    echeqsList: selectors.getEcheqsList(state),
    environmentCuit: selectors.getEnvironmentCuit(state),
    filters: selectors.getEcheqFilters(state),
    configForm: selectors.getConfigForm(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    fetchingEcheqsDownload: selectors.getFetchingEcheqList(state),
    echeqPagination: selectors.getEcheqsPagination(state),
    stateList: selectors.getStateList(state),
    accountsFetching: accountsSelectors.getFetching(state),
    serverSearchDate: selectors.getServerSearchDate(state),
    idSelectorError: selectors.getIdSelectorErrorState(state),
    numberSelectorError: selectors.getNumberSelectorErrorState(state),
    numberFilter: selectors.getNumberListSelector(state),
    idFilter: selectors.getIdListSelector(state),
    showCleanFilterButton: selectors.getShowCleanFilterButton(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({
            createdFrom: getLimitDate(-7),
            createdTo: getLimitDate(0),
            createdDates: [getLimitDate(-7).toDate(), getLimitDate(0).toDate()],
            payedFrom: getLimitDate(0),
            payedTo: getLimitDate(7),
            payedDates: [getLimitDate(0).toDate(), getLimitDate(7).toDate()],
            payedCheck: true,
            emitedCheck: true,
            receiver: "",
            emitted: "",
            number: [],
            grouper: "",
            id: [],
            status: "TODOS",
            ownership: "TODOS",
            action: "all",
        }),
        validationSchema: ({ option }) =>
            Yup.lazy((values) => {
                const enableRangeDatefield = configUtils.getBoolean(
                    "frontend.show.RangeDatePicker.functionalities",
                    false,
                );
                let validationObject = {
                    amountFrom: values.amountTo
                        ? Yup.number().max(values.amountTo, i18n.get(`${FORM_ID}.amount.amountFrom.error`))
                        : Yup.number().nullable(),
                    amountTo: values.amountFrom
                        ? Yup.number().min(values.amountFrom, i18n.get(`${FORM_ID}.amount.amountTo.error`))
                        : Yup.number().nullable(),
                };
                if (!enableRangeDatefield) {
                    if (values.emitedCheck) {
                        validationObject = {
                            ...validationObject,
                            createdFrom: Yup.string()
                                .nullable()
                                .required(i18n.get("accounts.new.account.field.error.required")),
                            createdTo: Yup.string()
                                .nullable()
                                .required(i18n.get("accounts.new.account.field.error.required")),
                        };
                    }
                    if (values.payedCheck) {
                        validationObject = {
                            ...validationObject,
                            payedFrom: Yup.string()
                                .nullable()
                                .required(i18n.get("accounts.new.account.field.error.required")),
                            payedTo: Yup.string()
                                .nullable()
                                .required(i18n.get("accounts.new.account.field.error.required")),
                        };
                    }
                } else if (option === "emitted.massive.echeq") {
                    validationObject = {
                        ...validationObject,
                        createdDates: Yup.mixed().test(
                            "dates",
                            i18n.get(`${FORM_ID}.massive.validation.range.date`),
                            ([from, to]) => from && to,
                        ),
                    };
                }
                return Yup.object().shape(validationObject);
            }),
        handleSubmit: (formFilters, formikBag) => {
            const { dispatch, option, filters, isDesktop, idFilter, numberFilter } = formikBag.props;
            const { setSubmitting, setValues } = formikBag;
            const { orderColumName, orderDirection } = filters;
            const nrsIdsFiltersEnabled = configUtils.getBoolean("FilterEcheqs.NrsIds.functionalities", false);
            const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);
            let additionalFilters;
            if (nrsIdsFiltersEnabled) {
                additionalFilters = {
                    id: idFilter.map((item) => item.value),
                    ...(option !== "emitted.massive.echeq"
                        ? { number: numberFilter.map((item) => item.value) }
                        : { number: typeof formFilters.number === "string" ? [formFilters.number] : [] }),
                };
            } else {
                additionalFilters = {
                    id: typeof formFilters.id === "string" ? [formFilters.id] : [],
                    number: typeof formFilters.number === "string" ? [formFilters.number] : [],
                };
            }
            if (enableRangeDatefield) {
                setValues({
                    ...formFilters,
                    ...(formFilters.createdDates[0] && !formFilters.createdDates[1] && { createdDates: [null, null] }),
                    ...(option !== "emitted.massive.echeq" &&
                        formFilters.payedDates[0] &&
                        !formFilters.payedDates[1] && { payedDates: [null, null] }),
                });
            }

            const filter = {
                ...formFilters,
                ...(enableRangeDatefield && {
                    emitedCheck: formFilters.createdDates.every((d) => d),
                    createdFrom:
                        formFilters.createdDates[0] && formFilters.createdDates[1] ? formFilters.createdDates[0] : null,
                    createdTo: formFilters.createdDates[1],
                    ...(option !== "emitted.massive.echeq" && {
                        payedCheck: formFilters.payedDates.every((d) => d),
                        payedFrom:
                            formFilters.payedDates[0] && formFilters.payedDates[1] ? formFilters.payedDates[0] : null,
                        payedTo: formFilters.payedDates[1],
                    }),
                }),
                orderColumName,
                orderDirection,
                option,
                status: formFilters.status,
                pageNumber: 1,
                ...additionalFilters,
            };

            dispatch(actions.setEcheqFilters(filter));
            dispatch(actions.filterEcheqList(filter, setSubmitting, isDesktop));
        },
    }),
)(EcheqsMainPage);
