/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { string, func, number as numberType, bool, oneOfType, shape } from "prop-types";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import classNames from "classnames";
import { Link } from "react-router-dom";
import * as i18nUtils from "util/i18n";
import { isMobileNative } from "util/device";
import Favorite from "pages/_components/favorite/Favorite";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as accountsActions } from "reducers/accounts";
import { maskedAccountNumber, accountContextMenuItems } from "util/accounts";
import ContextMenu from "pages/_components/ContextMenu";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";
import { Button } from "@ui-kit/components/index.es";

class ListItem extends Component {
    static propTypes = {
        path: oneOfType([
            string,
            shape({
                pathname: string,
                search: string,
            }),
        ]).isRequired,
        name: string.isRequired,
        title: string.isRequired,
        reference: string.isRequired,
        currency: string.isRequired,
        amount: numberType.isRequired,
        amountLabel: string.isRequired,
        realBalance: numberType.isRequired,
        realBalanceLabel: string.isRequired,
        franchise: string,
        idProduct: string.isRequired,
        favorite: bool,
        balanceDiffers: string.isRequired,
        productTypeTitle: string.isRequired,
        dispatch: func.isRequired,
        loggedUser: shape({}).isRequired,
        activeEnvironment: shape({}).isRequired,
        isDesktop: bool,
    };

    static defaultProps = {
        franchise: "",
        favorite: false,
        isDesktop: true,
    };

    shareAccount = (e) => {
        e.preventDefault();
        const { activeEnvironment, shortLabel, productType, cbuAlias, cbu, dispatch } = this.props;

        const data = {
            header: activeEnvironment.name,
            cbu,
            accountType: shortLabel.substring(shortLabel.indexOf(productType)),
            alias: cbuAlias || "",
            cuitCuil: activeEnvironment.clients[0].idClient,
            footer: "global.companyName",
        };

        dispatch(accountsActions.shareAccount(data));
    };

    getContent = () => {
        const {
            amount,
            amountLabel,
            currency,
            name,
            reference,
            title,
            idProduct,
            favorite,
            balanceDiffers,
            realBalance,
            realBalanceLabel,
            dispatch,
            loggedUser,
            isDesktop,
            activeEnvironment,
        } = this.props;

        const favoriteComponent = (
            <span onClick={(e) => e.preventDefault()} onKeyDown={(e) => e.preventDefault()} role="button" tabIndex={0}>
                <Favorite favorite={favorite} idProduct={idProduct} loggedUser={loggedUser} dispatch={dispatch} />
            </span>
        );

        const nameWithN = /^\*\*\*\*\d+/.test(name) ? `${i18nUtils.get(`global.numero`)} ${name}` : name;

        if (isDesktop) {
            return (
                <>
                    <CardContainer className="accounts__card mt-075">
                        <Card
                            className="mb-0"
                            iconWithText={favoriteComponent}
                            title={
                                <div className="accounts__card-title">
                                    <span>{nameWithN}</span>
                                </div>
                            }
                            content={
                                <div className="accounts__card-content">
                                    <span>{title}</span>
                                    <span className="accounts__card-reference">{reference}</span>
                                    {favorite && (
                                        <span className="accounts__card-info">{i18nUtils.get(`account.favorite`)}</span>
                                    )}
                                </div>
                            }
                            rightContent={
                                <div className="accounts__card-right">
                                    <div className="accounts__card-real-balance">
                                        <span className="accounts__card-real-balance-info">{realBalanceLabel}</span>
                                        <FormattedAmount
                                            quantity={realBalance}
                                            currency={currency}
                                            className={`accounts__card-real-balance-data ${
                                                realBalance < 0 ? "red" : ""
                                            }`}
                                            cleanClassName
                                        />
                                        {balanceDiffers && (
                                            <span className="accounts__card-balance-differs">
                                                <I18n id="accounts.balance.differs.enabled" component="span" />
                                            </span>
                                        )}
                                    </div>
                                    <div className="accounts__card-balance">
                                        <span className="accounts__card-balance-info">{amountLabel}</span>
                                        <FormattedAmount
                                            quantity={amount}
                                            currency={currency}
                                            className={`accounts__card-balance-data ${amount < 0 ? "red" : ""}`}
                                            cleanClassName
                                        />
                                        {balanceDiffers && (
                                            <span className="accounts__card-balance-differs">
                                                <I18n id="accounts.balance.differs.enabled" component="span" />
                                            </span>
                                        )}
                                    </div>
                                    <ContextMenu
                                        isDesktop={isDesktop}
                                        items={accountContextMenuItems(dispatch, activeEnvironment, idProduct)}
                                        dropBtnClass="mt-125"
                                    />
                                </div>
                            }
                        />
                    </CardContainer>
                </>
            );
        }
        // Mobile
        return (
            <CardContainer className="accounts__card">
                <Card
                    iconWithText={favoriteComponent}
                    title={
                        <div className="accounts__card-title">
                            <span>{nameWithN}</span>
                        </div>
                    }
                    content={
                        <div className="accounts__card-content">
                            <span className="accounts__card-content-title">{title}</span>
                            <span className="accounts__card-reference">{reference}</span>
                        </div>
                    }
                    rightContent={
                        <div className="accounts__card-right">
                            <div className="accounts__card-real-balance">
                                <span className="accounts__card-real-balance-info">{realBalanceLabel}</span>
                                <FormattedAmount
                                    quantity={realBalance}
                                    currency={currency}
                                    className={`accounts__card-real-balance-data ${realBalance < 0 ? "red" : ""}`}
                                    cleanClassName
                                />
                                {balanceDiffers && (
                                    <span className="accounts__card-balance-differs">
                                        <I18n id="accounts.balance.differs.enabled" component="span" />
                                    </span>
                                )}
                            </div>
                            <div className="accounts__card-balance">
                                <span className="accounts__card-balance-info">{amountLabel}</span>
                                <FormattedAmount
                                    quantity={amount}
                                    currency={currency}
                                    className={`accounts__card-balance-data ${amount < 0 ? "red" : ""}`}
                                    cleanClassName
                                />
                                {balanceDiffers && (
                                    <span className="accounts__card-balance-differs">
                                        <I18n id="accounts.balance.differs.enabled" component="span" />
                                    </span>
                                )}
                            </div>
                        </div>
                    }
                    rightMenu={
                        <div
                            className="accounts__card-right-menu"
                            style={{ display: "flex", alignItems: "center", marginLeft: "-3rem" }}>
                            {isMobileNative && (
                                <Button
                                    icon="ShareAlt"
                                    iconColor="field-date-day"
                                    variant="text"
                                    iconVariant="fill"
                                    onClick={(e) => {
                                        this.shareAccount(e);
                                    }}
                                    className="btn-b-none justify-content-start my-0 ml-0 w-100 pr-0 background-transparent"
                                />
                            )}
                            <ContextMenu
                                isDesktop={isDesktop}
                                items={accountContextMenuItems(dispatch, activeEnvironment, idProduct)}
                            />
                        </div>
                    }
                />
            </CardContainer>
        );
    };

    render() {
        const { name, path, franchise, productTypeTitle, idProduct } = this.props;

        if (path) {
            return (
                <Link
                    aria-label={`${productTypeTitle}: ${franchise !== "" ? `${franchise} ` : ""}${name}`}
                    aria-describedby={idProduct}
                    className={classNames("no-link-color")}
                    to={path}
                    role="menuitem">
                    {this.getContent()}
                </Link>
            );
        }
        return this.getContent();
    }
}

class ExtendedAccountItem extends Component {
    static propTypes = {
        number: string.isRequired,
        productAlias: string,
        idProduct: string,
        productType: string.isRequired,
        amountLabel: string.isRequired,
        countableBalance: numberType.isRequired,
        balance: numberType.isRequired,
        hideAmountLabel: bool,
        showLink: bool,
        dispatch: func.isRequired,
        loggedUser: shape({}).isRequired,
        activeEnvironment: shape({}).isRequired,
    };

    static defaultProps = {
        productAlias: null,
        idProduct: null,
        hideAmountLabel: false,
        showLink: true,
    };

    productType = () => {
        const { productType } = this.props;

        if (productType === "CA") {
            return i18nUtils.get("accounts.productType.savings");
        }

        return i18nUtils.get("accounts.productType.checking");
    };

    render() {
        const {
            productAlias,
            number,
            balance,
            amountLabel = i18nUtils.get("accounts.availableBalance"),
            idProduct,
            hideAmountLabel,
            showLink,
            countableBalance,
            dispatch,
            loggedUser,
            activeEnvironment,
            ...props
        } = this.props;

        const numberOfuscado = maskedAccountNumber(number);

        const reference = `${i18nUtils.get("global.number")} ${numberOfuscado}`;
        const productName = productAlias || numberOfuscado;
        const productTypeTitle = i18nUtils.get("desktop.widgets.account");
        const realBalanceLabel = i18nUtils.get("accounts.balance.real.label");

        return (
            <ListItem
                {...props}
                idProduct={idProduct}
                productTypeTitle={productTypeTitle}
                title={this.productType()}
                name={productName}
                dispatch={dispatch}
                reference={reference}
                loggedUser={loggedUser}
                amount={balance}
                amountLabel={!hideAmountLabel && amountLabel}
                realBalance={countableBalance}
                realBalanceLabel={!hideAmountLabel && realBalanceLabel}
                path={showLink ? `/accounts/${idProduct}` : null}
                activeEnvironment={activeEnvironment}
            />
        );
    }
}
const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    loggedUser: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(ExtendedAccountItem);
