import React from "react";
import Button from "pages/_components/Button";
import Col from "react-bootstrap/lib/Col";
import { func, string, boolean } from "prop-types";
import { push } from "react-router-redux/actions";
import { actions as recoverPassActions } from "reducers/recoveryPassword";
import { connect } from "react-redux";
import { selectors as loginSelectors } from "reducers/login";

function ForgotUserButton({ label, dispatch, username, exchangeToken, onClick, values, className, disabled }) {
    const handleClick = () => {
        if (dispatch && label === "global.forgot.password") {
            dispatch(recoverPassActions.recoveryPassPreview(username, exchangeToken));
        } else if (dispatch) {
            dispatch(recoverPassActions.recoveryUserpassPre(values.document, values.documentType));
            dispatch(push("/recoveryUserpass/step0"));
        }
    };

    return (
        <Col sm={12} md={12} lg={12} className="col col-12 pl-0">
            <Button
                className={className}
                type="button"
                bsStyle="link-secondary"
                label={label}
                onClick={onClick || handleClick}
                disabled={disabled}
            />
        </Col>
    );
}

ForgotUserButton.defaultProps = {
    label: "global.forgot.user",
    onClick: null,
    values: {},
    className: "",
    disabled: false,
};

ForgotUserButton.propTypes = {
    label: string,
    dispatch: func.isRequired,
    username: string.isRequired,
    exchangeToken: string.isRequired,
    onClick: func,
    values: {},
    className: string,
    disabled: boolean,
};

const mapStateToProps = (state) => ({
    username: loginSelectors.getUsername(state),
    exchangeToken: loginSelectors.getExchangeToken(state),
});

export default connect(mapStateToProps)(ForgotUserButton);
