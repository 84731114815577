import React from "react";
import { shape, bool } from "prop-types";
import Notification from "pages/_components/Notification";
import Heading from "pages/_components/Heading";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import * as i18nUtils from "util/i18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import classNames from "classnames";

export default function FinancingSummaryDetail({ financing, isDesktop }) {
    FinancingSummaryDetail.propTypes = {
        financing: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };
    const {
        codigoMoneda,
        saldoCapital,
        saldoOperativo,
        fechaOrigen,
        cantidadCuotasTotales,
        importePactado,
    } = financing;

    const labelClassName = "mb-0";

    return (
        <>
            <Notification scopeToShow="financing" />
            <Container className={classNames("container-white mt-3 pb-3", { "mb-3": isDesktop })}>
                <div className="flex-grow container-white with-margin data-wrapper-inline">
                    <Col className="col col-12" md={5} sm={12}>
                        <Heading.DataGroup
                            labelClassName={labelClassName}
                            containerClassName={isDesktop ? "data-wrapper" : "f-dir-row data-wrapper my-0"}
                            label="financing.detail.subtitle.principalOwed"
                            data={
                                <FormattedAmount
                                    notBold
                                    currency={i18nUtils.get(`currency.label.${codigoMoneda}`)}
                                    quantity={saldoCapital}
                                />
                            }
                        />
                    </Col>
                    <Col className="col col-12" md={5} sm={12}>
                        <Heading.DataGroup
                            labelClassName={labelClassName}
                            containerClassName={isDesktop ? "data-wrapper" : "f-dir-row data-wrapper my-0"}
                            label="financing.detail.subtitle.debtBalance"
                            data={
                                <FormattedAmount
                                    notBold
                                    currency={i18nUtils.get(`currency.label.${codigoMoneda}`)}
                                    quantity={saldoOperativo}
                                />
                            }
                        />
                    </Col>
                    <Col className="col col-12" md={2} sm={12}>
                        <Heading.DataGroup
                            containerClassName={isDesktop ? "data-wrapper" : "f-dir-row data-wrapper my-0"}
                            labelClassName={labelClassName}
                            label="financing.detail.subtitle.originalCapital"
                            data={
                                <FormattedAmount
                                    notBold
                                    currency={i18nUtils.get(`currency.label.${codigoMoneda}`)}
                                    quantity={importePactado}
                                />
                            }
                        />
                    </Col>
                    <Col className="col col-12" md={5} sm={12}>
                        <Heading.DataGroup
                            containerClassName={isDesktop ? "data-wrapper" : "f-dir-row data-wrapper my-0"}
                            labelClassName={labelClassName}
                            label="financing.detail.subtitle.settlementDate"
                            data={fechaOrigen}
                        />
                    </Col>
                    <Col className="col col-12" md={5} sm={12}>
                        <Heading.DataGroup
                            containerClassName={isDesktop ? "data-wrapper" : "f-dir-row data-wrapper my-0"}
                            labelClassName={labelClassName}
                            label="financing.detail.subtitle.totalFees"
                            data={cantidadCuotasTotales}
                        />
                    </Col>
                </div>
            </Container>
        </>
    );
}
