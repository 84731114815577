/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import { func, shape, bool } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";
import moment from "moment";
import classNames from "classnames";

import Head from "pages/_components/Head";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import DateField from "pages/_components/fields/DateField";
import RangeDatePicker from "pages/_components/fields/rangedatepicker/RangeDatePicker";
import I18n from "pages/_components/I18n";
import Hint from "pages/_components/hints/Hint";
import Notification from "pages/_components/Notification";

import { selectors as checksSelectors, actions as checkActions } from "reducers/checks";

import * as i18n from "util/i18n";
import * as configUtils from "util/config";


const FORM_ID = "download.echeqs.list.cuit";

function DownloadEcheqListCuit({ dispatch, isDesktop, isSmallDesktop, values, fetching }) {
    DownloadEcheqListCuit.propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        values: shape({}).isRequired,
        fetching: bool.isRequired,
    };

    const { fromDate, toDate, dates} = values;
    const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

    const redirectMessges = () => dispatch(push("/communications"));

    useEffect(() => {
        const linkMessage = document.getElementById("idRedirectMsg");
        if (linkMessage) {
            linkMessage.onclick = redirectMessges;
        }
    }, []);

    const handleBack = () => dispatch(goBack());

    const title = `${FORM_ID}.title`;

    return (
        <>
            <Notification scopeToShow="echeqListCuit" />
            {isDesktop ? (
                <div className="echeqs__extract-rangue admin-detail-head">
                    <Head onBack={handleBack} headerClassName="mt-4" />
                    <div className="view-title d-flex">
                        <I18n
                            id={title}
                            component="h1"
                            componentProps={{
                                className: "my-35 ml-0 mr-2",
                            }}
                        />
                        <Hint
                            defaultValue={i18n.get(`${FORM_ID}.hint`)}
                            classNameMessage="echeqs__extract-rangue-hint-msg"
                            classNameImage="d-flex"
                            classNameHintP="font-weight-normal my-auto"
                            alwaysShowMessage
                        />
                    </div>
                </div>
            ) : (
                <Head
                    title={title}
                    titleClassName="text-center w-100"
                    headerClassName="blue-main-header-mobile"
                    onBack={handleBack}
                />
            )}
            <Container className="echeqs__extract-rangue flex-grow my-2">
                <Col xs={12}>
                    {!isDesktop ? (
                        <Hint
                            idMessage={`${FORM_ID}.hint`}
                            classNameMessage="echeqs__extract-rangue-hint-msg"
                            classNameImage="d-flex"
                            classNameHintP="font-weight-normal my-auto"
                            alwaysShowMessage
                        />
                    ) : (
                        undefined
                    )}
                </Col>
                <Col xs={12} className="px-0">
                    <Form>
                        <Container className="container-white my-2 py-3">
                            <Col xs={12} md={8} lg={enableRangeDatefield ? isSmallDesktop ? 6 : 4 : isSmallDesktop ? 8 : 6 }>
                                {
                                    enableRangeDatefield ?
                                        <Field
                                            idField="dates"
                                            component={RangeDatePicker}
                                            idForm={FORM_ID}
                                            name="dates"
                                            maxDate={new Date()}
                                        />
                                    :
                                        <>
                                            <Col xs={12} md={6} className={classNames({ "px-0": !isDesktop })}>
                                                <Field
                                                    idField="fromDate"
                                                    formikValueFirst
                                                    component={DateField}
                                                    hidePlaceholder
                                                    idForm={FORM_ID}
                                                    name="fromDate"
                                                    maxDate={toDate}
                                                />
                                            </Col>
                                            <Col xs={12} md={6} className={classNames({ "px-0": !isDesktop })}>
                                                <Field
                                                    idField="toDate"
                                                    formikValueFirst
                                                    component={DateField}
                                                    hidePlaceholder
                                                    idForm={FORM_ID}
                                                    name="toDate"
                                                    maxDate={moment()}
                                                    minDate={fromDate}
                                                />
                                            </Col>
                                        </>
                                }
                            </Col>
                        </Container>
                        <Container className="my-4">
                            <Col xs={12} md={8} lg={enableRangeDatefield ? isSmallDesktop ? 6 : 4 : isSmallDesktop ? 8 : 6 } className="justify-content-center d-flex">
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    image="images/download_bold_white.svg"
                                    imageStyle="mr-2 pb-1 icon-1-2"
                                    label={`${FORM_ID}.submit`}
                                    className={classNames("w-100 font-regular text-transform-none", {
                                        "my-0": isDesktop,
                                        "my-4": !isDesktop,
                                    })}
                                    loading={fetching}
                                    disabled={enableRangeDatefield && dates.some( date => !date )}
                                />
                            </Col>
                        </Container>
                    </Form>
                </Col>
            </Container>
        </>
    );
}

const mapStateToProps = (state) => ({
    fetching: checksSelectors.getFetchingDownload(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({
            fromDate: moment().subtract("7", "d"),
            toDate: moment(),
            dates: [moment().subtract("7", "d").toDate(), new Date()]
        }),
        handleSubmit: (form, formikBag) => {
            const {
                props: { dispatch },
            } = formikBag;
            const { fromDate, toDate, dates } = form;
            const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);
            if (enableRangeDatefield) {
                dispatch(checkActions.requestDownloadEcheqByCuit(...dates));
            } else {
                dispatch(checkActions.requestDownloadEcheqByCuit(fromDate, toDate));
            }
        },
    }),
)(DownloadEcheqListCuit);
