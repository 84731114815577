import { call, put, takeLatest, take } from "redux-saga/effects";
import { types } from "reducers/paymentsAFIP";
import * as paymentsAFIPMiddleware from "middleware/paymentsAFIP";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { routerActions } from "react-router-redux";
import { downloadPdf } from "util/download";
import { actions as formActions } from "reducers/form";
import { getResponseMessage } from "util/token";
import * as form from "middleware/form";
import { INTERNAL_ERROR } from "util/responses";
import { adjustIdFieldErrors } from "util/form";
import { buildMultiplePaymentsAFIP } from "util/paymentsAFIP";
import * as configUtils from "util/config";

const sagas = [
    takeLatest(types.LIST_ENABLED_CONTRIBUTORS_REQUEST, getEnabledContributors),
    takeLatest(types.ENABLE_CONTRIBUTOR_REQUEST, enableContributor),
    takeLatest(types.DELETE_ENABLED_CONTRIBUTOR_REQUEST, deleteEnabledContributor),
    takeLatest(types.LIST_PAYMENTS_AFIP_REQUEST, getPaymentsAFIP),
    takeLatest(types.PAY_PAYMENT_AFIP_PRE_REQUEST, payPaymentAFIPPre),
    takeLatest(types.PAY_MULTIPLE_AFIP_PRE_REQUEST, payMultipleAFIPPre),
    takeLatest(types.PAY_MULTIPLE_AFIP_REQUEST, payMultipleAFIP),
    takeLatest(types.PAY_PAYMENT_AFIP_PREVIEW_REQUEST, payPaymentAFIPPreview),
    takeLatest(types.PAY_PAYMENT_AFIP_REQUEST, payPaymentAFIP),
    takeLatest(types.DELETE_PAYMENT_AFIP_PRE_REQUEST, deletePaymentAFIPPre),
    takeLatest(types.DELETE_PAYMENT_AFIP_FROM_MAIN_PRE_REQUEST, deletePaymentAFIPPre),
    takeLatest(types.DELETE_PAYMENT_AFIP_REQUEST, deletePaymentAFIP),
    takeLatest(types.DOWNLOAD_PAY_CONFIRMATION_REQUEST, downloadPay),
    takeLatest(types.READ_PAYMENT_AFIP_REQUEST, readPaymentAFIP),
    takeLatest(types.READ_MULTPILE_PAYMENT_AFIP_REQUEST, readMultiplePaymentAFIP),
    takeLatest(types.GET_TOKEN_REQUEST, getToken),
    takeLatest(types.SET_IS_BACK_FROM_OTHER_PAGE, setIsBackFromOtherPage),
];

export default sagas;

function* getEnabledContributors() {
    const response = yield call(paymentsAFIPMiddleware.listEnabledContributors);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["enabledContributors"]));
        yield put({ type: types.LIST_ENABLED_CONTRIBUTORS_FAILURE });
    } else {
        yield put({
            type: types.LIST_ENABLED_CONTRIBUTORS_SUCCESS,
            enabledContributors: response.data.data.enabledContributors,
        });
    }
}

function* enableContributor({ cuit, reference, closeDrawer }) {
    const response = yield call(paymentsAFIPMiddleware.enableContributor, cuit, reference);

    if (response.type === "W") {
        const { NO_FIELD } = response.data.data;
        yield put(
            notificationActions.showNotification(NO_FIELD || i18n.get("global.unexpectedError"), "error", [
                "enabledContributors",
            ]),
        );

        yield put({ type: types.ENABLE_CONTRIBUTOR_FAILURE });
    } else {
        yield put(
            notificationActions.showNotification(
                i18n.get("payments.enabledContributors.list.enable.success.message"),
                "success",
                ["enabledContributors"],
            ),
        );

        yield put({
            type: types.LIST_ENABLED_CONTRIBUTORS_REQUEST,
        });

        yield put({
            type: types.ENABLE_CONTRIBUTOR_SUCCESS,
        });
    }
    closeDrawer();
}

function* deleteEnabledContributor({ cuit }) {
    const response = yield call(paymentsAFIPMiddleware.deleteEnabledContributor, cuit);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["enabledContributors"]));
        yield put({ type: types.DELETE_ENABLED_CONTRIBUTOR_FAILURE });
    } else {
        yield put(
            notificationActions.showNotification(
                i18n.get("payments.enabledContributors.delete.success.message"),
                "success",
                ["enabledContributors"],
            ),
        );

        yield put({
            type: types.LIST_ENABLED_CONTRIBUTORS_REQUEST,
        });
    }
}

function* getPaymentsAFIP({
    paymentType,
    generatedVEP,
    numberVEP,
    taxpayerCUIT,
    dateFrom,
    dateTo,
    pageNumber,
    thirdPartyCuit,
}) {
    const response = yield call(
        paymentsAFIPMiddleware.listPaymentsAFIP,
        paymentType,
        generatedVEP,
        numberVEP,
        taxpayerCUIT,
        dateFrom,
        dateTo,
        pageNumber,
        thirdPartyCuit,
    );

    if (response.type === "W") {
        yield put(notificationActions.showNotification(getResponseMessage(response), "error", ["servicePayments"]));
        yield put({ type: types.LIST_PAYMENTS_AFIP_FAILURE });
    } else {
        const { paymentsAFIP, totalPages } = response.data.data;

        yield put({
            type: types.LIST_PAYMENTS_AFIP_SUCCESS,
            paymentsAFIP: buildMultiplePaymentsAFIP(paymentsAFIP),
            pageNumber: Number(pageNumber),
            totalPages,
        });
    }
}

function* payPaymentAFIPPre({ paymentAFIP, redirect }) {
    const response = yield call(paymentsAFIPMiddleware.payPaymentAFIPPre);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["servicePayments"]));
        yield put({ type: types.PAY_PAYMENT_AFIP_PRE_FAILURE });
    } else {
        const { accounts } = response.data.data;

        yield put({
            type: types.PAY_PAYMENT_AFIP_PRE_SUCCESS,
            paymentAFIP,
            accounts,
        });

        if (redirect) {
            yield put(routerActions.push("/payPaymentAFIP"));
        }
    }
}

function* payMultipleAFIPPre({ selectedPaymentsAFIP, debitAccountMultipleAFIP, formikBag }) {
    try {
        const { type, ...response } = yield call(
            paymentsAFIPMiddleware.preparePaymentAFIP,
            selectedPaymentsAFIP,
            debitAccountMultipleAFIP,
        );
        if (type === "W") {
            const errors = response.data.data;
            const { message, ...restErrors } = errors;
            if (message) {
                yield put(notificationActions.showNotification(message, "error", ["servicePayments"]));
            }
            formikBag.setTouched(Object.keys(restErrors).reduce((acc, key) => ({ ...acc, [key]: true }), {}));
            formikBag.setErrors(adjustIdFieldErrors(restErrors));
        } else {
            yield put(routerActions.push("/payMultipleAFIPSummary"));
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* payMultipleAFIP({ payMultipleAFIPRequest, formikBag }) {
    const { selectedPaymentsAFIP } = payMultipleAFIPRequest;
    const { setErrors, setSubmitting } = formikBag;
    try {
        const response = yield call(paymentsAFIPMiddleware.payMultipleAFIP, payMultipleAFIPRequest);

        if (response.type === "W" && response.data.code !== INTERNAL_ERROR) {
            let errorMessage = response.data.data.NO_FIELD || i18n.get("global.unexpectedError");

            if (response.data.code === "COR054W" || response.data.code === "API524W") {
                errorMessage = response.data.message;
            }

            if (response.data.code === "COR020W") {
                setErrors(response.data.data);
            } else {
                yield put(notificationActions.showNotification(errorMessage, "error", ["multipleAFIPSummary"]));
            }
            yield put({ type: types.SUBMIT_PAYMENT_FAILURE });
        }

        if (response.data.code === "COR000I") {
            yield put(routerActions.push("/payMultipleAFIPSuccess"));
            yield put(
                notificationActions.showNotification(
                    i18n.get("services.multipleAFIP.success.final.message", null, {
                        PAYMENTS_QUANTITY: selectedPaymentsAFIP.length,
                    }),
                    "warning",
                    ["multipleAFIPSuccess"],
                ),
            );
        }
    } finally {
        yield put({
            type: types.HANDLE_BACK_TICKET,
        });
        setSubmitting(false);
    }
}

function* payPaymentAFIPPreview({ paymentAFIP, debitAccount }) {
    yield put({
        type: types.PAY_PAYMENT_AFIP_PREVIEW_SUCCESS,
        paymentAFIP,
        debitAccount,
    });

    yield put(routerActions.push("/payPaymentAFIPSummary"));
}

function* payPaymentAFIP({ summary, otp }) {
    const { formikBag } = summary;

    try {
        const response = yield call(
            paymentsAFIPMiddleware.payPaymentAFIP,
            { ...summary, amount: summary?.paymentAFIP?.amount, idAccount: summary?.debitAccount?.idProduct },
            otp,
        );
        if ((response.type === "W" && response.data.code !== INTERNAL_ERROR) || response.data.code === "COR065W") {
            yield put(
                notificationActions.showNotification(getResponseMessage(response), "error", [
                    "payPaymentAFIPSummary",
                    "multipleAFIPSummary",
                ]),
            );
            yield put({ type: types.PAY_PAYMENT_AFIP_FAILURE });
        } else {
            const { idTransaction } = response.data;
            const transactionResponse = yield call(form.readTransaction, idTransaction);
            const { idTransactionStatus } = transactionResponse.data.data?.transaction;

            if (idTransactionStatus === "PENDING") {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("pay.paymentAFIP.pending.success.message"),
                        "success",
                        ["paymentAFIPConfirmation"],
                    ),
                );
            } else if (response.data.code === INTERNAL_ERROR) {
                const chequesFunctionalities = configUtils.getBoolean(
                    "frontend.show.failedVoucher.chequesFunctionalities",
                    false,
                );
                if (chequesFunctionalities) {
                    const error = response.data.data.voucherErrorCode;
                    const message = response.data.data.voucherErrorMessage;

                    if (!error || !message) {
                        yield put(
                            routerActions.push({
                                pathname: "/error",
                                code: "API530W",
                            }),
                        );
                        formikBag.setSubmitting(false);
                        return;
                    }
                    const notificationMessage = i18n.get("notification.snackbar.failed.voucher", null, {
                        VOUCHER_MESSAGE: message,
                        VOUCHER_ERROR: error,
                    });
                    yield put(
                        notificationActions.showNotification(notificationMessage, "error", ["paymentAFIPConfirmation"]),
                    );
                } else {
                    yield put(
                        notificationActions.showNotification(response.data.data.NO_FIELD, "error", [
                            "paymentAFIPConfirmation",
                        ]),
                    );
                }
            } else {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("pay.paymentAFIP.confirmation.success.message"),
                        "success",
                        ["paymentAFIPConfirmation"],
                    ),
                );
            }
            yield put(formActions.readTransaction({ pathname: `/transaction/${idTransaction}`, newTransaction: true }));
            yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action
        }
    } finally {
        yield put({
            type: types.HANDLE_BACK_TICKET,
        });
        formikBag.setSubmitting(false);
    }
}

function* deletePaymentAFIPPre({ paymentAFIP }) {
    yield put({
        type: types.DELETE_PAYMENT_AFIP_PRE_SUCCESS,
        paymentAFIP,
    });

    yield put(routerActions.push("/deletePaymentAFIPSummary"));
}

function* deletePaymentAFIP({ summary, otp }) {
    const { formikBag } = summary;
    try {
        const response = yield call(paymentsAFIPMiddleware.deletePaymentAFIP, summary, otp);

        if (response.type === "W" && response.data.code !== "API516W") {
            if (["COR020W"].includes(response.data.code)) {
                formikBag.setErrors(response.data.data);
            } else if (["COR054W"].includes(response.data.code)) {
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["deletePaymentAFIPSummary"]),
                );
            } else {
                yield put(
                    notificationActions.showNotification(getResponseMessage(response), "error", [
                        "deletePaymentAFIPSummary",
                    ]),
                );
            }
            yield put({ type: types.DELETE_PAYMENT_AFIP_FAILURE });
        } else {
            const { idTransaction, openDate } = response.data;
            const transactionResponse = yield call(form.readTransaction, idTransaction);
            const {
                idTransactionStatus,
                data: { voucherServiceMessage, voucherErrorMessage },
            } = transactionResponse.data.data?.transaction;

            if (idTransactionStatus === "PENDING") {
                yield put(
                    formActions.readTransaction({
                        pathname: `/transaction/${response.data.idTransaction}`,
                        newTransaction: true,
                    }),
                );
                yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action
            } else if (idTransactionStatus === "FAILED" && voucherServiceMessage && voucherErrorMessage) {
                const chequesFunctionalities = configUtils.getBoolean(
                    "frontend.show.failedVoucher.chequesFunctionalities",
                    false,
                );
                if (chequesFunctionalities) {
                    const error = response.data.data.voucherErrorCode;
                    const message = response.data.data.voucherErrorMessage;
                    const notificationMessage = i18n.get("notification.snackbar.failed.voucher", null, {
                        VOUCHER_MESSAGE: message,
                        VOUCHER_ERROR: error,
                    });
                    yield put(notificationActions.showNotification(notificationMessage, "error", ["servicePayments"]));
                } else {
                    yield put(
                        notificationActions.showNotification(voucherServiceMessage || voucherErrorMessage, "error", [
                            "servicePayments",
                        ]),
                    );
                }
            } else {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("delete.paymentAFIP.confirmation.success.message"),
                        "success",
                        ["servicePayments"],
                    ),
                );
            }

            const { numberVEP } = summary;
            yield put({
                type: types.DELETE_PAYMENT_AFIP_SUCCESS,
                newSummary: { ...summary, idTransaction, openDate },
                numberVEP,
            });

            yield put(routerActions.push("/servicePayments"));

            yield put({
                type: "payments/PAYMENT_SELECTED",
            });
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* readPaymentAFIP({ paymentAFIP }) {
    yield put({
        type: types.READ_PAYMENT_AFIP_SUCCESS,
        paymentAFIP,
    });

    yield put(routerActions.push("/paymentAFIP"));
}

function* readMultiplePaymentAFIP({ paymentAFIP }) {
    yield put({
        type: types.READ_PAYMENT_AFIP_SUCCESS,
        paymentAFIP,
    });

    yield put(routerActions.push("/payMultipleAFIPDetail"));
}

function* downloadPay({ summary }) {
    const { type, data } = yield call(paymentsAFIPMiddleware.downloadPay, summary);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_PAY_CONFIRMATION_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "paymentAFIPConfirmation",
            ]),
        );
    } else {
        const { content, fileName } = data.data;

        downloadPdf(fileName, content);

        yield put({ type: types.DOWNLOAD_PAY_CONFIRMATION_SUCCESS });
    }
}

function* getToken() {
    try {
        const response = yield call(paymentsAFIPMiddleware.getToken);

        if (response.type === "W") {
            yield put(notificationActions.showNotification(response.data.message, "error", ["servicePayments"]));
            yield put({ type: types.GET_TOKEN_FAILURE });
        } else {
            const { tokenAFIP } = response.data.data;

            yield put({
                type: types.GET_TOKEN_SUCCESS,
                tokenAFIP,
            });
        }
    } catch (e) {
        yield put(routerActions.push({ pathname: "/error", code: "API530W" }));
    }
}

function* setIsBackFromOtherPage({ isReturnFromDetail }) {
    if (isReturnFromDetail) {
        yield put(routerActions.goBack());
    } else {
        yield put(routerActions.push("/servicePayments"));
    }
}
