import {
    Box,
    Button,
    Checkbox,
    Chip,
    Form,
    FormattedAmount,
    OrderBy,
    Radio,
    RadioGroup,
    ScrollerX,
    Separator,
    Switch,
    Text,
    useMediaQuery,
} from "@ui-kit/components/index.es";
import { arrayOf, bool, func, node, number, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transactionsActions, selectors as transactionsSelector } from "reducers/transactions";
import { actions as transferActions, selectors as transferSelectors } from "reducers/transfer";
import * as configUtils from "util/config";
import Yup from "yup";

import Hint from "pages/_components/hints/Hint";
import * as i18n from "util/i18n";

const FORM_ID = "transfers.historic";

const OrderByWrapper = ({ defaultValueSelector, defaultValueRadioButtons, onChange, propList }) => {
    const [key, setKey] = useState(0);

    // Observa los cambios en los valores predeterminados y actualiza la key para forzar el re-render
    useEffect(() => {
        setKey((prevKey) => prevKey + 1); // Cambia la key para forzar la re-renderización
    }, [defaultValueSelector, defaultValueRadioButtons]);

    return (
        <OrderBy
            key={key} // Usamos una key dinámica para forzar el re-render
            defaultValueSelector={defaultValueSelector}
            defaultValueRadioButtons={defaultValueRadioButtons}
            onChange={onChange}
            propList={propList}
        />
    );
};

OrderByWrapper.propTypes = {
    defaultValueSelector: string.isRequired,
    defaultValueRadioButtons: string.isRequired,
    onChange: func.isRequired,
    propList: shape({}).isRequired,
};

const RadioGroupWrapper = ({ name, children, onChange, value, error, direction, ...props }) => {
    const [key, setKey] = useState(0);

    // Observa los cambios en el valor y actualiza la key para forzar la re-renderización
    useEffect(() => {
        setKey((prevKey) => prevKey + 1); // Cambia la key para forzar el re-render
    }, [value]);

    return (
        <RadioGroup
            key={key} // Usamos una key dinámica para forzar la re-renderización
            name={name}
            onChange={onChange}
            value={value}
            error={error}
            direction={direction}
            {...props}>
            {children}
        </RadioGroup>
    );
};

RadioGroupWrapper.propTypes = {
    name: string.isRequired,
    children: node.isRequired,
    onChange: func.isRequired,
    value: string.isRequired,
    direction: string.isRequired,
    error: string,
};

RadioGroupWrapper.defaultProps = {
    error: "",
};

function TransferHistoricFilters(props) {
    TransferHistoricFilters.propTypes = {
        dispatch: func.isRequired,
        fetching: func.isRequired,
        setOrder: func.isRequired,
        filterByStatus: func.isRequired,
        onSubmit: func.isRequired,
        onSelectCurrency: func.isRequired,
        onSelectAll: func.isRequired,
        onResetFilters: func.isRequired,
        onSign: func.isRequired,
        onDeleteDrafts: func.isRequired,
        onDeletePendings: func.isRequired,
        filters: shape({}).isRequired,
        activeEnvironment: shape({}).isRequired,
        selectedCurrency: number.isRequired,
        pendingQuantity: number.isRequired,
        selectedTransactionRows: arrayOf().isRequired,
        transactions: arrayOf().isRequired,
        hasChanged: bool.isRequired,
        selectedChip: string.isRequired,
        showFilters: bool.isRequired,
        formikProps: shape({}).isRequired,
        setFormikProps: func.isRequired,
        filtersHistoric: shape({}).isRequired,
        filtersPending: shape({}).isRequired,
        filtersDraft: shape({}).isRequired,
        filtersScheduled: shape({}).isRequired,
        handleFilterObjectType: func.isRequired,
        getFiltersByOption: func.isRequired,
        controlActivity: bool,
        controlARS: string,
        controlUSD: string,
        isDesktop: bool.isRequired,
        onlyMyPendings: bool.isRequired,
    };

    TransferHistoricFilters.defaultProps = {
        controlActivity: false,
        controlARS: undefined,
        controlUSD: undefined,
    };

    const {
        dispatch,
        fetching,
        setOrder,
        filterByStatus,
        onSubmit,
        onSelectCurrency,
        selectedCurrency,
        filters,
        onResetFilters,
        hasChanged,
        onSelectAll,
        onSign,
        onDeleteDrafts,
        onDeletePendings,
        selectedTransactionRows,
        transactions,
        pendingQuantity,
        selectedChip,
        showFilters,
        formikProps,
        setFormikProps,
        filtersHistoric,
        filtersPending,
        filtersDraft,
        filtersScheduled,
        handleFilterObjectType,
        getFiltersByOption,
        isDesktop,
        onlyMyPendings,
    } = props;

    let limitMonths;
    if (selectedChip === "SCHEDULED") {
        limitMonths = configUtils.getInteger("frontend.scheduledTransactions.allowedPeriod.Months", 36);
    } else {
        limitMonths = configUtils.getInteger("frontend.Transfers.maxMonths.ToSearch", 3);
    }
    const minDate = new Date();
    minDate.setMonth(minDate.getMonth() - limitMonths);
    const defaultDateFrom = new Date();
    defaultDateFrom.setMonth(defaultDateFrom.getMonth() - 1);

    const isMobile = useMediaQuery("min", "md");
    const setShowFilters = (value) => dispatch(transferActions.setShowFilters(value));
    const setHasChanged = (value) => dispatch(transferActions.setHasFilterChanged(value));
    const setSelectedChip = (value) => dispatch(transferActions.setSelectedChip(value));

    const showMvp2 = configUtils.getBoolean("frontend.showMVP2.transfers.functionalities");
    const showMvp5 = configUtils.getBoolean("frontend.showMVP5.transfers.functionalities");
    const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

    const handleDefaultValueOrderBy = (filterType) => {
        const justOrderType = filterType?.orderBy?.split(" ")[0];
        const dateOrderTypes = new Set(["creation_date_time", "submit_date_time"]);
        return dateOrderTypes.has(justOrderType) ? "date" : "total_amount_data";
    };

    // eslint-disable-next-line no-confusing-arrow
    const handleDefaultValueRadioButtonOrderBy = (filterType) =>
        filterType?.orderBy?.endsWith("ASC") ? "ascendent" : "descendent";

    const [orderByRadio, setOrderByRadio] = useState(handleDefaultValueRadioButtonOrderBy(filtersHistoric));
    const [orderBySelector, setOrderBySelector] = useState(handleDefaultValueOrderBy(filtersHistoric));

    const [orderByRadioPending, setOrderByRadioPending] = useState(
        handleDefaultValueRadioButtonOrderBy(filtersPending),
    );
    const [orderBySelectorPending, setOrderBySelectorPending] = useState(handleDefaultValueOrderBy(filtersPending));

    const [orderByRadioScheduled, setOrderByRadioScheduled] = useState(
        handleDefaultValueRadioButtonOrderBy(filtersScheduled),
    );
    const [orderBySelectorScheduled, setOrderBySelectorScheduled] = useState(
        handleDefaultValueOrderBy(filtersScheduled),
    );

    const [orderByRadioDraft, setOrderByRadioDraft] = useState(handleDefaultValueRadioButtonOrderBy(filtersDraft));
    const [orderBySelectorDraft, setOrderBySelectorDraft] = useState(handleDefaultValueOrderBy(filtersDraft));
    const [hasControlActivity, setHasControlActivity] = useState(false);

    useEffect(() => {
        setOrderByRadio(handleDefaultValueRadioButtonOrderBy(filtersHistoric));
        setOrderBySelector(handleDefaultValueOrderBy(filtersHistoric));
    }, [filtersHistoric]);

    useEffect(() => {
        setOrderByRadioPending(handleDefaultValueRadioButtonOrderBy(filtersPending));
        setOrderBySelectorPending(handleDefaultValueOrderBy(filtersPending));
    }, [filtersPending]);

    useEffect(() => {
        setOrderByRadioDraft(handleDefaultValueRadioButtonOrderBy(filtersDraft));
        setOrderBySelectorDraft(handleDefaultValueOrderBy(filtersDraft));
    }, [filtersDraft]);

    useEffect(() => {
        setOrderByRadioPending(handleDefaultValueRadioButtonOrderBy(filtersScheduled));
        setOrderBySelectorPending(handleDefaultValueOrderBy(filtersScheduled));
    }, [filtersScheduled]);

    const setDateFromBasedOnChip = () => {
        let dateFromToReturn = null;
        const configDate = new Date();
        const limitMonths = configUtils.getInteger("frontend.scheduledTransactions.allowedPeriod.Months", 36);
        configDate.setMonth(configDate.getMonth() - limitMonths);
        switch (selectedChip) {
            case "historic":
                dateFromToReturn = filtersHistoric?.dateFrom;
                break;
            case "PENDING":
                dateFromToReturn = filtersPending?.dateFrom;
                break;
            case "SCHEDULED":
                dateFromToReturn = filtersScheduled?.dateFrom || configDate;
                break;
            case "DRAFT":
                dateFromToReturn = filtersDraft?.dateFrom;
                break;
            default:
                break;
        }
        return dateFromToReturn || defaultDateFrom;
    };

    const setDateToBasedOnChip = () => {
        let dateToReturn = null;
        switch (selectedChip) {
            case "historic":
                dateToReturn = filtersHistoric?.dateTo;
                break;
            case "PENDING":
                dateToReturn = filtersPending?.dateTo;
                break;
            case "SCHEDULED":
                dateToReturn = filtersScheduled?.dateTo;
                break;
            case "DRAFT":
                dateToReturn = filtersDraft?.dateTo;
                break;
            default:
                break;
        }

        return dateToReturn || new Date();
    };

    const [dateFrom, setDateFrom] = useState(setDateFromBasedOnChip);
    const [dateTo, setDateTo] = useState(setDateToBasedOnChip);
    const [switchOn, setSwitchOn] = useState(onlyMyPendings || false);

    useEffect(() => {
        if (formikProps?.setValues) {
            const { beneficiary, reference, onlyPendings, transferType } = filters;
            const filterToSet = {
                transferType,
                ...(enableRangeDatefield
                    ? {
                          dates: [dateFrom, dateTo],
                      }
                    : {
                          dateFrom,
                          dateTo,
                      }),
                beneficiary: beneficiary || "",
                reference: reference || "",
                onlyPendings,
                minAmount: filters.minAmount?.amount || 0,
                maxAmount: filters.maxAmount?.amount || 0,
            };
            formikProps.setValues(filterToSet);
            handleFilterObjectType(filterToSet);
        }
    }, [formikProps]);

    useEffect(() => {
        if (selectedTransactionRows) {
            const initial = 0;
            const { controlActivity, controlARS, controlUSD } = props;

            // Realiza control de importe sobre los elementos seleccionados (suma)
            if (controlActivity) {
                const filterTransactions = selectedTransactionRows?.filter(
                    (element) => element.transaction?.data?.transferKind === "other",
                );

                const acumArs = filterTransactions?.reduce(
                    (prev, curr) => prev + (curr?.transactionAmounts["0"] || 0),
                    initial,
                );
                const acumUsd = filterTransactions?.reduce(
                    (prev, curr) => prev + (curr?.transactionAmounts["2"] || 0),
                    initial,
                );
                setHasControlActivity(acumArs >= controlARS || acumUsd >= controlUSD);
            }
        }
    }, [selectedTransactionRows]);

    const cleanFilters = (selectedOption) => {
        setHasChanged(false);
        if (formikProps?.handleReset) {
            formikProps.handleReset();
        }
        onResetFilters(selectedOption || selectedChip);
    };

    const initialValues = {
        transferType: {
            label: i18n.get(`${FORM_ID}.all`),
            value: "all",
        },
        beneficiary: "",
        reference: "",
        minAmount: 0,
        maxAmount: 0,
        ...(enableRangeDatefield
            ? {
                  dates: [dateFrom, dateTo],
              }
            : {
                  dateFrom,
                  dateTo,
              }),
        onlyPendings: false,
    };

    const handleOnSubmit = (selectedFilters) => {
        const isFormChanged = Object.keys(selectedFilters).some((key) => selectedFilters[key] !== initialValues[key]);
        setHasChanged(isFormChanged);
        onSubmit({
            ...selectedFilters,
            ...(enableRangeDatefield
                ? {
                      dateFrom: selectedFilters.dates[0],
                      dateTo: selectedFilters.dates[1],
                  }
                : {}),
            selectedOption: selectedChip,
            onlyMyPendings,
        });
    };

    const parseAmount = (value) => {
        if (typeof value === "string") {
            return parseFloat(value.replaceAll(".", "").replace(",", ""));
        }
        return value;
    };

    const getOrderBy = (option) => {
        if (option === "historic") {
            return "submit_date_time";
        }
        return "creation_date_time";
    };

    const historicOrderByOptions = [
        {
            label: i18n.get(`${FORM_ID}.orderBy.amount`),
            value: "total_amount_data",
        },
        {
            label: i18n.get(`${FORM_ID}.orderBy.executionDate`),
            value: "date",
        },
    ];
    const scheduledOrderByOptions = [
        {
            label: i18n.get(`${FORM_ID}.orderBy.amount`),
            value: "total_amount_data",
        },
        {
            label: i18n.get(`${FORM_ID}.orderBy.creationDate`),
            value: "date",
        },
    ];
    const pendingDraftOrderByOptions = [
        {
            label: i18n.get(`${FORM_ID}.orderBy.amount`),
            value: "total_amount_data",
        },
        {
            label: i18n.get(`${FORM_ID}.orderBy.creationDate`),
            value: "date",
        },
    ];

    // eslint-disable-next-line arrow-body-style
    const addDefaultDates = (filter) => {
        // eslint-disable-next-line no-shadow
        const dateFrom = filter.dateFrom || setDateFromBasedOnChip();
        // eslint-disable-next-line no-shadow
        const dateTo = filter.dateTo || setDateToBasedOnChip();
        return {
            ...filter,
            ...(enableRangeDatefield
                ? {
                      dates: [dateFrom, dateTo],
                  }
                : {
                      dateFrom,
                      dateTo,
                  }),
        };
    };

    // eslint-disable-next-line arrow-body-style
    const addDefaultAmounts = (filter) => {
        return {
            ...filter,
            minAmount: filter.minAmount?.amount || 0,
            maxAmount: filter.maxAmount?.amount || 0,
        };
    };

    const gettingFilterByChip = (selectedOption) => {
        let filterToReturn = getFiltersByOption(selectedOption);
        // Agrega las fechas por defecto si faltan
        filterToReturn = addDefaultDates(filterToReturn);
        // Agrega los montos por defecto si faltan
        filterToReturn = addDefaultAmounts(filterToReturn);
        onSelectCurrency(filterToReturn.minAmountCurrency || 0);
        return filterToReturn;
    };

    const handleChipSelection = (selectedOption) => {
        setSelectedChip(selectedOption);
        dispatch(transferActions.setOnlyMyPendings(false));
        setSwitchOn(false);
        const filtersByOption = getFiltersByOption(selectedOption);
        let dateType = getOrderBy(selectedOption);
        let orderBy = `${dateType} DESC`;
        let status = selectedOption === "historic" ? null : selectedOption;

        if (filtersByOption && filtersByOption?.orderBy) {
            const splittedOrderBy = filtersByOption.orderBy.split(" ");
            // eslint-disable-next-line prefer-destructuring
            dateType = splittedOrderBy[0];
            orderBy = filtersByOption?.orderBy;
        }
        if (filtersByOption?.status) {
            status = filtersByOption?.status;
        }

        const filterToSet = gettingFilterByChip(selectedOption);
        if (formikProps?.setValues) {
            formikProps.setValues({
                ...filterToSet,
                ...(enableRangeDatefield
                    ? {
                          dates: [filterToSet.dates[0], filterToSet.dates[1]],
                      }
                    : {}),
            });
            dispatch(
                transferActions.loadListRequest(
                    {
                        ...filterToSet,
                        ...(enableRangeDatefield
                            ? {
                                  dateFrom: filterToSet.dates[0],
                                  dateTo: filterToSet.dates[1],
                              }
                            : {}),
                        status,
                        dateType,
                        orderBy,
                    },
                    false,
                    false,
                ),
            );
            dispatch(transactionsActions.clearSelectedTransactionRows());
        } else {
            cleanFilters(selectedOption);
        }
    };

    const handleChangeOnlyPending = () => {
        onSubmit({ ...filtersPending, onlyMyPendings: !switchOn });
        setSwitchOn(!switchOn);
    };

    const isAllChecked = () => {
        // Se retiran las transacciones que tienen control de importe ya que estas no deben ser chequedas
        const filterTransactions = transactions?.filter((element) => !element.transaction?.control);

        return (
            filterTransactions?.length > 0 &&
            filterTransactions.every((transaction) =>
                selectedTransactionRows?.some(
                    (selectedTransaction) =>
                        transaction.transaction.idTransaction === selectedTransaction.transaction.idTransaction,
                ),
            )
        );
    };

    const handleDelete = () => {
        if (selectedChip === "PENDING") {
            onDeletePendings();
        } else {
            onDeleteDrafts();
        }
    };

    const dateLabel = () => {
        if (selectedChip === "historic") {
            return i18n.get(`${FORM_ID}.orderBy.executionDate`);
        }
        return i18n.get(`${FORM_ID}.orderBy.creationDate`);
    };

    const hasSomeUnavaliableToSign = selectedTransactionRows?.some(
        (item) => !item.transaction?.signatureAvaliableByUser,
    );

    const multipleSignEnabled = () => {
        const { activeEnvironment } = props;
        return (
            activeEnvironment?.permissions["multiple.signatures"] &&
            selectedTransactionRows.length > 0 &&
            !hasSomeUnavaliableToSign &&
            !hasControlActivity
        );
    };

    const initialValue = 0;
    const amountArs = selectedTransactionRows?.reduce(
        (prev, curr) => prev + (curr?.transactionAmounts["0"] || 0),
        initialValue,
    );
    const amountUsd = selectedTransactionRows?.reduce(
        (prev, curr) => prev + (curr?.transactionAmounts["2"] || 0),
        initialValue,
    );

    const handleSettingOrderByChip = (value) => {
        switch (selectedChip) {
            case "historic":
                setOrderByRadio(value?.direction);
                setOrderBySelector(value?.orderBy);
                break;
            case "PENDING":
                setOrderByRadioPending(value?.direction);
                setOrderBySelectorPending(value?.orderBy);
                break;
            case "SCHEDULED":
                setOrderByRadioScheduled(value?.direction);
                setOrderBySelectorScheduled(value?.orderBy);
                break;
            case "DRAFT":
                setOrderByRadioDraft(value?.direction);
                setOrderBySelectorDraft(value?.orderBy);
                break;
            default:
                break;
        }
    };

    const handleSetOrder = (value) => {
        const orderDirection = value?.direction === "ascendent" ? " ASC" : " DESC";
        handleSettingOrderByChip(value);
        if (value.orderBy === "date") {
            setOrder(getOrderBy(selectedChip) + orderDirection);
        } else {
            setOrder(value.orderBy + orderDirection);
        }
    };

    const hasSummittedForm = () => {
        switch (selectedChip) {
            case "historic":
                return filtersHistoric?.hasSummitted;
            case "PENDING":
                return filtersPending?.hasSummitted;
            case "SCHEDULED":
                return filtersScheduled?.hasSummitted;
            case "DRAFT":
                return filtersDraft?.hasSummitted;
            default:
                return false;
        }
    };

    const transferTypeOptions = [
        {
            label: i18n.get(`${FORM_ID}.all`),
            value: "all",
        },
        {
            label: i18n.get(`${FORM_ID}.internal`),
            value: "transfers.internal.send",
        },
        {
            label: i18n.get(`${FORM_ID}.internal.tad`),
            value: "transfers.internal.tad.send",
        },
        {
            label: i18n.get(`${FORM_ID}.thirdParties`),
            value: "transfers.thirdParties.send",
        },
        {
            label: i18n.get(`${FORM_ID}.thirdParties.tad`),
            value: "transfers.thirdParties.tad.send",
        },
    ];
    if (showMvp5) {
        transferTypeOptions.push(
            {
                label: i18n.get(`${FORM_ID}.toContact`),
                value: "transfers.toContact.send",
            },
            {
                label: i18n.get(`${FORM_ID}.toContact.tad`),
                value: "transfers.toContact.tad.send",
            },
        );
    }
    const maxMonths = configUtils.getInteger("frontend.Transfers.maxMonths.ToSearch", 3);

    return (
        <>
            <Box
                mt={32}
                mx={isDesktop ? 48 : null}
                mb={showFilters ? 24 : 0}
                {...(!isMobile && { justify: "between", align: "center" })}>
                <Box flex gap={12}>
                    {isMobile ? (
                        <ScrollerX>
                            <Box>
                                <Chip
                                    label={i18n.get("transfers.historic.tab")}
                                    onClick={() => {
                                        handleChipSelection("historic");
                                    }}
                                    pressed={selectedChip === "historic"}
                                    size="small"
                                />
                            </Box>

                            {showMvp2 && (
                                <Box>
                                    <Chip
                                        label={i18n.get("transfers.historic.tab.pending")}
                                        onClick={() => {
                                            handleChipSelection("PENDING");
                                        }}
                                        size="small"
                                        notification={pendingQuantity}
                                        pressed={selectedChip === "PENDING"}
                                    />
                                </Box>
                            )}
                            {showMvp2 && (
                                <Box>
                                    <Chip
                                        label={i18n.get("transfers.historic.tab.scheduled")}
                                        onClick={() => {
                                            handleChipSelection("SCHEDULED");
                                        }}
                                        size="small"
                                        pressed={selectedChip === "SCHEDULED"}
                                    />
                                </Box>
                            )}
                            {showMvp2 && (
                                <Box>
                                    <Chip
                                        label={i18n.get("transfers.historic.tab.drafts")}
                                        onClick={() => {
                                            handleChipSelection("DRAFT");
                                        }}
                                        size="small"
                                        pressed={selectedChip === "DRAFT"}
                                    />
                                </Box>
                            )}
                        </ScrollerX>
                    ) : (
                        <>
                            <Chip
                                label={i18n.get("transfers.historic.tab")}
                                onClick={() => {
                                    handleChipSelection("historic");
                                }}
                                pressed={selectedChip === "historic"}
                                size="small"
                            />

                            {showMvp2 && (
                                <Chip
                                    label={i18n.get("transfers.historic.tab.pending")}
                                    onClick={() => {
                                        handleChipSelection("PENDING");
                                    }}
                                    size="small"
                                    notification={pendingQuantity}
                                    pressed={selectedChip === "PENDING"}
                                />
                            )}
                            {showMvp2 && (
                                <Chip
                                    label={i18n.get("transfers.historic.tab.scheduled")}
                                    onClick={() => {
                                        handleChipSelection("SCHEDULED");
                                    }}
                                    size="small"
                                    pressed={selectedChip === "SCHEDULED"}
                                />
                            )}
                            {showMvp2 && (
                                <Chip
                                    label={i18n.get("transfers.historic.tab.drafts")}
                                    onClick={() => {
                                        handleChipSelection("DRAFT");
                                    }}
                                    size="small"
                                    pressed={selectedChip === "DRAFT"}
                                />
                            )}
                        </>
                    )}
                </Box>
                <Box mt={isMobile ? 24 : 0}>
                    <Button
                        text={i18n.get(
                            // eslint-disable-next-line no-nested-ternary
                            FORM_ID + (showFilters ? ".hideFilters" : hasChanged ? ".activeFilters" : ".showFilters"),
                        )}
                        iconVariant="outline"
                        variant="outline"
                        size="medium"
                        icon={showFilters ? "EyeOff" : "Eye"}
                        onClick={() => setShowFilters(!showFilters)}
                    />
                    {isMobile && hasSummittedForm() && (
                        <Button
                            text={i18n.get("global.clean.filters")}
                            variant="text"
                            size="small"
                            icon="Error"
                            className="ml-2"
                            onClick={() => cleanFilters()}
                        />
                    )}
                </Box>
            </Box>
            <Box mx={isDesktop ? 48 : null}>
                <Form
                    disabledSubmitByDirty={hasChanged}
                    hiddeForm={!showFilters}
                    rowSpacingForm={24}
                    buttonSubmit={{
                        colProps: {
                            size: {
                                lg: 2,
                                xs: 12,
                            },
                        },
                        text: i18n.get(`${FORM_ID}.filter`),
                        variant: "solid",
                        icon: "Commands",
                    }}
                    initialValues={initialValues}
                    inputsList={[
                        {
                            colProps: {
                                size: {
                                    lg: 4,
                                    xs: 12,
                                },
                            },
                            component: "selectField",
                            id: "transferType",
                            selectLabel: i18n.get(`${FORM_ID}.transferType`),
                            iconMobile: "TransferAlt",
                            defaultOption: transferTypeOptions[0],
                            options: transferTypeOptions,
                        },
                        {
                            colProps: {
                                size: {
                                    lg: 4,
                                    md: 6,
                                    xs: 12,
                                },
                            },
                            component: "textField",
                            id: "beneficiary",
                            label: i18n.get(`${FORM_ID}.beneficiary`),
                            placeholder: i18n.get(`${FORM_ID}.beneficiary.placeholder`),
                            maxLength: 100,
                        },
                        {
                            colProps: {
                                size: {
                                    lg: 4,
                                    md: 6,
                                    xs: 12,
                                },
                            },
                            component: "textField",
                            id: "reference",
                            label: i18n.get(`${FORM_ID}.reference`),
                            maxLength: 50,
                        },
                        {
                            colProps: {
                                size: {
                                    lg: 3,
                                    md: 6,
                                    xs: 12,
                                },
                            },
                            component: "amountField",
                            allowNegative: false,
                            currency: selectedCurrency,
                            currencyList: [
                                {
                                    label: i18n.get(`currency.label.0`),
                                    value: 0,
                                },
                                {
                                    label: i18n.get(`currency.label.2`),
                                    value: 2,
                                },
                            ],
                            id: "minAmount",
                            label: i18n.get(`${FORM_ID}.amountFrom`),
                            onSelectCurrency: (e) => {
                                onSelectCurrency(e.value);
                            },
                            maxLength: 24, // maximo de 18 numeros + puntos y comas
                        },
                        {
                            colProps: {
                                size: {
                                    lg: 3,
                                    md: 6,
                                    xs: 12,
                                },
                            },
                            component: "amountField",
                            allowNegative: false,
                            currency: selectedCurrency,
                            currencyList: [
                                {
                                    label: i18n.get(`currency.label.0`),
                                    value: 0,
                                },
                                {
                                    label: i18n.get(`currency.label.2`),
                                    value: 2,
                                },
                            ],
                            id: "maxAmount",
                            label: i18n.get(`${FORM_ID}.amountTo`),
                            onSelectCurrency: (e) => {
                                onSelectCurrency(e.value);
                            },
                            maxLength: 24, // maximo de 18 numeros + puntos y comas
                        },
                        ...(enableRangeDatefield
                            ? [
                                  {
                                      colProps: {
                                          size: {
                                              lg: 4,
                                              md: 12,
                                              xs: 12,
                                          },
                                      },
                                      component: "rangeDateField",
                                      id: "dates",
                                      label: dateLabel(),
                                      minDate,
                                      startDate: dateFrom,
                                      endDate: dateTo,
                                      value: [dateFrom, dateTo],
                                      maxMonthsOfRange: maxMonths,
                                      onDateChange: ([from, to]) => {
                                          setDateFrom(from);
                                          setDateTo(to);
                                      },
                                  },
                              ]
                            : [
                                  {
                                      colProps: {
                                          size: {
                                              lg: 2,
                                              md: 6,
                                              xs: 12,
                                          },
                                      },
                                      component: "dateField",
                                      id: "dateFrom",
                                      label: `${dateLabel()} ${i18n.get(`${FORM_ID}.From`)}`,
                                      minDate,
                                      startDate: dateFrom,
                                      endDate: dateTo,
                                      maxDate: dateTo,
                                      value: dateFrom,
                                      selectsStart: true,
                                      onDateChange: (e) => {
                                          setDateFrom(e);
                                      },
                                  },
                                  {
                                      colProps: {
                                          size: {
                                              lg: 2,
                                              md: 6,
                                              xs: 12,
                                          },
                                      },
                                      component: "dateField",
                                      id: "dateTo",
                                      label: `${dateLabel()} ${i18n.get(`${FORM_ID}.To`)}`,
                                      startDate: dateFrom,
                                      minDate: dateFrom,
                                      endDate: dateTo,
                                      value: dateTo,
                                      selectsEnd: true,
                                      onDateChange: (e) => {
                                          setDateTo(e);
                                      },
                                  },
                              ]),
                    ]}
                    onSubmit={(selectedFilters) => handleOnSubmit(selectedFilters)}
                    validationSchema={Yup.lazy((values) =>
                        Yup.object().shape({
                            beneficiary: Yup.string()
                                .min(3, i18n.get(`${FORM_ID}.validation.length`))
                                .matches(/^[^[\]+?:\-_&*@#{}$()/";]+$/, i18n.get(`${FORM_ID}.validation.format`)),
                            reference: Yup.string()
                                .min(3, i18n.get(`${FORM_ID}.validation.length`))
                                .matches(/^[^[\]+?:.\-_&*@#{}$()/";]+$/, i18n.get(`${FORM_ID}.validation.format`)),
                            minAmount: Yup.string().test(
                                "minAmount",
                                i18n.get(`${FORM_ID}.validation.minAmount`),
                                (minAmount) => parseAmount(minAmount) <= parseAmount(values.maxAmount),
                            ),
                            maxAmount: Yup.string().test(
                                "maxAmount",
                                i18n.get(`${FORM_ID}.validation.maxAmount`),
                                (maxAmount) => parseAmount(maxAmount) >= parseAmount(values.minAmount),
                            ),
                            ...(enableRangeDatefield && {
                                dates: Yup.mixed().test(
                                    "dates",
                                    i18n.get(`${FORM_ID}.validation.range.date`),
                                    ([from, to]) => from && to,
                                ),
                            }),
                        }),
                    )}
                    setFormikProps={setFormikProps}
                />
            </Box>
            <Box flex justify={showFilters ? "between" : "end"}>
                {showFilters && (
                    <Box ml={isDesktop ? 48 : null} mt={24} w="100%">
                        {selectedChip === "historic" && (
                            <OrderByWrapper
                                defaultValueSelector={orderBySelector}
                                defaultValueRadioButtons={orderByRadio}
                                onChange={(e) => handleSetOrder(e)}
                                propList={historicOrderByOptions}
                            />
                        )}
                        {selectedChip === "SCHEDULED" && (
                            <OrderByWrapper
                                defaultValueRadioButtons={orderByRadioScheduled}
                                defaultValueSelector={orderBySelectorScheduled}
                                onChange={(e) => handleSetOrder(e)}
                                propList={scheduledOrderByOptions}
                            />
                        )}
                        {selectedChip === "PENDING" && (
                            <OrderByWrapper
                                defaultValueRadioButtons={orderByRadioPending}
                                defaultValueSelector={orderBySelectorPending}
                                onChange={(e) => handleSetOrder(e)}
                                propList={pendingDraftOrderByOptions}
                            />
                        )}
                        {selectedChip === "DRAFT" && (
                            <OrderByWrapper
                                defaultValueRadioButtons={orderByRadioDraft}
                                defaultValueSelector={orderBySelectorDraft}
                                onChange={(e) => handleSetOrder(e)}
                                propList={pendingDraftOrderByOptions}
                            />
                        )}
                    </Box>
                )}
                {!isMobile && hasSummittedForm() && (
                    <Button
                        text={i18n.get("global.clean.filters")}
                        variant="text"
                        size="medium"
                        icon="Error"
                        className={`ml-2 ${isDesktop && "mr-5"}`}
                        onClick={() => cleanFilters()}
                    />
                )}
            </Box>
            {isMobile && <Separator color="neutral-light" mt={24} />}
            {selectedChip === "historic" && (
                <Box ml={isDesktop ? 48 : null} my={24} flex>
                    <RadioGroupWrapper
                        direction="row"
                        name="default1"
                        onChange={(e) => filterByStatus(e.target.value)}
                        value={filtersHistoric.status || "TODOS"}>
                        <Radio id="all" label={i18n.get(`${FORM_ID}.all`)} value="TODOS" isDisabled={fetching} />
                        <Radio
                            id="finished"
                            label={i18n.get(`${FORM_ID}.finished`)}
                            value="FINALIZADA"
                            isDisabled={fetching}
                        />
                        <Radio
                            id="failed"
                            label={i18n.get(`${FORM_ID}.failed`)}
                            value="FALLIDA"
                            isDisabled={fetching}
                        />
                    </RadioGroupWrapper>
                </Box>
            )}
            {(selectedChip === "PENDING" || selectedChip === "DRAFT") && (
                <Box mt={24} mb={0} ml={isMobile ? 16 : 12} mx={isDesktop ? 48 : null} column>
                    {selectedChip === "PENDING" && (
                        <>
                            <Box
                                flex={!isMobile}
                                align={!isMobile ? "center" : undefined}
                                justify={!isMobile ? "between" : undefined}>
                                <Text align="left" variant="bodyStrong">
                                    {i18n.get(`${FORM_ID}.pending.total.title`)}
                                </Text>
                                <Box flex align="center">
                                    <FormattedAmount
                                        amount={amountArs}
                                        currency="ARS"
                                        variant="bodyStrong"
                                        className="ml-0"
                                    />
                                    <Text align="left" variant="bodyStrong" className="ml-1">
                                        {" "}
                                        |{" "}
                                    </Text>
                                    <FormattedAmount amount={amountUsd} currency="USD" variant="bodyStrong" />
                                </Box>
                            </Box>
                            <Text variant="captionBold">{`${i18n.get(`${FORM_ID}.pending.selected`)} ${
                                selectedTransactionRows.length
                            }`}</Text>
                        </>
                    )}
                    <Box
                        {...(!isMobile && { justify: "between" })}
                        column={isMobile}
                        reverse={isMobile}
                        mb={selectedChip === "DRAFT" ? 12 : undefined}>
                        <Box flex {...(!isMobile && { justify: "between" })} gap={8} mt={24}>
                            <Checkbox
                                label={i18n.get(`${FORM_ID}.select.all`)}
                                isChecked={isAllChecked()}
                                className="m-0"
                                onChange={(e) => {
                                    onSelectAll(e.target.checked);
                                }}
                            />
                            {selectedChip === "PENDING" && (
                                <Button
                                    text={i18n.get(`${FORM_ID}.pending.sign`)}
                                    variant="solid"
                                    size="small"
                                    className="px-3"
                                    onClick={() => {
                                        onSign();
                                    }}
                                    isInactive={!multipleSignEnabled()}
                                />
                            )}
                            <Button
                                text={i18n.get(`${FORM_ID}.pending.delete`)}
                                variant="solid"
                                size="small"
                                className="px-3"
                                onClick={() => {
                                    handleDelete();
                                }}
                                isInactive={selectedTransactionRows < 1 || hasControlActivity}
                            />{" "}
                            {selectedChip === "PENDING" && hasControlActivity && (
                                <div>
                                    <Hint
                                        defaultValue={configUtils.get("frontend.mobileSignatureControl.tooltip", "")}
                                        classNameGroup="d-flex align-items-center w-100 mb-2"
                                        classNameHintP="m-0 ms-2"
                                        alwaysShowMessage
                                    />
                                </div>
                            )}
                        </Box>
                        {selectedChip === "PENDING" && (
                            <Box mt={24}>
                                <Switch
                                    label={i18n.get(`${FORM_ID}.pending.only.dispatch`)}
                                    onChange={() => {
                                        handleChangeOnlyPending();
                                    }}
                                    isChecked={switchOn}
                                />
                            </Box>
                        )}
                    </Box>
                    {selectedChip === "DRAFT" && (
                        <Text variant="captionBold">{`${i18n.get(`${FORM_ID}.drafts.selected`)} ${
                            selectedTransactionRows.length
                        }`}</Text>
                    )}
                </Box>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    fetching: transferSelectors.isFetchingMore(state),
    filters: transferSelectors.getFilters(state),
    selectedTransactionRows: transactionsSelector.getSelectedTransactionRows(state),
    transactions: transferSelectors.getTransfers(state),
    hasChanged: transferSelectors.getHasFilterChanged(state),
    selectedChip: transferSelectors.getSelectedChip(state),
    pendingQuantity: transferSelectors.getPendingQuantity(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    showFilters: transferSelectors.getShowFilters(state),
    filtersHistoric: transferSelectors.getFiltersHistoric(state),
    filtersPending: transferSelectors.getFiltersPending(state),
    filtersScheduled: transferSelectors.getFiltersScheduled(state),
    filtersDraft: transferSelectors.getFiltersDraft(state),
    controlActivity: transferSelectors.getControlActivity(state),
    controlARS: transferSelectors.getControlARS(state),
    controlUSD: transferSelectors.getControlUSD(state),
    onlyMyPendings: transferSelectors.getOnlyMyPendings(state),
});
export default connect(mapStateToProps)(TransferHistoricFilters);
