import * as API from "middleware/api";
/* eslint import/prefer-default-export: 0 */

export const loadListFinanRequest = (filters) => API.executeWithAccessToken("/list.financing", { ...filters });

export const getFinancingDetailMiddleware = (numOperacion, financingList, stateFeed, filters) =>
    API.executeWithAccessToken("/list.financingDetail", { numOperacion, financingList, stateFeed, ...filters });
export const downloadListRequest = (
    format,
    financing,
    title,
    dateFrom,
    dateTo,
    currency,
    registersByPage,
    categoryCode,
) =>
    API.executeWithAccessToken("/financing.exportList", {
        format,
        financing,
        title,
        dateFrom,
        dateTo,
        currency,
        registersByPage,
        categoryCode,
    });
export const downloadListFeedRequest = (
    format,
    financingFeed,
    financingDetail,
    lastDateFromFilter,
    lastDateToFilter,
    optionSelected,
) =>
    API.executeWithAccessToken("/financing.downloadInformation", {
        format,
        financing: financingFeed,
        financingDetail,
        lastDateFromFilter,
        lastDateToFilter,
        optionSelected,
    });
export const downloadFeedRequestMiddleware = (format, financing, financingDetail) =>
    API.executeWithAccessToken("/financing.downloadFeedInformation", { format, financing, financingDetail });
