/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { bool, func, shape, string, number, arrayOf } from "prop-types";

import { selectors as servicePaymentsSelectors } from "reducers/servicePayments";
import { actions as thirdPaymentActions } from "reducers/thirdPayment";

import * as i18nUtils from "util/i18n";
import { exportGenericTicketPdf, getGeneralTicketItem } from "util/transaction";
import * as accountUtils from "util/accounts";

import PageLoading from "pages/_components/PageLoading";
import TransactionTicket from "pages/_components/TransactionTicket";
import ContextMenu from "pages/_components/ContextMenu";
import NewPaymentTransactionData from "./_components/NewPaymentTransactionData";

const FORM_ID = "services.newPayment";

class NewPaymentTicket extends Component {
    static propTypes = {
        summary: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
        }),
        debinDetail: bool.isRequired,
        fetching: bool,
        fetchingAccounts: bool,
        accounts: shape({}),
        history: shape({}).isRequired,
    };

    static defaultProps = {
        loggedUser: null,
        fetching: false,
        fetchingAccounts: false,
        accounts: null,
    };

    state = {
        showDrawerMobile: false,
        fetchingDownload: false,
    };

    componentDidMount() {
        const { summary, dispatch } = this.props;

        if (!summary && localStorage.getItem("flow")) {
            dispatch(push(`${JSON.parse(localStorage.getItem("flow"))}`));
        }
        dispatch(thirdPaymentActions.setNotRememberFormData());
    }

    centerContentMobile = (isDesktop) => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ margin: isDesktop && "-0.5rem 2rem" }}>
                {i18nUtils.get("services.newPayment.summary.title")}
            </h1>
        </div>
    );

    exportPdf = async () => {
        this.setState({ fetchingDownload: true });
        const { summary } = this.props;
        const { summaryInfo } = summary;

        const ticketData = {
            ticketName: i18nUtils.get("services.newPayment.summary.title"),
            ticketBody: [
                getGeneralTicketItem(`negotiate.transaction.date.label`, summary?.submitDateTime, "datefulltime"),
                getGeneralTicketItem(
                    `echeq.transaction.voucher`,
                    summary?.idTransaction.substring(0, 8).toUpperCase(),
                    "string",
                ),
                getGeneralTicketItem(`${FORM_ID}.rubros.label`, summaryInfo?.selectedRubro?.description, "string"),
                getGeneralTicketItem(`${FORM_ID}.entities.label`, summaryInfo?.selectedEntity?.description, "string"),
                getGeneralTicketItem(`${FORM_ID}.cpe.label`, summaryInfo?.selectedCPE, "string"),
                getGeneralTicketItem(`${FORM_ID}.amountToPay`, summaryInfo?.selectedAmountToPay, "amountARS"),
                getGeneralTicketItem(
                    `${FORM_ID}.expiration`,
                    summaryInfo?.selectedConceptExpirationDate,
                    summaryInfo?.selectedEntity?.handleBaseDebts ? "date" : "string",
                ),
                getGeneralTicketItem(`${FORM_ID}.concept`, summaryInfo?.selectedConcept?.description, "string"),
                getGeneralTicketItem(
                    `${FORM_ID}.account.label`,
                    summaryInfo?.selectedDebitAccount &&
                        accountUtils.getIncomingDebinAccountName(summaryInfo.selectedDebitAccount),
                    "string",
                ),
                getGeneralTicketItem(
                    `${FORM_ID}.paymentReference`,
                    summaryInfo?.paymentReference ? summaryInfo?.paymentReference : "",
                    "string",
                ),
            ],
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    buttonsDetail = () => {
        const { dispatch } = this.props;

        return [
            {
                label: "services.newPayment.confirmationTicket.goToDesktop",
                bsStyle: "outline",
                onClick: () => dispatch(push(`/desktop`)),
            },
            {
                label: "services.newPayment.confirmationTicket.goToPayments",
                bsStyle: "primary",
                onClick: () => dispatch(push(`/servicePayments`)),
            },
        ];
    };

    rightContentTransactionScheme = (isFinished) => {
        const { isDesktop, summary } = this.props;

        if (isDesktop || isFinished) {
            return <></>;
        }

        return (
            <>
                {summary?.idTransactionStatus !== "CANCELLED" ||
                    (summary?.idTransactionStatus !== "FAILED" && (
                        <ContextMenu
                            isDesktop={isDesktop}
                            buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                            items={[
                                {
                                    label: "pendingTransaction.info.scheme",
                                    onClick: () => this.setState({ showDrawerMobile: true }),
                                },
                            ]}
                        />
                    ))}
            </>
        );
    };

    render() {
        const { summary, isDesktop, isSmallDesktop, fetching } = this.props;
        const { showDrawerMobile, fetchingDownload } = this.state;
        const isFinished = summary?.idTransactionStatus === "FINISHED";

        return (
            <>
                {summary && (
                    <TransactionTicket
                        notificationScope="forms"
                        handleClick={this.exportPdf}
                        handleClickMessage="global.download"
                        uniqueDownload={isFinished}
                        rightContent={() => this.rightContentTransactionScheme(isFinished)}
                        downloadImageWhite={!isDesktop}
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        hasInlineButtons
                        hideMobileMenu={!isDesktop}
                        summary={summary}
                        fetching={fetching}
                        generateSignFunction
                        ignoreHomeButton
                        buttonsDetail={this.buttonsDetail()}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                        summaryTitle="services.newPayment.summary.title"
                        showDrawerMobile={showDrawerMobile}
                        closeMobileDrawer={() => this.setState({ showDrawerMobile: false })}
                        isFetchingExport={fetchingDownload}>
                        <NewPaymentTransactionData {...this.props} FORM_ID={FORM_ID} />
                    </TransactionTicket>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    summary: servicePaymentsSelectors.getSummary(state),
    fetching: servicePaymentsSelectors.getFetching(state),
});

export default connect(mapStateToProps)(NewPaymentTicket);
