import React, { Component } from "react";
import { bool, shape, arrayOf, func, number } from "prop-types";
import { connect } from "react-redux";

import { selectors as ecomexSelectors, actions as ecomexActions } from "reducers/ecomex";

import Table from "pages/_components/Table";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Col from "react-bootstrap/lib/Col";
import FormattedAmount from "pages/_components/FormattedAmount";
import FilterTableHead from "pages/transactions/FilterTableHead";
import PaginationWithPageSelector from "pages/_components/pagination/PaginationWithPageSelector";
import Heading from "pages/_components/Heading";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "pages/_components/Spinner";

const FORM_ID = "ecomex.consultOperations.list";

class EcomexOperationsList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        ecomexOperations: arrayOf(shape({})).isRequired,
        filter: shape({}).isRequired,
        pageNumber: number.isRequired,
        numberPerPageOptions: arrayOf(shape({})).isRequired,
        numberPerPage: number.isRequired,
        totalPages: number.isRequired,
        filtersData: shape({}).isRequired,
    };

    getOperationDetail = (operation) => {
        const { dispatch } = this.props;

        dispatch(ecomexActions.getOperationDetail(operation));
    };

    filterChange = (filterName, filterType) => {
        const { dispatch, ecomexOperations } = this.props;

        const filter = {
            filterSelected: filterName,
            directionFilter: filterType,
        };

        dispatch(ecomexActions.filterChange(ecomexOperations, filter));
    };

    paginateEcomexList = (pageNumber) => {
        const { dispatch, filtersData, numberPerPage } = this.props;
        const {
            optionSelected,
            ecomexState,
            operationType,
            beneficiaryName,
            operationNumber,
            openDateFrom,
            openDateTo,
            dueDateFrom,
            dueDateTo,
        } = filtersData;

        dispatch(
            ecomexActions.listEcomexOperations(
                pageNumber,
                numberPerPage,
                optionSelected,
                ecomexState,
                operationType,
                beneficiaryName,
                operationNumber,
                openDateFrom,
                openDateTo,
                dueDateFrom,
                dueDateTo,
            ),
        );
    };

    paginateEcomexListMobile = () => {
        const { dispatch, filtersData, pageNumber, numberPerPage } = this.props;
        const {
            optionSelected,
            ecomexState,
            operationType,
            beneficiaryName,
            operationNumber,
            openDateFrom,
            openDateTo,
            dueDateFrom,
            dueDateTo,
        } = filtersData;

        dispatch(
            ecomexActions.moreListEcomexOperations(
                pageNumber + 1,
                numberPerPage,
                optionSelected,
                ecomexState,
                operationType,
                beneficiaryName,
                operationNumber,
                openDateFrom,
                openDateTo,
                dueDateFrom,
                dueDateTo,
            ),
        );
    };

    changeNumberPerPage = (numberPerPage) => {
        const { dispatch, filtersData } = this.props;
        const {
            optionSelected,
            ecomexState,
            operationType,
            beneficiaryName,
            operationNumber,
            openDateFrom,
            openDateTo,
            dueDateFrom,
            dueDateTo,
        } = filtersData;

        dispatch(
            ecomexActions.listEcomexOperations(
                1,
                numberPerPage,
                optionSelected,
                ecomexState,
                operationType,
                beneficiaryName,
                operationNumber,
                openDateFrom,
                openDateTo,
                dueDateFrom,
                dueDateTo,
            ),
        );
    };

    renderListEcomexOperations = () => {
        const {
            isDesktop,
            ecomexOperations,
            filter,
            pageNumber,
            numberPerPageOptions,
            numberPerPage,
            totalPages,
        } = this.props;

        if (!(ecomexOperations && ecomexOperations.length > 0)) {
            return (
                <div className="text-center no-more-data">
                    <div className="illustration-wrapper">
                        <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                    </div>
                    <p className="text-lead">
                        <I18n id={`${FORM_ID}.empty.message`} />
                    </p>
                </div>
            );
        }

        const numberPerPageOptionsToShow = numberPerPageOptions.map((numberPerPageOption) => ({
            value: numberPerPageOption,
            label: numberPerPageOption,
        }));

        const list = ecomexOperations.map((item) => this.renderListEcomexOperationsItem(item));

        if (isDesktop) {
            return (
                <>
                    <Container className="flex-grow scrollable">
                        <Col md={12} className="with-table-header-margin px-0">
                            <Table>
                                <Table.Header className="container-white ecomex-table-header">
                                    <Table.HeaderData align="left">
                                        <div className="flex-table">
                                            <div className="text-align-left">
                                                <I18n id={`${FORM_ID}.column.ecomexState`} />
                                            </div>
                                            <FilterTableHead
                                                filterName="ecomexState"
                                                columnFilter={filter}
                                                changeFilter={this.filterChange}
                                            />
                                        </div>
                                    </Table.HeaderData>
                                    <Table.HeaderData>
                                        <div className="flex-table justify-content-center">
                                            <div className="text-align-center justify-content-center min-width-75">
                                                <I18n id={`${FORM_ID}.column.openDate`} />
                                            </div>
                                            <FilterTableHead
                                                filterName="fechaOrigen"
                                                columnFilter={filter}
                                                changeFilter={this.filterChange}
                                            />
                                        </div>
                                    </Table.HeaderData>
                                    <Table.HeaderData>
                                        <div className="flex-table justify-content-center">
                                            <div className="text-align-center justify-content-center min-width-75">
                                                <I18n id={`${FORM_ID}.column.dueDate`} />
                                            </div>
                                            <FilterTableHead
                                                filterName="fechaVencimiento"
                                                columnFilter={filter}
                                                changeFilter={this.filterChange}
                                            />
                                        </div>
                                    </Table.HeaderData>
                                    <Table.HeaderData align="left">
                                        <div className="flex-table justify-content-left">
                                            <div className="text-align-left">
                                                <I18n id={`${FORM_ID}.column.beneficiaryName`} />
                                            </div>
                                            <FilterTableHead
                                                filterName="nombreBeneficiario"
                                                columnFilter={filter}
                                                changeFilter={this.filterChange}
                                            />
                                        </div>
                                    </Table.HeaderData>
                                    <Table.HeaderData align="center">
                                        <div className="flex-table justify-content-center">
                                            <div className="text-align-right justify-content-center min-width-75">
                                                <I18n id={`${FORM_ID}.column.currency`} />
                                            </div>
                                            <FilterTableHead
                                                filterName="siglaDivisa"
                                                columnFilter={filter}
                                                changeFilter={this.filterChange}
                                            />
                                        </div>
                                    </Table.HeaderData>
                                    <Table.HeaderData align="right">
                                        <div className="flex-table justify-content-right">
                                            <div className="text-align-right justify-content-center min-width-75">
                                                <I18n id={`${FORM_ID}.column.quantity`} />
                                            </div>
                                            <FilterTableHead
                                                filterName="importe"
                                                columnFilter={filter}
                                                changeFilter={this.filterChange}
                                            />
                                        </div>
                                    </Table.HeaderData>
                                    <Table.HeaderData align="right" className="pr-25">
                                        <div className="flex-table justify-content-right">
                                            <div className="text-align-right justify-content-center min-width-75">
                                                <I18n id={`${FORM_ID}.column.amount`} />
                                            </div>
                                            <FilterTableHead
                                                filterName="saldo"
                                                columnFilter={filter}
                                                changeFilter={this.filterChange}
                                            />
                                        </div>
                                    </Table.HeaderData>
                                </Table.Header>

                                <Table.Body>{list}</Table.Body>
                            </Table>
                        </Col>
                    </Container>

                    {totalPages > 0 && (
                        <PaginationWithPageSelector
                            totalPages={totalPages}
                            pageNumber={pageNumber}
                            action={this.paginateEcomexList}
                            showFirstAndLastButton
                            numberPerPageOptionsToShow={numberPerPageOptionsToShow}
                            changeNumberPerPage={this.changeNumberPerPage}
                            className="ecomex-pagination"
                            selectedValue={numberPerPage}
                        />
                    )}
                </>
            );
        }

        return (
            <Container className="flex-grow scrollable mt-6">
                <Col md={12} className="with-table-header-margin px-0">
                    <InfiniteScroll
                        dataLength={list.length}
                        next={this.paginateEcomexListMobile}
                        hasMore={totalPages !== pageNumber}
                        loader={<Spinner />}>
                        {list}
                    </InfiniteScroll>
                </Col>
            </Container>
        );
    };

    renderListEcomexOperationsItem = (item) => {
        const { isDesktop } = this.props;
        const {
            siglaOperacion,
            sucursalOperacion,
            numeroOperacion,
            fechaOrigen,
            fechaVencimiento,
            nombreBeneficiario,
            siglaDivisa,
            importe,
            saldo,
        } = item;

        if (isDesktop) {
            return (
                <Table.Row className="container-white cursor-pointer" onClick={() => this.getOperationDetail(item)}>
                    <Table.Data align="left">
                        <div className="data-text light-font">{`${siglaOperacion} ${sucursalOperacion} ${numeroOperacion}`}</div>
                    </Table.Data>
                    <Table.Data align="center">
                        <div className="data-text light-font">{fechaOrigen}</div>
                    </Table.Data>
                    <Table.Data align="center">
                        <div className="data-text light-font">{fechaVencimiento}</div>
                    </Table.Data>
                    <Table.Data align="left">
                        <div className="data-text light-font">{nombreBeneficiario}</div>
                    </Table.Data>
                    <Table.Data align="center" className="pl-3">
                        <div className="data-text light-font">{siglaDivisa}</div>
                    </Table.Data>
                    <Table.Data align="right" className="pr-3">
                        <FormattedAmount noCurrency className="data-amount light-font" quantity={importe} notBold />
                    </Table.Data>
                    <Table.Data align="right" className="pr-4">
                        <FormattedAmount noCurrency className="data-amount light-font" quantity={saldo} notBold />
                    </Table.Data>
                </Table.Row>
            );
        }

        return (
            <div
                className="account-table-wrapper account-table-header cursor-pointer py-2 my-2"
                onClick={() => this.getOperationDetail(item)}
                onKeyDown={() => this.getOperationDetail(item)}
                role="button"
                tabIndex={0}>
                <Col sm={6}>
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex space-between"
                        dataClassName="data-desc small-font"
                        label={`${FORM_ID}.column.ecomexState`}
                        data={`${siglaOperacion} ${sucursalOperacion} ${numeroOperacion}`}
                    />
                </Col>
                <Col sm={6}>
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex space-between"
                        dataClassName="data-desc small-font"
                        label={`${FORM_ID}.column.openDate`}
                        data={fechaOrigen}
                    />
                </Col>
                <Col sm={6}>
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex space-between"
                        dataClassName="data-desc small-font"
                        label={`${FORM_ID}.column.dueDate`}
                        data={fechaVencimiento}
                    />
                </Col>
                <Col sm={6}>
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.beneficiaryName`}
                        data={nombreBeneficiario}
                    />
                </Col>
                <Col sm={6}>
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex space-between"
                        dataClassName="data-desc small-font"
                        label={`${FORM_ID}.column.currency`}
                        data={siglaDivisa}
                    />
                </Col>
                <Col sm={6}>
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex space-between"
                        dataClassName="data-desc small-font"
                        label={`${FORM_ID}.column.quantity`}
                        data={<FormattedAmount noCurrency className="data-amount" quantity={importe} notBold />}
                    />
                </Col>
                <Col sm={6}>
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex space-between"
                        dataClassName="data-desc small-font"
                        label={`${FORM_ID}.column.amount`}
                        data={<FormattedAmount noCurrency className="data-amount" quantity={saldo} notBold />}
                    />
                </Col>
            </div>
        );
    };

    render() {
        return <>{this.renderListEcomexOperations()}</>;
    }
}

const mapStateToProps = (state) => ({
    ecomexOperations: ecomexSelectors.getEcomexOperations(state),
    filter: ecomexSelectors.getFilter(state),
    pageNumber: ecomexSelectors.getPageNumber(state),
    numberPerPageOptions: ecomexSelectors.getNumberPerPageOptions(state),
    numberPerPage: ecomexSelectors.getNumberPerPage(state),
    totalPages: ecomexSelectors.getTotalPages(state),
    filtersData: ecomexSelectors.getFiltersData(state),
});

export default connect(mapStateToProps)(EcomexOperationsList);
