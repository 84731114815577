export const types = {
    LOAD_LIST_REQUEST: "financing/LOAD_LIST_REQUEST",
    LOAD_LIST_FAILURE: "financing/LOAD_LIST_FAILURE",
    LOAD_LIST_SUCCESS: "financing/LOAD_LIST_SUCCESS",
    LOAD_CATEGORY: "financing/LOAD_CATEGORY",
    GET_FINANCING_DETAIL_REQUEST: "financing/GET_FINANCING_DETAIL_REQUEST",
    GET_FINANCING_DETAIL_SUCCESS: "financing/GET_FINANCING_DETAIL_SUCCESS",
    GET_FINANCING_DETAIL_FAILURE: "financing/GET_FINANCING_DETAIL_FAILURE",
    CLEAR_DATA: "financing/CLEAR_DATA",
    DOWNLOAD_LIST_REQUEST: "deposits/DOWNLOAD_LIST_REQUEST",
    DOWNLOAD_LIST_FAILURE: "deposits/DOWNLOAD_LIST_FAILURE",
    DOWNLOAD_LIST_SUCCESS: "deposits/DOWNLOAD_LIST_SUCCESS",
    DOWNLOAD_LISTFEED_REQUEST: "deposits/DOWNLOAD_LISTFEED_REQUEST",
    DOWNLOAD_LISTFEED_FAILURE: "deposits/DOWNLOAD_LISTFEED_FAILURE",
    DOWNLOAD_LISTFEED_SUCCESS: "deposits/DOWNLOAD_LISTFEED_SUCCESS",
    DOWNLOAD_FEED_REQUEST: "deposits/DOWNLOAD_FEED_REQUEST",
    DOWNLOAD_FEED_FAILURE: "deposits/DOWNLOAD_FEED_FAILURE",
    DOWNLOAD_FEED_SUCCESS: "deposits/DOWNLOAD_FEED_SUCCESS",
    SET_OPTION_SELECTED: "financing/DOWNLOAD_FEED_SUCCESS",
};

export const INITIAL_STATE = {
    fetching: false,
    financingList: [],
    dateFrom: null,
    dateTo: null,
    categoryCode: null,
    currency: null,
    financingDetail: null,
    financingFeed: null,
    fetchingDownload: false,
    optionSelected: "toPay",
    lastDateFromFilter: "",
    lastDateToFilter: "",
    pageNumber: 1,
    totalPages: 1,
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        filters = {},
        financingList,
        cat,
        financingDetail,
        financingFeed,
        optionSelected,
        lastDateFromFilter,
        lastDateToFilter,
        pageNumber,
        totalPages,
    } = action;
    switch (action.type) {
        case types.CLEAR_DATA:
            return {
                ...state,
                ...INITIAL_STATE,
            };

        case types.LOAD_LIST_REQUEST:
            return {
                ...state,
                ...filters,
                fetching: true,
            };
        case types.LOAD_LIST_SUCCESS:
            return {
                ...state,
                financingList: financingList?.recuperarClienteFinanciacionDetalleEnt,
                totalPages,
                pageNumber,
                fetching: false,
            };
        case types.LOAD_LIST_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LOAD_CATEGORY:
            return {
                ...state,
                category: cat,
            };
        case types.GET_FINANCING_DETAIL_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.GET_FINANCING_DETAIL_SUCCESS:
            return {
                ...state,
                financingDetail,
                financingFeed,
                lastDateFromFilter,
                lastDateToFilter,
                fetching: false,
            };
        case types.GET_FINANCING_DETAIL_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.DOWNLOAD_LIST_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_LIST_FAILURE:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_LIST_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_LISTFEED_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_LISTFEED_FAILURE:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_LISTFEED_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_FEED_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_FEED_FAILURE:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_FEED_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };

        case types.SET_OPTION_SELECTED:
            return {
                ...state,
                optionSelected,
            };

        default:
            return state;
    }
};
export const actions = {
    clearData: () => ({
        type: types.CLEAR_DATA,
    }),
    loadListRequest: (filters) => ({
        type: types.LOAD_LIST_REQUEST,
        filters,
    }),
    loadCategorySelected: (cat) => ({
        type: types.LOAD_CATEGORY,
        cat,
    }),
    getFinancingDetailReques: (numOperacion, financingList, stateFeed, filters) => ({
        type: types.GET_FINANCING_DETAIL_REQUEST,
        numOperacion,
        financingList,
        stateFeed,
        filters,
    }),
    downloadList: (format, financing, title, dateFrom, dateTo, currency, registersByPage, categoryCode) => ({
        type: types.DOWNLOAD_LIST_REQUEST,
        format,
        financing,
        title,
        dateFrom,
        dateTo,
        currency,
        registersByPage,
        categoryCode,
    }),
    downloadListFeed: (
        format,
        financingFeed,
        financingDetail,
        lastDateFromFilter,
        lastDateToFilter,
        optionSelected,
    ) => ({
        type: types.DOWNLOAD_LISTFEED_REQUEST,
        format,
        financingFeed,
        financingDetail,
        lastDateFromFilter,
        lastDateToFilter,
        optionSelected,
    }),
    downloadFeed: (format, financingFeed, financingDetail) => ({
        type: types.DOWNLOAD_FEED_REQUEST,
        format,
        financingFeed,
        financingDetail,
    }),
    setOptionSelected: (optionSelected) => ({
        type: types.SET_OPTION_SELECTED,
        optionSelected,
    }),
};

export const selectors = {
    getFetching: ({ financing }) => financing.fetching,
    getFinancing: ({ financing }) => financing.financingList,
    getCategory: ({ financing }) => financing.category,
    getFinancingDetail: ({ financing }) => financing.financingDetail,
    getFinancingFeed: ({ financing }) => financing.financingFeed,
    getLastDateFromFilter: ({ financing }) => financing.lastDateFromFilter,
    getLastDateToFilter: ({ financing }) => financing.lastDateToFilter,
    getFetchingDownload: ({ financing }) => financing.fetchingDownload,
    getOptionSelected: ({ financing }) => financing.optionSelected,
    getTotalPages: ({ financing }) => financing.totalPages,
    getPageNumber: ({ financing }) => financing.pageNumber,
};
