import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { bool, func, number, shape } from "prop-types";
import { Field, Form, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";
import Yup from "yup";
import moment from "moment";

import { actions as paymentsAFIPActions, selectors as paymentsAFIPSelectors } from "reducers/paymentsAFIP";
import { selectors as sessionSelectors } from "reducers/session";

import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import Pagination from "pages/_components/pagination/Pagination";
import PageLoading from "pages/_components/PageLoading";
import Image from "pages/_components/Image";
import ContextMenu from "pages/_components/ContextMenu";
import Table from "pages/_components/Table";

import Selector from "pages/_components/fields/formik/Selector";
import TextField from "pages/_components/fields/TextField";
import { DateField } from "pages/_components/fields/DateField";

import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";

import * as i18nUtils from "util/i18n";
import Heading from "pages/_components/Heading";

import classNames from "classnames";

const FORM_ID = "payments.afip.list";

class PaymentAFIPList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        fetching: bool.isRequired,
        isFetchingPaymentsAFIP: bool.isRequired,
        values: shape({}),
        paymentsAFIP: shape([]).isRequired,
        pageNumber: number.isRequired,
        totalPages: number.isRequired,
        enabledContributors: shape([]).isRequired,
        activeEnvironment: shape({}).isRequired,
        setFieldValue: func.isRequired,
    };

    static defaultProps = {
        values: {},
    };

    state = {
        selectedDateFrom: null,
        showForm: true,
    };

    componentDidMount() {
        const { dispatch, setFieldValue } = this.props;

        setFieldValue("paymentType", "stillToPay");
        setFieldValue("generatedVEP", "");
        setFieldValue("taxpayerCUIT", "");
        setFieldValue("numberVEP", "");
        dispatch(paymentsAFIPActions.listEnabledContributors());
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(paymentsAFIPActions.cleanFormState());
    }

    getPaymentsAFIPNextPage = (pageNumber) => {
        const {
            dispatch,
            values: { paymentType, generatedVEP, numberVEP, taxpayerCUIT, dateFrom, dateTo },
        } = this.props;

        let dateFromFinal = dateFrom;
        let dateToFinal = dateTo;

        if (dateFrom && !dateTo) {
            dateToFinal = moment();
        }

        if (dateTo && !dateFrom) {
            dateFromFinal = dateTo;
        }

        dispatch(
            paymentsAFIPActions.fetchPaymentsAFIP(
                paymentType,
                generatedVEP,
                numberVEP,
                taxpayerCUIT,
                dateFromFinal,
                dateToFinal,
                pageNumber,
            ),
        );
    };

    renderPaymentsAFIP = () => {
        const { dispatch, paymentsAFIP, isDesktop, activeEnvironment } = this.props;

        const list = paymentsAFIP.map((paymentAFIP) => {
            const { numberVEP, conceptDesc, description, dueDate, amount, type, token } = paymentAFIP;

            const contextMenuItems = [];

            if (
                type === "PE" &&
                activeEnvironment.permissions.accounts &&
                activeEnvironment.permissions.afipVepPaymentsManage &&
                token
            ) {
                contextMenuItems.push({
                    label: "payments.afip.list.actions.pay",
                    onClick: () => {
                        dispatch(paymentsAFIPActions.cleanHandleBackFromTicket());
                        dispatch(paymentsAFIPActions.payPaymentAFIPPre(paymentAFIP));
                    },
                });
            }

            if (type === "PE" && activeEnvironment.permissions.afipVepDeleteManage && token) {
                contextMenuItems.push({
                    label: "payments.afip.list.actions.delete",
                    onClick: () => {
                        dispatch(paymentsAFIPActions.deletePaymentAFIPPreFromMain(paymentAFIP));
                    },
                });
            }

            const readPaymentAFIP = () => dispatch(paymentsAFIPActions.readPaymentAFIP(paymentAFIP));

            if (isDesktop) {
                return (
                    <Table.Row className="container-white">
                        <Table.Data onClick={readPaymentAFIP} className="cursor-pointer">
                            <div className="data-text color-dark-grey font-light">{Number(numberVEP)}</div>
                        </Table.Data>
                        <Table.Data onClick={readPaymentAFIP} className="cursor-pointer">
                            <div className="data-text color-dark-grey font-light">{conceptDesc}</div>
                        </Table.Data>
                        <Table.Data onClick={readPaymentAFIP} className="cursor-pointer">
                            <div className="data-text color-dark-grey font-light">{description}</div>
                        </Table.Data>
                        <Table.Data onClick={readPaymentAFIP} className="cursor-pointer">
                            <FormattedDate
                                className="color-dark-grey font-light"
                                date={dueDate}
                                dateFormat="dd/MM/yyyy"
                            />
                        </Table.Data>
                        <Table.Data onClick={readPaymentAFIP} className="cursor-pointer">
                            <FormattedAmount
                                currency={amount.currency}
                                className="data-amount color-dark-grey font-light"
                                quantity={amount.quantity}
                                notBold
                            />
                        </Table.Data>
                        <Table.Data className="pr-0">
                            {contextMenuItems.length > 0 && (
                                <ContextMenu
                                    styleContext={{ display: "flex", justifyContent: "center" }}
                                    items={contextMenuItems}
                                />
                            )}
                        </Table.Data>
                    </Table.Row>
                );
            }
            return (
                <div
                    className={classNames(
                        "account-table-wrapper account-table-header my-3 py-3 d-flex justify-content-center",
                        { "pl-3": contextMenuItems.length > 0 },
                        { "px-3": !contextMenuItems.length > 0 },
                    )}>
                    <div
                        onClick={readPaymentAFIP}
                        className={`${type === "PE" ? "col-xs-11 px-0" : "col-xs-12 px-0"}`}
                        onKeyDown={readPaymentAFIP}
                        role="button"
                        tabIndex={0}>
                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex space-between container-details"
                            label={`${FORM_ID}.numberVEP`}
                            data={Number(numberVEP)}
                        />

                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex space-between container-details"
                            label={`${FORM_ID}.concept`}
                            data={conceptDesc}
                        />

                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex space-between container-details"
                            label={`${FORM_ID}.description`}
                            data={description}
                        />

                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex space-between container-details"
                            label={`${FORM_ID}.dueDate`}
                            data={<FormattedDate date={dueDate} dateFormat="dd/MM/yyyy" />}
                        />

                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex space-between container-details"
                            label={`${FORM_ID}.amount`}
                            data={
                                <FormattedAmount
                                    currency={amount.currency}
                                    className="data-amount"
                                    quantity={amount.quantity}
                                    notBold
                                />
                            }
                        />
                    </div>
                    {contextMenuItems.length > 0 && (
                        <Table.Data aling="left" className="p-0 pt-15 justify-content-start">
                            <ContextMenu dropBtnClass="with-svg-dropdown" items={contextMenuItems} />
                        </Table.Data>
                    )}
                </div>
            );
        });

        return (
            <>
                {list.length > 0 && (
                    <Container className="flex-grow">
                        <Col md={12} className={`with-table-header-margin px-0 ${!isDesktop && "table-vep-mobile"}`}>
                            <Table>
                                {isDesktop && (
                                    <Table.Header className="container-white">
                                        <Table.HeaderData className="border-bottom-none">
                                            <I18n id={`${FORM_ID}.numberVEP`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData className="border-bottom-none">
                                            <I18n id={`${FORM_ID}.concept`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData className="border-bottom-none">
                                            <I18n id={`${FORM_ID}.description`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData className="border-bottom-none">
                                            <I18n id={`${FORM_ID}.dueDate`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData align="right" className="border-bottom-none">
                                            <I18n id={`${FORM_ID}.amount`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData className="pr-0 border-bottom-none" />
                                    </Table.Header>
                                )}
                                <Table.Body>{list}</Table.Body>
                            </Table>
                        </Col>
                    </Container>
                )}
            </>
        );
    };

    handleChangeDateFrom = (selectedDate) => {
        this.setState({ selectedDateFrom: selectedDate });
    };

    hangleChangePaymentType = () => {
        const { setFieldValue } = this.props;
        setFieldValue("generatedVEP", "");
        setFieldValue("taxpayerCUIT", "");
        setFieldValue("numberVEP", "");
    };

    hangleChangeGeneratedVEP = () => {
        const { setFieldValue } = this.props;
        setFieldValue("taxpayerCUIT", "");
        setFieldValue("numberVEP", "");
    };

    render() {
        const {
            fetching,
            isFetchingPaymentsAFIP,
            paymentsAFIP,
            pageNumber,
            totalPages,
            values,
            enabledContributors,
            isDesktop,
        } = this.props;
        const { showForm } = this.state;

        const { paymentType, generatedVEP, dateFrom, dateTo } = values;
        const { selectedDateFrom } = this.state;

        const paymentsTypes = [
            {
                id: "stillToPay",
                label: i18nUtils.get(`${FORM_ID}.paymentsType.stillToPay`),
            },
            {
                id: "paymentsMade",
                label: i18nUtils.get(`${FORM_ID}.paymentsType.paymentsMade`),
            },
        ];

        const generatedVEPTypes = [
            {
                value: "1",
                label: i18nUtils.get(`${FORM_ID}.generatedVEP.options.owns`),
            },
            {
                value: "2",
                label: i18nUtils.get(`${FORM_ID}.generatedVEP.options.third`),
            },
            {
                value: "3",
                label: i18nUtils.get(`${FORM_ID}.generatedVEP.options.taxpayer`),
            },
        ];

        const taxpayerCUITs = enabledContributors?.map((enabledContributor) => ({
            value: enabledContributor.cuit,
            label: enabledContributor.cuit,
        }));

        return (
            <>
                {!isDesktop && (
                    <Col xs={12} className="d-flex justify-content-end">
                        <Button
                            block
                            className="btn-link p-0 right ml-0 min-height-auto"
                            label={showForm ? "echeq.button.hideFilter" : "echeq.button.seeFilter"}
                            onClick={() => this.setState((prev) => ({ ...prev, showForm: !prev.showForm }))}
                        />
                    </Col>
                )}

                <PageLoading
                    className={classNames({ "line-loader": isDesktop })}
                    loading={fetching || isFetchingPaymentsAFIP}>
                    {(isDesktop || showForm) && (
                        <div className="pagosvep mb-1 mt-2">
                            <Form autoComplete="off">
                                <Container
                                    className={`align-items-left account-header-align-items-left ${isDesktop &&
                                        "account-header-detail"}`}
                                    gridClassName="form-content form-pay-afip px-3"
                                    rowClassName="justify-content-left pb-3 filters">
                                    <div className="pagosvep__checkbox">
                                        <Field
                                            component={Selector}
                                            options={paymentsTypes}
                                            idForm={FORM_ID}
                                            idField="paymentType"
                                            name="paymentType"
                                            inLineControl
                                            isRequired
                                            renderAs="radio"
                                            hideLabel
                                            defaultValue={paymentType}
                                            onCustomChange={this.hangleChangePaymentType}
                                            customOnChange
                                        />
                                    </div>
                                    <div
                                        className={classNames("pagosvep__filters", {
                                            tercero: paymentType === "paymentsMade" && generatedVEP === "2",
                                            contribuyente: paymentType === "paymentsMade" && generatedVEP === "3",
                                        })}>
                                        <Container
                                            className="w-100"
                                            gridClassName="px-0"
                                            rowClassName={classNames("justify-content-left w-100", {
                                                "mx-0": !isDesktop,
                                            })}>
                                            <Col className={isDesktop ? "px-1" : "px-3"}>
                                                <Field
                                                    component={Selector}
                                                    options={generatedVEPTypes}
                                                    idForm={FORM_ID}
                                                    name="generatedVEP"
                                                    isRequired
                                                    placeholder={i18nUtils.get(`${FORM_ID}.generatedVEP.placeholder`)}
                                                    onCustomChange={this.hangleChangeGeneratedVEP}
                                                    customOnChange
                                                />
                                            </Col>
                                            {generatedVEP && generatedVEP === "3" && (
                                                <>
                                                    <Col className={`${isDesktop ? "px-1" : "px-3"}`}>
                                                        <Field
                                                            component={Selector}
                                                            options={taxpayerCUITs}
                                                            idForm={FORM_ID}
                                                            name="taxpayerCUIT"
                                                            isRequired
                                                            placeholder={i18nUtils.get(
                                                                `${FORM_ID}.taxpayerCUIT.placeholder`,
                                                            )}
                                                        />
                                                    </Col>
                                                    <Col className={`${isDesktop ? "px-1" : "px-3"}`}>
                                                        <Field
                                                            component={TextField}
                                                            hidePlaceholder
                                                            idForm={FORM_ID}
                                                            name="numberVEP"
                                                            type="text"
                                                            label={`${FORM_ID}.numberVEP`}
                                                            pattern="[0-9]*"
                                                            maxLength={12}
                                                        />
                                                    </Col>
                                                </>
                                            )}

                                            {generatedVEP && generatedVEP === "2" && (
                                                <Col className={`${isDesktop ? "px-1" : "px-3"}`}>
                                                    <Field
                                                        component={TextField}
                                                        hidePlaceholder
                                                        idForm={FORM_ID}
                                                        name="thirdPartyCuit"
                                                        type="text"
                                                        label={`${FORM_ID}.thirdPartyCuit`}
                                                        pattern="[0-9]*"
                                                        maxLength={11}
                                                    />
                                                </Col>
                                            )}

                                            {isDesktop && (
                                                <>
                                                    {paymentType === "paymentsMade" && (
                                                        <Col className="px-1">
                                                            <Field
                                                                idField="dateFrom"
                                                                component={DateField}
                                                                idForm={FORM_ID}
                                                                name="dateFrom"
                                                                hidePlaceholder
                                                                handleChange={this.handleChangeDateFrom}
                                                                maxDate={dateTo}
                                                            />
                                                        </Col>
                                                    )}

                                                    {paymentType === "paymentsMade" && (
                                                        <Col className="px-1">
                                                            <Field
                                                                idField="dateTo"
                                                                component={DateField}
                                                                hidePlaceholder
                                                                idForm={FORM_ID}
                                                                name="dateTo"
                                                                minDate={selectedDateFrom}
                                                            />
                                                        </Col>
                                                    )}
                                                </>
                                            )}

                                            {!isDesktop && (
                                                <div className="flex-table px-3 space-between w-100">
                                                    {paymentType === "paymentsMade" && (
                                                        <>
                                                            <Col sm={6} className="pl-0 pr-1">
                                                                <I18n
                                                                    component="p"
                                                                    id={`${FORM_ID}.payday`}
                                                                    componentProps={{ className: "my-0" }}
                                                                />
                                                                <Field
                                                                    idField="dateFromMobile"
                                                                    component={DateField}
                                                                    idForm={FORM_ID}
                                                                    name="dateFromMobile"
                                                                    hidePlaceholder
                                                                    startDate={dateFrom}
                                                                    handleChange={this.handleChangeDateFrom}
                                                                    endDate={dateTo}
                                                                />
                                                            </Col>
                                                            <Col
                                                                sm={6}
                                                                className="pl-1 pr-0"
                                                                style={{ maxWidth: "44vw" }}>
                                                                <I18n
                                                                    component="p"
                                                                    id={`${FORM_ID}.payday`}
                                                                    componentProps={{ className: "invisible my-0" }}
                                                                />
                                                                <Field
                                                                    idField="dateToMobile"
                                                                    component={DateField}
                                                                    hidePlaceholder
                                                                    idForm={FORM_ID}
                                                                    name="dateToMobile"
                                                                    minDate={selectedDateFrom}
                                                                />
                                                            </Col>
                                                        </>
                                                    )}
                                                </div>
                                            )}

                                            <Col className={isDesktop ? " px-1" : " px-3"}>
                                                <div className="form-group mt-4">
                                                    <Button
                                                        bsStyle="primary"
                                                        label={`${FORM_ID}.btn.filter.label`}
                                                        loading={isFetchingPaymentsAFIP}
                                                        type="submit"
                                                        className="my-1"
                                                    />
                                                </div>
                                            </Col>
                                        </Container>
                                    </div>
                                </Container>
                            </Form>
                        </div>
                    )}

                    <div>
                        {paymentsAFIP && this.renderPaymentsAFIP()}
                        {paymentsAFIP && paymentsAFIP.length > 0 && (
                            <div className="d-flex w-100 justify-content-end">
                                <Pagination
                                    totalPages={totalPages}
                                    pageNumber={pageNumber}
                                    action={this.getPaymentsAFIPNextPage}
                                />
                            </div>
                        )}
                        {(!paymentsAFIP || !paymentsAFIP.length > 0) && !isFetchingPaymentsAFIP && (
                            <div
                                className="text-center no-more-data px-3 d-flex f-dir-col justify-content-center h-60vh"
                                key="noMoreMovements">
                                <div className="illustration-wrapper mx-auto my-0">
                                    <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                                </div>
                                <p className="text-lead">
                                    <I18n id={`${FORM_ID}.empty.message`} />
                                </p>
                            </div>
                        )}
                    </div>
                </PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: paymentsAFIPSelectors.getFetching(state),
    paymentsAFIP: paymentsAFIPSelectors.getPaymentsAFIP(state),
    pageNumber: paymentsAFIPSelectors.getPageNumber(state),
    totalPages: paymentsAFIPSelectors.getTotalPages(state),
    isFetchingPaymentsAFIP: paymentsAFIPSelectors.getFetchingPaymentsAFIP(state),
    enabledContributors: paymentsAFIPSelectors.getEnabledContributors(state),
    prevFormState: paymentsAFIPSelectors.getPrevFormState(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            paymentType: props.prevFormState.paymentType || "stillToPay",
            generatedVEP: props.prevFormState.generatedVEP || "",
            taxpayerCUIT: props.prevFormState.taxpayerCUIT || "",
            numberVEP: props.prevFormState.numberVEP || "",
            thirdPartyCuit: props.prevFormState.thirdPartyCuit || "",
            dateFrom: props.isDesktop ? null : moment().subtract(6, "months"),
            dateTo: props.isDesktop ? null : moment(new Date()),
        }),
        validationSchema: () =>
            Yup.lazy((values) => {
                const { generatedVEP } = values;

                let validations = {
                    paymentType: Yup.string().required(i18nUtils.get(`${FORM_ID}.paymentType.error.required`)),
                    generatedVEP: Yup.string().required(i18nUtils.get(`${FORM_ID}.generatedVEP.error.required`)),
                };

                if (generatedVEP === "2") {
                    validations = {
                        ...validations,
                        thirdPartyCuit: Yup.string().required(
                            i18nUtils.get(`${FORM_ID}.thirdPartyCuit.error.required`),
                        ),
                    };
                }

                if (generatedVEP === "3") {
                    validations = {
                        ...validations,
                        numberVEP: Yup.string().required(i18nUtils.get(`${FORM_ID}.numberVEP.error.required`)),
                        taxpayerCUIT: Yup.string().required(i18nUtils.get(`${FORM_ID}.taxpayerCUIT.error.required`)),
                    };
                }

                return Yup.object().shape({
                    ...validations,
                });
            }),
        handleSubmit: (values, formikBag) => {
            const { dispatch } = formikBag.props;
            const {
                paymentType,
                generatedVEP,
                numberVEP,
                taxpayerCUIT,
                dateFrom,
                dateTo,
                thirdPartyCuit,
                dateFromMobile,
                dateToMobile,
            } = values;
            const dateFromMerged = dateFromMobile || dateFrom;
            const dateToMerged = dateToMobile || dateTo;

            let dateFromFinal = paymentType === "stillToPay" ? null : dateFromMerged;
            let dateToFinal = paymentType === "stillToPay" ? null : dateToMerged;

            if (dateFromMerged && !dateToMerged) {
                dateToFinal = moment();
            }

            if (dateToMerged && !dateFromMerged) {
                dateFromFinal = dateToMerged;
            }

            dispatch(paymentsAFIPActions.saveFormState(values));
            dispatch(
                paymentsAFIPActions.fetchPaymentsAFIP(
                    paymentType,
                    generatedVEP,
                    numberVEP,
                    taxpayerCUIT,
                    dateFromFinal,
                    dateToFinal,
                    "1",
                    thirdPartyCuit,
                ),
            );
        },
    }),
)(PaymentAFIPList);
