/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { selectors, actions } from "reducers/checks";
import Col from "react-bootstrap/lib/Col";
import * as configUtils from "util/config";
import { connect } from "react-redux";
import { arrayOf, string, shape, func, bool } from "prop-types";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import FormattedDate from "pages/_components/FormattedDate";
import * as i18n from "util/i18n";
import InfoTag from "pages/_components/InfoTag";
import { statusTagColor, echeqShape } from "util/checks";
import { Link } from "react-router-dom";
import EmittedContextMenu from "pages/checks/Echeqs/EmittedContextMenu";
import Image from "pages/_components/Image";
import Spinner from "pages/_components/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

const FORM_ID = "echeq";

class ReceivedEcheqs extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        setSubmitting: func.isRequired,
        option: string.isRequired,
        echeqsList: arrayOf(echeqShape).isRequired,
        environmentCuit: string.isRequired,
        filters: shape({
            createdFrom: string.isRequired,
            createdTo: string.isRequired,
            payedFrom: string.isRequired,
            payedTo: string.isRequired,
            payedCheck: bool.isRequired,
            emitedCheck: bool.isRequired,
            receiver: string.isRequired,
            emitted: string.isRequired,
            status: shape({ value: string.isRequired, label: string.isRequired }).isRequired,
            number: string.isRequired,
            grouper: string.isRequired,
            id: string.isRequired,
            orderColumName: string.isRequired,
            orderDirection: string.isRequired,
        }).isRequired,
        isDesktop: bool.isRequired,
        hasMoreData: bool.isRequired,
        fetchNextTransactions: func.isRequired,
        orderByData: func.isRequired,
    };

    componentDidMount() {
        const { filters, orderByData } = this.props;
        orderByData(filters.orderColumName, filters.orderDirection, this.changeFilter);
    }

    changeFilter = (filterName, filterDirecion) => {
        const { filters, dispatch, option, setSubmitting, isDesktop } = this.props;

        const filtersEcheq = {
            ...filters,
            option,
            status: filters.status,
            orderColumName: filterName,
            orderDirection: filterDirecion,
            pageNumber: 1,
        };
        dispatch(actions.setEcheqFilters(filtersEcheq));
        setSubmitting(true);
        dispatch(actions.filterEcheqList(filtersEcheq, setSubmitting, isDesktop));
    };

    cardsEmittedEcheqs = () => {
        const { echeqsList, isDesktop, environmentCuit } = this.props;

        const listSorted = echeqsList;

        return listSorted.map((object) => {
            const beneficiaryDocument = object.beneficiario_documento_tipo
                .toUpperCase()
                .concat(" ", object.beneficiario_documento);
            const beneficiaryDocumentNumber = beneficiaryDocument.replace(/\D/g, "");

            return (
                <CardContainer
                    key={object.cheque_numero}
                    renderAs={Link}
                    to={`/echeqs/emitted/detail/${object.cheque_id}`}>
                    <Card
                        title={
                            <div className="echeqs__cards-title">
                                <div className="d-flex echeqs__emmit-info">
                                    <I18n
                                        id="echeqs.emitedCheck.label"
                                        componentProps={{ className: "echeqs__emittedDate" }}
                                    />
                                    <FormattedDate date={object.fecha_emision} />
                                </div>
                                <div className="d-flex">
                                    <I18n
                                        id={`${FORM_ID}.paymentDate`}
                                        componentProps={{ className: "echeqs__paymentDate" }}
                                    />
                                    <FormattedDate date={object.fecha_pago} />
                                </div>
                            </div>
                        }
                        content={
                            <div className="echeqs__cards-content">
                                <span className="echeqs__echeqNumber">{object.cheque_numero}</span>
                                {object.tenencia_beneficiario_documento === environmentCuit && (
                                    <Image src="images/ownershipEcheqs.svg" />
                                )}
                            </div>
                        }
                        bottom={
                            <div className="echeqs__cards-bottom">
                                <div className="echeqs__beneficiary">
                                    <I18n
                                        id="echeq.beneficiary"
                                        componentProps={{ className: "echeqs__beneficiary-title" }}
                                    />
                                    <span>{object.beneficiario_nombre.replaceAll("#", "Ñ")}</span>
                                </div>
                                <I18n
                                    id={`${FORM_ID}.received.from.document`}
                                    componentProps={{ className: "echeqs__beneficiary-document" }}
                                />
                                <span className="echeqs__beneficiary-document-number">{beneficiaryDocumentNumber}</span>
                            </div>
                        }
                        rightContent={
                            <div className="echeqs__cards-rightContent">
                                <div className="echeqs__cards-right-info">
                                    <div className="echeqs__cards-amount">
                                        <FormattedAmount
                                            currency={i18n.get("currency.label.".concat(object.emisor_moneda))}
                                            quantity={object.monto}
                                        />
                                    </div>
                                    <InfoTag
                                        type="info"
                                        message={object.estado}
                                        tagBackground={statusTagColor(object.estado)}
                                        moreStyles={{
                                            alignSelf: "flex-end",
                                            width: "fit-content",
                                            color: "black",
                                            margin: "auto",
                                            marginRight: 0,
                                        }}
                                    />
                                </div>
                                <div className="echeqs__context-menu-container">
                                    <button
                                        type="button"
                                        className="btn btn-link p-0 m-0"
                                        onClick={(e) => e.stopPropagation()}>
                                        <EmittedContextMenu echeq={object} isDesktop={isDesktop} />
                                    </button>
                                </div>
                            </div>
                        }
                    />
                </CardContainer>
            );
        });
    };

    echeqsListMobile = (echeq) => {
        const {
            cheque_numero: cheqNro,
            estado,
            monto,
            beneficiario_nombre: benefNombre,
            beneficiario_documento_tipo: typeDoc,
            beneficiario_documento: doc,
            emisor_moneda: emisorMoneda,
            fecha_pago: fechaPago,
            fecha_emision: fechaEmision,
            cheque_id: cheqId,
        } = echeq;

        const { isDesktop } = this.props;

        return (
            <CardContainer renderAs={Link} to={`/echeqs/emitted/detail/${cheqId}`} className="px-3">
                <Card
                    hrClass="ml-2"
                    title={
                        <div className="d-flex w-100 space-between echeqs__cards-title">
                            <div className="d-flex w-100">
                                <I18n id="echeq.number" componentProps={{ className: "ml-2 echeq__info" }} />
                                <span className="echeq__data">{cheqNro}</span>
                            </div>
                            <button type="button" className="btn btn-link p-0 m-0" onClick={(e) => e.stopPropagation()}>
                                <EmittedContextMenu echeq={echeq} isDesktop={isDesktop} />
                            </button>
                        </div>
                    }
                    content={
                        <div>
                            <hr className="ml-2" />
                            <div className="echeqs__cards-content">
                                <div className="d-flex space-between w-100 pb-1 align-items-center">
                                    <I18n id="echeqs.status.label" componentProps={{ className: "ml-2 echeq__info" }} />
                                    <InfoTag
                                        type="info"
                                        message={estado}
                                        tagBackground={statusTagColor(estado)}
                                        tagClass="d-flex px-2 ml-2 my-0 mr-0"
                                        moreStyles={{
                                            color: "black",
                                        }}
                                    />
                                </div>
                                <div className="d-flex space-between w-100">
                                    <I18n
                                        id="echeqs.emitedCheck.label"
                                        componentProps={{ className: "ml-2 echeq__info" }}
                                    />
                                    <FormattedDate date={fechaEmision} />
                                </div>
                                <div className="d-flex space-between w-100">
                                    <I18n
                                        id="echeqs.payedCheck.label"
                                        componentProps={{ className: "ml-2 echeq__info" }}
                                    />
                                    <FormattedDate showTime={false} date={fechaPago} />
                                </div>
                                <div className="d-flex space-between w-100 pt-1">
                                    <I18n id="echeq.beneficiary" componentProps={{ className: "ml-2 echeq__info" }} />
                                    <span className="echeq__data">{benefNombre.replaceAll("#", "Ñ")}</span>
                                </div>
                                <div className="d-flex space-between w-100 pt-1">
                                    <I18n
                                        id="echeqs.document.label"
                                        componentProps={{ className: "ml-2 echeq__info" }}
                                    />
                                    <span className="echeq__data">{`${typeDoc.toUpperCase()} ${doc}`}</span>
                                </div>
                            </div>
                        </div>
                    }
                    rightContent={
                        <div className="echeqs__cards-rightContent">
                            <FormattedAmount
                                currency={i18n.get("currency.label.".concat(emisorMoneda))}
                                quantity={monto}
                                notBold={isDesktop}
                                className="echeq__data"
                            />
                        </div>
                    }
                />
            </CardContainer>
        );
    };

    render() {
        const { echeqsList, isDesktop, hasMoreData, fetchNextTransactions } = this.props;
        const endOfListItem = (
            <div className="table-row table-end mb-5" key="noMoreTransactions">
                <I18n id="echeqs.noMoreEcheqs" />
            </div>
        );

        const enableNewPagination = configUtils.getBoolean("frontend.show.nuevoComponentePaginado.enabled", false);

        if (!isDesktop) {
            return (
                <>
                    <InfiniteScroll
                        dataLength={echeqsList.length}
                        next={fetchNextTransactions}
                        hasMore={hasMoreData}
                        loader={<Spinner />}
                        endMessage={endOfListItem}>
                        {echeqsList.map((echeq) => this.echeqsListMobile(echeq))}
                    </InfiniteScroll>
                </>
            );
        }

        return (
            <Container className="flex-grow" rowClassName={`${!enableNewPagination && "mheight-table-echeq"}`}>
                <Col xs={12} className="px-0">
                    <div
                        style={{ borderSpacing: "0px 2px", width: "100%", minHeight: "520px" }}
                        ref={(tableRef) => {
                            this.child = tableRef;
                        }}>
                        <Container className="flex-grow align-items-center" gridClassName="scrolleable form-content">
                            {this.cardsEmittedEcheqs()}
                        </Container>
                    </div>
                </Col>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    echeqsList: selectors.getEcheqsList(state),
    environmentCuit: selectors.getEnvironmentCuit(state),
    hasMoreData: selectors.getHasMoreData(state),
});

export default connect(mapStateToProps)(ReceivedEcheqs);
