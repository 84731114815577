import * as accountsMiddleware from "middleware/accounts";
import * as form from "middleware/form";
import * as transactionsMiddleware from "middleware/transactions";
import {
    cancelScheduledTransaction,
    downloadTransfersHistoric,
    getCbuCvuFromAliasActivity,
    loadAccountLimitsActivities,
    loadPreActivity,
    sendExternalTadTransaction,
    sendExternalTransaction,
    sendInternalTadTransaction,
    sendInternalTransaction,
    sendSchedulerEmail,
    sendTransferToContactTadTransaction,
    sendTransferToContactTransaction,
    validateExternalTransaction,
    validateInternalTransaction,
    validateTransferToContact,
} from "middleware/transfer";
import { push } from "react-router-redux";
import { actions as formActions } from "reducers/form";
import { actions as frequentDestinationActions } from "reducers/frequentDestination";
import { actions as notificationActions } from "reducers/notification";
import { actions as transactionsActions } from "reducers/transactions";
import { actions as transferActions, types } from "reducers/transfer";
import { delay } from "redux-saga";
import { call, put, take, takeLatest } from "redux-saga/effects";
import * as configUtils from "util/config";
import { downloadPdf, downloadXls } from "util/download";
import * as i18n from "util/i18n";
import { INSUFFICIENT_SIGNATURES, INTERNAL_ERROR, SCHEDULED_TRANSACTION } from "util/responses";
import { exportGenericTicketPdfWithSuccessMessage, getGeneralTicketItem } from "util/transaction";

const sagas = [
    takeLatest(types.SEND_INTERNAL_TRANSFER_REQUEST, sendInternalTransfer),
    takeLatest(types.SEND_EXTERNAL_TRANSFER_REQUEST, sendExternalTransfer),
    takeLatest(types.SEND_TRANSFER_TO_CONTACT_REQUEST, sendTransferToContact),
    takeLatest(types.CREATE_INTERNAL_TRANSFER_REQUEST, validateInternalTransfer),
    takeLatest(types.CREATE_EXTERNAL_TRANSFER_REQUEST, validateExternalTransfer),
    takeLatest(types.CREATE_TRANSFER_TO_CONTACT_REQUEST, validateToContactTransfer),
    takeLatest(types.GET_ACCOUNT_INFO, getAccountInfo),
    takeLatest(types.TRANSFER_PRE_ACTIVITY_REQUEST, transferPreActivity),
    takeLatest(types.CANCEL_SCHEDULED_TRANSACTION_REQUEST, cancelScheduled),
    takeLatest(types.ACCOUNT_LIMITS_REQUEST, accountLimitsActivities),
    takeLatest(types.SEND_SCHEDULER_EMAIL_REQUEST, sendSchedulerEmailActivity),
    takeLatest(types.GET_CBU_CVU_INFO, getCbuCvuFromAlias),
    takeLatest(types.LOAD_HISTORIC_LIST_REQUEST, loadHistoricListRequest),
    takeLatest(types.LOAD_MORE_TRANSFERS_REQUEST, fetchMoreTransfers),
    takeLatest(types.LOAD_PENDING_QUANTITY_REQUEST, loadPendingQuantityRequest),
    takeLatest(types.FREQUENT_DESTINATION_TRANSFER_CREATE_REQUEST, frequentDestinationCreateRequest),
    takeLatest(types.READ_CANCEL_SCHEDULED, readCancelScheduled),
    takeLatest(types.READ_DOWNLOAD_TRANSACTION, readDownloadTransaction),
    takeLatest(types.DOWNLOAD_TRANSFERS_HISTORIC, downloadTransfers),
];

export default sagas;

function* transferPreActivity() {
    const response = yield call(loadPreActivity);

    if (response.type === "W") {
        yield put({ type: types.TRANSFER_PRE_ACTIVITY_FAILURE });
    } else {
        yield put({
            type: types.TRANSFER_PRE_ACTIVITY_SUCCESS,
            concepts: response.data.data.concepts,
            accounts: response.data.data.accounts,
            serverDate: response.data.data.serverDate,
        });
    }
}

function* cancelScheduled({
    idTransaction,
    otp,
    creditAccount,
    cbu,
    debitAccount,
    amount,
    currency,
    formikBag,
    clientUser,
}) {
    const response = yield call(
        cancelScheduledTransaction,
        idTransaction,
        otp,
        creditAccount,
        cbu,
        debitAccount,
        amount,
        currency,
        clientUser,
    );

    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            formikBag.setErrors(response.data.data);
        } else {
            yield put(
                notificationActions.showNotification(response.data.message, "error", ["transactionScheduledCancel"]),
            );
        }
        yield put({ type: types.CANCEL_SCHEDULED_TRANSACTION_FAILURE });
    } else {
        yield put(
            formActions.readTransaction({
                pathname: `/transaction/${response.data.data?.idTransactionToCancel || response.data.idTransaction}`,
                newTransaction: true,
            }),
        );

        const { data } = response;
        const { code } = data;

        if (code === SCHEDULED_TRANSACTION) {
            yield put(
                notificationActions.showNotification(i18n.get("scheduler.cancel.success"), "success", [
                    "transactionScheduled",
                ]),
            );
        } else if (code !== INSUFFICIENT_SIGNATURES && idTransaction !== null) {
            yield put(
                notificationActions.showNotification(i18n.get("scheduler.cancel.success"), "success", [
                    "pendingTransfer",
                ]),
            );
        }

        yield put({
            type: types.CANCEL_SCHEDULED_TRANSACTION_SUCCESS,
            idTransaction,
        });
    }
}

function* sendInternalTransfer({ summary, otp }) {
    const { formikBag, tadTrx } = summary;

    try {
        let response = null;
        if (tadTrx) {
            response = yield call(sendInternalTadTransaction, summary, otp);
        } else {
            response = yield call(sendInternalTransaction, summary, otp);
        }
        if (response.type === "W" && response.data.code !== INTERNAL_ERROR) {
            if (response.data.code === "COR020W") {
                formikBag.setErrors(response.data.data);
            } else {
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["form"], null, !tadTrx),
                );
            }
            yield put({ type: types.SEND_INTERNAL_TRANSFER_FAILURE });
        } else {
            const { data } = response;
            const { code } = data;
            if (code !== INTERNAL_ERROR && code !== INSUFFICIENT_SIGNATURES) {
                if (code === SCHEDULED_TRANSACTION) {
                    const TDAhourFrom = configUtils.get("frontend.TAD.workingHours.hourFrom", "10:00:00");
                    const TDAhourTo = configUtils.get("frontend.TAD.workingHours.hourTo", "17:00:00");
                    yield put(
                        notificationActions.showNotification(
                            i18n.get(
                                !tadTrx
                                    ? "transfers.schedule.success.message"
                                    : "transfer.TAD.warning.message.scheduled",
                                null,
                                {
                                    DATE_FROM: TDAhourFrom.substring(0, 5),
                                    DATE_TO: TDAhourTo.substring(0, 5),
                                },
                            ),
                            !tadTrx ? "success" : "warning",
                            ["transactionScheduled"],
                        ),
                    );
                } else if (summary.idTransaction) {
                    yield put(
                        notificationActions.showNotification(i18n.get("transfers.modify.success.message"), "success", [
                            "transferTransaction",
                            "pendingTransfer",
                        ]),
                    );
                }
            }

            yield put({
                type: types.SEND_INTERNAL_TRANSFER_PENDING,
                idTransaction: response.data.idTransaction,
            });

            const scheduled = summary.scheduler && Object.keys(summary.scheduler).length > 1;
            if ((code === SCHEDULED_TRANSACTION || summary.idTransaction !== null) && scheduled) {
                const {
                    userId,
                    summary: { clientUser, creditAccount },
                    envName,
                } = formikBag.props;
                yield put({
                    type: types.SEND_SCHEDULER_EMAIL_REQUEST,
                    summary: {
                        ...summary,
                        idActivity: "transfers.internal.send",
                        userId,
                        formikBag,
                        internalTransfer: true,
                        _originName: clientUser?.firstName || envName || "",
                        _originDocument: `${clientUser?.cuil.startsWith("3") ? "CUIT" : "CUIL"} ${clientUser?.cuil}`,
                        _creditAccountLabel: `**${creditAccount?.shortLabel}`,
                    },
                });
            }

            yield put(
                formActions.readTransaction({
                    pathname: `/transaction/${response.data.idTransaction}`,
                    newTransaction: true,
                }),
            );
            yield take("READ_TRANSACTION_REQUEST");
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* getAccountInfo({ account, accountType, isTransfer }) {
    const response = yield call(accountsMiddleware.getAccountInfo, account, isTransfer);
    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["form"]));
    } else if (accountType === "debit") {
        yield put({
            type: types.SET_DEBIT_ACCOUNT_INFO,
            accountInfo: response.data.data.account,
        });
    } else {
        yield put({
            type: types.SET_CREDIT_ACCOUNT_INFO,
            accountInfo: response.data.data.account,
        });
    }
}

function* sendExternalTransfer({ summary, otp }) {
    const { formikBag, tadTrx } = summary;
    const {
        summary: { creditAccountDetail },
    } = formikBag.props;

    // eslint-disable-next-line no-param-reassign
    summary = {
        ...summary,
        _bankName: creditAccountDetail.bankName,
    };

    try {
        let response = null;
        if (tadTrx) {
            response = yield call(sendExternalTadTransaction, summary, otp);
        } else {
            response = yield call(sendExternalTransaction, summary, otp);
        }
        if (response.type === "W" && response.data.code !== INTERNAL_ERROR) {
            if (response.data.code === "COR020W") {
                formikBag.setErrors(response.data.data);
            } else {
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["form"], null, !tadTrx),
                );
            }
            yield put({ type: types.SEND_EXTERNAL_TRANSFER_FAILURE });
        } else {
            const { data } = response;
            const { code } = data;
            if (code !== INTERNAL_ERROR && code !== INSUFFICIENT_SIGNATURES) {
                if (code === SCHEDULED_TRANSACTION) {
                    const TDAhourFrom = configUtils.get("frontend.TAD.workingHours.hourFrom", "10:00:00");
                    const TDAhourTo = configUtils.get("frontend.TAD.workingHours.hourTo", "17:00:00");
                    yield put(
                        notificationActions.showNotification(
                            i18n.get(
                                !tadTrx
                                    ? "transfers.schedule.success.message"
                                    : "transfer.TAD.warning.message.scheduled",
                                null,
                                {
                                    DATE_FROM: TDAhourFrom.substring(0, 5),
                                    DATE_TO: TDAhourTo.substring(0, 5),
                                },
                            ),
                            !tadTrx ? "success" : "warning",
                            ["transactionScheduled"],
                        ),
                    );
                } else if (summary.idTransaction) {
                    yield put(
                        notificationActions.showNotification(i18n.get("transfers.modify.success.message"), "success", [
                            "transferTransaction",
                            "pendingTransfer",
                        ]),
                    );
                }
            }

            yield put(transactionsActions.isFirstTransactionCBURequest(summary.cbu));

            yield put({
                type: types.SEND_EXTERNAL_TRANSFER_PENDING,
                summary: { idTransaction: response.data.idTransaction },
            });
            const scheduled = summary.scheduler && Object.keys(summary.scheduler).length > 1;
            if ((code === SCHEDULED_TRANSACTION || summary.idTransaction !== null) && scheduled) {
                const {
                    userId,
                    summary: { clientUser },
                    envName,
                    envDocument,
                } = formikBag.props;
                yield put({
                    type: types.SEND_SCHEDULER_EMAIL_REQUEST,
                    summary: {
                        ...summary,
                        idActivity: "transfers.thirdParties.send",
                        userId,
                        formikBag,
                        internalTransfer: false,
                        _originName: envName || "",
                        _originDocument: `${envDocument.startsWith("3") ? "CUIT" : "CUIL"} ${envDocument}`,
                        _beneficitName: clientUser?.firstName || "",
                        _beneficitDocument: `${clientUser?.cuil.startsWith("3") ? "CUIT" : "CUIL"} ${clientUser?.cuil}`,
                    },
                });
            }

            yield put(
                formActions.readTransaction({
                    pathname: `/transaction/${response.data.idTransaction}`,
                    newTransaction: true,
                }),
            );
            yield take("READ_TRANSACTION_REQUEST");
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* sendTransferToContact({ summary, otp }) {
    const { formikBag, tadTrx } = summary;
    const {
        summary: { creditAccountDetail },
    } = formikBag.props;

    // eslint-disable-next-line no-param-reassign
    summary = {
        ...summary,
        _bankName: creditAccountDetail.bankName,
    };

    try {
        let response = null;
        if (tadTrx) {
            response = yield call(sendTransferToContactTadTransaction, summary, otp);
        } else {
            response = yield call(sendTransferToContactTransaction, summary, otp);
        }

        if (response.type === "W" && response.data.code !== INTERNAL_ERROR) {
            if (response.data.code === "COR020W") {
                formikBag.setErrors(response.data.data);
            } else {
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["form"], null, !tadTrx),
                );
            }
            yield put({ type: types.SEND_TRANSFER_TO_CONTACT_FAILURE });
        } else {
            const { data } = response;
            const { code } = data;

            if (code !== INTERNAL_ERROR && code !== INSUFFICIENT_SIGNATURES) {
                if (code === SCHEDULED_TRANSACTION) {
                    const TDAhourFrom = configUtils.get("frontend.TAD.workingHours.hourFrom", "10:00:00");
                    const TDAhourTo = configUtils.get("frontend.TAD.workingHours.hourTo", "17:00:00");
                    yield put(
                        notificationActions.showNotification(
                            i18n.get(
                                !tadTrx
                                    ? "transfers.schedule.success.message"
                                    : "transfer.TAD.warning.message.scheduled",
                                null,
                                {
                                    DATE_FROM: TDAhourFrom.substring(0, 5),
                                    DATE_TO: TDAhourTo.substring(0, 5),
                                },
                            ),
                            !tadTrx ? "success" : "warning",
                            ["transactionScheduled"],
                        ),
                    );
                } else if (summary.idTransaction) {
                    yield put(
                        notificationActions.showNotification(i18n.get("transfers.modify.success.message"), "success", [
                            "transferTransaction",
                            "pendingTransfer",
                        ]),
                    );
                }
            }

            yield put(transactionsActions.isFirstTransactionCBURequest(summary.cbu));

            yield put({
                type: types.SEND_TRANSFER_TO_CONTACT_PENDING,
                summary: { idTransaction: response.data.idTransaction },
            });
            const scheduled = summary.scheduler && Object.keys(summary.scheduler).length > 1;
            if ((code === SCHEDULED_TRANSACTION || summary.idTransaction !== null) && scheduled) {
                const {
                    userId,
                    summary: { clientUser },
                    envName,
                    envDocument,
                } = formikBag.props;
                yield put({
                    type: types.SEND_SCHEDULER_EMAIL_REQUEST,
                    summary: {
                        ...summary,
                        idActivity: "transfers.thirdParties.send",
                        userId,
                        formikBag,
                        internalTransfer: false,
                        _originName: envName || "",
                        _originDocument: `${envDocument.startsWith("3") ? "CUIT" : "CUIL"} ${envDocument}`,
                        _beneficitName: clientUser?.firstName || "",
                        _beneficitDocument: `${clientUser?.cuil.startsWith("3") ? "CUIT" : "CUIL"} ${clientUser?.cuil}`,
                    },
                });
            }

            yield put(
                formActions.readTransaction({
                    pathname: `/transaction/${response.data.idTransaction}`,
                    newTransaction: true,
                }),
            );
            yield take("READ_TRANSACTION_REQUEST");
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* validateToContactTransfer({ summary }) {
    const {
        accounts,
        debitAccount,
        cbu,
        alias,
        currency,
        amount,
        reference,
        sendEmail,
        email,
        emailText,
        concept,
        scheduler,
        transferKind,
        tadTrx,
    } = summary;

    const response = yield call(
        validateTransferToContact,
        accounts,
        debitAccount,
        cbu,
        alias,
        currency,
        amount,
        reference,
        sendEmail,
        email,
        emailText,
        concept,
        scheduler,
        transferKind,
        tadTrx,
    );
    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            if (response.data.data.message) {
                yield put(
                    notificationActions.showNotification(response.data.data.message, "error", ["form"], null, !tadTrx),
                );
            } else {
                yield put(
                    notificationActions.showNotification(response.data.message, "error", ["form"], null, !tadTrx),
                );
            }
            yield put({ type: types.CREATE_TRANSFER_TO_CONTACT_FAILURE });
        }
    } else {
        const { accountDetail, clientUser, pspEntityName, control } = response.data.data;

        yield put(push(`/transfer/summary`));
        yield call(delay, 300);
        yield put({
            type: types.CREATE_TRANSFER_TO_CONTACT_SUCCESS,
            creditAccountDetail: accountDetail,
            pspEntityName,
            clientUser,
            isCVU: !!accountDetail?.cbu?.startsWith("000"),
            tadTrx,
            control,
        });
    }
}

function* validateExternalTransfer({ summary, formikBag }) {
    const {
        accounts,
        debitAccount,
        cbu,
        alias,
        currency,
        amount,
        reference,
        sendEmail,
        email,
        emailText,
        concept,
        scheduler,
        transferKind,
        tadTrx,
    } = summary;

    const response = yield call(
        validateExternalTransaction,
        accounts,
        debitAccount,
        cbu,
        alias,
        currency,
        amount,
        reference,
        sendEmail,
        email,
        emailText,
        concept,
        scheduler,
        transferKind,
        tadTrx,
    );
    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            if (response.data.data.message) {
                yield put(
                    notificationActions.showNotification(response.data.data.message, "error", ["form"], null, !tadTrx),
                );
            } else {
                formikBag.setErrors(response.data.data);
            }
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", ["form"], null, !tadTrx));
        }
        yield put({ type: types.CREATE_EXTERNAL_TRANSFER_FAILURE });
    } else {
        const { accountDetail, clientUser, pspEntityName, control } = response.data.data;

        yield put(push(`/transfer/summary`));
        yield call(delay, 300);
        yield put({
            type: types.CREATE_EXTERNAL_TRANSFER_SUCCESS,
            creditAccountDetail: accountDetail,
            pspEntityName,
            clientUser,
            isCVU: !!accountDetail?.cbu?.startsWith("000"),
            tadTrx,
            control,
        });
    }
}

function* validateInternalTransfer({ summary, formikBag }) {
    const {
        debitAccount,
        creditAccount,
        currency,
        amount,
        reference,
        sendEmail,
        email,
        emailText,
        scheduler,
        tadTrx,
    } = summary;

    const response = yield call(
        validateInternalTransaction,
        debitAccount,
        creditAccount,
        currency,
        amount,
        reference,
        sendEmail,
        email,
        emailText,
        scheduler,
        tadTrx,
    );
    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            if (response.data.data.message) {
                yield put(
                    notificationActions.showNotification(response.data.data.message, "error", ["form"], null, !tadTrx),
                );
            } else {
                formikBag.setErrors(response.data.data);
            }
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", ["form"], null, !tadTrx));
        }
        yield put({ type: types.CREATE_INTERNAL_TRANSFER_FAILURE });
    } else {
        const { accountDetail, clientUser } = response.data.data;
        yield put(push(`/transfer/summary`));
        yield call(delay, 300);
        yield put({
            type: types.CREATE_INTERNAL_TRANSFER_SUCCESS,
            creditAccountDetail: accountDetail,
            clientUser,
            tadTrx,
        });
    }
}

function* accountLimitsActivities({ idAccount, idActivity }) {
    const response = yield call(loadAccountLimitsActivities, idAccount, idActivity);

    if (response.type === "W") {
        yield put({ type: types.ACCOUNT_LIMITS_FAILURE });
    } else {
        const { limit, limitUsed } = response.data.data;

        yield put({
            type: types.ACCOUNT_LIMITS_SUCCESS,
            limit,
            limitUsed,
        });
    }
}

function* sendSchedulerEmailActivity({ summary }) {
    const response = yield call(sendSchedulerEmail, summary);

    if (response.type === "W") {
        yield put({ type: types.SEND_SCHEDULER_EMAIL_FAILURE });
    } else {
        yield put({
            type: types.SEND_SCHEDULER_EMAIL_SUCCESS,
        });
    }
}

function* getCbuCvuFromAlias(alias) {
    const response = yield call(getCbuCvuFromAliasActivity, alias);
    const { cbuCvu } = response.data.data;
    const isCVU = cbuCvu?.startsWith("000");
    yield put({
        type: types.SET_IS_CVU,
        fetchingAlias: false,
        isCVU,
        cvuCbuFromAlias: cbuCvu === null || cbuCvu === undefined ? null : cbuCvu,
    });
}

function* loadHistoricListRequest({ filters, onlyPendings, pendingDispatch }) {
    const response = yield call(transactionsMiddleware.loadListRequest, filters, onlyPendings, pendingDispatch);

    if (response.type === "W") {
        yield put(transferActions.loadListFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transfers"]));
    } else {
        const {
            transactions,
            pageNumber,
            totalPages,
            totalRows,
            controlActivity,
            controlARS,
            controlUSD,
        } = response.data.data;
        yield put(
            transferActions.loadListSuccess(
                transactions,
                pageNumber,
                totalPages,
                totalRows,
                controlActivity,
                controlARS,
                controlUSD,
            ),
        );
    }
}
function* loadPendingQuantityRequest({ filters, onlyPendings, pendingDispatch }) {
    const response = yield call(transactionsMiddleware.loadListRequest, filters, onlyPendings, pendingDispatch);

    if (response.type === "W") {
        yield put(transferActions.loadListFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transfers"]));
    } else {
        const { totalRows } = response.data.data;
        yield put(transferActions.loadPendingQuantitySuccess(totalRows));
    }
}

function* fetchMoreTransfers({ filters, onlyPendings, pendingDispatch, filter }) {
    const page = filters.pageNumber;
    const response = yield call(
        transactionsMiddleware.loadListRequest,
        { ...filters, pageNumber: page },
        onlyPendings,
        pendingDispatch,
        filter,
    );

    if (response.type === "W") {
        yield put(transferActions.loadListFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transfers"]));
    } else {
        const { transactions, pageNumber, totalPages, totalRows } = response.data.data;
        yield put(
            transferActions.loadMoreTransfersSuccess(
                transactions,
                pageNumber,
                totalPages === 0 ? filters.totalPages : totalPages,
                totalRows,
            ),
        );
    }
}

function* frequentDestinationCreateRequest({ params }) {
    const { cbu, name, otp, recipientEmail, idEnvironment, scopeToShow } = params;
    const response = yield call(
        transactionsMiddleware.frequentDestinationCreateRequest,
        cbu,
        recipientEmail,
        name,
        otp,
    );

    if (response.type === "W") {
        const { NO_FIELD } = response.data.data;
        const { message } = response.data;
        if (NO_FIELD) {
            yield put(notificationActions.showNotification(NO_FIELD, "error", ["frequentDestinationDrawer"]));
        } else if (message) {
            yield put(notificationActions.showNotification(message, "error", ["frequentDestinationDrawer"]));
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    ["frequentDestinationDrawer"],
                ]),
            );
        }
        yield put({
            type: types.FREQUENT_DESTINATION_TRANSFER_CREATE_FAILED,
        });
    } else {
        yield put(
            notificationActions.showNotification(
                i18n.get("transfers.frequentDestinationModal.create.success"),
                "success",
                [scopeToShow],
            ),
        );
        yield put({
            type: types.FREQUENT_DESTINATION_TRANSFER_CREATE_SUCCESS,
            frequentDestination: response?.data?.data?.frequentDestination,
        });
        yield put(frequentDestinationActions.loadListRequest(idEnvironment, null));
    }
}

function* readCancelScheduled({ idTransaction }) {
    const response = yield call(form.readTransaction, idTransaction);
    const { transaction } = response.data.data;

    const accountInfoResponse = yield call(accountsMiddleware.getAccountInfo, transaction.data?.debitAccount, true);
    const debitAccountInfo = accountInfoResponse.data.data.account;
    const creditAccountInfoResponse = yield call(
        accountsMiddleware.getAccountInfo,
        transaction.data?.creditAccount,
        true,
    );
    const creditAccountInfo = creditAccountInfoResponse.data.data.account;

    const creatorFullName = transaction.userCreatorLastName.concat(" ", transaction.userCreatorFirstName);
    const userEditorFullName = transaction.userEditorLastName.concat(" ", transaction.userEditorFirstName);
    const {
        creationDateTime,
        signatures,
        pendingDispatch,
        idTransactionStatus,
        modificationDateTime,
        idActivity,
    } = transaction;
    yield put(
        transferActions.loadScheduledTransactionCancel({
            ...transaction.data,
            debitAccount: debitAccountInfo,
            creditAccount: creditAccountInfo,
            currency: transaction.data.amount.currency,
            amount: transaction.data.amount.quantity,
            creatorFullName,
            signatures,
            creationDate: creationDateTime,
            idTransaction: transaction.idTransaction,
            idActivity,
            pendingDispatch,
            userEditorFullName,
            idTransactionStatus,
            modificationDateTime,
        }),
    );
    yield put(push("/transfer/scheduled/cancel"));
}

function* readDownloadTransaction({ idTransaction, dispatch, activeEnvironment }) {
    const response = yield call(form.readTransaction, idTransaction);
    const { transaction } = response.data.data;
    const { idTransactionStatus, submitDateTime } = transaction;
    const {
        email,
        sendEmail,
        emailText,
        reference,
        cbu,
        creditAccount,
        voucher,
        debitAccount,
        transferKind,
        creditAccountDetail,
        clientUser,
        pspEntityName,
        amount,
        currency,
        concept,
        scheduler,
        tadTrx,
        idActivity,
    } = transaction.data;

    const accountInfoResponse = yield call(accountsMiddleware.getAccountInfo, debitAccount, true);
    const debitAccountInfo = accountInfoResponse.data.data.account;
    const creditAccountInfoResponse = yield call(accountsMiddleware.getAccountInfo, creditAccount, true);
    const creditAccountInfo = creditAccountInfoResponse.data.data.account;

    let internalTransfer;
    if (idActivity === "transfers.internal.send" || idActivity === "transfers.internal.tad.send") {
        internalTransfer = true;
    } else if (
        idActivity === "transfers.thirdParties.send" ||
        idActivity === "transfers.thirdParties.tad.send" ||
        idActivity === "transfers.toContact.send" ||
        idActivity === "transfers.toContact.tad.send"
    ) {
        internalTransfer = false;
    }

    const getTitle = () => {
        let title = "";

        if (idActivity === "transfers.internal.send" || idActivity === "transfers.internal.tad.send") {
            title = "transfers.summary.internal.label";
        } else if (idActivity === "transfers.thirdParties.send" || idActivity === "transfers.thirdParties.tad.send") {
            title = "transfers.summary.external.label";
        } else if (idActivity === "transfers.toContact.send" || idActivity === "transfers.toContact.tad.send") {
            title = "transfers.summary.toContact.label";
        } else if (idActivity === "scheduled.transactions.cancel") {
            title = "activities.scheduled.transactions.cancel";
        }
        return title;
    };

    // eslint-disable-next-line no-shadow
    const getAccountDescription = ({ number, productType, currency }) => {
        if (!number) {
            return "";
        }
        return `${number} ${productType} ${i18n.get(`currency.label.${currency}`)}`;
    };

    // eslint-disable-next-line no-shadow
    const getCuitDescription = (clientUser) => {
        if (clientUser?.cuil !== undefined) {
            return ` ${i18n.get(`transferSummary.cuil.input.label`)} ${clientUser?.cuil}`;
        }
        return ` ${i18n.get(`transferSummary.cuit.input.label`)} ${clientUser?.cuit}`;
    };

    const generateTicketBody = () => {
        let creditAccountValue;
        if (internalTransfer) {
            creditAccountValue = creditAccountInfo?.number
                ? getAccountDescription(creditAccountInfo)
                : cbu !== undefined && cbu.slice(-14, -1);
        } else {
            creditAccountValue = cbu !== undefined && cbu.slice(-14, -1);
        }

        const isInternalPending = idTransactionStatus === "PENDING" && internalTransfer;

        const transferKindOptions = [
            {
                value: `same`,
                label: i18n.get("transfers.titular.same.label"),
            },
            {
                value: `other`,
                label: i18n.get("transfers.titular.other.label"),
            },
        ];

        const data = [];
        if (idTransactionStatus === "FINISHED") {
            data.push(getGeneralTicketItem(`transferSummary.date.label`, submitDateTime, "datefulltime"));
            data.push(getGeneralTicketItem(`transferConfirmation.idTransaction.label`, voucher || "", "string"));
        } else {
            data.push(
                getGeneralTicketItem(
                    `transferConfirmation.idTransaction.label`,
                    idTransaction.substring(0, 8).toUpperCase() || idTransaction.substring(0, 8).toUpperCase(),
                    "string",
                ),
            );
        }

        data.push(getGeneralTicketItem("transferSummary.payer.label", activeEnvironment?.name, "string"));
        data.push(
            getGeneralTicketItem(
                "transfers.debitAccount.label",
                debitAccountInfo?.number !== undefined && getAccountDescription(debitAccountInfo),
                "string",
            ),
        );
        data.push(getGeneralTicketItem("transfers.creditAccount.label", creditAccountValue || "", "string"));
        if (!internalTransfer) {
            data.push(
                getGeneralTicketItem(
                    "transfers.transferKind.label",
                    transferKindOptions.find(({ value }) => value === transferKind).label,
                    "string",
                ),
            );

            data.push(getGeneralTicketItem("transferSummary.cbuOrCvu.label", creditAccountDetail?.cbu, "string"));
        }

        if (!isInternalPending) {
            if (clientUser?.cuit || clientUser?.cuil) {
                data.push(getGeneralTicketItem("transferSummary.cuit.label", getCuitDescription(clientUser), "string"));
            }

            if (!internalTransfer && creditAccountDetail?.cuit) {
                data.push(
                    getGeneralTicketItem("transferSummary.alias.label", clientUser?.cuit || clientUser?.cuil, "string"),
                );
            }

            if (!internalTransfer) {
                data.push(getGeneralTicketItem("transferSummary.owner.label", clientUser?.firstName || "", "string"));
            }

            if (pspEntityName) {
                data.push(getGeneralTicketItem("transferSummary.entity.label", pspEntityName, "string"));
            }
            if (!pspEntityName && creditAccountDetail?.bankName) {
                data.push(getGeneralTicketItem("transferSummary.bank.label", creditAccountDetail?.bankName, "string"));
            }
        }

        const amountValue = amount?.quantity || amount;
        const currencyValue = currency?.toString() || (amount?.currency ? amount.currency.toString() : "");
        data.push(
            getGeneralTicketItem(
                "transfers.amount.label",
                amountValue,
                `amount${i18n.get(`currency.label.${currencyValue}`)}`,
            ),
        );

        if (!internalTransfer && concept) {
            data.push(
                getGeneralTicketItem(
                    "transfers.concept.label",
                    `${concept} - ${i18n.get(`transfers.concept.${concept}`)}`,
                    "string",
                ),
            );
        }

        if (idTransactionStatus === "SCHEDULED") {
            data.push(getGeneralTicketItem("forms.confirmation.scheduler", scheduler, "string"));
        }

        data.push(getGeneralTicketItem("transfers.reference.label", reference || "", "string"));

        if (email && sendEmail) {
            data.push(getGeneralTicketItem("transfers.email.label", email, "string"));
            if (emailText) {
                data.push(getGeneralTicketItem("transfers.emailText.label", emailText, "string"));
            }
        }

        return data;
    };
    const ticketMessage =
        tadTrx && idTransactionStatus !== "FAILED"
            ? i18n.get(
                  internalTransfer
                      ? "transfer.TAD.summary.internalTransfer"
                      : "transfer.TAD.summary.thirdPartiesTransfer",
              )
            : undefined;

    const ticketData = {
        ticketName: i18n.get(getTitle()),
        ticketBody: [...generateTicketBody()],
        ticketMessage,
    };

    exportGenericTicketPdfWithSuccessMessage(ticketData, dispatch, i18n.get("global.download.success"));
}

function* downloadTransfers({ dateFrom, dateTo, format }) {
    try {
        const { type, data } = yield call(downloadTransfersHistoric, dateFrom, dateTo, format);
        if (type === "W") {
            yield put({ type: types.DOWNLOAD_TRANSFERS_HISTORIC_FAILURE });
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["form"]));
        } else {
            const { content, fileName } = data.data;
            if (format === "pdf") {
                downloadPdf(fileName, content);
            } else {
                downloadXls(fileName, content);
            }

            yield put({ type: types.DOWNLOAD_TRANSFERS_HISTORIC_SUCCESS });
            yield put(
                notificationActions.showNotification(
                    i18n.get("global.download.success"),
                    "success",
                    ["form"],
                    null,
                    false,
                ),
            );
        }
    } catch (error) {
        yield put({ type: types.DOWNLOAD_TRANSFERS_HISTORIC_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["form"]));
    }
}
