import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func, shape } from "prop-types";
import { push } from "react-router-redux";
import PageLoading from "pages/_components/PageLoading";
import TransferTransactionData from "pages/transfer/TransferTransactionData";
import TransactionTicket from "pages/_components/TransactionTicket";
import { selectors as transferSelectors, actions as transferActions } from "reducers/transfer";
import { selectors as sessionSelectors } from "reducers/session";
import { exportGenericTicketPdf, getGeneralTicketItem } from "util/transaction";
import { selectors as frequentDestinationSelectors } from "reducers/frequentDestination";
import * as i18nUtils from "util/i18n";
import getScheduledData from "util/transferScheduled.js";

class TransferScheduled extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        summary: shape({}).isRequired,
        internalTransfer: bool.isRequired,
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        activeEnvironment: shape({}).isRequired,
        scheduledCancel: bool.isRequired,
    };

    state = {
        fetchingDownload: false,
    };

    componentDidMount() {
        const { summary, dispatch } = this.props;
        if (!summary?.idTransaction && localStorage.getItem("flow")) {
            dispatch(push(`${JSON.parse(localStorage.getItem("flow"))}`));
        }
    }

    centerContentMobile = () => {
        const { internalTransfer } = this.props;
        return (
            <div className="title-account-header-multiline-background-blue">
                <h1>
                    {internalTransfer
                        ? i18nUtils.get("transfers.summary.internal.label")
                        : i18nUtils.get("transfers.summary.external.label")}
                </h1>
            </div>
        );
    };

    handlePreCancel = () => {
        const { dispatch } = this.props;
        dispatch(push("/transfer/scheduled/cancel"));
    };

    getAccountDescription = ({ number, productType, currency }) => {
        if (!number) {
            return "";
        }
        return `${number} ${productType} ${i18nUtils.get(`currency.label.${currency}`)}`;
    };

    getCuitDescription = (clientUser) => {
        if (clientUser?.cuil !== undefined) {
            return ` ${i18nUtils.get(`transferSummary.cuil.input.label`)} ${clientUser?.cuil}`;
        }
        return ` ${i18nUtils.get(`transferSummary.cuit.input.label`)} ${clientUser?.cuit}`;
    };

    handleDownload = async () => {
        this.setState({ fetchingDownload: true });

        const { summary, internalTransfer, scheduledCancel, activeEnvironment } = this.props;

        const scheduled = summary?.idTransactionStatus === "SCHEDULED";
        const canceled = summary?.idTransactionStatus === "CANCELED";

        const scheduledIsCancel = scheduledCancel && canceled;

        let ticketBody = [];

        if (!scheduled) {
            ticketBody = [
                ...ticketBody,
                getGeneralTicketItem(`forms.transaction.ticket.date`, summary.creationDate, "datetime"),
            ];
        }

        ticketBody = [
            ...ticketBody,
            getGeneralTicketItem(
                `pay.paymentAFIP.idTransaction`,
                summary?.idTransaction.substring(0, 8).toUpperCase(),
                "string",
            ),
        ];
        if (scheduledIsCancel) {
            ticketBody = [
                ...ticketBody,
                getGeneralTicketItem(
                    `scheduled.transactions.cancel.idTransactionToCancel.label`,
                    summary.idTransactionToCancel,
                    "string",
                ),
            ];
        }

        if (!scheduledIsCancel || scheduled) {
            ticketBody = [
                ...ticketBody,
                getGeneralTicketItem(
                    `transfers.debitAccount.label`,
                    summary.debitAccount?.number !== undefined && this.getAccountDescription(summary.debitAccount),
                    "string",
                ),
                getGeneralTicketItem(`transferSummary.payer.label`, activeEnvironment?.name, "string"),
            ];

            if (internalTransfer) {
                ticketBody = [
                    ...ticketBody,
                    getGeneralTicketItem(
                        `transfers.creditAccount.label`,
                        summary.creditAccount?.number !== undefined &&
                            this.getAccountDescription(summary.creditAccount),
                        "string",
                    ),
                ];
            }

            if (!internalTransfer) {
                ticketBody = [
                    ...ticketBody,
                    getGeneralTicketItem(
                        `transfers.creditAccount.label`,
                        summary.cbu !== undefined && summary.cbu.substring(9),
                        "string",
                    ),
                ];
            }
        }

        if (!internalTransfer) {
            ticketBody = [
                ...ticketBody,
                getGeneralTicketItem(
                    `transfers.transferKind.label`,
                    i18nUtils.get(`transfers.titular.${summary.transferKind}.label`),
                    "string",
                ),
                getGeneralTicketItem(`transferSummary.cbuOrCvu.label`, summary.creditAccountDetail?.cbu, "string"),
            ];
        }

        if (summary.clientUser?.cuit || summary.clientUser?.cuil) {
            ticketBody = [
                ...ticketBody,
                getGeneralTicketItem(
                    `transferSummary.cuit.label`,
                    this.getCuitDescription(summary.clientUser),
                    "string",
                ),
            ];
        }

        if (!internalTransfer && summary.creditAccountDetail?.cuit) {
            ticketBody = [
                ...ticketBody,
                getGeneralTicketItem(
                    `transferSummary.alias.label`,
                    this.getCuitDescription(summary.clientUser?.cuit || summary.clientUser?.cuil),
                    "string",
                ),
            ];
        }

        if (!internalTransfer) {
            ticketBody = [
                ...ticketBody,
                getGeneralTicketItem(`transferSummary.owner.label`, summary.clientUser?.firstName || "", "string"),
            ];
        }

        if (summary.creditAccountDetail?.bankName) {
            ticketBody = [
                ...ticketBody,
                getGeneralTicketItem(`transferSummary.bank.label`, summary.creditAccountDetail?.bankName, "string"),
            ];
        }
        if (!scheduledIsCancel) {
            ticketBody = [
                ...ticketBody,
                getGeneralTicketItem(
                    `transfers.amount.label`,
                    summary.amount,
                    summary.currency.toString() === "0" ? "amountARS" : "amountUSD",
                ),
            ];
        }

        if (!internalTransfer && summary.concept) {
            ticketBody = [
                ...ticketBody,
                getGeneralTicketItem(
                    `transfers.concept.label`,
                    summary.concept.concat(" - ", i18nUtils.get(`transfers.concept.${summary.concept}`)),
                    "string",
                ),
            ];
        }

        if (summary.idTransactionStatus === "PENDING" && summary.scheduler) {
            ticketBody = [
                ...ticketBody,
                getGeneralTicketItem(`forms.confirmation.scheduler`, getScheduledData(summary.scheduler), "string"),
            ];
        }

        ticketBody = [
            ...ticketBody,
            getGeneralTicketItem(`transfers.reference.label`, summary.reference || "", "string"),
        ];

        if (summary.email) {
            ticketBody = [...ticketBody, getGeneralTicketItem(`transfers.email.label`, summary.email, "string")];
        }
        if (summary.email && summary.emailText) {
            ticketBody = [
                ...ticketBody,
                getGeneralTicketItem("transfers.emailText.label", summary.emailText, "string"),
            ];
        }
        const ticketMessage =
            summary.tadTrx && summary.idTransactionStatus !== "FAILED"
                ? i18nUtils.get(
                      internalTransfer
                          ? "transfer.TAD.summary.internalTransfer"
                          : "transfer.TAD.summary.thirdPartiesTransfer",
                  )
                : undefined;

        const ticketData = {
            ticketName: internalTransfer
                ? i18nUtils.get("transfers.summary.internal.label")
                : i18nUtils.get("transfers.summary.external.label"),
            ticketBody,
            ticketMessage,
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    buttonsDetail = () => {
        const { dispatch, activeEnvironment } = this.props;

        if (activeEnvironment.permissions.scheduledTransactionsCancel) {
            return [
                {
                    label: "global.goToHome",
                    bsStyle: "primary",
                    onClick: () => dispatch(push("/desktop")),
                },
                {
                    label: "scheduler.cancel.button",
                    bsStyle: "primary",
                    onClick: this.handlePreCancel,
                },
            ];
        }
        return [
            {
                label: "global.goToHome",
                bsStyle: "primary",
                onClick: () => dispatch(push("/desktop")),
            },
        ];
    };

    getAccountObject = (account, type) => {
        const { dispatch } = this.props;
        dispatch(transferActions.getAccountInfo(account, type, true));
    };

    render() {
        const { summary, isDesktop, internalTransfer, fetching } = this.props;

        const { fetchingDownload } = this.state;
        if (summary.debitAccount?.number === undefined) {
            this.getAccountObject(summary.debitAccount, "debit");
        }
        if (summary.creditAccount?.number === undefined) {
            this.getAccountObject(summary.creditAccount, "credit");
        }

        return (
            summary &&
            summary.idTransaction && (
                <TransactionTicket
                    notificationScope="transactionScheduled"
                    handleClick={this.handleDownload}
                    handleClickMessage="global.download"
                    headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                    centerElement={isDesktop ? undefined : this.centerContentMobile}
                    uniqueDownload
                    rightContent={() => this.handleDownload}
                    hasInlineButtons
                    hideMobileMenu={!isDesktop}
                    summary={summary}
                    buttonsDetail={this.buttonsDetail()}
                    isDesktop={isDesktop}
                    downloadImageWhite={!isDesktop}
                    isFetchingExport={fetchingDownload}
                    fetching={fetching}
                    generateSignFunction
                    ignoreHomeButton
                    summaryTitle={
                        internalTransfer ? "transfers.summary.internal.label" : "transfers.summary.external.label"
                    }>
                    <TransferTransactionData />
                </TransactionTicket>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    summary: transferSelectors.getSummary(state),
    internalTransfer: transferSelectors.getInternalTransfer(state),
    fetching: transferSelectors.getFetching(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    idTransaction: transferSelectors.getIdTransaction(state),
    scheduledCancel: transferSelectors.getScheduledCancel(state),
    frequentDestination: frequentDestinationSelectors.getFrequentDestination(state),
    idEnvironment: frequentDestinationSelectors.getEnviromentId(state),
});

export default connect(mapStateToProps)(TransferScheduled);
