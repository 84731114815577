/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import Yup from "yup";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { Button, Box, Form, Spinner, Text, useMediaQuery } from "@ui-kit/components/index.es";

import CreateTemplateModal from "pages/v2/transfer/CreateTemplateModal";
import { getBoolean } from "util/config";

import { actions as transferActions, selectors as transferSelectors } from "reducers/transfer";
import { actions as templateActions, selectors as templateSelectors } from "reducers/template";
import { actions as transactionLinesActions } from "reducers/form/transactionLines";
import {
    actions as frequentDestinationActions,
    selectors as frequentDestinationSelectors,
} from "reducers/frequentDestination";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as holidaysActions } from "reducers/holidays";
import { actions as formActions } from "reducers/form";

import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import * as dateUtils from "util/date";
import * as schedulerUtils from "util/scheduler";
import { saveLocation, updateSchedulerToSend } from "util/transaction";

import * as transferMiddleware from "middleware/transfer";
import { saveDraft } from "middleware/form";
import { numberFormat } from "util/number";
import { selectors as i18nSelectors } from "reducers/i18n";
import { replace } from "react-router-redux";
import SchedulerModal from "./scheduler/SchedulerModal";
import TADWarningModal from "./TADWarningModal";

import AgendaDrawer from "./AgendaDrawer";
import TemplateDrawer from "./TemplateDrawer";
import Message from "./scheduler/Message";
import Limits from "./Limits";

const MAX_REQUIRED = "22";
const ID_FORM = "transferThirdParties";

function TransferThirdPartiesForm(props) {
    TransferThirdPartiesForm.propTypes = {
        creditAccountConfirmed: bool.isRequired,
        fetching: bool.isRequired,
        fetchingSubmit: bool.isRequired,
        dispatch: func.isRequired,
        onCreateTemplate: func.isRequired,
        isDesktop: bool.isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        limit: number.isRequired,
        limitUsed: number.isRequired,
        summary: shape({}).isRequired,
        concepts: arrayOf(shape({})).isRequired,
        isCVU: bool.isRequired,
        isValidCbu: func.isRequired,
        agendaVisible: bool.isRequired,
        isRetailEnvironment: bool.isRequired,
        isTemplatesVisible: bool.isRequired,
        setIsTemplatesVisible: func.isRequired,
        templates: shape({}).isRequired,
        activeEnvironment: shape({
            permissions: shape({
                transferInternal: bool,
                transferThirdParties: bool,
            }),
        }).isRequired,
        selectedFromAgenda: bool.isRequired,
        listFrequentDestinations: shape({}).isRequired,
        idEnvironment: number.isRequired,
        cvuCbuFromAlias: string,
    };

    const {
        creditAccountConfirmed,
        dispatch,
        isDesktop,
        fetching,
        location,
        accounts,
        summary,
        templates,
        isTemplatesVisible,
        setIsTemplatesVisible,
        setAgendaVisible,
        limit,
        limitUsed,
        onCreateTemplate,
        agendaVisible,
        listFrequentDestinations,
        lang,
    } = props;

    const [values, setValues] = useState({
        debitAccount: "",
        transferKind: "",
        concept: "",
        creditAccount: "",
        amount: "",
        reference: "",
        sendEmail: false,
        email: "",
        emailText: "",
        scheduler: {},
    });
    const [draftTemplateButtonDisabled, setDraftTemplateButtonDisabled] = useState(true);
    const [flagAgendaDrawer, setFlagAgendaDrawer] = useState(false);
    const [formikProps, setFormikProps] = useState({});
    const [disabledMail, setDisabledMail] = useState(false);
    const [showFullList, setShowFullList] = useState(false);
    const [tadWarningPopupVisible, setTadWarningPopupVisible] = useState(false);
    const [tadWarningShowed, setTadWarningShowed] = useState(false);
    const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
    const [isTemplateOn, setIsTemplateOn] = useState(false);
    const isMobile = useMediaQuery("min", "md");

    const {
        debitAccount,
        creditAccount,
        amount,
        reference,
        email,
        emailText,
        sendEmail,
        transferKind,
        concept,
    } = values;

    const extendedAmountVariableEnabled = getBoolean("increase.size.amount.link.iso8583", false);

    useEffect(() => {
        const { idEnvironment } = props;
        dispatch(templateActions.loadTemplateList(ID_FORM));
        dispatch(frequentDestinationActions.loadListRequest(idEnvironment, null));

        return () => {
            dispatch(transferActions.resetAgendaDrawerValues());
        };
    }, []);

    useEffect(() => {
        const { debitAccount: debitAccountDraft } = values;
        dispatch(transferActions.preActivity());
        dispatch(holidaysActions.listHolidays());
        dispatch(transferActions.resetAgendaDrawerValues());

        const { cbu, email, transferKind, isFrequentDestinations } = summary;

        if (location?.pathname === "/v2/transfer/transfer") {
            saveLocation(props);
            if (location?.state) {
                const { isInternal, debitAccount } = location?.state;
                dispatch(transferActions.changeTransferGroup(isInternal));
                setValues({
                    ...values,
                    debitAccount,
                    creditAccount: "",
                    amount: shape({
                        amount: 0,
                        currency: "",
                    }),
                    cbuOrAlias: "",
                    reference: "",
                    email: "",
                    emailText: "",
                    concept: "VAR",
                    transferKind: "",
                });
                dispatch(transferActions.accountLimitsActivities(debitAccount, getActivityId()));
            } else {
                const { activeEnvironment } = props;
                const isInternalTrx = transferKind === "other" ? false : activeEnvironment.permissions.transferInternal;

                if (!isInternalTrx && isFrequentDestinations) {
                    if (cbu) {
                        setValues({
                            ...values,
                            cbuOrAlias: cbu,
                            email,
                            transferKind,
                        });
                    }
                } else if (email && isFrequentDestinations) {
                    setValues({
                        ...values,
                        email,
                    });
                }
                dispatch(transferActions.changeTransferGroup(isInternalTrx));
            }
        } else if (location?.pathname === "/transfer/modify" && debitAccountDraft) {
            dispatch(transferActions.accountLimitsActivities(debitAccountDraft, getActivityId()));
        }
    }, []);

    useEffect(() => {
        const {
            transferKind,
            creditAccount,
            reference,
            amount,
            concept,
            email,
            emailText,
            sendEmail,
            cbu,
            alias,
            scheduler,
        } = summary;

        const creditAccountTemplate = creditAccount?.value || creditAccount?.idProduct || cbu || alias;
        setValues({
            ...values,
            creditAccount: creditAccountTemplate,
            amount: amount || "",
            transferKind: transferKindOptions.find(({ value }) => value === transferKind),
            concept: conceptsFiltered[0] || conceptsFiltered.find(({ value }) => value === concept),
            reference: reference || "",
            email: email || "",
            emailText: emailText || "",
            sendEmail,
            scheduler,
        });
        setShowFullList(false);
        setDraftTemplateButtonDisabled(true);
        checkDisabledMail(sendEmail, email);
    }, [summary]);

    useEffect(() => {
        const { selectedFromAgenda } = props;
        if (selectedFromAgenda) {
            setFlagAgendaDrawer(false);
        }
    }, [listFrequentDestinations]);

    useEffect(() => {
        const amountField = document.getElementById("amount");
        if (amountField) {
            amountField.focus();
        }
    }, [showFullList]);

    const selectedCurrency = () => {
        const selectedAccount = accounts.find((account) => account.idProduct === values.debitAccount);
        return i18n.get(`currency.label.${selectedAccount?.currency}`);
    };

    const selectedAccount = () => accounts.find((account) => account.idProduct === values.debitAccount);
    const getDefaultAccount = () => accounts?.find((account) => account.favorite)?.idProduct || accounts[0]?.idProduct;

    useEffect(() => {
        if (!selectedAccount()) {
            const defaultAccount = getDefaultAccount();
            if (defaultAccount) {
                setValues({ ...values, debitAccount: defaultAccount });
                dispatch(transferActions.accountLimitsActivities(defaultAccount, "transfers.thirdParties.send"));
                if (formikProps?.setValues) {
                    formikProps.setValues({ debitAccount: defaultAccount });
                }
            }
        }
    }, [values.debitAccount]);

    useEffect(() => {
        if (creditAccountConfirmed) {
            dispatch(transferActions.setCreditAccountConfirmed(false));
        }
    }, [values.creditAccount]);

    useEffect(() => {
        if (amount && debitAccount) {
            const parsedAmount = parseAmount(amount);
            const foundAccount = accounts?.find((account) => account.idProduct === debitAccount);
            if (foundAccount) {
                const { currency } = foundAccount;
                if (parsedAmount > 0) {
                    setHint({ amount: parsedAmount, currency });
                }
            }
        }
    }, [amount, debitAccount]);

    const handleDebitAccountSelect = (accountId) => {
        const { dispatch } = props;
        setValues({ ...values, debitAccount: accountId });
        dispatch(transferActions.accountLimitsActivities(accountId, "transfers.thirdParties.send"));
    };

    const transferKindOptions = [
        {
            value: "same",
            label: i18n.get("transfers.form.transfer.kind.same"),
        },
        {
            value: "other",
            label: i18n.get("transfers.form.transfer.kind.other"),
        },
    ];

    const conceptsFilter = () => {
        const { isCVU, concepts } = props;
        return isCVU
            ? concepts
                  .filter(({ id }) => !["BRH", "SON", "ROP", "OIH", "BNR", "APC", "SIS"].includes(id))
                  .map(({ id, name }) => ({
                      value: id,
                      label: `${id} - ${name}`,
                  }))
            : concepts.map(({ id, name }) => ({
                  value: id,
                  label: `${id} - ${name}`,
              }));
    };

    const conceptsFiltered = conceptsFilter();

    const mapAccounts = accounts.map((account) => ({
        accountName: account.productAlias || account.shortLabel,
        amount: account.balance,
        currency: i18n.get(`currency.label.${account.currency}`),
        description: i18n.get(`productType.${account.productType}`),
        id: account.idProduct,
    }));

    if (!values.concept && conceptsFiltered[0]) {
        setValues({ ...values, concept: conceptsFiltered[0] });
    }

    if (!values.debitAccount) {
        const { dispatch } = props;
        const { debitAccount } = summary;
        let defaultAccount;
        if (debitAccount) {
            defaultAccount = debitAccount.value || debitAccount.idProduct || debitAccount;
        } else {
            defaultAccount = getDefaultAccount();
        }
        if (defaultAccount) {
            setValues({ ...values, debitAccount: defaultAccount });
            dispatch(transferActions.accountLimitsActivities(defaultAccount, "transfers.thirdParties.send"));
            if (formikProps?.setValues) {
                formikProps.setValues({ debitAccount: defaultAccount });
            }
        }
    }

    const getActivityId = () => "transfers.thirdParties.send";

    const setHint = (value) => {
        const isTadTrx = isTad(value);
        if (isTadTrx) {
            setAgendaVisible(false);
            setTadWarningPopupVisible(true);
            if (!tadWarningShowed) {
                setTadWarningShowed(true);
            }
        } else {
            setTadWarningPopupVisible(false);
            setTadWarningShowed(false);
            setAgendaVisible(true);
        }
    };

    const changeCbu = (record) => {
        const transferKindDestination = record.isOwn === "1" ? "same" : "other";
        setValues({
            ...values,
            cbuOrAlias: record.account,
            transferKind: transferKindDestination,
            email: record.email,
        });
        verifyCVU(record.account);
    };

    const isValidAlias = (value) => {
        /* Regex that accept letter from a to z including capital letters, (.), (-), numbers
        and excluding (Ñ) and (ñ) */
        const reg = /^(?:(?![ñÑ])[a-zA-Z.\-\d])+$/;
        return reg.test(value);
    };

    const isValidCbu = (value) => {
        const reg = /^\d+$/;
        return reg.test(value) && value.length === parseInt(MAX_REQUIRED, 10);
    };

    // VERIFICAR EN ONBLUR / OnChange de cbu/alias
    const verifyCVU = (value) => {
        if (isValidCbu(value)) {
            const isCVUvalue = value.startsWith("000");
            dispatch(transferActions.setIsCVU(isCVUvalue));
        } else if (isValidAlias(value)) {
            dispatch(transferActions.getCbuCvuFromAlias(value));
        }
    };

    const closeTADModal = () => {
        setTadWarningPopupVisible(false);
    };

    const getActivityIdTad = () => "transfers.thirdParties.tad.send";

    const handleCreateTemplate = (propsFormik) => {
        const { currency } = accounts.find((account) => account.idProduct === values.debitAccount);
        onCreateTemplate({ ...propsFormik.values, currency });
    };

    const parseAmount = (value) => {
        if (typeof value === "string") {
            return parseFloat(value.replaceAll(".", "").replace(",", "."));
        }
        return value;
    };

    const hasToAddBeneficiary = (amountParameter, currencyParameter) => {
        const { isRetailEnvironment } = props;
        const environmentTypeTag = isRetailEnvironment ? "retail" : "corporate";
        const currencyTypeTag = currencyParameter === 2 || currencyParameter === "2" ? "Dolares" : "Pesos";
        const amountControl = configUtils.getInteger(
            `frontend.controlAgenda.amount${currencyTypeTag}.${environmentTypeTag}`,
            10000,
        );

        return amountParameter >= amountControl;
    };

    const controlContactAgenda = (value, selectedCreditAccount) => {
        const { selectedFromAgenda, cvuCbuFromAlias } = props;
        const controlAgendaAvailable = configUtils.getBoolean(
            "frontend.ControlAgenda.validation.transfers.enable",
            false,
        );

        const creditAccountUsed = selectedCreditAccount || values?.cbuOrAlias;
        const found = listFrequentDestinations.find(
            (frequentDestination) =>
                frequentDestination.cbu === creditAccountUsed || frequentDestination.cbu === cvuCbuFromAlias,
        );
        let existCbu = false;
        if (found) {
            existCbu = true;
        } else {
            dispatch(transferActions.setSelectedFromAgenda(existCbu));
            dispatch(
                transferActions.setAgendaDrawerValues({
                    cbuOrAliasAgenda: creditAccountUsed,
                    emailAgenda: "",
                    referenceAgenda: "",
                    tokenAgenda: "",
                }),
            );
        }

        dispatch(transferActions.setFrequentDestinationExistOnList(existCbu));

        const hasCbuOrAliasOnField =
            creditAccountUsed !== "" && creditAccountUsed !== null && creditAccountUsed !== undefined;

        const hasToActivateFlagAgendaDrawer =
            controlAgendaAvailable &&
            hasToAddBeneficiary(parseAmount(value?.amount), value?.currency) &&
            !selectedFromAgenda &&
            hasCbuOrAliasOnField &&
            !existCbu;

        dispatch(transferActions.setAgendaDrawerVisible(hasToActivateFlagAgendaDrawer));
        if (hasCbuOrAliasOnField && hasToActivateFlagAgendaDrawer) {
            dispatch(
                transferActions.setAgendaDrawerValues({
                    cbuOrAliasAgenda: creditAccountUsed,
                    emailAgenda: "",
                    referenceAgenda: "",
                    tokenAgenda: "",
                }),
            );
        }
        setFlagAgendaDrawer(hasToActivateFlagAgendaDrawer);
    };

    const handleChangeAmount = (amount) => {
        const { currency } = accounts.find((account) => account.idProduct === values.debitAccount);
        if (creditAccountConfirmed) {
            controlContactAgenda({ amount: parseAmount(amount), currency });
        }

        setValues({ ...values, amount });
    };

    const handleAccountSearch = async (cbuCvuOrAlias) => {
        const { isValidCbu } = props;
        let cbuCvu;
        let alias;
        if (isValidCbu(cbuCvuOrAlias)) {
            cbuCvu = cbuCvuOrAlias;
        } else {
            alias = cbuCvuOrAlias;
        }
        return new Promise((resolve, reject) => {
            transferMiddleware
                .getAccountByCbu(cbuCvu, alias)
                .then((response) => {
                    const { clientUser, accountDetail, documentType } = response.data.data;
                    if (!clientUser && !accountDetail) {
                        reject(response.data.message);
                    }
                    resolve({
                        dataAccount: {
                            owner: clientUser.firstName,
                            document: clientUser.cuil,
                            documentType,
                            bankName: accountDetail.bankName,
                        },
                    });
                })
                .catch(() => {
                    reject(i18n.get("problems.finding.cbu"));
                });
        });
    };

    const handleConfirmCreditAccount = (value) => {
        dispatch(transferActions.setCreditAccountConfirmed(true));
        setIsTemplateOn(false);
        verifyCVU(value);
        setDraftTemplateButtonDisabled(false);
        setValues({
            ...values,
            creditAccount: value,
            cbuOrAlias: value,
        });
        if (values.amount) {
            const { currency } = selectedAccount();
            controlContactAgenda({ amount: values.amount, currency }, value);
        }
        dispatch(
            transferActions.setAgendaDrawerValues({
                cbuOrAliasAgenda: value,
                emailAgenda: "",
                referenceAgenda: "",
                tokenAgenda: "",
            }),
        );
    };

    const referenceMaxLength = configUtils.getBoolean("frontend.transfers.reference.maxLength", 12);
    const idActivity = "transfers.thirdParties.send";

    const isTad = (amountWithCurrency) => {
        const { isRetailEnvironment, activeEnvironment, isCVU } = props;
        const value = amountWithCurrency.amount;
        const environmentTypeTag = isRetailEnvironment ? "Retail" : "Corporate";
        const currencyTag = amountWithCurrency.currency.toString() === "0" ? "Pesos" : "Dolares";
        const minAmount = configUtils.getInteger(`frontend.TAD.minAmount${currencyTag}.${environmentTypeTag}`, 100000);
        const maxAmount = configUtils.getInteger(`frontend.TAD.maxAmount${currencyTag}.${environmentTypeTag}`, 1000000);
        const enabledCorporate = configUtils.getBoolean("frontend.show.TAD.functionalities.Corporate", false);
        const enabledRetail = configUtils.getBoolean("frontend.show.TAD.functionalities.Retail", false);

        let tadEnabled = false;

        if (isCVU) {
            return false;
        }

        if (!isRetailEnvironment) {
            if (enabledCorporate) {
                tadEnabled = true;
            }
        } else if (enabledRetail) {
            tadEnabled = true;
        }

        if (tadEnabled && activeEnvironment?.permissions?.transferDiferredAccreditation) {
            if (value >= minAmount && value <= maxAmount) {
                return true;
            }
        }
        return false;
    };
    const handleTemplateSelect = (template) => {
        const amount = template.amount?.amount || 0;
        const amountWithCurrency = template.amount;
        verifyCVU(template.cbuOrAlias);
        const isTadTrx = isTad(amountWithCurrency);
        const creditAccountTemplate = template.creditAccount || template.cbuOrAlias;
        setIsTemplatesVisible(false);
        setValues({
            ...values,
            debitAccount: template.debitAccount,
            creditAccount: creditAccountTemplate,
            cbuOrAlias: creditAccountTemplate,
            transferKind: transferKindOptions.find(({ value }) => value === template.transferKind),
            concept: conceptsFiltered.find(({ value }) => value === template.concept),
            amount,
            reference: template.reference,
            sendEmail: template.sendEmail,
            emailText: template.emailText,
            email: template.email,
            flagHint: isTadTrx,
            tadTrx: isTadTrx,
        });
        setShowFullList(false);
        setDraftTemplateButtonDisabled(true);
        checkDisabledMail(template.sendEmail, template.email);
        dispatch(transferActions.setCreditAccountConfirmed(false));
        dispatch(transactionLinesActions.loadTransactionLinesTemplateData(template));
        const { debitAccount } = template;
        if (debitAccount) {
            dispatch(transferActions.accountLimitsActivities(debitAccount, "transfers.internal.send"));
        }
        if (creditAccount === creditAccountTemplate) {
            setIsTemplateOn(true);
        }
        if (creditAccountTemplate) {
            formikProps.setFieldTouched("creditAccount", true);
        }
    };

    const getDraftSummary = (draftValues) => {
        const { idTransaction, accounts } = props;
        const {
            debitAccount,
            creditAccount,
            amount,
            currency,
            reference,
            sendEmail,
            email,
            emailText,
            tadTrx,
            concept,
            transferKind,
            scheduler,
            flagHint,
        } = draftValues;

        const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === debitAccount);
        const schedulerToSend = scheduler && scheduler.selectedOption !== schedulerUtils.TODAY ? scheduler : null;

        const isCbu = () => /^\d+$/.test(creditAccount);
        return {
            accounts,
            debitAccount: completeDebitAccount,
            cbu: isCbu() ? creditAccount : null,
            alias: !isCbu() ? creditAccount : null,
            currency,
            amount,
            reference,
            sendEmail,
            email,
            emailText,
            transferKind: transferKind?.value,
            concept: concept?.value,
            scheduler: schedulerToSend,
            idTransaction,
            tadTrx,
            flagHint,
        };
    };

    const onSaveDraft = (draftValues) => {
        const { idTransaction } = props;
        const { transferKind, concept, debitAccount, creditAccount } = draftValues;
        const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === debitAccount);
        const parsedAmount = parseAmount(draftValues.amount) || 0;
        const tadTrx = isTad({
            currency: completeDebitAccount.currency,
            amount: parsedAmount,
        });
        dispatch(
            formActions.saveDraft({
                idForm: ID_FORM,
                idActivityDraft: tadTrx ? getActivityIdTad() : getActivityId(),
                data: {
                    ...draftValues,
                    cbuOrAlias: creditAccount,
                    amount: {
                        quantity: parsedAmount,
                        currency: completeDebitAccount.currency,
                    },
                    currency: completeDebitAccount.currency,
                    debitAccount: { value: debitAccount },
                    creditAccount: { value: creditAccount },
                    transferKind: transferKind?.value,
                    concept: concept?.value,
                },
                idTransaction: idTransaction || null,
                scheduler: draftValues?.scheduler,
                summary: getDraftSummary({
                    ...draftValues,
                    amount: parsedAmount,
                    currency: completeDebitAccount.currency,
                }),
            }),
        );
    };

    const onSubmit = ({
        debitAccount,
        creditAccount,
        amount,
        reference,
        sendEmail,
        email,
        emailText,
        concept,
        transferKind,
        flagHint,
        flagAgendaDrawer,
    }) => {
        if (tadWarningPopupVisible) {
            return;
        }
        const { dispatch, accounts, location, summary, isCVU } = props;
        const { scheduler } = values;

        if (flagAgendaDrawer) {
            dispatch(transferActions.setAgendaDrawerVisible(true));
            return;
        }

        const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === debitAccount);
        const currency = parseInt(completeDebitAccount.currency, 10);
        const isCbu = () => {
            const reg = /^\d+$/;
            return reg.test(creditAccount);
        };

        let schedulerToSend = scheduler && scheduler.selectedOption !== schedulerUtils.TODAY ? scheduler : null;
        const newValueDate = schedulerToSend?.valueDate;
        schedulerToSend = { ...schedulerToSend, newValueDate };
        delete schedulerToSend.valueDate;

        const tadTrx = isTad({
            currency,
            amount: parseAmount(amount),
        });
        if (!isCVU && tadTrx) {
            const schedulerTad = updateSchedulerToSend();
            if (schedulerTad) {
                schedulerToSend = schedulerTad;
            }
        }

        if (isDesktop) {
            dispatch(replace("/v2/transfer/thirdParties/back"));
        }
        dispatch(
            transferActions.createExternalTransfer(
                {
                    accounts,
                    debitAccount: completeDebitAccount,
                    cbu: isCbu() ? creditAccount : null,
                    alias: !isCbu() ? creditAccount : null,
                    currency,
                    amount: parseAmount(amount),
                    reference,
                    sendEmail,
                    email,
                    emailText,
                    concept: concept.value,
                    transferKind: transferKind.value,
                    scheduler: schedulerToSend,
                    idTransaction: summary.idTransaction,
                    tadTrx,
                    flagHint,
                },
                null, // formikBag,
            ),
        );

        if (location?.pathname === "/transfer/modify") {
            saveDraft();
        }
    };

    const handleSchedulerChange = (data) => {
        if (data.frequency === schedulerUtils.ONCE) {
            setValues({
                ...values,
                scheduler: {
                    selectedOption: data.frequency,
                    valueDate: moment(data.startsOn),
                    program: null,
                },
            });
        } else {
            const newProgramData = {
                frequency: data.frequency === schedulerUtils.CUSTOM ? data.customFreq : data.frequency, // one of DAY, WEEK, MONTH, YEAR
                frequencyValue: data.frequency === schedulerUtils.CUSTOM ? data.customFreqValue : "1", // int that represents the frequency, frequency = DAY +  frequencyValue = 2 => every 2 days
                days: dateUtils.getDayFromDate(data.startsOn), // int that represents the days of the week used by the java class Days at the backend. Same class used by the calendar restriction
                day: data.startsOn.getDate(), // day of the month.
                occurrence: null, // occurrence=3 + days=4 every 3rd WEDNESDAY of the month
                startsOn: data.startsOn,
                lapse: data.lapse.lapse, // no change at all
                number: data.lapse.number,
                date: data.lapse.date,
                isCustom: data.frequency === schedulerUtils.CUSTOM,
                selectedOption: data.frequency,
            };
            if (newProgramData.frequency === schedulerUtils.WEEK && newProgramData.isCustom) {
                // if it's custom, I take the days from the bitwisedays control
                newProgramData.days = data.days;
            }
            if (newProgramData.frequency === schedulerUtils.MONTH && data.occurrenceType === "occurrence") {
                newProgramData.occurrence = dateUtils.weekOfMonth(moment(data.startsOn));
                newProgramData.day = null;
            }
            setValues({
                ...values,
                scheduler: {
                    selectedOption: newProgramData.frequency,
                    program: newProgramData,
                },
            });
        }
        setIsScheduleModalVisible(false);
    };

    const checkDisabledMail = (sendEmail, emailAdress) => {
        if (sendEmail && emailAdress === "") {
            setDisabledMail(true);
        } else if (emailAdress !== "" && sendEmail) {
            setDisabledMail(false);
        } else if (emailAdress === "" && !sendEmail) {
            setDisabledMail(false);
        }
    };
    useEffect(() => {
        checkDisabledMail(sendEmail, values.email);
    }, [sendEmail, values.email]);

    const customHandleKeyDown = (e) => {
        const { decimalSeparator } = numberFormat(lang);
        const { value, selectionStart, selectionEnd } = e.target;
        const integerPart = value.split(decimalSeparator)[0].replace(/\D/g, "");
        const extendedAmountEnabled = getBoolean("increase.size.amount.link.iso8583", false);
        const maxSize = extendedAmountEnabled ? 15 : 10;

        // Array de teclas permitidas para movimiento, navegación y selección
        const allowedKeys = [
            "Backspace",
            "Delete",
            "ArrowLeft",
            "ArrowRight",
            "Tab",
            "Home",
            "End",
            "Control",
            "Meta",
            "Shift",
        ];

        // Permitir combinaciones con teclas de control (Ctrl, Cmd, etc.)
        if (e.ctrlKey || e.metaKey || e.shiftKey) {
            return; // Permitir combinaciones de teclas como Ctrl+A, Ctrl+C, etc.
        }

        const isAllSelected = selectionStart === 0 && selectionEnd === value.length;

        // Detectar si el cursor está en la parte decimal
        const decimalIndex = value.indexOf(decimalSeparator);
        // Permitir el ingreso del separador decimal cuando se alcanza el número máximo de dígitos
        if (
            (e.key === decimalSeparator || e.key === ".") &&
            (selectionStart <= decimalIndex || decimalIndex === -1) &&
            integerPart.length >= maxSize
        ) {
            return; // Permitir el ingreso del separador decimal
        }

        if (isAllSelected) {
            return; // Permitir el reemplazo al seleccionar todo el texto
        }

        // Si el cursor está en la parte entera y el número ya tiene 10 dígitos
        if (
            integerPart.length >= maxSize &&
            (selectionStart <= decimalIndex || decimalIndex === -1) &&
            !allowedKeys.includes(e.key)
        ) {
            e.preventDefault(); // Bloquea la entrada de más dígitos en la parte entera si se excede el límite
        }
    };

    const inputList = [
        {
            colProps: {
                size: 12,
            },
            component: "accountSelect",
            id: "debitAccount",
            label: i18n.get("transfers.form.debit.account"),
            required: true,
            options: mapAccounts,
            defaultValue: debitAccount,
            value: debitAccount,
            onChange: (id) => handleDebitAccountSelect(id),
        },
        {
            colProps: {
                size: 12,
            },
            id: "limits",
            customElement: () => (
                <Limits
                    values={values}
                    limit={limit}
                    limitUsed={limitUsed}
                    isDesktop={isDesktop}
                    currency={selectedAccount()?.currency}
                />
            ),
        },
        {
            colProps: {
                size: 12,
            },
            component: "selectField",
            id: "transferKind",
            required: true,
            iconMobile: "TransferAlt",
            options: transferKindOptions,
            value: values.transferKind,
            onSelectOption: (option) => setValues({ ...values, transferKind: option }),
            selectLabel: i18n.get(`${isMobile ? "transfers.form.transfer.kind.mb" : "transfers.form.transfer.kind"}`),
            placeholder: i18n.get("transfers.form.select.transfer.placeholder"),
        },
        {
            colProps: {
                size: 12,
            },
            component: "accountSearcher",
            id: "creditAccount",
            required: true,
            value: values.creditAccount,
            disabled: creditAccountConfirmed,
            enableDeletion: true,
            invalidFormatText: i18n.get("transfers.validation.invalidFormat"),
            searchCallback: (cbuCvuOrAlias) => handleAccountSearch(cbuCvuOrAlias),
            onCancel: (e) => {
                setValues({ ...values, creditAccount: undefined, cbuOrAlias: undefined });
            },
            onDelete: (e) => {
                setValues({ ...values, creditAccount: undefined, cbuOrAlias: undefined });
            },
            onAccept: (e) => handleConfirmCreditAccount(e),
            onChange: (e) => {
                setValues({ ...values, creditAccount: e.target.value, cbuOrAlias: e.target.value });
            },
            placeholder: i18n.get("transfers.form.cbu.placeholder"),
            updateOnChange: isTemplateOn,
        },
    ];

    if (showFullList && draftTemplateButtonDisabled) {
        setDraftTemplateButtonDisabled(false);
    }

    if (values.debitAccount && values.transferKind && creditAccountConfirmed && !showFullList) {
        setShowFullList(true);
    }

    if (showFullList) {
        inputList.push(
            {
                colProps: {
                    size: 12,
                },
                component: "amountField",
                tabIndex: 0,
                allowNegative: false,
                currency: selectedCurrency(),
                id: "amount",
                label: i18n.get("transfers.form.amount"),
                required: true,
                onBlur: (e, propsFormik) => handleChangeAmount(propsFormik.values.amount),
                maxLength: extendedAmountVariableEnabled ? undefined : 15,
                extendedAmountEnabled: extendedAmountVariableEnabled,
                onKeyDown: (e) => customHandleKeyDown(e),
                afterElement: tadWarningShowed ? (
                    <Box mx={12}>
                        <Button
                            icon="Info"
                            variant="text"
                            onClick={(e) => {
                                e.preventDefault();
                                setTadWarningPopupVisible(true);
                            }}
                        />
                    </Box>
                ) : null,
                helper: tadWarningShowed ? (
                    <Text color="primary" variant="caption">
                        {configUtils.get("frontend.TAD.warning.text")}
                    </Text>
                ) : null,
            },
            {
                colProps: {
                    size: 12,
                },
                component: "selectField",
                id: "concept",
                required: true,
                options: conceptsFiltered,
                value: values.concept,
                onSelectOption: (option) => setValues({ ...values, concept: option }),
                selectLabel: i18n.get("transfers.form.transfer.concept"),
                searcheable: true,
            },
            {
                colProps: {
                    size: 12,
                },
                component: "textField",
                id: "reference",
                value: reference,
                onChange: (e) => {
                    setValues({ ...values, reference: e.target.value });
                },
                label: i18n.get("transfers.form.reference"),
                placeholder: i18n.get("transfers.form.reference.placeholder"),
                maxLength: referenceMaxLength,
            },
            {
                colProps: {
                    size: 12,
                },
                id: "schedule",
                customElement: () =>
                    agendaVisible ? (
                        <Box>
                            <Button
                                text={i18n.get("transfers.form.schedule")}
                                icon="CalendarAdd"
                                variant="solid"
                                id="modal-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsScheduleModalVisible(true);
                                }}
                            />
                            <Box mt={8}>
                                <Message value={values.scheduler || { valueDate: "" }} />
                            </Box>
                        </Box>
                    ) : (
                        <></>
                    ),
            },
            {
                colProps: {
                    size: 12,
                },
                component: "checkbox",
                id: "sendEmail",
                label: i18n.get("transfers.form.send.mail"),
                isChecked: values.sendEmail,
                onChange: () => setValues({ ...values, sendEmail: !values.sendEmail }),
            },
        );
    }

    if (values.sendEmail && showFullList) {
        inputList.push(
            {
                colProps: {
                    size: 12,
                },
                component: "textField",
                id: "email",
                label: "Email",
                value: email,
                onChange: (e) => {
                    setValues({ ...values, email: e.target.value });
                },
                maxLength: 50,
                required: true,
            },
            {
                colProps: {
                    size: 12,
                },
                component: "textField",
                id: "emailText",
                value: emailText,
                onChange: (e) => {
                    setValues({ ...values, emailText: e.target.value });
                },
                label: "Mensaje",
                maxLength: 50,
            },
        );
    }

    const { fetchingSubmit } = props;
    if ((fetching && !accounts.length) || !values.debitAccount || fetchingSubmit) {
        return (
            <Box flex justify="center" align="center" column py={64}>
                <Spinner size="large" />
            </Box>
        );
    }

    const initialValues = {
        debitAccount: "",
        transferKind: "",
        concept: "",
        creditAccount: "",
        amount: "",
        reference: "",
        sendEmail: false,
        email: "",
        emailText: "",
    };

    if (formikProps?.setValues) {
        formikProps.setValues({
            debitAccount,
            transferKind,
            concept,
            creditAccount,
            amount,
            reference,
            sendEmail,
            email,
            emailText,
        });
    }

    return (
        <>
            <SchedulerModal
                isVisible={isScheduleModalVisible}
                onClose={() => setIsScheduleModalVisible(false)}
                onScheduleClick={(data) => handleSchedulerChange(data)}
                scheduler={summary?.scheduler}
            />
            <TADWarningModal
                isVisible={tadWarningPopupVisible}
                onClick={changeCbu}
                closeModal={closeTADModal}
                isDesktop={isDesktop}
            />
            <Box p={24}>
                <Form
                    wrapButtons
                    wrapButtonsFlex
                    buttonSubmit={{
                        colProps: {
                            size: {
                                md: 3,
                                xs: 12,
                            },
                        },
                        text: i18n.get("transfers.form.continue"),
                        variant: "solid",
                    }}
                    initialValues={initialValues}
                    inputsList={inputList}
                    buttonsList={[
                        {
                            colProps: {
                                size: {
                                    xs: 12,
                                    lg: 4,
                                },
                            },
                            w: isMobile || window.innerWidth <= 1010 ? "100%" : 200,
                            component: "button",
                            key: "draft",
                            text: i18n.get("transfers.form.save.draft"),
                            icon: "Save",
                            isInactive: draftTemplateButtonDisabled,
                            variant: "outline",
                            type: "button",
                            onClick: (e, propsFormik) => {
                                setDraftTemplateButtonDisabled(true);
                                onSaveDraft(propsFormik.values);
                            },
                        },
                        {
                            colProps: {
                                size: {
                                    xs: 12,
                                    lg: 4,
                                },
                            },
                            w: isMobile || window.innerWidth <= 1010 ? "100%" : 200,
                            component: "button",
                            key: "template",
                            text: i18n.get("transfers.form.save.template"),
                            icon: "Save",
                            isInactive: draftTemplateButtonDisabled,
                            variant: "outline",
                            type: "button",
                            id: "modal-button",
                            onClick: (e, propsFormik) => handleCreateTemplate(propsFormik),
                        },
                        {
                            colProps: {
                                size: {
                                    xs: 12,
                                    lg: 4,
                                },
                            },
                            w: isMobile || window.innerWidth <= 1010 ? "100%" : 200,
                            component: "button",
                            key: "submit",
                            text: i18n.get("transfers.form.continue"),
                            icon: "ArrowRight",
                            iconVariant: "outline",
                            variant: "solid",
                            type: "submit",
                            extraInactiveCondition: !creditAccountConfirmed || disabledMail,
                            onClick: () => {},
                        },
                    ]}
                    onSubmit={(v) => onSubmit({ ...v, flagAgendaDrawer })}
                    setFormikProps={setFormikProps}
                    rowSpacingForm={32}
                    validationSchema={Yup.object().shape({
                        debitAccount: Yup.string().required(i18n.get("transfers.validation.required")),
                        creditAccount: Yup.string()
                            .test("is-valid-credit-account", i18n.get("transfers.validation.invalidFormat"), (v) => {
                                if (!v) {
                                    return true;
                                }
                                // si tiene 22 digitos numericos es CBU o CVU
                                if (/^\d{22}$/.test(v)) {
                                    return true;
                                }
                                // si tiene entre 6 y 20 caracteres alfanuméricos, guiones o puntos es alias
                                return /^[a-zA-Z0-9.-]{6,20}$/.test(v);
                            })
                            .required(i18n.get("transfers.validation.required")),
                        transferKind: Yup.string().required(i18n.get("transfers.validation.required")),
                        concept: Yup.string().required(i18n.get("transfers.validation.required")),
                        amount: Yup.string()
                            .required(i18n.get("transfers.validation.required"))
                            .test(
                                "test",
                                i18n.get("transfers.validation.min.amount"),
                                (amount) => parseAmount(amount) > 0,
                            ),
                        email: sendEmail
                            ? Yup.string()
                                  .required(i18n.get("transfers.validation.required"))
                                  .email(i18n.get("transfers.validation.invalid"))
                            : Yup.string(),
                    })}
                />
                <CreateTemplateModal values={values} idActivityTemplate={idActivity} idForm={ID_FORM} />
            </Box>
            <TemplateDrawer
                isVisible={isTemplatesVisible}
                setVisible={setIsTemplatesVisible}
                templates={templates}
                onSelect={handleTemplateSelect}
            />
            <AgendaDrawer />
        </>
    );
}

const mapStateToProps = (state) => ({
    fetching: transferSelectors.getFetching(state),
    fetchingSubmit: transferSelectors.getFetchingSubmit(state),
    accounts: transferSelectors.getAccounts(state),
    limit: transferSelectors.getLimit(state),
    limitUsed: transferSelectors.getLimitUsed(state),
    concepts: transferSelectors.getConcepts(state),
    isCVU: transferSelectors.getIsCVU(state),
    summary: transferSelectors.getSummary(state),
    templates: templateSelectors.getTemplateList(state),
    selectedFromAgenda: transferSelectors.getSelectedFromAgenda(state),
    frequentDestinationExistOnList: transferSelectors.getFrequentDestinationExistOnList(state),
    listFrequentDestinations: frequentDestinationSelectors.getListFrequentDestinations(state),
    idEnvironment: frequentDestinationSelectors.getEnviromentId(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    isRetailEnvironment: sessionSelectors.isRetailEnvironment(state),
    cvuCbuFromAlias: transferSelectors.getCvuCbuFromAlias(state),
    lang: i18nSelectors.getLang(state),
    creditAccountConfirmed: transferSelectors.getCreditAccountConfirmed(state),
});

export default withRouter(connect(mapStateToProps)(TransferThirdPartiesForm));
