import React, { Component } from "react";
import Yup from "yup";
import { func, shape, bool, string, number, arrayOf } from "prop-types";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import Col from "react-bootstrap/lib/Col";
import { Field, Form, withFormik } from "formik";
import { compose } from "redux";

import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Head from "pages/_components/Head";
import Button from "pages/_components/Button";
import * as i18nUtils from "util/i18n";
import * as accountUtils from "util/accounts";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { actions as loginActions } from "reducers/login";
import PageLoading from "pages/_components/PageLoading";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Notification from "pages/_components/Notification";
import isTokenActive from "util/token";
import classNames from "classnames";

const FORM_ID = "debin.addAccount.summary";
class DeclineDebinConfirm extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        debinDetail: shape({}).isRequired,
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
            securitySeal: number,
            userFullName: string,
        }),
        accounts: shape({}),
        fetchingAccounts: bool.isRequired,
        // accountName: string.isRequired,
        isSubmitting: bool.isRequired,
    };

    static defaultProps = {
        loggedUser: null,
        accounts: null,
    };

    componentDidMount() {
        const { dispatch, loggedUser, accounts, debinDetail } = this.props;

        if (!accounts.length > 0) {
            dispatch(accountsActions.listAccounts(loggedUser.userId));
        }

        if (!debinDetail) {
            dispatch(goBack());
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    handleDownload = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.downloadDocumentRequest({ format: "pdf" }));
    };

    getTitle = () => {
        const { isDesktop } = this.props;

        if (isDesktop) {
            return (
                <>
                    <div className="view-title data-wrapper-flex">
                        <h1 className="my-0 mr-3">{i18nUtils.get("payments.debin.decline.header.title")}</h1>
                    </div>
                    <p className="my-1">{i18nUtils.get("payments.debin.decline.header.subtitle")}</p>
                </>
            );
        }

        return (
            <div className="title-account-header-multiline-background-blue">
                <h1>{i18nUtils.get("payments.debin.decline.header.title")}</h1>
            </div>
        );
    };

    render() {
        const { isDesktop, fetchingAccounts, accounts, debinDetail, isSubmitting } = this.props;

        if (!debinDetail) {
            return <></>;
        }

        const continueButton = (
            <Col className="col col-12" lg={12} xl={12} md={12} sm={12}>
                <Button
                    type="submit"
                    bsStyle="primary"
                    label="global.confirm"
                    image="images/arrowRight.svg"
                    imageStyle="mr-2"
                    loading={isSubmitting}
                />
            </Col>
        );

        const credentials = (
            <>
                {!isTokenActive(!isDesktop) ? (
                    <div className="admin-content-center">
                        <I18n id="transfer.summary.confirm.label" />
                    </div>
                ) : (
                    <div className="admin-content-center pb-3">
                        <I18n
                            id="confirmation.withoutotp.text"
                            component="p"
                            componentProps={{ className: "confirmation__text-without-otp" }}
                        />
                    </div>
                )}
                <div className="admin-content-center">
                    <Field
                        placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                        name="otp"
                        type="otp"
                        idForm={FORM_ID}
                        component={Credential}
                    />
                </div>
            </>
        );

        const {
            buyerDetail,
            concept,
            description,
            expirationDate,
            id,
            sellerDetail,
            amount: { currency, quantity },
            amount,
            reference,
        } = debinDetail;

        const loggedUserAccount = accountUtils.getUserAccount(accounts, buyerDetail?.cbu);
        const arrayAccountData = loggedUserAccount && loggedUserAccount.extraInfoMasked.split("|");

        return (
            <>
                <Notification scopeToShow="decline-debin" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        onClose={isDesktop && this.handleClose}
                        headerClassName={classNames({
                            "blue-main-header-mobile": !isDesktop,
                        })}
                        centerElement={!isDesktop && this.getTitle}
                        emptyRightOption
                    />
                    {isDesktop && (
                        <Head
                            centerElement={this.getTitle}
                            centerContentClassName="d-flex flex-column align-items-start"
                        />
                    )}
                </div>
                <PageLoading loading={fetchingAccounts}>
                    <MainContainer>
                        <Form className="above-the-fold">
                            {!isDesktop && (
                                <div className="admin-content-center d-flex justify-content-center">
                                    <I18n
                                        id="payments.debin.decline.header.subtitle"
                                        component="h2"
                                        componentProps={{ className: "py-3 px-4 f-size-35" }}
                                    />
                                </div>
                            )}
                            <Container className="flex-grow container-white text-uppercase with-margin py-2">
                                <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                    <Heading.DataGroup
                                        label="payments.debin.confirm.table.header.debitAccount"
                                        data={
                                            <>
                                                {arrayAccountData && arrayAccountData.length > 0 ? (
                                                    <p
                                                        className={classNames("my-0 light-font data-desc", {
                                                            "text-left": isDesktop,
                                                            "text-right": !isDesktop,
                                                        })}>
                                                        {`${arrayAccountData[1]} ${arrayAccountData[0]}
                                                        ${i18nUtils.get(`currency.label.${arrayAccountData[2]}`)}`}
                                                    </p>
                                                ) : (
                                                    <p> - </p>
                                                )}
                                                {!isDesktop &&
                                                    (loggedUserAccount ? (
                                                        <div className="d-flex">
                                                            <span className="mr-1">
                                                                {i18nUtils.get("accounts.availableBalance")}
                                                            </span>
                                                            <FormattedAmount
                                                                currency={loggedUserAccount.currency}
                                                                quantity={loggedUserAccount.balance}
                                                                className="data-desc"
                                                                notBold
                                                            />
                                                        </div>
                                                    ) : (
                                                        <p> - </p>
                                                    ))}
                                            </>
                                        }
                                        containerClassName={classNames("transfer-data", {
                                            "space-between mb-0": !isDesktop,
                                            "data-wrapper mb-0": isDesktop,
                                        })}
                                        labelClassName={!isDesktop ? "mb-0" : ""}
                                    />
                                    {isDesktop && (
                                        <Heading.DataGroup
                                            containerClassName="transfer-data"
                                            data={
                                                loggedUserAccount ? (
                                                    <div className="d-flex">
                                                        <span className="mr-1">
                                                            {i18nUtils.get("accounts.availableBalance")}
                                                        </span>
                                                        <FormattedAmount
                                                            currency={loggedUserAccount.currency}
                                                            quantity={loggedUserAccount.balance}
                                                            notBold
                                                            className="data-desc"
                                                        />
                                                    </div>
                                                ) : (
                                                    <p> - </p>
                                                )
                                            }
                                        />
                                    )}
                                </Col>
                                <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                    <Heading.DataGroup
                                        containerClassName={`transfer-data data-wrapper${
                                            !isDesktop ? "-flex space-between mb-0" : ""
                                        }`}
                                        labelClassName={!isDesktop ? "mb-0" : ""}
                                        label="payments.debin.detail.table.header.concept"
                                        data={concept ? i18nUtils.get(`transfers.concept.${concept}`) : <p> - </p>}
                                    />
                                </Col>
                                <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                    <Heading.DataGroup
                                        containerClassName={`transfer-data data-wrapper${
                                            !isDesktop ? "-flex space-between mb-0" : ""
                                        }`}
                                        labelClassName={!isDesktop ? "mb-0" : ""}
                                        label="payments.debin.detail.table.header.description"
                                        data={description || <p> - </p>}
                                    />
                                </Col>
                                <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                    <Heading.DataGroup
                                        containerClassName={`transfer-data data-wrapper${
                                            !isDesktop ? "-flex space-between mb-0" : ""
                                        }`}
                                        labelClassName={!isDesktop ? "mb-0" : ""}
                                        label="payments.debin.detail.table.header.expirationDate"
                                        data={
                                            expirationDate ? (
                                                <FormattedDate date={expirationDate} dateFormat="dd/MM/yyyy" showTime />
                                            ) : (
                                                <p> - </p>
                                            )
                                        }
                                    />
                                </Col>
                            </Container>
                            <Container className="flex-grow container-white text-uppercase with-margin py-2">
                                <Col className="col col-12 px-0" md={12} xl={12} lg={12} sm={12}>
                                    <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data ${
                                                !isDesktop ? "data-wrapper-flex space-between mb-0" : ""
                                            }`}
                                            labelClassName={!isDesktop ? "mb-0" : ""}
                                            label="payments.debin.confirm.table.header.id"
                                            data={id}
                                        />
                                    </Col>
                                    <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data ${
                                                !isDesktop ? "data-wrapper-flex space-between mb-0" : ""
                                            }`}
                                            labelClassName={!isDesktop ? "mb-0" : ""}
                                            label="payments.debin.detail.table.header.sender"
                                            data={sellerDetail.titular || <span> - </span>}
                                        />
                                    </Col>
                                    <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data ${
                                                !isDesktop ? "data-wrapper-flex space-between mb-0" : ""
                                            }`}
                                            labelClassName={!isDesktop ? "mb-0" : ""}
                                            label="payments.debin.confirm.table.header.documentType"
                                            data={`${sellerDetail.documentType} ${sellerDetail.documentNumber}`}
                                        />
                                    </Col>
                                    <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                !isDesktop ? " space-between mb-0" : ""
                                            }`}
                                            labelClassName={!isDesktop ? "mb-0" : ""}
                                            label="payments.debin.detail.table.header.amount"
                                            data={
                                                amount ? (
                                                    <FormattedAmount
                                                        currency={currency}
                                                        quantity={quantity}
                                                        className="data-desc"
                                                        notBold
                                                    />
                                                ) : (
                                                    <p> - </p>
                                                )
                                            }
                                        />
                                    </Col>
                                </Col>
                                <Col className="col col-12 px-0" md={12} xl={12} lg={12} sm={12}>
                                    <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                !isDesktop ? "space-between mb-0" : ""
                                            }`}
                                            labelClassName={!isDesktop ? "mb-0" : ""}
                                            label="payments.debin.confirm.table.header.creditAccount"
                                            data={accountUtils.getAccountNumber(sellerDetail.cbu)}
                                        />
                                    </Col>
                                    <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                !isDesktop ? " space-between mb-0" : ""
                                            }`}
                                            labelClassName={!isDesktop ? "mb-0" : ""}
                                            label={`payments.debin.confirm.table.header.type${
                                                sellerDetail.cbu?.startsWith("000") ? "cvu" : "cbu"
                                            }`}
                                            data={sellerDetail.cbu}
                                        />
                                    </Col>
                                    <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                !isDesktop ? "space-between mb-0" : ""
                                            }`}
                                            labelClassName={!isDesktop ? "mb-0" : ""}
                                            label={`payments.debin.confirm.table.header.${sellerDetail.cbu?.startsWith("000") ? "entity": "bank"}`}
                                            data={sellerDetail.bank ? sellerDetail.bank : <p> - </p>}
                                        />
                                    </Col>
                                    <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                !isDesktop ? "space-between mb-0" : ""
                                            }`}
                                            labelClassName={!isDesktop ? "mb-0" : ""}
                                            label="payments.debin.detail.table.header.reference"
                                            data={reference || <span> - </span>}
                                        />
                                    </Col>
                                </Col>
                            </Container>
                            <Container
                                className="flex-grow align-items-center container-white pt-3 pb-2"
                                gridClassName="form-content">
                                <Col className="col col-12 col-xl-4" sm={12} md={10} lg={6}>
                                    {credentials}
                                    {continueButton}
                                </Col>
                            </Container>
                        </Form>
                    </MainContainer>
                </PageLoading>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    debinDetail: debinSelectors.getDebinDetail(state),
    accountName: debinSelectors.getAccountName(state),
    loggedUser: sessionSelectors.getUser(state),
    fetchingAccounts: accountsSelectors.getFetching(state),
    accounts: accountsSelectors.getAccounts(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18nUtils.get(`${FORM_ID}.otp.error.required`)),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, debinDetail, accounts, loggedUser } = formikBag.props;

            const {
                id: idDebin,
                amount: { currency, quantity: amount },
                buyerDetail,
            } = debinDetail;

            const loggedUserAccount = accountUtils.getUserAccount(accounts, buyerDetail?.cbu);
            dispatch(
                debinActions.declineIncomingRequestSend(
                    currency,
                    amount,
                    idDebin,
                    otp,
                    loggedUserAccount,
                    loggedUser.userId,
                    formikBag,
                ),
            );
        },
    }),
)(DeclineDebinConfirm);
