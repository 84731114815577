import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool } from "prop-types";
import { Field, Form, withFormik } from "formik";

import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import { compose } from "redux";
import Button from "pages/_components/Button";
import Credential from "pages/_components/fields/credentials/Credential";
import Yup from "yup";
import * as i18nUtils from "util/i18n";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";

import { actions as ecomexActions, selectors as ecomexSelectors } from "reducers/ecomex";
import { selectors as sessionSelectors } from "reducers/session";

const FORM_ID = "transferSummary";
class EcomexDeleteTransaction extends Component {
    static propTypes = {
        handleSubmit: func.isRequired,
        isDesktop: bool.isRequired,
        fetching: bool.isRequired,
    };

    render() {
        const { isDesktop, handleSubmit, fetching } = this.props;

        return (
            <Container className="px-2">
                <Form
                    className="above-the-fold"
                    onSubmit={(e) => {
                        handleSubmit(e);
                        e.stopPropagation();
                    }}>
                    <Container className="align-items-center" gridClassName="form-content">
                        <I18n id="ecomex.transaction.delete.request" component="h1" />
                        <div className="admin-content-center pb-3">
                            <I18n
                                id="ecomex.transaction.delete.info"
                                component="p"
                                componentProps={{ className: "confirmation__text-without-otp" }}
                            />
                        </div>
                        <div className="admin-content-center">
                            <Field
                                name="otp"
                                type="otp"
                                idForm={FORM_ID}
                                component={Credential}
                                placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                            />
                        </div>

                        <Col className="confirmation__content-button" lg={12} md={12} sm={12}>
                            <Row>
                                <div className="admin-content-center">
                                    <Button
                                        image="images/arrowRight.svg"
                                        imageStyle={isDesktop ? "mr-2" : "mr-1"}
                                        type="submit"
                                        bsStyle="primary"
                                        label="global.confirm"
                                        loading={fetching}
                                    />
                                </div>
                            </Row>
                        </Col>
                    </Container>
                </Form>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    summary: ecomexSelectors.getSummary(state),
    userId: sessionSelectors.getUser(state).userId,
    envName: sessionSelectors.getActiveEnvironment(state).name,
    envDocument: sessionSelectors.getActiveEnvironment(state).clients[0]?.idClient,
    fetching: ecomexSelectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18nUtils.get(`${FORM_ID}.otp.error.required`)),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, summary, userId } = formikBag.props;
            const { transactionData } = summary;
            const { requestIdEcomex } = transactionData;

            const ecomexTransaction = {
                userId,
                idEnvironment: summary?.idEnvironment,
                lang: summary?.lang,
                channel: summary?.channel,
                transactionData: summary?.transactionData,
                _otp: otp,
                idTransaction: summary?.idTransaction,
                ecomexRequest: requestIdEcomex,
                deleting: true,
            };

            dispatch(ecomexActions.sendAcceptOrRejectEcomexTransaction(ecomexTransaction));
        },
    }),
)(EcomexDeleteTransaction);
