import { types as productsTypes } from "reducers/products";

export const types = {
    ACCOUNT_DETAILS_REQUEST: "accounts/ACCOUNT_DETAILS_REQUEST",
    ACCOUNT_PROYECTED_BALANCE_SUCCESS: "accounts/ACCOUNT_PROYECTED_BALANCE_SUCCESS",
    ACCOUNT_DIFERED_MOVEMENTS_SUCCESS: "accounts/ACCOUNT_DIFERED_MOVEMENTS_SUCCESS",
    ACCOUNT_DETAILS_SUCCESS: "accounts/ACCOUNT_DETAILS_SUCCESS",
    ACCOUNT_OPENING_DATA: "accounts/ACCOUNT_OPENING_DATA",
    CLEAR_ACCOUNT_OPENING_DATA: "accounts/CLEAR_ACCOUNT_OPENING_DATA",
    ACCOUNT_RESPONSE_DATA: "accounts/ACCOUNT_RESPONSE_DATA",
    OPENING_ACCOUNT: "accounts/OPENING_ACCOUNT",
    ACCOUNT_DOWNLOAD_STATEMENT_REQUEST: "accounts/ACCOUNT_DOWNLOAD_STATEMENT_REQUEST",
    ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS: "accounts/ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS",
    ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST: "accounts/ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST",
    ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS: "accounts/ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS",
    ACCOUNT_GET_HISTORIC_BALANCE_FAILURE: "accounts/ACCOUNT_GET_HISTORIC_BALANCE_FAILURE",
    ACCOUNT_GET_HISTORIC_BALANCE_REQUEST: "accounts/ACCOUNT_GET_HISTORIC_BALANCE_REQUEST",
    ACCOUNT_GET_HISTORIC_BALANCE_SUCCESS: "accounts/ACCOUNT_GET_HISTORIC_BALANCE_SUCCESS",
    ACCOUNT_MOVEMENT_DETAILS_REQUEST: "accounts/ACCOUNT_MOVEMENT_DETAILS_REQUEST",
    ACCOUNT_MOVEMENT_DETAILS_SUCCESS: "accounts/ACCOUNT_MOVEMENT_DETAILS_SUCCESS",
    ACCOUNT_READ_REQUEST: "accounts/ACCOUNT_READ_REQUEST",
    ACCOUNT_READ_SUCCESS: "accounts/ACCOUNT_READ_SUCCESS",
    CLOSE_OPTIONS: "accounts/CLOSE_OPTIONS",
    DOWNLOAD_MOVEMENTS_REQUEST: "accounts/DOWNLOAD_MOVEMENTS_REQUEST",
    DOWNLOAD_MOVEMENTS_FAILURE: "accounts/DOWNLOAD_MOVEMENTS_FAILURE",
    DOWNLOAD_MOVEMENTS_SUCCESS: "accounts/DOWNLOAD_MOVEMENTS_SUCCESS",
    DOWNLOAD_ACCOUNTS_LIST_REQUEST: "accounts/DOWNLOAD_ACCOUNTS_LIST_REQUEST",
    DOWNLOAD_ACCOUNTS_LIST_SUCCESS: "accounts/DOWNLOAD_ACCOUNTS_LIST_SUCCESS",
    DOWNLOAD_ACCOUNTS_LIST_FAILURE: "accounts/DOWNLOAD_ACCOUNTS_LIST_FAILURE",
    DOWNLOAD_HISTORIC_BALANCE_REQUEST: "accounts/DOWNLOAD_HISTORIC_BALANCE_REQUEST",
    DOWNLOAD_HISTORIC_BALANCE_SUCCESS: "accounts/DOWNLOAD_HISTORIC_BALANCE_SUCCESS",
    DOWNLOAD_HISTORIC_BALANCE_FAILURE: "accounts/DOWNLOAD_HISTORIC_BALANCE_FAILURE",
    EDIT_MOVEMENT_NOTE_REQUEST: "accounts/EDIT_MOVEMENT_NOTE_REQUEST",
    LIST_ACCOUNT_STATEMENTS_REQUEST: "accounts/LIST_ACCOUNT_STATEMENTS_REQUEST",
    LIST_ACCOUNT_STATEMENTS_SUCCESS: "accounts/LIST_ACCOUNT_STATEMENTS_SUCCESS",
    LIST_ACCOUNTS_REQUEST: "accounts/LIST_ACCOUNTS_REQUEST",
    LIST_ACCOUNTS_SUCCESS: "accounts/LIST_ACCOUNTS_SUCCESS",
    RESET_FILTERS: "accounts/RESET_FILTERS",
    SET_MOBILE_FILTER: "accounts/SET_MOBILE_FILTER",
    SET_SELECTED_ACCOUNT: "accounts/SET_SELECTED_ACCOUNT",
    SET_SELECTED_MOVEMENT: "accounts/SET_SELECTED_MOVEMENT",
    SET_UNIQUE_ACCOUNT: "accounts/SET_UNIQUE_ACCOUNT",
    SET_FAVORITE_ACCOUNT: "accounts/SET_FAVORITE_ACCOUNT",
    TOGGLE_OPTIONS: "accounts/TOGGLE_OPTIONS",
    ACCOUNT_FETCH_MOVEMENTS_REQUEST: "accounts/ACCOUNT_FETCH_MOVEMENTS_REQUEST",
    ACCOUNT_FETCH_MOVEMENTS_SUCCESS: "accounts/ACCOUNT_FETCH_MOVEMENTS_SUCCESS",
    ACCOUNT_PROYECTED_BALANCE: "accounts/ACCOUNT_PROYECTED_BALANCE",
    ACCOUNT_DIFERED_MOVEMENTS: "accounts/ACCOUNT_DIFERED_MOVEMENTS",
    ACCOUNT_DOWNLOAD_INFORMATION_REQUEST: "accounts/ACCOUNT_DOWNLOAD_INFORMATION_REQUEST",
    ACCOUNT_DOWNLOAD_INFORMATION_SUCCESS: "accounts/ACCOUNT_DOWNLOAD_INFORMATION_SUCCESS",
    MODIFY_CBU_ALIAS_REQUEST: "accounts/MODIFY_CBU_ALIAS_REQUEST",
    MODIFY_CBU_ALIAS_SUCCESS: "accounts/MODIFY_CBU_ALIAS_SUCCESS",
    MODIFY_CBU_ALIAS_RETURN_ERROR: "accounts/MODIFY_CBU_ALIAS_RETURN_ERROR",
    MODIFY_CBU_ALIAS_RETURN: "accounts/MODIFY_CBU_ALIAS_RETURN",
    MODIFY_CBU_ALIAS_PANEL_CLOSE: "accounts/MODIFY_CBU_ALIAS_PANEL_CLOSE",
    ACCOUNT_LATEST_MOVEMENTS_REQUEST: "accounts/ACCOUNT_LATEST_MOVEMENTS_REQUEST",
    ACCOUNT_LATEST_MOVEMENTS_SUCCESS: "accounts/ACCOUNT_LATEST_MOVEMENTS_SUCCESS",
    ACCOUNT_LATEST_MOVEMENTS_FAILURE: "accounts/ACCOUNT_LATEST_MOVEMENTS_FAILURE",
    ACCOUNT_REMOVE_MOVEMENTS: "accounts/ACCOUNT_REMOVE_MOVEMENTS",
    DOWNLOAD_LATEST_MOVEMENTS_REQUEST: "accounts/DOWNLOAD_LATEST_MOVEMENTS_REQUEST",
    DOWNLOAD_LATEST_MOVEMENTS_SUCCESS: "accounts/DOWNLOAD_LATEST_MOVEMENTS_SUCCESS",
    DOWNLOAD_LATEST_MOVEMENTS_FAILURE: "accounts/DOWNLOAD_LATEST_MOVEMENTS_FAILURE",
    ACCOUNT_SUMMARIES_QUERY_REQUEST: "accounts/ACCOUNT_SUMMARIES_QUERY_REQUEST",
    ACCOUNT_SUMMARIES_QUERY_SUCCESS: "accounts/ACCOUNT_SUMMARIES_QUERY_SUCCESS",
    ACCOUNT_SUMMARIES_QUERY_FAILURE: "accounts/ACCOUNT_SUMMARIES_QUERY_FAILURE",
    ACCOUNT_SUMMARIES_EXTRACT_REQUEST: "accounts/ACCOUNT_SUMMARIES_EXTRACT_REQUEST",
    ACCOUNT_SUMMARIES_EXTRACT_FINISH: "accounts/ACCOUNT_SUMMARIES_EXTRACT_FINISH",
    ACCOUNT_LATEST_MOVEMENTS_SELECTED: "accounts/LATEST_MOVEMENTS_SELECTED",
    ACCOUNT_SUMMARIES_CLEAN: "accounts/ACCOUNT_SUMMARIES_CLEAN",
    REMEMBER_ACCOUNTS_HREF: "accounts/REMEMBER_ACCOUNTS_HREF",
    ACCOUNT_SHARE_DATA: "accounts/ACCOUNT_SHARE_DATA",
};

export const INITIAL_STATE = {
    account: {}, // No encuentro que se utilice, dado que el detalle utiliza selectedAccount
    accounts: [],
    credentialGroups: [],
    equivalentTotalBalance: null,
    equivalentTotalBalanceInUSD: null,
    fetching: false,
    fetchingDownload: false,
    fetchingMovements: false,
    fetchingBalance: false,
    firstFetched: true,
    idSelectedAccount: null,
    mobileFilter: null,
    movements: [],
    moreMovements: false,
    pageNumber: 1,
    selectedAccount: null,
    proyectedBalances: null,
    diferedMovementsObject: null,
    selectedFilter: null,
    selectedMovement: null,
    statements: null,
    voucher: null,
    isOptionsVisible: false,
    historicBalances: null,
    dateFrom: null,
    dateTo: null,
    filters: {
        channels: [],
        check: null,
        dateFrom: null,
        dateTo: null,
        minAmount: null,
        maxAmount: null,
        reference: null,
    },
    accountOpening: null,
    totalCount: 0,
    fetchingAlias: false,
    latestMovements: [],
    pendingMovements: [],
    listDateToYear: null,
    idTransaction: null,
    isLatestMovementsSelected: true,
    accountsLastHref: "/desktop",
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        account,
        accountOpeningData,
        accounts,
        listProyectedBalances,
        diferedMovements,
        alias,
        findBy,
        dateFrom,
        dateTo,
        lastDateFrom,
        lastDateTo,
        pendingDateFrom,
        pendingDateTo,
        amountFrom,
        amountTo,
        voucher,
        detail,
        longDetail,
        equivalentTotalBalance,
        equivalentTotalBalanceInUSD,
        filters,
        historicBalances,
        historicBalancesTotalPages,
        historicBalancesPageNumber,
        idSelectedAccount,
        mobileFilter,
        moreStatements,
        pageNumber,
        selectedAccount,
        selectedMovement,
        statement,
        statements,
        statementLines,
        totalCount,
        credentialGroups,
        latestMovements,
        latestMovementsTotalPages,
        latestMovementsPageNumber,
        pendingMovements,
        pendingMovementsTotalPages,
        pendingMovementsPageNumber,
        listDateToYear,
        responseData,
        isLatestMovementsSelected,
        extractId,
        accountsLastHref,
    } = action;

    switch (action.type) {
        case types.CLOSE_OPTIONS:
            return {
                ...state,
                isOptionsVisible: false,
            };
        case types.SET_FAVORITE_ACCOUNT:
            return { ...state, fetching: true };
        case types.ACCOUNT_DETAILS_REQUEST:
            return {
                ...state,
                fetching: true,
                filters: filters || state.filters,
                movements: [],
                historicBalances: null,
                pageNumber: 1,
                fetchingDownload: false,
            };
        case types.ACCOUNT_DIFERED_MOVEMENTS:
        case types.ACCOUNT_PROYECTED_BALANCE:
            return {
                ...state,
                fetching: true,
            };
        case types.ACCOUNT_PROYECTED_BALANCE_SUCCESS:
            return {
                ...state,
                proyectedBalances: listProyectedBalances,
                fetching: false,
            };
        case types.ACCOUNT_DIFERED_MOVEMENTS_SUCCESS:
            return {
                ...state,
                diferedMovementsObject: diferedMovements,
                fetching: false,
            };
        case types.ACCOUNT_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                firstFetched: false,
                moreMovements: moreStatements,
                movements: statements,
                totalCount,
                selectedAccount: account,
                credentialGroups,
            };
        case types.ACCOUNT_READ_REQUEST:
            return {
                ...state,
                isOptionsVisible: false,
                fetching: true,
                idSelectedAccount,
                historicBalances: null,
                fetchingDownload: false,
            };
        case types.ACCOUNT_READ_SUCCESS:
            return {
                ...state,
                fetching: false,
                selectedAccount: account,
            };
        case types.ACCOUNT_OPENING_DATA:
            return {
                ...state,
                accountOpening: { ...state.accountOpening, ...accountOpeningData },
            };
        case types.CLEAR_ACCOUNT_OPENING_DATA:
            return {
                ...state,
                accountOpening: {},
            };
        case types.ACCOUNT_RESPONSE_DATA:
            return {
                ...state,
                accountOpening: { ...state.accountOpening, ...responseData },
            };
        case types.ACCOUNT_DOWNLOAD_STATEMENT_REQUEST:
            return { ...state, fetching: true };
        case types.ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS:
            return { ...state, fetchingDownload: false };
        case types.ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST:
            return {
                ...state,
                fetchingMovements: true,
            };
        case types.ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS:
            return {
                ...state,
                fetchingMovements: false,
                movements: state.movements ? state.movements.concat(statements) : statements,
                moreMovements: moreStatements,
                pageNumber,
            };
        case types.ACCOUNT_GET_HISTORIC_BALANCE_FAILURE:
            return { ...state, fetchingMovements: false };
        case types.ACCOUNT_GET_HISTORIC_BALANCE_REQUEST:
            return { ...state, dateFrom, dateTo, fetchingBalance: true };

        case types.ACCOUNT_GET_HISTORIC_BALANCE_SUCCESS:
            return {
                ...state,
                historicBalances,
                historicBalancesTotalPages,
                historicBalancesPageNumber,
                fetchingBalance: false,
            };
        case types.ACCOUNT_MOVEMENT_DETAILS_REQUEST:
            return { ...state, fetching: true };
        case types.ACCOUNT_MOVEMENT_DETAILS_SUCCESS:
            return { ...state, fetching: false, voucher: statement.voucher };
        case types.LIST_ACCOUNT_STATEMENTS_REQUEST:
            return {
                ...state,
                fetchingMovements: true,
            };
        case types.LIST_ACCOUNT_STATEMENTS_SUCCESS:
            return {
                ...state,
                fetchingMovements: false,
                statements: statementLines,
            };
        case types.LIST_ACCOUNTS_REQUEST:
            return {
                ...state,
                fetching: true,
                movements: [],
                moreMovements: false,
                selectedMovement: null,
                pageNumber: 1,
            };
        case types.LIST_ACCOUNTS_SUCCESS:
            return {
                ...state,
                accounts,
                equivalentTotalBalance,
                equivalentTotalBalanceInUSD,
                fetching: false,
                isOptionsVisible: false,
            };
        case types.RESET_FILTERS:
            return { ...state, filters: INITIAL_STATE.filters };
        case types.SET_MOBILE_FILTER:
            return {
                ...state,
                mobileFilter,
            };
        case types.SET_SELECTED_MOVEMENT:
            return {
                ...state,
                selectedMovement,
            };
        case types.SET_UNIQUE_ACCOUNT:
            return {
                ...state,
                accounts,
                equivalentTotalBalance,
                equivalentTotalBalanceInUSD,
                selectedAccount: accounts[0],
            };

        case types.TOGGLE_OPTIONS: {
            return {
                ...state,
                isOptionsVisible: !state.isOptionsVisible,
            };
        }

        case productsTypes.CHANGE_PRODUCT_ALIAS_SUCCESS: {
            const thereIsASelectedAccount = !!state.selectedAccount;
            if (thereIsASelectedAccount) {
                return {
                    ...state,
                    selectedAccount: {
                        ...state.selectedAccount,
                        productAlias: alias,
                    },
                };
            }
            return state;
        }
        case types.DOWNLOAD_MOVEMENTS_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_MOVEMENTS_FAILURE:
        case types.DOWNLOAD_MOVEMENTS_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_ACCOUNTS_LIST_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_ACCOUNTS_LIST_FAILURE:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_ACCOUNTS_LIST_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_HISTORIC_BALANCE_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_HISTORIC_BALANCE_FAILURE:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_HISTORIC_BALANCE_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };

        case types.ACCOUNT_FETCH_MOVEMENTS_REQUEST:
            return {
                ...state,
                pageNumber: 1,
                filters: action.filters,
                fetchingMovements: true,
            };
        case types.ACCOUNT_FETCH_MOVEMENTS_SUCCESS:
            return {
                ...state,
                fetchingMovements: false,
                movements: action.statements,
                moreMovements: action.moreStatements,
                totalCount: action.totalCount,
            };
        case types.ACCOUNT_DOWNLOAD_INFORMATION_REQUEST:
            return { ...state, fetchingDownload: true };
        case types.ACCOUNT_DOWNLOAD_INFORMATION_SUCCESS:
            return { ...state, fetching: false };
        case types.MODIFY_CBU_ALIAS_REQUEST:
            return { ...state, fetchingAlias: true };
        case types.MODIFY_CBU_ALIAS_RETURN:
            return { ...state, fetchingAlias: false };
        case types.MODIFY_CBU_ALIAS_RETURN_ERROR:
            return { ...state, fetchingAlias: false, aliasError: true };
        case types.MODIFY_CBU_ALIAS_SUCCESS:
            return {
                ...state,
                selectedAccount,
                fetchingAlias: false,
                aliasError: false,
            };
        case types.ACCOUNT_LATEST_MOVEMENTS_FAILURE:
            return { ...state, fetchingMovements: false };
        case types.ACCOUNT_LATEST_MOVEMENTS_REQUEST:
            return {
                ...state,
                latestMovementsPageNumber,
                pendingMovementsPageNumber,
                findBy,
                lastDateFrom,
                lastDateTo,
                pendingDateFrom,
                pendingDateTo,
                amountFrom,
                amountTo,
                voucher,
                detail,
                fetchingMovements: true,
                longDetail,
            };
        case types.ACCOUNT_LATEST_MOVEMENTS_SUCCESS:
            return {
                ...state,
                latestMovements,
                latestMovementsTotalPages,
                latestMovementsPageNumber,
                pendingMovements,
                pendingMovementsTotalPages,
                pendingMovementsPageNumber,
                fetchingMovements: false,
            };
        case types.ACCOUNT_REMOVE_MOVEMENTS:
            return {
                ...state,
                latestMovements: INITIAL_STATE.latestMovements,
                latestMovementsTotalPages: INITIAL_STATE.latestMovementsTotalPages,
                latestMovementsPageNumber: INITIAL_STATE.latestMovementsPageNumber,
                pendingMovements: INITIAL_STATE.pendingMovements,
                pendingMovementsTotalPages: INITIAL_STATE.pendingMovementsTotalPages,
                pendingMovementsPageNumber: INITIAL_STATE.pendingMovementsPageNumber,
            };
        case types.DOWNLOAD_LATEST_MOVEMENTS_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
                isFetchingMovements: true,
            };
        case types.DOWNLOAD_LATEST_MOVEMENTS_FAILURE:
            return {
                ...state,
                fetchingDownload: false,
                isFetchingMovements: false,
            };
        case types.DOWNLOAD_LATEST_MOVEMENTS_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
                isFetchingMovements: false,
            };
        case types.ACCOUNT_SUMMARIES_QUERY_REQUEST:
            return {
                ...state,
                fetchingMovements: true,
            };
        case types.ACCOUNT_SUMMARIES_QUERY_SUCCESS:
            return {
                ...state,
                listDateToYear,
                fetchingMovements: false,
            };
        case types.ACCOUNT_SUMMARIES_EXTRACT_FINISH:
            return {
                ...state,
                [`${extractId}Downloading`]: false,
            };
        case types.ACCOUNT_SUMMARIES_EXTRACT_REQUEST:
            return {
                ...state,
                [`${extractId}Downloading`]: true,
            };

        case types.ACCOUNT_LATEST_MOVEMENTS_SELECTED:
            return {
                ...state,
                isLatestMovementsSelected,
            };
        case types.ACCOUNT_SUMMARIES_CLEAN:
            return {
                ...state,
                listDateToYear: null,
            };
        case types.REMEMBER_ACCOUNTS_HREF:
            return {
                ...state,
                accountsLastHref,
            };
        default:
            return state;
    }
};

export const actions = {
    closeOptions: () => ({
        type: types.CLOSE_OPTIONS,
    }),
    details: (idSelectedAccount, userId, filters, formikBag) => ({
        type: types.ACCOUNT_DETAILS_REQUEST,
        idSelectedAccount,
        userId,
        filters,
        formikBag,
    }),
    accountOpeningData: (openingData) => ({
        type: types.ACCOUNT_OPENING_DATA,
        accountOpeningData: openingData,
    }),
    clearOpeningData: () => ({
        type: types.CLEAR_ACCOUNT_OPENING_DATA,
    }),
    proyectedBalance: (accountId) => ({
        type: types.ACCOUNT_PROYECTED_BALANCE,
        accountId,
    }),
    diferedMovements: (accountId, pageNumber) => ({
        type: types.ACCOUNT_DIFERED_MOVEMENTS,
        accountId,
        pageNumber,
    }),
    downloadMovements: (idAccount, format) => ({
        type: types.DOWNLOAD_MOVEMENTS_REQUEST,
        idAccount,
        format,
    }),
    downloadStatement: (idAccount, idStatement) => ({
        type: types.ACCOUNT_DOWNLOAD_STATEMENT_REQUEST,
        idAccount,
        idStatement,
    }),
    editMovementNote: (accountId, movementId, note) => ({
        type: types.EDIT_MOVEMENT_NOTE_REQUEST,
        accountId,
        movementId,
        note,
    }),
    openingAccount: (openingAccountData, otp, userId, formikBag) => ({
        type: types.OPENING_ACCOUNT,
        openingAccountData,
        otp,
        userId,
        formikBag,
    }),
    downloadAccountsList: (format) => ({
        type: types.DOWNLOAD_ACCOUNTS_LIST_REQUEST,
        format,
    }),
    downloadHistoricBalance: (accountId, format, dateFrom, dateTo) => ({
        type: types.DOWNLOAD_HISTORIC_BALANCE_REQUEST,
        accountId,
        format,
        dateFrom,
        dateTo,
    }),
    fetchMoreMovements: (accountId, filters) => ({
        type: types.ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST,
        accountId,
        filters,
    }),
    historicBalance: ({ dateFrom, dateTo, selectedAccount, numberPage }) => ({
        type: types.ACCOUNT_GET_HISTORIC_BALANCE_REQUEST,
        dateFrom,
        dateTo,
        selectedAccount,
        numberPage,
    }),
    listAccounts: (userId) => ({
        type: types.LIST_ACCOUNTS_REQUEST,
        userId,
    }),
    listStatements: (idAccount) => ({
        type: types.LIST_ACCOUNT_STATEMENTS_REQUEST,
        idAccount,
    }),
    movementDetails: (idAccount, idStatement) => ({
        type: types.ACCOUNT_MOVEMENT_DETAILS_REQUEST,
        idAccount,
        idStatement,
    }),
    readAccount: (idAccount) => ({
        type: types.ACCOUNT_READ_REQUEST,
        idAccount,
    }),
    resetFilters: () => ({
        type: types.RESET_FILTERS,
    }),
    setMobileFilter: (mobileFilter) => ({
        type: types.SET_MOBILE_FILTER,
        mobileFilter,
    }),
    setSelectedMovement: (selectedMovement) => ({
        type: types.SET_SELECTED_MOVEMENT,
        selectedMovement,
    }),
    setFavoriteAccount: (idProduct, userId, favorite) => ({
        type: types.SET_FAVORITE_ACCOUNT,
        idProduct,
        userId,
        favorite,
    }),
    toggleOptions: () => ({
        type: types.TOGGLE_OPTIONS,
    }),
    fetchMovements: (accountId, filters) => ({
        type: types.ACCOUNT_FETCH_MOVEMENTS_REQUEST,
        accountId,
        filters,
    }),
    downloadInformation: (idAccount, data) => ({
        type: types.ACCOUNT_DOWNLOAD_INFORMATION_REQUEST,
        idAccount,
        data,
    }),
    modifyCbuAlias: (idAccount, cuit, cbu, cbuAlias, newAlias, credentials, formikBag) => ({
        type: types.MODIFY_CBU_ALIAS_REQUEST,
        idAccount,
        cuit,
        cbu,
        cbuAlias,
        newAlias,
        credentials,
        formikBag,
    }),
    fetchLatestMovements: ({
        selectedAccount,
        latestMovementsPageNumber,
        pendingMovementsPageNumber,
        findBy,
        lastDateFrom,
        lastDateTo,
        pendingDateFrom,
        pendingDateTo,
        amountFrom,
        amountTo,
        voucher,
        detail,
        type: movementType,
        isLatestMovementsSelected,
        longDetail,
    }) => ({
        type: types.ACCOUNT_LATEST_MOVEMENTS_REQUEST,
        selectedAccount,
        latestMovementsPageNumber,
        pendingMovementsPageNumber,
        findBy,
        lastDateFrom,
        lastDateTo,
        pendingDateFrom,
        pendingDateTo,
        amountFrom,
        amountTo,
        voucher,
        detail,
        movementType,
        isLatestMovementsSelected,
        longDetail,
    }),
    removeMovements: () => ({
        type: types.ACCOUNT_REMOVE_MOVEMENTS,
    }),
    downloadLatestMovements: ({
        format,
        isLatestMovementsSelected,
        latestMovementsPageNumber,
        pendingMovementsPageNumber,
        findBy,
        dateFrom,
        dateTo,
        selectedAccount,
        pageNumber,
        amountFrom,
        amountTo,
        voucher,
        detail,
        type: movementType,
    }) => ({
        type: types.DOWNLOAD_LATEST_MOVEMENTS_REQUEST,
        format,
        isLatestMovementsSelected,
        latestMovementsPageNumber,
        pendingMovementsPageNumber,
        findBy,
        dateFrom,
        dateTo,
        selectedAccount,
        pageNumber,
        amountFrom,
        amountTo,
        voucher,
        detail,
        movementType,
    }),
    summariesQuery: (accountId, year, month) => ({
        type: types.ACCOUNT_SUMMARIES_QUERY_REQUEST,
        accountId,
        year,
        month,
    }),
    summariesExtract: (extractId, productType) => ({
        type: types.ACCOUNT_SUMMARIES_EXTRACT_REQUEST,
        extractId,
        productType,
    }),
    setIsLatestMovements: (isLatestMovementsSelected) => ({
        type: types.ACCOUNT_LATEST_MOVEMENTS_SELECTED,
        isLatestMovementsSelected,
    }),
    cleanAccountSummaries: () => ({
        type: types.ACCOUNT_SUMMARIES_CLEAN,
    }),
    accountsLastHref: (accountsLastHref) => ({
        type: types.REMEMBER_ACCOUNTS_HREF,
        accountsLastHref,
    }),
    shareAccount: (contentData) => ({
        type: types.ACCOUNT_SHARE_DATA,
        contentData,
    }),
};

export const selectors = {
    getAccount: ({ accounts }) => accounts.account,
    getAccounts: ({ accounts }) => accounts.accounts,
    getAccountOpeningData: ({ accounts }) => accounts.accountOpening,
    getTotalAccounts: ({ accounts }) => accounts.accounts.length,
    getUSDTotalBalance: ({ accounts }) => accounts.equivalentTotalBalanceInUSD,
    getARSTotalBalance: ({ accounts }) => accounts.equivalentTotalBalance,
    getFetching: ({ accounts }) => accounts.fetching,
    getFetchingExtractDownload: ({ accounts }, extractId) => accounts[`${extractId}Downloading`],
    getFetchingDownload: ({ accounts }) => accounts.fetchingDownload,
    getFetchingMovements: ({ accounts }) => accounts.fetchingMovements,
    getFilters: ({ accounts }) => accounts.filters,
    getFirstFetched: ({ accounts }) => accounts.firstFetched,
    getHistoricBalanceDateTo: ({ accounts }) => accounts.dateTo,
    getHistoricBalanceDateFrom: ({ accounts }) => accounts.dateFrom,
    getHistoricBalances: ({ accounts }) => accounts.historicBalances,
    getHistoricBalancesPageNumber: ({ accounts }) => accounts.historicBalancesPageNumber,
    getHistoricBalancesTotalPages: ({ accounts }) => accounts.historicBalancesTotalPages,
    getIdSelectedAccount: ({ accounts }) => accounts.idSelectedAccount,
    getIsOptionsVisible: ({ accounts }) => accounts.isOptionsVisible,
    getSelectedAccount: ({ accounts }) => accounts.selectedAccount,
    getProyectedBalances: ({ accounts }) => accounts.proyectedBalances,
    getDiferedMovements: ({ accounts }) => accounts.diferedMovementsObject,
    getSelectedFilter: ({ accounts }) => accounts.selectedFilter,
    getSelectedMovement: ({ accounts }) => accounts.selectedMovement,
    getStatements: ({ accounts }) => accounts.statements,
    getPageNumber: ({ accounts }) => accounts.pageNumber,
    getMobileFilter: ({ accounts }) => accounts.mobileFilter,
    getMoreMovements: ({ accounts }) => accounts.moreMovements,
    getMovements: ({ accounts }) => accounts.movements,
    getVoucher: ({ accounts }) => accounts.voucher,
    getTotalCount: ({ accounts }) => accounts.totalCount,
    getFetchingAlias: ({ accounts }) => accounts.fetchingAlias,
    getAliasError: ({ accounts }) => accounts.aliasError,
    getCredentialGroups: ({ accounts }) => accounts.credentialGroups,
    getLatestMovements: ({ accounts }) => accounts.latestMovements,
    getLatestMovementsPageNumber: ({ accounts }) => accounts.latestMovementsPageNumber,
    getLatestMovementsTotalPages: ({ accounts }) => accounts.latestMovementsTotalPages,
    getPendingMovements: ({ accounts }) => accounts.pendingMovements,
    getPendingMovementsPageNumber: ({ accounts }) => accounts.pendingMovementsPageNumber,
    getPendingMovementsTotalPages: ({ accounts }) => accounts.pendingMovementsTotalPages,
    getListDateToYear: ({ accounts }) => accounts.listDateToYear,
    isLatestMovementsSelected: ({ accounts }) => accounts.isLatestMovementsSelected,
    getFetchingBalance: ({ accounts }) => accounts.fetchingBalance,
    getAccountsLastHref: ({ accounts }) => accounts.accountsLastHref,
};
