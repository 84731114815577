import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { bool, func, shape, string } from "prop-types";
import Yup from "yup";
import Button from "pages/_components/Button";
import { Form, Field, withFormik } from "formik";
import { compose } from "redux";
import classNames from "classnames";

import * as i18nUtils from "util/i18n";

import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import { actions, selectors } from "reducers/frequentDestination";
import { routerActions } from "react-router-redux/actions";
import Credential from "pages/_components/fields/credentials/Credential";
import Heading from "pages/_components/Heading";
import isTokenActive from "util/token";
import { selectors as statusSelectors } from "reducers/status";

const FORM_ID = "deleteFrequentDestination";

class CreateFrequentDestinationConfirmations extends Component {
    static propTypes = {
        fetching: bool.isRequired,
        frequentDestination: {},
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        match: shape({
            params: shape({
                idTransaction: string.isRequired,
            }).isRequired,
        }).isRequired,
        lastPathname: string.isRequired,
    };

    static defaultProps = {
        frequentDestination: {},
    };

    componentDidMount() {
        const { dispatch, match, frequentDestination } = this.props;
        const idFrequentDestination = match.params.id;

        if (!frequentDestination.idFrequentDestination) {
            dispatch(actions.deleteFrequentDestinationPreviewRequest(idFrequentDestination));
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18nUtils.get("frequentDestination.delete.title")}</h1>
        </div>
    );

    render() {
        const { fetching, frequentDestination, isDesktop } = this.props;

        const containerClassName = `transfer-data data-wrapper-flex ${
            !isDesktop ? "space-between mb-0 container-details" : "mb-1"
        }`;
        const labelClassName = isDesktop ? "mb-0" : "";

        return (
            <Fragment>
                <Notification scopeToShow="frequentDestinationDeleteConfirmation" />
                <div className={`admin-detail-head px-0 ${isDesktop ? "" : "mb-0"}`}>
                    <Head
                        onBack={this.handleBack}
                        closeLinkTo="/FrequentDestination"
                        centerElement={isDesktop ? null : this.centerContentMobile}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        emptyRightOption
                    />
                    {isDesktop && (
                        <div className="view-title mb-3">
                            <h1>{i18nUtils.get("frequentDestination.delete.title")}</h1>
                        </div>
                    )}
                </div>
                <MainContainer showLoader={fetching} viewPageClassName="confirmation__container">
                    <Form className={!isDesktop && "above-the-fold"}>
                        <Container
                            className={!isDesktop && "align-items-center flex-grow container-white with-margin"}
                            gridClassName={!isDesktop ? "form-content" : "confirmation__form confirmation__box"}>
                            <div className={classNames("admin-content-center", { "mb-3": !isDesktop })}>
                                <I18n
                                    id="frequentDestination.delete.subtitle"
                                    component="h1"
                                    componentProps={!isDesktop && { className: "font-size-20-px mx-3" }}
                                />
                            </div>
                            <Col sm={6} lg={12} className="col col-12 col-xl-12">
                                <Col className="col col-12 px-0 col-xl-12" sm={12} lg={12}>
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.numberAccount"
                                        data={`${frequentDestination.accountNumber}`}
                                    />
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.accountType"
                                        data={`${frequentDestination.productType}`}
                                    />
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.own"
                                        data={`${frequentDestination.isOwn === "1" ? "SI" : "NO"}`}
                                    />
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.cbu"
                                        data={`${frequentDestination.cbu}`}
                                    />
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.document"
                                        data={`${frequentDestination.recipientDocumentType}
                                            ${frequentDestination.recipientDocumentNumber}`}
                                    />
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.recipientName"
                                        data={`${frequentDestination.recipientName}`}
                                    />
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.bank"
                                        data={`${frequentDestination.localBank}/${frequentDestination.subsidiaryBank}`}
                                    />
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.email"
                                        data={frequentDestination.recipientEmail}
                                    />
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.referent"
                                        data={`${frequentDestination.name}`}
                                    />

                                    {!isTokenActive(!isDesktop) ? (
                                        <div className="admin-content-center mt-5">
                                            <I18n
                                                id="transfer.summary.confirm.label"
                                                component="p"
                                                componentProps={{
                                                    className: "account-dropdown-header-font font-light px-3",
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div className="admin-content-center pb-3">
                                            <I18n
                                                id="confirmation.withoutotp.text"
                                                component="p"
                                                componentProps={{ className: "confirmation__text-without-otp" }}
                                            />
                                        </div>
                                    )}
                                </Col>
                            </Col>
                            <Col sm={12} lg={12} className="col col-12 d-flex justify-content-center col-xl-12">
                                <Col sm={12} md={6} lg={12} className="col col-12 account__solicitude-pass px-0">
                                    <Field
                                        placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                                        name="otp"
                                        type="otp"
                                        idForm={FORM_ID}
                                        component={Credential}
                                    />
                                </Col>
                            </Col>
                            <Container className="flex-grow align-items-center w-100" gridClassName="form-content">
                                <Col lg={12} md={12} sm={12} className="px-0">
                                    <Col className="confirmation__content-button" lg={12} md={12} sm={12}>
                                        <div className="admin-content-center">
                                            <Button
                                                type="submit"
                                                bsStyle="primary"
                                                label="global.confirm"
                                                loading={fetching}
                                                image="images/arrowRight.svg"
                                                imageStyle={isDesktop ? "mr-2" : "mr-1"}
                                                className="text-capitalize"
                                            />
                                        </div>
                                    </Col>
                                </Col>
                            </Container>
                        </Container>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    credentialGroups: selectors.getCredentialGroups(state),
    frequentDestination: selectors.getFrequentDestinationDelete(state),
    fetching: selectors.getFetching(state),
    lastPathname: statusSelectors.getPathname(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18nUtils.get("accounts.new.account.otp.error.required")),
            }),

        handleSubmit: ({ otp }, formikBag) => {
            const { frequentDestination, dispatch, lastPathname } = formikBag.props;
            const { idFrequentDestination } = frequentDestination;
            dispatch(actions.deleteFrequentDestinationRequest(idFrequentDestination, otp, formikBag, lastPathname));
        },
    }),
)(CreateFrequentDestinationConfirmations);
