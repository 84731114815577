/* eslint-disable import/no-unresolved */
import {
    BottomSheet,
    Box,
    Button,
    Card,
    Container,
    FormattedDate,
    List,
    Separator,
    Tag,
    Text,
    ThemeProvider,
    useMediaQuery,
} from "@ui-kit/components/index.es";
import { deleteDraftRequest } from "middleware/transactions";
import moment from "moment";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as formActions } from "reducers/form";
import { selectors as agendaSelectors } from "reducers/frequentDestination";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import { actions as transferActions, selectors as transferSelectors } from "reducers/transfer";
import * as configUtils from "util/config";
import * as i18n from "util/i18n";
import { findScheduleDesc } from "util/scheduler";
import getTransferPath, { itemIsClickeable } from "util/transfers";
import TransferHistoricFilters from "../../transfer/_components/TransferHistoricFilters";
import TransfersAgenda from "../../transfer/_components/TransfersAgenda";

const FORM_ID = "transfers.historic";

function TransferHistoric(props) {
    TransferHistoric.propTypes = {
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        fetchingHistoric: bool.isRequired,
        isDesktop: bool.isRequired,
        fetchingMore: bool.isRequired,
        transactions: arrayOf().isRequired,
        selectedTransactionRows: arrayOf().isRequired,
        totalPages: number.isRequired,
        pageNumber: number.isRequired,
        filters: shape({}).isRequired,
        activeEnvironment: shape({}).isRequired,
        location: shape({}).isRequired,
        selectedChip: string.isRequired,
        filtersHistoric: shape({}).isRequired,
        filtersPending: shape({}).isRequired,
        filtersDraft: shape({}).isRequired,
        filtersScheduled: shape({}).isRequired,
        onlyMyPendings: bool.isRequired,
    };
    TransferHistoric.defaultProps = {};

    const {
        dispatch,
        fetching,
        fetchingHistoric,
        isDesktop,
        fetchingMore,
        transactions,
        totalPages,
        pageNumber,
        filters,
        selectedTransactionRows,
        selectedChip,
        filtersHistoric,
        filtersPending,
        filtersDraft,
        filtersScheduled,
        activeEnvironment,
        onlyMyPendings,
    } = props;
    const [showTransferOptionsMb, setShowTransferOptionsMb] = useState(false);
    const [orderBy, setOrderBy] = useState("submit_date_time DESC");
    const [selectedCurrency, setSelectedCurrency] = useState(0);
    const [formikProps, setFormikProps] = useState({});

    const isMobile = useMediaQuery("min", "md");

    const dateFrom = new Date();
    dateFrom.setMonth(dateFrom.getMonth() - 1);
    const dateTo = new Date();
    const defaultFilters = {
        orderBy: "submit_date_time DESC",
        dateType: "submit_date_time",
        dateFrom,
        dateTo,
        pageNumber: 1,
        filter: "last",
        onlyTransfers: true,
        transferType: {
            label: i18n.get(`${FORM_ID}.all`),
            value: "all",
        },
        onlyPendings: false,
    };

    const getDefaultFilter = (selectedOption) => {
        const basicDataFilter = {
            dateFrom,
            dateTo,
            pageNumber: 1,
            filter: "last",
            onlyTransfers: true,
            transferType: {
                label: i18n.get(`${FORM_ID}.all`),
                value: "all",
            },
            onlyPendings: false,
        };
        const scheduledDateFrom = new Date();
        const limitMonths = configUtils.getInteger("frontend.scheduledTransactions.allowedPeriod.Months", 36);
        scheduledDateFrom.setMonth(scheduledDateFrom.getMonth() - limitMonths);

        switch (selectedOption) {
            case "historic":
                return { ...basicDataFilter, orderBy: "submit_date_time DESC", dateType: "submit_date_time" };
            case "PENDING":
                return {
                    ...basicDataFilter,
                    orderBy: "creation_date_time DESC",
                    dateType: "creationDate",
                    status: "PENDING",
                };
            case "SCHEDULED":
                return {
                    ...basicDataFilter,
                    dateFrom: scheduledDateFrom,
                    orderBy: "creation_date_time DESC",
                    dateType: "creation_date_time",
                    status: "SCHEDULED",
                };
            case "DRAFT":
                return {
                    ...basicDataFilter,
                    orderBy: "creation_date_time DESC",
                    dateType: "creationDate",
                    status: "DRAFT",
                };
            default:
                return { ...basicDataFilter };
        }
    };

    useEffect(() => {
        const { location } = props;
        let onlyPendings = onlyMyPendings;
        if (!isDesktop) {
            dispatch(transferActions.resetListTransfers());
        }
        if (selectedChip !== "PENDING") {
            onlyPendings = false;
            dispatch(transferActions.setOnlyMyPendings(false));
        }
        if (!localStorage.getItem("flow") || localStorage.getItem("flow") !== "/v2/transfer/historic") {
            localStorage.setItem("flow", JSON.stringify("/v2/transfer/historic"));
        }
        dispatch(
            transferActions.loadListRequest(
                {
                    ...filters,
                    dateFrom: filters.dateFrom || dateFrom,
                    dateTo: filters.dateTo || dateTo,
                    pageNumber: isDesktop ? pageNumber : 1,
                },
                onlyPendings,
                false,
            ),
        );
        dispatch(
            transferActions.loadPendingQuantityRequest(
                {
                    ...filters,
                    status: "PENDING",
                    dateFrom: filters.dateFrom || dateFrom,
                    dateTo: filters.dateTo || dateTo,
                    pageNumber: isDesktop ? pageNumber : 1,
                },
                true,
                false,
            ),
        );
        dispatch(transactionsActions.saveTransactionRoute(location?.pathname));
        dispatch(transferActions.preActivity());
    }, []);

    const cleanSelectedRows = () => {
        dispatch(transactionsActions.clearSelectedTransactionRows());
    };

    const handleResetFilters = (selectedOption) => {
        switch (selectedOption) {
            case "historic":
                dispatch(transferActions.resetHistoric());
                break;
            case "PENDING":
                dispatch(transferActions.resetPendingFilters());
                break;
            case "SCHEDULED":
                dispatch(transferActions.resetScheduledFilters());
                break;
            case "DRAFT":
                dispatch(transferActions.resetDraftFilters());
                break;
            default:
        }
    };

    const getFiltersByOption = (selectedOption) => {
        switch (selectedOption) {
            case "historic":
                return { ...filtersHistoric };
            case "PENDING":
                return { ...filtersPending };
            case "SCHEDULED":
                return { ...filtersScheduled };
            case "DRAFT":
                return { ...filtersDraft };
            default:
                return {};
        }
    };

    const resetFilters = (selectedOption) => {
        cleanSelectedRows();
        handleResetFilters(selectedOption);
        const defaultOptionFilter = getDefaultFilter(selectedOption || selectedChip);
        dispatch(transferActions.loadListRequest({ ...defaultOptionFilter }, onlyMyPendings || false, false));
    };

    const handleFilterObjectType = (selectedFilters, selectedChipValue) => {
        switch (selectedChipValue) {
            case "historic":
                dispatch(transferActions.setFiltersHistoric(selectedFilters));
                break;
            case "PENDING":
                dispatch(transferActions.setFiltersPending(selectedFilters));
                break;
            case "SCHEDULED":
                dispatch(transferActions.setFiltersScheduled(selectedFilters));
                break;
            case "DRAFT":
                dispatch(transferActions.setFiltersDraft(selectedFilters));
                break;
            default:
        }
    };

    const onSubmit = (selectedFilters) => {
        const { transferType, minAmount, maxAmount, selectedOption, onlyMyPendings, status } = selectedFilters;
        const statusFromHistoric = filters?.status;
        cleanSelectedRows();
        dispatch(transferActions.setFilters(selectedFilters));
        dispatch(transferActions.setOnlyMyPendings(onlyMyPendings));
        const minAmountFormated = minAmount ? parseFloat(minAmount.replace(/\./g, "").replace(",", ".")) : undefined;
        const maxAmountFormated = maxAmount ? parseFloat(maxAmount.replace(/\./g, "").replace(",", ".")) : undefined;

        const filtersToSend = {
            ...filters,
            ...selectedFilters,
            minAmount:
                minAmountFormated !== undefined
                    ? {
                          currency: selectedCurrency,
                          amount: minAmountFormated,
                      }
                    : undefined,
            maxAmount:
                maxAmountFormated !== undefined
                    ? {
                          currency: selectedCurrency,
                          amount: maxAmountFormated,
                      }
                    : undefined,
            idActivity: transferType.value !== "all" ? transferType.value : undefined,
            pageNumber: 1,
            filter: "last",
            onlyTransfers: true,
            status: status || (selectedOption === "historic" ? statusFromHistoric : selectedOption),
            hasSummitted: true,
        };

        handleFilterObjectType(filtersToSend, selectedOption);
        dispatch(transferActions.loadListRequest(filtersToSend, onlyMyPendings, false));
    };

    const handleFilterButtonsClick = (fieldName = "orderBy", value = "submit_date_time DESC") => {
        cleanSelectedRows();
        let filtersToSend;
        if (filters === null) {
            filtersToSend = {
                orderBy,
                dateFrom,
                dateTo,
                pageNumber: 1,
                filter: "last",
                onlyTransfers: true,
            };
        } else {
            filtersToSend = {
                ...filters,
                [fieldName]: value,
            };
            if (value === null) {
                delete filtersToSend.fieldName;
            }
        }

        handleFilterObjectType(filtersToSend, filtersToSend?.selectedOption || selectedChip);
        dispatch(transferActions.loadListRequest(filtersToSend, onlyMyPendings || false, false));
    };

    const setOrder = (value) => {
        setOrderBy(value);
        handleFilterButtonsClick("orderBy", value);
    };

    const onSelectCurrency = (currency) => {
        setSelectedCurrency(currency);
        dispatch(transferActions.setFilters({ ...filters, minAmountCurrency: currency, maxAmountCurrency: currency }));
    };

    const filterByStatus = (status) => {
        handleFilterButtonsClick("status", status === "TODOS" ? null : status);
    };

    const fetchMoreTransactions = (newPageNumber) => {
        // eslint-disable-next-line no-shadow
        let { filters } = props;
        filters = filters
            ? { ...filters, pageNumber: newPageNumber }
            : { ...defaultFilters, pageNumber: newPageNumber };
        dispatch(transactionsActions.clearSelectedTransactionRows());
        if (isDesktop) {
            dispatch(transferActions.loadListRequest(filters, onlyMyPendings || false, false));
        } else {
            dispatch(transferActions.loadMoreTransfersRequest(filters, onlyMyPendings || false, false));
        }
    };

    const paginationProps = {
        totalPages,
        pageNumber,
        action: fetchMoreTransactions,
    };

    const onClickOnCard = (idTransaction) => {
        dispatch(routerActions.push(`/transaction/${idTransaction}`));
    };

    const formatDate = (date) => {
        const dateToParse = moment(date).format();
        return new Date(dateToParse);
    };

    const handleSelect = (selected, transaction) => {
        if (selected) {
            dispatch(transactionsActions.saveSelectedTransactionRows(transaction));
        } else {
            dispatch(transactionsActions.removeSelectedTransactionRows(transaction));
        }
    };

    const getBeneficiary = (transaction) => {
        const { idActivity, idTransactionStatus } = transaction;
        // eslint-disable-next-line no-shadow
        const { activeEnvironment } = props;
        if (
            idTransactionStatus === "DRAFT" &&
            (idActivity === "transfers.third.parties.send" || idActivity === "transfers.internal.send")
        ) {
            return {
                cuitCuil: activeEnvironment.clients[0].idClient,
                name: activeEnvironment.name,
            };
        }
        return {
            cuitCuil: transaction.data.clientUser?.cuil,
            name: transaction.data.clientUser?.firstName,
        };
    };

    const getDate = (transaction) => {
        const { idTransactionStatus } = transaction;
        if (idTransactionStatus === "PENDING" || idTransactionStatus === "DRAFT") {
            return formatDate(transaction.creationDateTime);
        }
        return formatDate(transaction.submitDateTime);
    };

    const handleSingleDeleteDrafts = async (idTransaction) => {
        const response = await deleteDraftRequest(idTransaction);
        const { data } = response.data;
        if (data.deleted) {
            const message = i18n.get(`${FORM_ID}.delete.success.singular`);
            dispatch(notificationActions.showNotification(message, "success", ["transfersHistoric"]));
        } else {
            dispatch(
                notificationActions.showNotification(i18n.get(`${FORM_ID}.delete.warn`), "warn", ["transfersHistoric"]),
            );
        }
        onSubmit(filters);
    };

    const handleCancelScheduled = async (idTransaction) => {
        dispatch(transferActions.readCancelScheduled(idTransaction));
    };

    const handleDownloadTransaction = async (idTransaction) => {
        dispatch(transferActions.readDownloadTransaction(idTransaction, dispatch, activeEnvironment));
    };

    const handleCopyTransaction = (transactionData) => {
        const {
            idActivity,
            reference,
            email,
            emailText,
            debitAccount,
            creditAccount,
            cbu,
            amount,
            sendEmail,
            scheduler,
            concept,
            transferKind,
        } = transactionData;
        if (idActivity === "transfers.internal.send" || idActivity === "transfers.internal.tad.send") {
            dispatch(
                transferActions.loadInternalTransfer({
                    debitAccount: { value: debitAccount },
                    creditAccount: { value: creditAccount },
                    amount: amount.quantity,
                    scheduler,
                    reference,
                    sendEmail,
                    email,
                    emailText,
                    nonEditable: true,
                }),
            );
            dispatch(push("/v2/transfer/internal/"));
        } else if (idActivity === "transfers.thirdParties.send" || idActivity === "transfers.thirdParties.tad.send") {
            dispatch(
                transferActions.loadExternalTransfer({
                    debitAccount: { value: debitAccount },
                    cbu,
                    amount: amount.quantity,
                    scheduler,
                    reference,
                    sendEmail,
                    email,
                    emailText,
                    concept,
                    transferKind,
                    nonEditable: true,
                }),
            );
            dispatch(push("/v2/transfer/thirdParties/"));
        } else {
            dispatch(
                transferActions.loadExternalTransfer({
                    debitAccount: { value: debitAccount },
                    cbu,
                    amount: amount.quantity,
                    scheduler,
                    reference,
                    sendEmail,
                    email,
                    emailText,
                    concept,
                    transferKind,
                    nonEditable: true,
                }),
            );
            dispatch(push("/v2/transfer/toContact/"));
        }
    };

    const handleModifyPendingTransaction = (idTransaction) => {
        dispatch(formActions.modifyTransaction(idTransaction));
    };

    const handleRepeatTransaction = (transfer) => {
        const { accounts } = props;
        const { transaction } = transfer;
        const { idActivity, data } = transaction;
        const {
            debitAccount,
            creditAccount,
            cbu,
            alias,
            currency,
            amount,
            reference,
            sendEmail,
            email,
            emailText,
            scheduler,
            tadTrx,
            concept,
            transferKind,
        } = data;

        const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === debitAccount);
        const completeCreditAccount = accounts.find(({ idProduct }) => idProduct === creditAccount);
        if (idActivity === "transfers.internal.send" || idActivity === "transfers.internal.tad.send") {
            dispatch(
                transferActions.createInternalTransfer(
                    {
                        amount: parseFloat(amount.quantity),
                        currency,
                        debitAccount: completeDebitAccount,
                        creditAccount: completeCreditAccount,
                        reference,
                        sendEmail,
                        email,
                        emailText,
                        scheduler,
                        tadTrx,
                    },
                    null,
                ),
            );
        } else if (idActivity === "transfers.thirdParties.send" || idActivity === "transfers.thirdParties.tad.send") {
            dispatch(
                transferActions.createExternalTransfer(
                    {
                        accounts,
                        debitAccount: completeDebitAccount,
                        cbu,
                        alias,
                        currency,
                        amount: parseFloat(amount.quantity),
                        reference,
                        sendEmail,
                        email,
                        emailText,
                        concept,
                        transferKind,
                        scheduler,
                        tadTrx,
                    },
                    null, // formikBag,
                ),
            );
        } else {
            dispatch(
                transferActions.createTransferToContact(
                    {
                        accounts,
                        debitAccount: completeDebitAccount,
                        cbu,
                        alias,
                        currency,
                        amount: parseFloat(amount.quantity),
                        reference,
                        sendEmail,
                        email,
                        emailText,
                        concept,
                        transferKind,
                        scheduler,
                        tadTrx,
                    },
                    null, // formikBag,
                ),
            );
        }
    };

    const formatDateDesktop = (date, displayTime) => (
        <FormattedDate
            variant="bodyBold"
            date={date}
            dateFormat="short"
            timeFormat={displayTime ? "medium" : undefined}
            locale="es-ES"
            mx={4}
        />
    );

    const renderTransferCard = (transfer) => {
        const { transactionAmounts, transaction } = transfer;
        const {
            data,
            idTransaction,
            idTransactionStatus,
            userCreatorLastName,
            userCreatorFirstName,
            nextExecutionDate,
        } = transaction;
        const { concept, reference, transferKind, scheduler, idActivity } = data;

        const cardStatusMap = new Map();
        cardStatusMap.set("FINISHED", "success");
        cardStatusMap.set("FAILED", "error");
        cardStatusMap.set("PENDING", "pending");
        cardStatusMap.set("SCHEDULED", "scheduled");
        cardStatusMap.set("DRAFT", "draft");

        const status = cardStatusMap.get(transaction.idTransactionStatus);
        const currency = Object.keys(transactionAmounts)[0];
        const currencyText = i18n.get(`currency.label.${currency}`);
        const quantity = Object.values(transactionAmounts)[0];
        const date = getDate(transaction);
        const nextDate = nextExecutionDate ? formatDate(nextExecutionDate) : undefined;
        const conceptDescription = `${concept} - ${i18n.get(`transfers.concept.${concept}`)}`;
        const displayTime = idTransactionStatus === "PENDING" || idTransactionStatus === "DRAFT";
        const kind =
            transferKind === "other"
                ? i18n.get("transfers.titular.other.label")
                : i18n.get("transfers.titular.same.label");

        const transactionsTad = ["transfers.thirdParties.tad.send", "transfers.internal.tad.send"];
        const recurencyTag =
            transaction.idTransactionStatus === "PENDING" && transactionsTad.includes(idActivity)
                ? ""
                : findScheduleDesc(transaction?.program?.frequency?.frequencyUnit || scheduler?.selectedOption);

        const showCheckbox = (status === "pending" && !transaction?.control) || status === "draft";
        const isSelected = selectedTransactionRows.find(
            (selectedTransaction) => selectedTransaction.transaction.idTransaction === idTransaction,
        );

        const getCardTitle = () => {
            if (
                idTransactionStatus === "PENDING" ||
                idTransactionStatus === "DRAFT" ||
                idTransactionStatus === "SCHEDULED"
            ) {
                return i18n.get(`${FORM_ID}.card.title.draftPending`);
            }
            return i18n.get(`${FORM_ID}.card.title.historic`);
        };

        const beneficiary = getBeneficiary(transaction);
        const cuitCuil = `${i18n.get(`transfers.historic.cuit.cuil`)}: ${beneficiary.cuitCuil || "-"}`;
        const preparedByText = i18n.get(`${FORM_ID}.card.title.pending.2`);
        const userCreator = `${userCreatorLastName} ${userCreatorFirstName}`;
        const pendingTitle2 = `${preparedByText}: ${userCreator}`;
        const scheduledTitle2 = i18n.get(`${FORM_ID}.card.title.scheduled`);
        const showMVP5transfers = configUtils.getBoolean("frontend.showMVP5.transfers.functionalities");
        const menu = [];
        if (showMVP5transfers) {
            if (idTransactionStatus === "DRAFT") {
                menu.push({
                    icon: "Retry",
                    iconVariant: "outline",
                    label: i18n.get("transfers.historic.card.resume"),
                    callback: () => {
                        onClickOnCard(idTransaction);
                    },
                });
                menu.push({
                    icon: "Trash",
                    iconVariant: "outline",
                    label: i18n.get("transfers.historic.card.delete"),
                    callback: () => {
                        handleSingleDeleteDrafts(idTransaction);
                    },
                });
            }
            if (idTransactionStatus === "SCHEDULED") {
                menu.push({
                    icon: "Cancel",
                    iconVariant: "outline",
                    label: i18n.get("transfers.historic.card.cancel"),
                    callback: () => {
                        handleCancelScheduled(idTransaction);
                    },
                });
            }
            if (idTransactionStatus === "FINISHED" || idTransactionStatus === "FAILED") {
                menu.push({
                    icon: "Retry",
                    iconVariant: "outline",
                    label: i18n.get("transfers.historic.card.repeat"),
                    callback: () => {
                        handleRepeatTransaction(transfer);
                    },
                });
            }
            if (idTransactionStatus === "FINISHED") {
                menu.push({
                    icon: "ClipboardContent",
                    iconVariant: "outline",
                    label: i18n.get("transfers.historic.card.copy"),
                    callback: () => {
                        handleCopyTransaction(data);
                    },
                });
                menu.push({
                    icon: "SendDown",
                    iconVariant: "outline",
                    label: i18n.get("transfers.historic.card.download"),
                    callback: () => {
                        handleDownloadTransaction(idTransaction);
                    },
                });
            }
            if (idTransactionStatus === "PENDING") {
                menu.push({
                    icon: "Retry",
                    iconVariant: "outline",
                    label: i18n.get("transfers.historic.card.repeat"),
                    callback: () => {
                        handleRepeatTransaction(transfer);
                    },
                });
                menu.push({
                    icon: "Pencil",
                    iconVariant: "outline",
                    label: i18n.get("transfers.historic.card.modify"),
                    callback: () => {
                        handleModifyPendingTransaction(idTransaction);
                    },
                });

                if (!transaction?.control && transaction?.signatureAvaliableByUser) {
                    menu.push({
                        icon: "WritingPending",
                        iconVariant: "outline",
                        label: i18n.get("transfers.historic.card.sign"),
                        callback: () => {
                            // eslint-disable-next-line no-use-before-define
                            handleSignTransaction(transfer);
                        },
                    });
                }
                if (!transaction?.control) {
                    menu.push({
                        icon: "Trash",
                        iconVariant: "outline",
                        label: i18n.get("transfers.historic.card.delete"),
                        callback: () => {
                            // eslint-disable-next-line no-use-before-define
                            handleDeletePendings(transfer);
                        },
                    });
                }
            }
        }

        const lastTransaction = transactions[transactions.length - 1]?.transaction;
        const lastIdTransaction = lastTransaction?.idTransaction;
        return (
            <Card
                iconText={!isMobile}
                key={idTransaction}
                menuToUp={idTransaction === lastIdTransaction}
                title={
                    isMobile ? (
                        <Box flex align="start" column>
                            <Text variant="captionBold">{beneficiary.name || "-"}</Text>
                            <Box flex align="center">
                                <Text variant="caption">{cuitCuil || "-"}</Text>
                                <Tag variant="info" className="p-025 ml-2">
                                    <Text variant="caption">{kind}</Text>
                                </Tag>
                            </Box>
                        </Box>
                    ) : (
                        <Box flex align="center">
                            <Text variant="bodyBold" className="d-flex">
                                {`${getCardTitle()}:`}
                                {formatDateDesktop(date, displayTime)}
                                {idTransactionStatus === "PENDING" ? `| ${pendingTitle2}` : ""}
                                {nextDate && idTransactionStatus === "SCHEDULED" ? `| ${scheduledTitle2}:` : ""}
                                {nextDate && idTransactionStatus === "SCHEDULED"
                                    ? formatDateDesktop(nextDate, displayTime)
                                    : ""}
                            </Text>
                        </Box>
                    )
                }
                variant={status}
                showCheckbox={showCheckbox}
                isChecked={isSelected}
                handleCheckbox={() => handleSelect(!isSelected, transfer)}
                content={
                    isMobile ? (
                        <Box flex column className="w-100">
                            <Box flex justify="between">
                                <Text variant="captionBold">{getCardTitle().toUpperCase()}</Text>
                                <FormattedDate
                                    variant="caption"
                                    date={date}
                                    dateFormat="short"
                                    timeFormat={displayTime ? "medium" : undefined}
                                    locale="es-ES"
                                    ml={4}
                                />
                            </Box>
                            {idTransactionStatus === "SCHEDULED" && (
                                <Box flex justify="between">
                                    <Text variant="captionBold">
                                        {i18n.get(`${FORM_ID}.card.title.scheduled`).toUpperCase()}
                                    </Text>
                                    <FormattedDate
                                        variant="caption"
                                        date={nextDate}
                                        dateFormat="short"
                                        timeFormat={displayTime ? "medium" : undefined}
                                        locale="es-ES"
                                        ml={4}
                                    />
                                </Box>
                            )}
                            {idTransactionStatus === "PENDING" ? (
                                <Box flex justify="between">
                                    <Text variant="captionBold">{`${preparedByText.toUpperCase()}`}</Text>
                                    <Text variant="caption">{userCreator}</Text>
                                </Box>
                            ) : null}
                            {concept ? (
                                <Box flex justify="between">
                                    <Text variant="captionBold">CONCEPTO</Text>
                                    <Text variant="caption">{conceptDescription}</Text>
                                </Box>
                            ) : null}
                            <Box flex justify="between">
                                <Text variant="captionBold">REFERENCIA</Text>
                                {reference ? (
                                    <Text variant="caption" className="align-right">
                                        {reference}
                                    </Text>
                                ) : null}
                            </Box>
                        </Box>
                    ) : (
                        <Text variant="body2">{`${beneficiary.name || "-"} | ${cuitCuil || "-"}`}</Text>
                    )
                }
                infoOne={!isMobile && kind}
                bottom={
                    <Text variant="captionBold">{`${
                        concept ? `${i18n.get("transfers.historic.card.concept")}: ${conceptDescription} | ` : ""
                    }Referencia: ${reference}`}</Text>
                }
                id="card-Default"
                currency={currencyText}
                amount={quantity}
                onClick={() => itemIsClickeable(transaction) && onClickOnCard(idTransaction)}
                status={recurencyTag ? { label: recurencyTag?.toUpperCase(), variant: "neutral" } : null}
                menuOptions={showMVP5transfers ? menu : undefined}
                tooltip={
                    transaction?.idTransactionStatus === "PENDING" &&
                    transaction?.control && {
                        label: configUtils.get("frontend.mobileSignatureControl.tooltip", ""),
                        id: `pending-tooltip-control-${transaction?.idTransaction}`,
                    }
                }
            />
        );
    };

    const handleBack = () => dispatch(push("/desktop"));

    const makeTransfersPermissions = () => {
        // eslint-disable-next-line no-shadow
        const { activeEnvironment } = props;
        return !!(
            activeEnvironment?.permissions?.transferInternal || activeEnvironment?.permissions?.transferThirdParties
        );
    };

    const handleSelectAll = (checked) => {
        // Se fitran las transacciones que tienen control de importe
        const filterTransactions = transactions?.filter((element) => !element.transaction?.control);
        filterTransactions.forEach((transfer) => {
            const selected =
                selectedTransactionRows?.filter(
                    (selectedTransaction) =>
                        selectedTransaction.transaction.idTransaction === transfer.transaction.idTransaction,
                ).length > 0;
            if (checked && !selected) {
                dispatch(transactionsActions.saveSelectedTransactionRows(transfer));
            } else if (!checked && selected) {
                dispatch(transactionsActions.removeSelectedTransactionRows(transfer));
            }
        });
    };

    const handleMultipleSigns = () => {
        dispatch(
            transactionsActions.setMultipleSignaturesData({
                activitySelected: {
                    idActivity: "transfers.external.internal",
                    activityName: i18n.get("transfers.historic.multiple.select.title"),
                },
                selectedTransactionRows,
                filtered: true,
            }),
        );
        dispatch(push("/pendingTransaction/signMultiple"));
    };

    const handleSignTransaction = (transaction) => {
        dispatch(
            transactionsActions.setMultipleSignaturesData({
                activitySelected: {
                    idActivity: "transfers.external.internal",
                    activityName: i18n.get("transfers.historic.multiple.select.title"),
                },
                selectedTransactionRows: [transaction],
                filtered: true,
            }),
        );
        dispatch(push("/pendingTransaction/signMultiple"));
    };

    const deleteDraft = async (idTransaction) => {
        const response = await deleteDraftRequest(idTransaction);
        const { data } = response.data;

        return data;
    };

    const handleMultipleDeleteDrafts = () => {
        const bodyPromises = selectedTransactionRows.map((object) => {
            const { idTransaction } = object.transaction;
            return deleteDraft(idTransaction);
        });

        Promise.all(bodyPromises).then((values) => {
            const allDeleted = values.every((value) => value.deleted);
            if (allDeleted) {
                const message =
                    selectedTransactionRows.length > 1
                        ? i18n.get(`${FORM_ID}.delete.success`)
                        : i18n.get(`${FORM_ID}.delete.success.singular`);
                dispatch(notificationActions.showNotification(message, "success", ["transfersHistoric"]));
            } else {
                dispatch(
                    notificationActions.showNotification(i18n.get(`${FORM_ID}.delete.warn`), "warn", [
                        "transfersHistoric",
                    ]),
                );
            }
            onSubmit(filters);
        });
    };

    const handleMultipleDeletePendings = () => {
        dispatch(
            transactionsActions.setMultipleSignaturesData({
                activitySelected: {
                    idActivity: "transfers.external.internal",
                    activityName: i18n.get("transfers.historic.multiple.select.title"),
                },
                selectedTransactionRows,
                filtered: true,
            }),
        );
        dispatch(push("/pendingTransaction/deleteMultiple"));
    };

    const handleDeletePendings = (transaction) => {
        dispatch(
            transactionsActions.setMultipleSignaturesData({
                activitySelected: {
                    idActivity: "transfers.external.internal",
                    activityName: i18n.get("transfers.historic.multiple.select.title"),
                },
                selectedTransactionRows: [transaction],
                filtered: true,
            }),
        );
        dispatch(push("/pendingTransaction/deleteMultiple"));
    };

    const enableAgenda = () => {
        // eslint-disable-next-line no-shadow
        const { activeEnvironment } = props;
        const showMvp3 = configUtils.getBoolean("frontend.showMVP3.transfers.functionalities");
        return activeEnvironment.permissions?.frequentDestination && showMvp3;
    };

    const enableTransferToContact = () => {
        const showMVP5transfers = configUtils.getBoolean("frontend.showMVP5.transfers.functionalities");
        return (
            activeEnvironment.permissions?.transferToContact &&
            activeEnvironment.permissions?.frequentDestination &&
            showMVP5transfers
        );
    };

    const transferPath = getTransferPath();

    const showMVP4transfers = configUtils.getBoolean("frontend.showMVP4.transfers.functionalities");
    const showMVP6transfers = configUtils.getBoolean("frontend.showMVP6.transfers.functionalities");

    return (
        <ThemeProvider>
            <Notification scopeToShow="transfersHistoric" />
            {!isDesktop && (
                <>
                    <Box className="admin-detail-head z-index-1 px-0">
                        <Head
                            onBack={handleBack}
                            headerClassName="blue-main-header-mobile"
                            centerElement={() => (
                                <Text as="h1" className="m-0">
                                    {i18n.get("transfers.main.label")}
                                </Text>
                            )}
                            rightContent={() => (
                                <Button
                                    variant="solid"
                                    icon="SendDown"
                                    iconVariant="outline"
                                    className="p-0"
                                    size="large"
                                    onClick={() => {
                                        dispatch(push("/v2/transferDownload"));
                                    }}
                                />
                            )}
                            accessibilityTextId="transfers.main.label"
                        />
                    </Box>
                    <Container fixed>
                        <Box mt={12} p={12} radius={8} shadow="default">
                            <Button
                                justify="right"
                                text={i18n.get(`${FORM_ID}.btn.createTransfer`)}
                                variant="solid"
                                icon="CashflowOut"
                                className="w-100"
                                disabled={!makeTransfersPermissions()}
                                onClick={() => {
                                    dispatch(transferActions.clearTransferData());
                                    if (!isDesktop && showMVP4transfers) {
                                        setShowTransferOptionsMb(true);
                                    } else {
                                        dispatch(push(transferPath));
                                    }
                                }}
                            />
                            <BottomSheet
                                title={i18n.get("transfers.make.transfer.title.mb")}
                                iconTitle="TransferAlt"
                                onClose={() => setShowTransferOptionsMb(false)}
                                isVisible={showTransferOptionsMb}>
                                <Box column gap={20} py={24} px={12} pb={24}>
                                    {activeEnvironment.permissions?.transferInternal && (
                                        <Button
                                            text={i18n.get("transfers.form.internal.title")}
                                            variant="solid"
                                            icon="CashflowOut"
                                            className="w-100"
                                            disabled={!makeTransfersPermissions()}
                                            onClick={() => {
                                                dispatch(push(transferPath));
                                            }}
                                        />
                                    )}
                                    {activeEnvironment.permissions?.transferThirdParties && (
                                        <Button
                                            text={i18n.get("transfers.form.thirdParties.title")}
                                            variant="solid"
                                            icon="CashflowOut"
                                            className="w-100"
                                            disabled={!makeTransfersPermissions()}
                                            onClick={() => {
                                                dispatch(push(getTransferPath("thirdParties")));
                                            }}
                                        />
                                    )}
                                    {enableTransferToContact() && (
                                        <Button
                                            text={i18n.get("transfers.form.contact.title")}
                                            variant="solid"
                                            icon="CashflowOut"
                                            className="w-100"
                                            disabled={!makeTransfersPermissions()}
                                            onClick={() => {
                                                dispatch(push(getTransferPath("toContact")));
                                            }}
                                        />
                                    )}
                                </Box>
                            </BottomSheet>
                            {enableAgenda() && (
                                <>
                                    <Separator color="neutral-light" mt={12} />
                                    <TransfersAgenda formikProps={formikProps} isDesktop={isDesktop} />
                                </>
                            )}
                        </Box>
                    </Container>
                </>
            )}
            <Container fixed={!isDesktop} className="h-100">
                {isDesktop && (
                    <Box className="admin-detail-head px-0" mx={isDesktop ? 48 : null}>
                        <Head onBack={handleBack} accessibilityTextId="transfers.main.label" />
                        <Box flex align="center" justify="between">
                            <Text variant="h2">{i18n.get("transfers.main.label")}</Text>
                            <Box flex justify="between" gap={12}>
                                <Button
                                    justify="right"
                                    text={i18n.get(`${FORM_ID}.btn.createTransfer`)}
                                    variant="solid"
                                    icon="CashflowOut"
                                    disabled={!makeTransfersPermissions()}
                                    onClick={() => {
                                        dispatch(transferActions.clearTransferData());
                                        dispatch(push(transferPath));
                                    }}
                                />
                                {showMVP6transfers && (
                                    <Button
                                        justify="right"
                                        text={i18n.get(`${FORM_ID}.btn.download`)}
                                        variant="outline"
                                        icon="SendDown"
                                        iconVariant="outline"
                                        onClick={() => {
                                            dispatch(push("/v2/transferDownload"));
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                )}
                {enableAgenda() && isDesktop && (
                    <Box mx={isDesktop ? 48 : null}>
                        <TransfersAgenda formikProps={formikProps} isDesktop={isDesktop} />
                    </Box>
                )}
                <TransferHistoricFilters
                    dispatch={dispatch}
                    setOrder={setOrder}
                    filterByStatus={filterByStatus}
                    onSubmit={onSubmit}
                    onSelectCurrency={onSelectCurrency}
                    onResetFilters={resetFilters}
                    onSelectAll={handleSelectAll}
                    onDeletePendings={handleMultipleDeletePendings}
                    onDeleteDrafts={handleMultipleDeleteDrafts}
                    onSign={handleMultipleSigns}
                    selectedCurrency={selectedCurrency}
                    formikProps={formikProps}
                    setFormikProps={setFormikProps}
                    handleFilterObjectType={handleFilterObjectType}
                    getFiltersByOption={getFiltersByOption}
                    isDesktop={isDesktop}
                    onlyMyPendings={onlyMyPendings}
                />
                <Box mx={isDesktop ? 48 : null} flex className="min-height-40vh" justify="center">
                    <List
                        emptyStateText={i18n.get("transactions.list.none")}
                        array={transactions}
                        mapCallback={renderTransferCard}
                        paginationProps={paginationProps}
                        loading={fetching || fetchingMore || fetchingHistoric}
                        isInfiniteScroll={!isDesktop}
                        endMessage={i18n.get("transactions.list.noMoreTransactions")}
                    />
                </Box>
            </Container>
        </ThemeProvider>
    );
}

const mapStateToProps = (state) => ({
    accounts: transferSelectors.getAccounts(state),
    transactions: transferSelectors.getTransfers(state),
    selectedTransactionRows: transactionsSelectors.getSelectedTransactionRows(state),
    fetching: transferSelectors.getFetching(state),
    fetchingHistoric: transferSelectors.getFetchingHistoric(state),
    filters: transferSelectors.getFilters(state),
    pageNumber: transferSelectors.getPageNumber(state),
    hasMoreData: transferSelectors.getHasMoreData(state),
    fetchingMore: transferSelectors.isFetchingMore(state),
    totalPages: transferSelectors.getTotalPages(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    idEnvironment: agendaSelectors.getEnviromentId(state),
    listFrequentDestinations: agendaSelectors.getListFrequentDestinations(state),
    selectedChip: transferSelectors.getSelectedChip(state),
    filtersHistoric: transferSelectors.getFiltersHistoric(state),
    filtersPending: transferSelectors.getFiltersPending(state),
    filtersScheduled: transferSelectors.getFiltersScheduled(state),
    filtersDraft: transferSelectors.getFiltersDraft(state),
    onlyMyPendings: transferSelectors.getOnlyMyPendings(state),
});

export default withRouter(connect(mapStateToProps)(TransferHistoric));
