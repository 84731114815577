import { call, put, takeLatest } from "redux-saga/effects";
import { types } from "reducers/metrocorp";
import * as metrocorpMiddleware from "middleware/metrocorp";
import moment from "moment";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { downloadPdf, downloadXls } from "util/download";
import { delay } from "redux-saga";

const sagas = [
    takeLatest(types.LIST_METROCORP_REQUEST, listMetrocorp),
    takeLatest(types.LIST_METROCORP_PRE_REQUEST, listMetrocorpPre),
    takeLatest(types.DOWNLOAD_LIST_METROCORP_REQUEST, downloadList),
    takeLatest(types.DOWNLOAD_DETAIL_METROCORP_REQUEST, downloadDetail),
    takeLatest(types.GET_DETAIL_MOVEMENT_REQUEST, getDetail),
    takeLatest(types.DOWNLOAD_DETAIL_METROCORP_BY_REFERENCE_REQUEST, downloadDetailByReference),
];

export default sagas;

function* listMetrocorp({ optionSelected, principalAccount, species, date, dateFrom, dateTo, page, isDesktop }) {
    const response = yield call(
        metrocorpMiddleware.listMetrocorp,
        optionSelected,
        principalAccount,
        species,
        date,
        dateFrom,
        dateTo,
        page,
    );

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["metrocorp.list"]));
        yield put({ type: types.LIST_METROCORP_FAILURE });
    } else {
        const { holdings, futureValues, movements, fullMovementsList, dateHoldings, totalPages } = response.data.data;

        yield put({
            type: types.LIST_METROCORP_SUCCESS,
            holdings,
            futureValues,
            movements,
            fullMovementsList,
            dateHoldings,
            movementsTotalPages: totalPages,
            page,
            isDesktop,
        });
    }
}

function* listMetrocorpPre() {
    const response = yield call(metrocorpMiddleware.listMetrocorpPre);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["metrocorp.list"]));
        yield put({ type: types.LIST_METROCORP_PRE_FAILURE });
    } else {
        const { holdings, clients, activeTotal } = response.data.data;

        yield put({
            type: types.LIST_METROCORP_PRE_SUCCESS,
            holdings,
            clients,
            activeTotal,
            filtersData: {
                principalAccountClient: `${clients[0]?.nombre} ${clients[0]?.apellido}`,
                principalAccount: clients[0]?.codClie,
                species: "all",
                date: moment(),
                page: 1,
            },
        });
    }
}

function* downloadList({ summary, format }) {
    const { type, data } = yield call(metrocorpMiddleware.downloadList, summary, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_LIST_METROCORP_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["metrocorp.list"]),
        );
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("global.successDownload"), "success", ["metrocorp.list"]),
        );
        yield call(delay, 3000);

        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_LIST_METROCORP_SUCCESS });
    }
}

function* getDetail({ reference, codEspe, nombre, apellido, currency }) {
    const response = yield call(metrocorpMiddleware.getDetail, reference);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["metrocorp.list"]));
        yield put({ type: types.GET_DETAIL_MOVEMENT_FAILURE });
    } else {
        const { movementDetail } = response.data.data;
        const updatedMovementDetail = { ...movementDetail, codEspe, Nombre: nombre, Apellido: apellido, currency };

        yield put({
            type: types.GET_DETAIL_MOVEMENT_SUCCESS,
            movementDetail: updatedMovementDetail,
        });
    }
}

function* downloadDetail({ detail, format, onClose = () => {} }) {
    const { type, data } = yield call(metrocorpMiddleware.downloadDetail, detail, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_DETAIL_METROCORP_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["metrocorp.detail"]),
        );
    } else {
        onClose();
        yield put(
            notificationActions.showNotification(i18n.get("global.successDownload"), "success", ["metrocorp.list"]),
        );

        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_DETAIL_METROCORP_SUCCESS });
    }
}

function* downloadDetailByReference({ reference, codEspe, nombre, apellido, currency, format }) {
    const response = yield call(metrocorpMiddleware.getDetail, reference);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["metrocorp.list"]));
        yield put({ type: types.GET_DETAIL_MOVEMENT_FAILURE });
    } else {
        const { movementDetail } = response.data.data;
        const updatedMovementDetail = { ...movementDetail, codEspe, Nombre: nombre, Apellido: apellido, currency };

        const { type, data } = yield call(metrocorpMiddleware.downloadDetail, updatedMovementDetail, format);
        if (type === "W") {
            yield put({ type: types.DOWNLOAD_DETAIL_METROCORP_FAILURE });
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["metrocorp.detail"]),
            );
        } else {
            yield call(delay, 2000);

            yield put(
                notificationActions.showNotification(i18n.get("global.successDownload"), "success", ["metrocorp.list"]),
            );
            const { content, fileName } = data.data;

            if (format === "pdf") {
                downloadPdf(fileName, content);
            } else {
                downloadXls(fileName, content);
            }

            yield put({ type: types.DOWNLOAD_DETAIL_METROCORP_BY_REFERENCE_SUCCESS });
        }
    }
}
