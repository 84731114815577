/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { func, instanceOf, shape, number, string, bool, oneOfType, array } from "prop-types";

import * as i18nUtils from "util/i18n";
import { actions as notificationActions } from "reducers/notification";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";

import Notification from "pages/_components/Notification";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import TransactionItem from "pages/transactions/_components/HistoricTransactionItem";
import Image from "pages/_components/Image";
import { SALARY_PAYMENT_ID_FORM } from "constants.js";
import Pagination from "pages/_components/pagination/Pagination";
import Measure from "react-measure";
import Scroll from "pages/_components/Scroll";
import CardContainer from "pages/_components/Card/CardContainer";

import Spinner from "pages/_components/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { itemIsClickeable } from "util/transfers";
import { isMobile } from "react-device-detect";

class List extends Component {
    static propTypes = {
        defaultFilters: shape({
            dateFrom: instanceOf(Date),
            dateTo: instanceOf(Date),
            pageNumber: number,
            filter: string,
        }),
        dispatch: func.isRequired,
        pageNumber: number,
        totalPages: number,
        onlyPendings: bool,
        pendingDispatch: bool,
        filters: shape({
            idFilter: string,
            dateFrom: instanceOf(Date),
            dateTo: instanceOf(Date),
            pageNumber: number,
            minAmount: number,
            maxAmount: number,
        }),
        showSearchButton: bool.isRequired,
        transactions: oneOfType([null, array]).isRequired,
        isDesktop: bool.isRequired,
        hasMoreData: bool.isRequired,
        fetchingMore: bool.isRequired,
        showEnvironment: bool,
        itemsAreClickeable: bool,
    };

    static defaultProps = {
        defaultFilters: null,
        pageNumber: 1,
        totalPages: 1,
        onlyPendings: false,
        pendingDispatch: false,
        filters: null,
        showEnvironment: false,
        itemsAreClickeable: true,
    };

    state = {
        searchMoreTransactionsVisible: false,
        dimensions: null,
    };

    componentDidMount() {
        window.scrollTo(0, 1);
    }

    fetchMoreTransactions = (pageNumber) => {
        const { defaultFilters, dispatch, onlyPendings, pendingDispatch, isDesktop } = this.props;
        let { filters } = this.props;

        filters = filters ? { ...filters, pageNumber } : { ...defaultFilters, pageNumber };
        if (isDesktop) {
            dispatch(
                transactionsActions.loadMoreTransactionsRequest(filters, onlyPendings, pendingDispatch, isDesktop),
            );
        } else {
            dispatch(
                transactionsActions.loadMoreTransactionsRequestMobile(
                    filters,
                    onlyPendings,
                    pendingDispatch,
                    isDesktop,
                ),
            );
        }
    };

    onPullForMoreInfo = () => {
        const { showSearchButton } = this.props;
        if (showSearchButton) {
            this.setState({ searchMoreTransactionsVisible: true });
        }
    };

    onMobileItemCLick = (event, transaction) => {
        const { dispatch, isDesktop } = this.props;

        if (
            transaction.transaction.idForm === SALARY_PAYMENT_ID_FORM &&
            !isDesktop &&
            transaction.transaction.idTransactionStatus === "DRAFT"
        ) {
            event.preventDefault();
            dispatch(
                notificationActions.showNotification(i18nUtils.get("massive.payments.mobile.disable"), "error", [
                    "transactionList",
                ]),
            );
        }
    };

    fetchNextTransactions = () => {
        const { pageNumber } = this.props;

        this.fetchMoreTransactions(pageNumber + 1);
    };

    buildScroll = (list) => {
        const { hasMoreData, pageNumber, fetchingMore, dispatch } = this.props;
        const { dimensions } = this.state;

        return (
            <div className="containerDetails container-fluid container-historic-list">
                <InfiniteScroll
                    dataLength={list.length}
                    next={this.fetchNextTransactions}
                    hasMore={hasMoreData}
                    loader={<Spinner />}
                    endMessage={
                        <div className="table-row table-end" key="noMoreTransactions">
                            <I18n id="transactions.list.noMoreTransactions" />
                        </div>
                    }>
                    {list}
                </InfiniteScroll>
            </div>
        );
    };

    render() {
        const { transactions, isDesktop, showEnvironment, itemsAreClickeable, totalPages, pageNumber } = this.props;

        const { searchMoreTransactionsVisible } = this.state;
        if (!transactions) {
            return null;
        }

        const list = transactions.map((transaction) => (
            <CardContainer
                className={`${isDesktop ? "container-white" : "border-big-transparent infinite-scroll-height-80-px"}`}
                key={transaction.transaction.idTransaction}
                renderAs={itemsAreClickeable && itemIsClickeable(transaction.transaction) ? Link : "div"}
                onClick={itemsAreClickeable ? (e) => this.onMobileItemCLick(e, transaction) : null}
                to={
                    itemsAreClickeable ? `/transaction/${transaction.transaction.idTransaction}` : `/transactions/list`
                }>
                <TransactionItem
                    key={`transaction-${transaction.transaction.idTransaction}`}
                    transaction={transaction}
                    isDesktop={isDesktop}
                    showEnvironment={showEnvironment}
                />
            </CardContainer>
        ));

        if (!isDesktop) {
            if (!transactions.length) {
                return (
                    <div className="text-center no-more-data" key="noMoreMovements">
                        <div className="illustration-wrapper">
                            <Image src="images/coloredIcons/folder-empty.svg" className="svg-big-icon" />
                        </div>
                        <p className="text-lead">
                            <I18n id="transactions.list.none" />
                        </p>
                    </div>
                );
            }

            return (
                <>
                    <Notification scopeToShow="transactionList" />
                    {this.buildScroll(list)}
                </>
            );
        }

        return (
            <>
                <Container className="flex-grow scrollable">
                    <Col className="col col-12 px-0">
                        <div
                            style={{ borderSpacing: "0px 2px", width: "100%" }}
                            ref={(tableRef) => {
                                this.child = tableRef;
                            }}>
                            {transactions.length ? (
                                <div className="w-100">{list}</div>
                            ) : (
                                <div className="text-center no-more-data" key="noMoreMovements">
                                    <div className="illustration-wrapper">
                                        <Image src="images/coloredIcons/folder-empty.svg" className="svg-big-icon" />
                                    </div>
                                    <p className="text-lead" style={{ opacity: 1 }}>
                                        <I18n id="transactions.list.none" />
                                    </p>
                                </div>
                            )}
                        </div>
                    </Col>
                </Container>

                <div className="d-flex w-100 justify-content-end">
                    <div className="d-flex mt-2">
                        <Pagination
                            totalPages={totalPages}
                            pageNumber={pageNumber}
                            action={this.fetchMoreTransactions}
                        />
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    transactions: transactionsSelectors.getTransactions(state),
    hasMoreData: transactionsSelectors.getHasMoreData(state),
    pageNumber: transactionsSelectors.getPageNumber(state),
    totalPages: transactionsSelectors.getTotalPages(state),
    filters: transactionsSelectors.getFilters(state),
    fetchingMore: transactionsSelectors.isFetchingMore(state),
});

export default connect(mapStateToProps)(List);
