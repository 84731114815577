import * as config from "util/config";

const getTransferPath = (type = "internal") => {
    const mvp4 = config.getBoolean("frontend.showMVP4.transfers.functionalities", false);
    if (mvp4) {
        return `/v2/transfer/${type}`;
    }
    return "/transfer";
};

export const itemIsClickeable = (transaction) => {
    const showMVP5transfers = config.getBoolean("frontend.showMVP5.transfers.functionalities");

    if (!showMVP5transfers) {
        const { idTransactionStatus, idActivity } = transaction;
        const blockedStatus = ["PENDING", "DRAFT"];
        return !(idActivity === "transfers.toContact.send" && blockedStatus.includes(idTransactionStatus));
    }

    return true;
};

export { getTransferPath as default };
