import { globalTypes } from "reducers/types/global";

export const types = {
    LIST_METROCORP_REQUEST: "metrocorp/LIST_METROCORP_REQUEST",
    LIST_METROCORP_FAILURE: "metrocorp/LIST_METROCORP_FAILURE",
    LIST_METROCORP_SUCCESS: "metrocorp/LIST_METROCORP_SUCCESS",
    LIST_METROCORP_PRE_REQUEST: "metrocorp/LIST_METROCORP_PRE_REQUEST",
    LIST_METROCORP_PRE_FAILURE: "metrocorp/LIST_METROCORP_PRE_FAILURE",
    LIST_METROCORP_PRE_SUCCESS: "metrocorp/LIST_METROCORP_PRE_SUCCESS",
    DOWNLOAD_LIST_METROCORP_REQUEST: "metrocorp/DOWNLOAD_LIST_METROCORP_REQUEST",
    DOWNLOAD_LIST_METROCORP_FAILURE: "metrocorp/DOWNLOAD_LIST_METROCORP_FAILURE",
    DOWNLOAD_LIST_METROCORP_SUCCESS: "metrocorp/DOWNLOAD_LIST_METROCORP_SUCCESS",
    DOWNLOAD_DETAIL_METROCORP_REQUEST: "metrocorp/DOWNLOAD_DETAIL_METROCORP_REQUEST",
    DOWNLOAD_DETAIL_METROCORP_FAILURE: "metrocorp/DOWNLOAD_DETAIL_METROCORP_FAILURE",
    DOWNLOAD_DETAIL_METROCORP_SUCCESS: "metrocorp/DOWNLOAD_DETAIL_METROCORP_SUCCESS",
    DOWNLOAD_DETAIL_METROCORP_BY_REFERENCE_REQUEST: "metrocorp/DOWNLOAD_DETAIL_METROCORP_BY_REFERENCE_REQUEST",
    DOWNLOAD_DETAIL_METROCORP_BY_REFERENCE_FAILURE: "metrocorp/DOWNLOAD_DETAIL_METROCORP_BY_REFERENCE_FAILURE",
    DOWNLOAD_DETAIL_METROCORP_BY_REFERENCE_SUCCESS: "metrocorp/DOWNLOAD_DETAIL_METROCORP_BY_REFERENCE_SUCCESS",
    GET_DETAIL_MOVEMENT_REQUEST: "metrocorp/GET_DETAIL_MOVEMENT_REQUEST",
    GET_DETAIL_MOVEMENT_FAILURE: "metrocorp/GET_DETAIL_MOVEMENT_FAILURE",
    GET_DETAIL_MOVEMENT_SUCCESS: "metrocorp/GET_DETAIL_MOVEMENT_SUCCESS",
    FILTER_CHANGE_METROCORP: "metrocorp/FILTER_CHANGE_METROCORP",
    SAVE_OPTION_SELECTED: "metrocorp/SAVE_OPTION_SELECTED",
};

export const INITIAL_STATE = {
    fetching: false,
    fetchingDownload: false,
    fetchingDetailDownload: false,
    fetchingDetailDownloadByReferenceMobile: undefined,
    clients: [],
    holdings: [],
    dateHoldings: [],
    futureValues: [],
    movements: [],
    fullMovementsListList: [],
    movementsTotalPages: 0,
    activeTotal: 0.0,
    movementDetail: null,
    filtersData: null,
    filter: {
        filterSelected: "species",
        directionFilter: "ascendent",
    },
    optionSelected: "holdings",
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        clients,
        holdings,
        dateHoldings,
        futureValues,
        movements,
        fullMovementsList,
        activeTotal,
        movementDetail,
        optionSelected,
        principalAccountClient,
        principalAccount,
        species,
        date,
        dateFrom,
        dateTo,
        page,
        filter,
        downloadReferenceIndex,
        filtersData,
        movementsTotalPages,
        isDesktop,
    } = action;

    switch (action.type) {
        case types.LIST_METROCORP_REQUEST:
            return {
                ...state,
                fetching: true,
                filtersData: {
                    principalAccountClient,
                    principalAccount,
                    species,
                    date: optionSelected === "holdings" ? date : undefined,
                    dateFrom: optionSelected === "movements" ? dateFrom : undefined,
                    dateTo: optionSelected === "movements" ? dateTo : undefined,
                    page,
                },
            };
        case types.LIST_METROCORP_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LIST_METROCORP_SUCCESS:
            return {
                ...state,
                fetching: false,
                holdings,
                futureValues,
                movements: isDesktop || page === 1 ? movements : [...state.movements, ...movements],
                fullMovementsList,
                dateHoldings,
                movementsTotalPages,
            };
        case types.LIST_METROCORP_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
                fetchingDownload: false,
                fetchingDetailDownload: false,
                fetchingDetailDownloadByReferenceMobile: undefined,
            };
        case types.LIST_METROCORP_PRE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LIST_METROCORP_PRE_SUCCESS:
            return {
                ...state,
                fetching: false,
                clients,
                holdings,
                activeTotal,
                filtersData,
                movementsTotalPages,
            };
        case types.DOWNLOAD_LIST_METROCORP_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_LIST_METROCORP_FAILURE:
        case types.DOWNLOAD_LIST_METROCORP_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.GET_DETAIL_MOVEMENT_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.GET_DETAIL_MOVEMENT_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.GET_DETAIL_MOVEMENT_SUCCESS:
            return {
                ...state,
                fetching: false,
                movementDetail,
            };
        case types.DOWNLOAD_DETAIL_METROCORP_REQUEST:
            return {
                ...state,
                fetchingDetailDownload: true,
            };
        case types.DOWNLOAD_DETAIL_METROCORP_FAILURE:
        case types.DOWNLOAD_DETAIL_METROCORP_SUCCESS:
            return {
                ...state,
                fetchingDetailDownload: false,
            };
        case types.DOWNLOAD_DETAIL_METROCORP_BY_REFERENCE_REQUEST:
            return {
                ...state,
                fetchingDetailDownloadByReferenceMobile: downloadReferenceIndex,
            };
        case types.DOWNLOAD_DETAIL_METROCORP_BY_REFERENCE_FAILURE:
        case types.DOWNLOAD_DETAIL_METROCORP_BY_REFERENCE_SUCCESS:
            return {
                ...state,
                fetchingDetailDownloadByReferenceMobile: undefined,
            };
        case types.FILTER_CHANGE_METROCORP:
            return {
                ...state,
                filter: {
                    filterSelected: filter.filterSelected,
                    directionFilter: filter.directionFilter === "ascendent" ? "descendent" : "ascendent",
                },
                futureValues: futureValues.sort((a, b) => (filter.directionFilter === "ascendent" ? a - b : b - a)),
            };
        case types.SAVE_OPTION_SELECTED:
            return {
                ...state,
                optionSelected,
                filtersData: {
                    principalAccountClient,
                    principalAccount,
                    species,
                    date: optionSelected === "holdings" ? date : undefined,
                    dateFrom: optionSelected === "movements" ? dateFrom : undefined,
                    dateTo: optionSelected === "movements" ? dateTo : undefined,
                    page,
                },
                holdings: [],
                futureValues: [],
                movements: [],
            };

        case globalTypes.RESET_SELECTED_ELEMENTS:
        case globalTypes.CLEAN_UP:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export const actions = {
    listMetrocorpPre: () => ({
        type: types.LIST_METROCORP_PRE_REQUEST,
    }),
    listMetrocorp: (
        optionSelected,
        principalAccountClient,
        principalAccount,
        species,
        date,
        dateFrom,
        dateTo,
        page,
        isDesktop,
    ) => ({
        type: types.LIST_METROCORP_REQUEST,
        optionSelected,
        principalAccountClient,
        principalAccount,
        species,
        date,
        dateFrom,
        dateTo,
        page,
        isDesktop,
    }),
    downloadMetrocorpList: (summary, format) => ({
        type: types.DOWNLOAD_LIST_METROCORP_REQUEST,
        summary,
        format,
    }),
    getMovementDetail: (reference, codEspe, nombre, apellido, currency) => ({
        type: types.GET_DETAIL_MOVEMENT_REQUEST,
        reference,
        codEspe,
        nombre,
        apellido,
        currency,
    }),
    downloadMetrocorpDetail: (detail, format, onClose) => ({
        type: types.DOWNLOAD_DETAIL_METROCORP_REQUEST,
        detail,
        format,
        onClose,
    }),
    downloadMetrocorpDetailByReference: (
        downloadReferenceIndex,
        reference,
        codEspe,
        nombre,
        apellido,
        currency,
        format,
    ) => ({
        type: types.DOWNLOAD_DETAIL_METROCORP_BY_REFERENCE_REQUEST,
        downloadReferenceIndex,
        reference,
        codEspe,
        nombre,
        apellido,
        currency,
        format,
    }),
    changeFilter: (futureValues, filter) => ({
        type: types.FILTER_CHANGE_METROCORP,
        futureValues,
        filter,
    }),
    saveOptionSelected: (
        optionSelected,
        principalAccountClient,
        principalAccount,
        species,
        date,
        dateFrom,
        dateTo,
    ) => ({
        type: types.SAVE_OPTION_SELECTED,
        optionSelected,
        principalAccountClient,
        principalAccount,
        species,
        date,
        dateFrom,
        dateTo,
    }),
};

export const selectors = {
    getFetching: ({ metrocorp }) => metrocorp.fetching,
    getFetchingDownload: ({ metrocorp }) => metrocorp.fetchingDownload,
    getClients: ({ metrocorp }) => metrocorp.clients,
    getHoldings: ({ metrocorp }) => metrocorp.holdings,
    getDateHoldings: ({ metrocorp }) => metrocorp.dateHoldings,
    getFutureValues: ({ metrocorp }) => metrocorp.futureValues,
    getMovements: ({ metrocorp }) => metrocorp.movements,
    getFullMovementsList: ({ metrocorp }) => metrocorp.fullMovementsList,
    getActiveTotal: ({ metrocorp }) => metrocorp.activeTotal,
    getMovementDetail: ({ metrocorp }) => metrocorp.movementDetail,
    getFiltersData: ({ metrocorp }) => metrocorp.filtersData,
    getMovementsTotalPages: ({ metrocorp }) => metrocorp.movementsTotalPages,
    getFilter: ({ metrocorp }) => metrocorp.filter,
    getFetchingDetailDownload: ({ metrocorp }) => metrocorp.fetchingDetailDownload,
    getFetchingDetailDownloadByReferenceMobile: ({ metrocorp }) => metrocorp.fetchingDetailDownloadByReferenceMobile,
    getOptionSelected: ({ metrocorp }) => metrocorp.optionSelected,
};
