import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { bool, func, shape } from "prop-types";
import { withFormik } from "formik";
import Yup from "yup";
import moment from "moment";

import PageLoading from "pages/_components/PageLoading";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";
import { DATETIME_FORMAT_WITH_T } from "constants.js";
import MovementsFilters from "./MovementsFilters";
import LastMovementsGrid from "./LastMovementsGrid";

import PendingsMovementsGrid from "./PendingsMovementsGrid";

const FORM_ID = "accounts.movements";
const INITIAL_MOVEMENTS_SELECTED = true;

function MovementsV2({
    dispatch,
    selectedAccount,
    isDesktop,
    isFetchingMovements,
    values: {
        findBy,
        lastDateFrom,
        lastDateTo,
        lastDates,
        pendingDateFrom,
        pendingDateTo,
        pendingDates,
        amountFrom,
        amountTo,
        voucher,
        detail,
        movementType,
    },
    setFieldValue,
    resetForm,
    fetchingDownload,
}) {
    const [isLatestMovementsSelected, setIsLatestMovementsSelected] = useState(INITIAL_MOVEMENTS_SELECTED);
    const [downloadExportValues, setDownloadExportValues] = useState({});

    const enableRangeDatefield = configUtils.getBoolean("frontend.show.RangeDatePicker.functionalities", false);

    const getPendingMinDate = () => {
        const isTest = configUtils.getBoolean("corePendingDate.isTest", false);
        const strDate = configUtils.get("coreDate.defaultPendingDate");
        return isTest && strDate ? moment(strDate, DATETIME_FORMAT_WITH_T) : moment().startOf("day");
    };

    const setDates = () => {
        const configuredDateFromValue = configUtils.getInteger("movements.dateTo.periodFront", 1);

        const startDateLatest = moment()
            .startOf("day")
            .subtract(configuredDateFromValue, "months");
        const endDateLatest = moment().startOf("day");
        const startDatePending = getPendingMinDate();
        const plusDays = configUtils.getInteger("movements.maxDays.default", 30);
        const endDatePending = getPendingMinDate().add(plusDays, "days");
        if (enableRangeDatefield) {
            setFieldValue("lastDates", [startDateLatest.toDate(), endDateLatest.toDate()]);
            setFieldValue("pendingDates", [startDatePending.toDate(), endDatePending.toDate()]);
        } else {
            setFieldValue("lastDateFrom", startDateLatest);
            setFieldValue("lastDateTo", endDateLatest);
            setFieldValue("pendingDateFrom", startDatePending);
            setFieldValue("pendingDateTo", endDatePending);
        }
        return { startDateLatest, endDateLatest, startDatePending, endDatePending };
    };

    const setDownloadValues = (initialValues = null) => {
        const tempDownloadValues = initialValues ?? {
            findBy,
            lastDateFrom,
            lastDateTo,
            pendingDateFrom,
            pendingDateTo,
            amountFrom,
            amountTo,
            voucher,
            detail,
            movementType,
            lastDates,
            pendingDates,
        };

        setDownloadExportValues(tempDownloadValues);
    };

    const fetchInitialMovements = (isSelectedLatestMovements) => {
        const { startDateLatest, endDateLatest, startDatePending, endDatePending } = setDates();
        let datesObject = {};
        if (enableRangeDatefield) {
            datesObject = {
                lastDates: [startDateLatest.toDate(), endDateLatest.toDate()],
                pendingDates: [startDatePending.toDate(), endDatePending.toDate()],
            };
        } else {
            datesObject = {
                lastDateFrom: startDateLatest,
                lastDateTo: endDatePending,
                pendingDateFrom: startDatePending,
                pendingDateTo: endDatePending,
            };
        }

        const initialValues = {
            ...datesObject,
            findBy: "period",
        };
        setDownloadValues(initialValues);
        dispatch(
            accountsActions.fetchLatestMovements({
                ...initialValues,
                selectedAccount,
                latestMovementsPageNumber: "1",
                pendingMovementsPageNumber: "1",
                isLatestMovementsSelected: isSelectedLatestMovements,
                lastDateFrom: enableRangeDatefield ? startDateLatest.toDate() : startDateLatest,
                lastDateTo: enableRangeDatefield ? endDateLatest.toDate() : endDatePending,
                pendingDateFrom: enableRangeDatefield ? startDatePending.toDate() : startDatePending,
                pendingDateTo: enableRangeDatefield ? endDatePending.toDate() : endDatePending,
            }),
        );
    };
    const handleMovementToShow = (isSelectedLatestMovements) => {
        setIsLatestMovementsSelected(isSelectedLatestMovements);
        resetForm();
        dispatch(accountsActions.setIsLatestMovements(isSelectedLatestMovements));
        fetchInitialMovements(isSelectedLatestMovements);
    };

    useEffect(() => {
        dispatch(accountsActions.setIsLatestMovements(INITIAL_MOVEMENTS_SELECTED));
        fetchInitialMovements(INITIAL_MOVEMENTS_SELECTED);
        return () => {
            handleMovementToShow(isLatestMovementsSelected);
            dispatch(accountsActions.removeMovements());
        };
    }, []);

    const getStrDate = (str) => {
        const date = str.substring(0, str.indexOf("T"));
        return moment(date).format("DD/MM/YYYY");
    };

    const getMovementsNextPage = (nextPage) =>
        dispatch(
            accountsActions.fetchLatestMovements({
                selectedAccount,
                latestMovementsPageNumber: isLatestMovementsSelected ? nextPage : undefined,
                pendingMovementsPageNumber: !isLatestMovementsSelected ? nextPage : undefined,
                findBy,
                lastDateFrom: lastDates ? lastDates[0] : lastDateFrom,
                lastDateTo: lastDates ? lastDates[1] : lastDateTo,
                pendingDateFrom: pendingDates ? pendingDates[0] : pendingDateFrom,
                pendingDateTo: pendingDates ? pendingDates[1] : pendingDateTo,
                amountFrom,
                amountTo,
                voucher,
                detail,
                isLatestMovementsSelected,
            }),
        );

    return (
        <PageLoading className="line-loader" loading={isFetchingMovements}>
            {selectedAccount && (
                <>
                    <MovementsFilters
                        isDesktop={isDesktop}
                        findBy={findBy}
                        isLatestMovementsSelected={isLatestMovementsSelected}
                        lastDateFrom={lastDateFrom}
                        lastDateTo={lastDateTo}
                        lastDates={lastDates}
                        pendingDateFrom={pendingDateFrom}
                        pendingDateTo={pendingDateTo}
                        pendingDates={pendingDates}
                        amountFrom={amountFrom}
                        amountTo={amountTo}
                        voucher={voucher}
                        detail={detail}
                        movementType={movementType}
                        getPendingMinDate={getPendingMinDate}
                        handleMovementToShow={handleMovementToShow}
                        setDates={setDates}
                        downloadExportValues={downloadExportValues}
                        fetchingDownload={fetchingDownload}
                        setDownloadValues={setDownloadValues}
                    />
                    {isLatestMovementsSelected ? (
                        <LastMovementsGrid
                            isDesktop={isDesktop}
                            getStrDate={getStrDate}
                            getMovementsNextPage={getMovementsNextPage}
                        />
                    ) : (
                        <PendingsMovementsGrid
                            isDesktop={isDesktop}
                            getStrDate={getStrDate}
                            getMovementsNextPage={getMovementsNextPage}
                        />
                    )}
                </>
            )}
        </PageLoading>
    );
}

MovementsV2.propTypes = {
    isDesktop: bool.isRequired,
    dispatch: func.isRequired,
    selectedAccount: shape({}).isRequired,
    isFetchingMovements: bool.isRequired,
    setFieldValue: func.isRequired,
    values: shape({}).isRequired,
    resetForm: func.isRequired,
    fetchingDownload: bool.isRequired,
};

const mapStateToProps = (state) => ({
    selectedAccount: accountsSelectors.getSelectedAccount(state),
    isFetchingMovements: accountsSelectors.getFetchingMovements(state),
    pendingMovementsTotalPages: accountsSelectors.getPendingMovementsTotalPages(state),
    isLatestMovementsSelected: accountsSelectors.isLatestMovementsSelected(state),
    fetchingDownload: accountsSelectors.getFetchingDownload(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            dateFrom: null,
            dateTo: null,
            lastDateFrom: null,
            lastDateTo: null,
            pendingDateFrom: null,
            pendingDateTo: null,
            findBy: "period",
            amountFrom: null,
            amountTo: null,
            voucher: null,
            detail: null,
        }),
        validationSchema: () =>
            Yup.lazy((values) => {
                const enableRangeDatefield = configUtils.getBoolean(
                    "frontend.show.RangeDatePicker.functionalities",
                    false,
                );
                return Yup.object().shape({
                    ...(enableRangeDatefield
                        ? {
                              lastDates: Yup.mixed().test(
                                  "lastDates",
                                  i18nUtils.get(`${FORM_ID}.validation.range.date`),
                                  ([from, to]) => from && to,
                              ),
                              pendingDates: Yup.mixed().test(
                                  "pendingDates",
                                  i18nUtils.get(`${FORM_ID}.validation.range.date`),
                                  ([from, to]) => from && to,
                              ),
                          }
                        : {
                              dateFrom: values.dateTo
                                  ? Yup.date()
                                        .nullable()
                                        .max(values.dateTo, i18nUtils.get(`${FORM_ID}.dateFrom.error`))
                                  : Yup.date().nullable(),
                              dateTo: values.dateFrom
                                  ? Yup.date()
                                        .nullable()
                                        .min(values.dateFrom, i18nUtils.get(`${FORM_ID}.dateTo.error`))
                                  : Yup.date().nullable(),
                          }),
                    voucher: values.voucher
                        ? Yup.number()
                              .typeError(i18nUtils.get("accounts.movements.integer.positive"))
                              .nullable()
                              .positive(i18nUtils.get("accounts.movements.integer.positive"))
                              .integer(i18nUtils.get("accounts.movements.integer.invalidValue"))
                        : Yup.number().nullable(),
                });
            }),
        handleSubmit: (
            {
                findBy,
                lastDateFrom,
                lastDateTo,
                lastDates,
                pendingDateFrom,
                pendingDateTo,
                pendingDates,
                amountFrom,
                amountTo,
                voucher,
                detail,
                longDetail,
                movementType,
            },
            formikBag,
        ) => {
            const { dispatch, selectedAccount, isLatestMovementsSelected } = formikBag.props;

            const type = movementType > 1 ? null : movementType;

            dispatch(
                accountsActions.fetchLatestMovements({
                    selectedAccount,
                    latestMovementsPageNumber: "1",
                    pendingMovementsPageNumber: "1",
                    findBy,
                    lastDateFrom: lastDates ? lastDates[0] : lastDateFrom,
                    lastDateTo: lastDates ? lastDates[1] : lastDateTo,
                    pendingDateFrom: pendingDates ? pendingDates[0] : pendingDateFrom,
                    pendingDateTo: pendingDates ? pendingDates[1] : pendingDateTo,
                    amountFrom,
                    amountTo,
                    voucher,
                    detail,
                    type,
                    isLatestMovementsSelected,
                    longDetail,
                }),
            );
        },
    }),
)(MovementsV2);
