export const allStatus = [
    { value: "ELIMINADO", label: "Eliminado" },
    { value: "ACREDITADO", label: "Acreditado" },
    { value: "INICIADO", label: "Iniciado" },
    { value: "VENCIDO", label: "Vencido" },
    { value: "RECHAZO DE CLIENTE", label: "Rechazado" },
    { value: "SIN GARANTIA", label: "Sin garantia" },
    { value: "SIN SALDO", label: "Sin saldo" },
    { value: "ERROR", label: "Error" },
    { value: "ERROR DEBITO", label: "Error débito" },
    { value: "ERROR ACREDITACION", label: "Error acreditación" },
    { value: "ERROR DATOS", label: "Error datos" },
];

export const statusTagColor = (state) => {
    switch (state) {
        case "ACREDITADO":
            return "#84efd0";
        case "CANCELADO":
            return "#feb0af";
        case "ELIMINADO":
            return "#feb0af";
        case "ERROR":
            return "#feb0af";
        case "ERROR ACREDITACION":
            return "#feb0af";
        case "ERROR DATOS":
            return "#feb0af";
        case "ERROR DEBITO":
            return "#feb0af";
        case "INICIADO":
            return "#ffe5b6";
        case "RECHAZADO":
            return "#feb0af";
        case "RECHAZO DE CLIENTE":
            return "#feb0af";
        case "VENCIDO":
            return "#feb0af";
        default:
            return "#feb0af";
    }
};

export const recurrencyDebinStatusActions = {
    ACTIVA: ["INACTIVAR", "BLOQUEAR"],
    INACTIVA: ["ACTIVAR", "BLOQUEAR"],
};

export const recurrencyDebinStatusActionsV3 = {
    ACTIVA: ["DESACTIVAR"],
    INACTIVA: ["ACTIVAR"],
    PENDIENTE: ["AUTORIZAR", "RECHAZAR"],
    RECHAZADO: ["AUTORIZAR"],
};

export const statusTagColorRecurrency = (state) => {
    switch (state) {
        case "ACTIVA":
            return "#84efd0";
        case "INACTIVA":
            return "#ffe5b6";
        default:
            return "#feb0af";
    }
};

export const statusTagColorRecurrencyV3 = (state) => {
    switch (state) {
        case "ACTIVA":
            return "#84EFD0";
        case "INACTIVA":
            return "#DDDDDD";
        case "RECHAZADO":
            return "#FEB0AF";
        case "PENDIENTE":
            return "#FFE4B6";
        default:
            return "#feb0af";
    }
};

export const documentTypeFormats = (documentType, document) => {
    const isnum = /^\d+$/.test(document);
    if (documentType === "DNI") {
        if (document.length < 6 || document.length > 8 || !isnum) {
            return false;
        }
    }

    if (documentType === "CUIT" || documentType === "CUIL" || documentType === "CDI") {
        if (document.length !== 11 || !isnum) {
            return false;
        }
    }
    return true;
};
